<div class="key-details-container">
  <div class="status-container" fxFlex="100" class="p-4">
    <div class="d-flex justify-content-between align-items-center pb-3" *ngIf="dataModel.data.is_admin">
      <div class="status-text">
        <span class="fw-bold">Charge Validation</span>
      </div>
      <div class="status-value">
        <span class="fw-bold status-pill" [ngClass]="getStatusColor(ChargeValidation)">{{ChargeValidation}}</span>
      </div>
    </div>
   <div *ngIf="dataModel.showButtons" >
    <div class="d-flex justify-content-between align-items-center pb-3" *ngIf="dataModel.data.is_admin ">
      <div class="status-text">
        <span class="fw-bold">Invoice Status</span>
      </div>
      <div class="status-value">
        <span class="fw-bold status-pill" [ngClass]="getStatusColor(InvoiceStatus)">{{InvoiceStatus}}</span>
      </div>
    </div>
    <!-- <div class="d-flex justify-content-between align-items-center pb-3">
      <div class="status-text">
        <span class="fw-bold">Matched Status</span>
      </div>
      <div class="status-value">
        <span class="fw-bold"
          [ngClass]="{'completed':MatchStatus=='Matched','in_progress':MatchStatus=='Not Matched'}">{{MatchStatus}}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center pb-3">
      <div class="status-text">
        <span class="fw-bold">Accountability Status</span>
      </div>
      <div class="status-value">
        <span class="fw-bold"
          [ngClass]="{'open':AccountabilityStatus=='Open','cancelled':AccountabilityStatus=='Not Open'}">{{AccountabilityStatus}}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center pb-3">
      <div class="status-text">
        <span class="fw-bold">Reconcillation Status</span>
      </div>
      <div class="status-value">
        <span class="fw-bold"
          [ngClass]="{'open':ReconcillationStatus=='Open','cancelled':ReconcillationStatus=='Not Open'}">{{ReconcillationStatus}}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center pb-3">
      <div class="status-text">
        <span class="fw-bold">Payment Status</span>
      </div>
      <div class="status-value">
        <span class="fw-bold"
          [ngClass]="{'open':PaymentStatus=='Open','cancelled':PaymentStatus=='Not Open'}">{{PaymentStatus}}</span>
      </div>
    </div> -->

    <div class="d-flex justify-content-between align-items-center pb-3"
    *ngIf="dataModel.data.is_admin">
      <div class="status-text">
        <span class="fw-bold">Approval Status</span>
      </div>
      <div class="status-value">
        <span class="fw-bold status-pill"
        [ngClass]="getStatusColor(InvoiceDOAStatus)">{{InvoiceDOAStatus}}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center pb-3">
      <div class="status-text">
        <span class="fw-bold">Liability Status</span>
      </div>
      <div class="status-value">
        <span class="fw-bold status-pill"
        [ngClass]="getStatusColor(LiabilityStatus)">{{LiabilityStatus}}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center pb-3" *ngIf="dataModel.data.is_admin ">
      <div class="status-text">
        <span class="fw-bold">Payment Status</span>
      </div>
      <div class="status-value">
        <span class="fw-bold status-pill"
        [ngClass]="getStatusColor(PaymentStatus)">{{PaymentStatus}}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center pb-3" *ngIf="dataModel.data.is_admin ">
      <div class="status-text">
        <span class="fw-bold">SAP Status</span>
      </div>
      <div class="status-value">
        <span class="fw-bold status-pill"
        [ngClass]="getStatusColor(SAPStatus)">{{SAPStatus}}</span>
      </div>
    </div>
   </div>

  </div>
  <ng-container *ngIf="dataModel.data.is_admin">
    <hr>
    <div class="stepper-container d-flex justify-content-center" xFlex="100">
      <app-pcp-stepper-cost [dataModel]="stepperCostDataModel" [configModel]="stepperCostConfigModel">
      </app-pcp-stepper-cost>
    </div>
  </ng-container>
  <hr>
  <div class="permit-details-container p-4">
    <div class="d-flex justify-content-between align-items-center">
      <div class="info-element" fxFlex="50" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Permit Type</label>
        <p>{{dataModel.data.permitType}}</p>
      </div>

      <div class="info-element" fxFlex="25" *ngIf="showPermitBtn">
        <label class="view-permit-label" for="">View Permit</label>
        <button mat mat-stroked-button (click)="onViewPermitClick()"><span
            class="material-icons icon-style">link</span></button>
      </div>
    </div>

    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px"
      fxLayoutAlign="flex-start">
      <div class="info-element" fxFlex="50" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Proposed Start Date/Time </label>
        <p>{{ dataModel.data.proposedStartDateTime | transformDateTime }}</p>
      </div>
      <!-- <div class="info-element" fxFlex="50" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Proposed End Date/Time </label>
        <p>{{ dataModel.data.proposedEndDateTime | transformDateTime }}</p>
      </div> -->
      <div class="info-element" fxFlex="50" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Reasonable End Date/Time </label>
        <p>{{ dataModel.data.reasonablePeriodEndDate | transformDateTime }}</p>
      </div>

    </div>

    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px"
      fxLayoutAlign="flex-start">
      <!-- <div class="info-element" fxFlex="50" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Proposed Duration</label>
        <p>{{ dataModel.data.proposedDuration }}</p>
      </div>
      <div class="info-element" fxFlex="50" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Expected End Date/Time </label>
        <p>{{ dataModel.data.expectedEndDateTime | transformDateTime }}</p>
      </div> -->
      <div class="info-element" fxFlex="75" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Assesment Status and Comments</label>
        <p>{{ dataModel.data.assesmentStatus | titlecase }}</p>
      </div>

    </div>

    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px"
      fxLayoutAlign="flex-start">
      <div class="info-element" fxFlex="50" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Actual Start Date/Time </label>
        <p>{{ dataModel.data.actualStartDateTime | transformDateTime }}</p>
      </div>
      <div class="info-element" fxFlex="50" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Actual End Date/Time </label>
        <p>{{ dataModel.data.actualEndDateTime | transformDateTime }}</p>
      </div>

    </div>

    <div *ngIf="chargeBtn" class="button-container">
      <div *ngFor="let item of dataModel.chargesButtonModel">
        <button mat mat-raised-button style="width: 100%;" color="primary"
          (click)="eventEmmiter(item.event)">{{item.label}}</button>

      </div>
    </div>

    <!-- <div class="detail-container my-3" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="10px"
      fxLayoutAlign="flex-start">
      <button mat-raised-button color="primary" fxFlex="50" (click)="onApproveChargeClick()">Approve Charge</button>

      <button mat-raised-button color="primary" fxFlex="50" (click)="onRequestApprovalClick()">Request Approval</button>
    </div>

    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="10px"
      fxLayoutAlign="flex-start">
      <button mat-raised-button color="primary" fxFlex="50" (click)="onUpdateChargeClick()">Update Charge Status</button>

      <button mat-raised-button color="primary" fxFlex="50" (click)="onAuditChargeClick()">Audit Charge</button>
    </div> -->
  </div>
</div>
