import {
  ChargeActivityConfigModel,
  ChargeActivityDataModel,
  ChargeActivityRequestModel,
  ChargeActivityRow,
} from './charge-activity-tab.model';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { FormControl } from '@angular/forms';
import { GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';

@Component({
  selector: 'app-charge-activity-log-tab',
  templateUrl: './charge-activity-log-tab.component.html',
  styleUrls: ['./charge-activity-log-tab.component.css'],
})
export class ChargeActivityLogTabComponent extends WidgetComponentBase {
  @Input() dataModel: ChargeActivityDataModel;
  @Input() configModel: ChargeActivityConfigModel;

  dataSource = new MatTableDataSource<ChargeActivityRow>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  searchInput = new FormControl('');
  pageChangeSubscription: any;
  _ChargeActivityRequestModel: ChargeActivityRequestModel;
  length: any;
  MAT_HELPER = MatTableHelper;
  DATA_HELPER = DataHelper;

  expandedElement: ChargeActivityRow;
  columnsToDisplay = ['Activity', 'InitiatedBy', 'Date'];

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _router: Router,
    public dialog: MatDialog,
    private _notificationService: NotificationService,
    private _validationService: ValidationService
  ) {
    super(_serverApi, _validationService);
    this._ChargeActivityRequestModel = new ChargeActivityRequestModel();
    this.searchInput.valueChanges.subscribe(this.onSearchValueChange);
  }
  getControlData() {
    //let id = this.dataModel.globalParameters.get('applicationId');
    let data = this.dataModel.globalParameters.get(
      GLOBAL_PARAM_KEY.VIEW_PERMIT_FORM
    );
    let url = '/api/v1/cpm/activity-log/';
    let chargeNumber = this.dataModel.globalParameters.get('chargeNumber');
    this._ChargeActivityRequestModel.objLogReferenceNumber = chargeNumber;
    this._ChargeActivityRequestModel.referenceNumber =
      'OBJECT_REFERENCE_NUMBER';
    // `/api/v1/applications/${id}/comments`
    return this.wgAPIMethodGet(url, this._ChargeActivityRequestModel);
  }
  convertData(response: any) {
    if (response) {
      console.log('api response activity tab', response);
      let items: ChargeActivityRow[] = [];
      response.content.forEach((element) => {
        let item: any = {};

        console.log(element);
        // item.Comment = element.activity_topic;
        item.Comment = element.log_details;
        item.CommentType = element.activity_type;
        item.CommentBy = element.activity_updated_by;
        item.Date = element.activity_updated_date;
        // item.CommentId = element.comment_id;
        // item.CommentRead = element.comment_read;
        // item.CommentReadBy = element.comment_read_by;
        // item.CommentReadDate = element.comment_read_date;
        // item.CommentReference = element.comment_reference;
        // item.CommentSubmit = element.comment_submit;
        // item.CommentTopic = element.comment_topic;
        // item.UserDisplayName = element.user_display_name;
        // item.CommentOrigin = element.comment_origin;
        items.push(item);
      });
      this.dataSource = new MatTableDataSource(items);
      this.length = response.totalElements; ///TO Do get lenght from API
    }
  }
  setFieldData() {}

  setMode(responseDataModel: any) {}

  setValue(responseDataModel: any) {}

  applyFilter(filterValue: string) {}

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.pageChangeSubscription = this.paginator.page.subscribe(() => {
      this._ChargeActivityRequestModel.pageno = this.paginator.pageIndex;
      this._ChargeActivityRequestModel.top = this.paginator.pageSize;
      this._ChargeActivityRequestModel.skip =
        this._ChargeActivityRequestModel.pageno *
        this._ChargeActivityRequestModel.top;
      //  TODO: will update this
      this.wgRefreshData();
    });

    // TODO: attach local handlers
    this.wgOnInit();
    // console.log('onInit', this.dataSource);
  }
  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.dataModel.apireqdata['pageno'] = this.paginator.pageIndex;
    this.dataModel.apireqdata.skip = 0;
    this.wgRefreshData();
    this.onTabChanged(null);
  }
  openDialog(rowData) {
    this.emitEvent('onViewChargeActivity', rowData);
  }

  onAddComment() {
    this.emitEvent('onAddComment');
  }

  search() {
    let searchString = this.searchInput.value;
    this._ChargeActivityRequestModel.searchString = searchString;
    this.wgRefreshData()
  }

  ngOnDestroy(): void {
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
  }
  onTabChanged(event) {
    let clearSearch = document.getElementsByClassName('clear-search');
    for (let i = 0; i < clearSearch.length; i++) {
      const slide = clearSearch[i] as HTMLElement;
      slide.style.visibility = 'hidden';
    }
  }

  clearSearch() {
    this.searchInput.reset();
    this._ChargeActivityRequestModel = new ChargeActivityRequestModel()
    this.wgRefreshData()
  }

  onSearchValueChange(searchVal) {
    let clearSearch = document.getElementsByClassName('clear-search');
    let slide: any;
    for (let i = 0; i < clearSearch.length; i++) {
      slide = clearSearch[i] as HTMLElement;
    }
    if (searchVal) {
      slide.style.visibility = 'visible';
    } else {
      slide.style.visibility = 'hidden';
    }
  }
}

const ELEMENT_DATA1: ChargeActivityRow[] = [
  {
    Comment: '',
    CommentType: '',
    CommentBy: '',
    CommentId: '',
    Date: '',
    CommentRead: null,
    CommentReadBy: '',
    CommentReadDate: '',
    CommentReference: '',
    CommentSubmit: null,
    CommentTopic: '',
    UserDisplayName: '',
    CommentOrigin: '',
  },
];
