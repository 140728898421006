<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">

      <div class="fpn-list custom-table">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

          <!-- Position Column -->
          <ng-container matColumnDef="FPNNumber">
            <th mat-header-cell *matHeaderCellDef>FPN Number</th>
            <td mat-cell *matCellDef="let element" style="padding-left: 20px !important;"> {{element.FPNNumber}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="WorksReferenceNumber">
            <th mat-header-cell *matHeaderCellDef>Works Ref. Number</th>
            <td mat-cell *matCellDef="let element"> {{element.WorksReferenceNumber}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="FPNStatus">
            <th mat-header-cell *matHeaderCellDef>FPN Status</th>
            <td mat-cell *matCellDef="let element"> {{element.FPNStatus}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="Offencecode">
            <th mat-header-cell *matHeaderCellDef>Offence code</th>
            <td mat-cell *matCellDef="let element"> {{element.Offencecode}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="OffenceDate">
            <th mat-header-cell *matHeaderCellDef>Offence Date</th>
            <td mat-cell *matCellDef="let element"> {{element.OffenceDate | transformDate}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="OffenceDetails">
            <th mat-header-cell *matHeaderCellDef>Offence Details</th>
            <td style="max-width: 300px;" mat-cell *matCellDef="let element"> {{element.OffenceDetails}} </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Actions </th>
            <td mat-cell *matCellDef="let row">
              <div fxLayout="row" fxLayoutAlign="center center">
                <button (click)="openDialog(row)" mat-icon-button matTooltip="Click to view" class="iconbutton"
                  color="primary">
                  <mat-icon aria-label="Edit">visibility</mat-icon>
                </button>
              </div>

            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>

    </div>
  </div>
</div>
