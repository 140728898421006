import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  WriteOffChargeFormConfigModel,
  WriteOffChargeFormModel,
} from './write-off-form.model';
import { MatDialog } from '@angular/material/dialog';
import { CHARGE_FORMS_NAME, GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { PERMIT_ACTIONS_CONFIG } from 'src/app/constants/db.constants';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { SaveFileService } from 'src/app/services/save-file.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';

@Component({
  selector: 'app-write-off-form',
  templateUrl: './write-off-form.component.html',
  styleUrls: ['./write-off-form.component.css'],
})
export class WriteOffFormComponent
  extends WidgetComponentBase
  implements OnInit
{
  @Input() dataModel: WriteOffChargeFormModel;
  @Input() configModel: WriteOffChargeFormConfigModel;

  WriteOffChargeForm: FormGroup;
  notesMessage: string = 'Max 500 characters.';

  arrLiabilityOrg: any[] = [];
  arrLiabilityOwner: any[] = [];

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _validationService: ValidationService,
    private _saveFileService: SaveFileService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit(): void {
    super.wgOnInit();

    this.createFormGroup();
  }
  OnDestroy() {
    this.WriteOffChargeForm.reset();
  }
  createFormGroup(): void {
    this.WriteOffChargeForm = this.fb.group({
      // writeOffAmount: ['', [Validators.required]],
      fileUp: [''],
      notes: ['', [Validators.maxLength(500)]],
    });
  }
  setFieldData() {}
  stopPropagation(event) {
    event.stopPropagation();
  }
  setMode(responseDataModel: any) {}
  setValue(responseDataModel: any) {}
  getControlData() {
    // let id = this.dataModel.globalParameters.get('applicationId');
    // // INFO: ServerAPI
    // let url = `/api/v1/applications/${id}`
    // return this.wgAPIMethodGet(url, null);
    // return new Promise((resolve, reject) => {
    //   let permitform: any = this.dataModel.globalParameters.get(
    //     GLOBAL_PARAM_KEY.VIEW_PERMIT_FORM
    //   );
    //   permitform = permitform ? permitform : new Object();
    //   if (permitform) {
    //     resolve(permitform);
    //   } else {
    //     reject(permitform);
    //   }
    // });
    let url = `/api/v1/cpm/actions/getDetails`;
    return this.wgAPIMethodGet(url, this.dataModel.apireqdata);
  }
  onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file) {
      const formData = new FormData();

      formData.append('thumbnail', file);

      console.log(file);
    }
  }
  convertData(response: any) {
    if (!response) return;

    console.log('response of Written off: ', response);

    this.dataModel.data.permitType = response.permit_type;
    this.dataModel.data.chargeDesc = response.charge_description;
    this.dataModel.data.chargeNumber = response.charge_number;

    this.dataModel.data.chargeAmount = response.charge_amount;

    this.dataModel.data.chargeStatus = response.charge_status;

    this.dataModel.data.chargeType = response.charge_type;

    this.dataModel.data.chargeId = response.charge_id;
  }
  getValue() {
    // let data: any;

    // data = this.dataModel.data;
    if (this.WriteOffChargeForm.valid) {
      return this.WriteOffChargeForm.value;
    }
  }

  resetForm() {
    this.WriteOffChargeForm.reset();
  }
  openFileUpload() {
    let data: any = {};
    data.reference = this.dataModel.data.chargeNumber;
    data.objectType = CHARGE_FORMS_NAME.WRITE_OFF_CHARGE;
    console.log('Event Emiited');

    this.emitEvent('openFileUploadWriteOffDialog', data);
  }

  onNotesChange(value) {
    if (value.length > 0) {
      let charRemaining = 500 - value.length;
      this.notesMessage = `Remaining characters ${charRemaining}.`;
    } else {
      this.notesMessage = 'Max 500 characters.';
    }
  }
  onSubmit() {
    let data: any = {};
    this.WriteOffChargeForm.markAllAsTouched();
    if (this.WriteOffChargeForm.valid) {
      data = {
        charge_id: this.dataModel.data.chargeId,
        charge_number: this.dataModel.data.chargeNumber,
        charge_type: this.dataModel.data.chargeType,
        // negotiatedAmount:
        //   this.WriteOffChargeForm.get('negotiatedAmount').value,
        notes_text: this.WriteOffChargeForm.get('notes').value,
      };
      return data;
    }
  }
}
