<div class="permit-details">
  <!--     <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px"
        fxLayoutAlign="flex-start">
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Invoice Reference Number</label>
            <p>{{ dataModel.data.fpnReferenceNumber }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Permit Reference Number</label>
            <p>{{ dataModel.data.permitRefNumber }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">FPN Offence Code</label>
            <p>{{ dataModel.data.offenceCode }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Assigned By</label>
            <p>{{ dataModel.data.assignedBy }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Assigned To</label>
            <p>{{ dataModel.data.assignedTo }}</p>
        </div>
    </div>
    <hr /> -->

  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <!--         <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Draft Invoice Reference Number</label>
            <p>{{ dataModel.data.draftInvoiceRefNumber }}</p>
        </div> -->
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Charge Number</label>
      <p>{{ dataModel.data.chargeNumber }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Permit Reference Number</label>
      <p>{{ dataModel.data.permitRefNumber }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Project Reference Number</label>
      <p>{{ dataModel.data.projectReferenceNumber }}</p>
    </div>

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="" *ngIf="dataModel.data.chargeType == 'Defect' ">Assigned By</label>
      <p *ngIf="dataModel.data.chargeType == 'Defect' ">{{ dataModel.data.assignedBy }}</p>
    </div>

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)"
      *ngIf="dataModel.data.chargeType == 'Defect' ">
      <label for="">Assigned To</label>
      <p>{{ dataModel.data.assignedTo }}</p>
    </div>
    <!--        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Assigned By</label>
            <p>{{ dataModel.data.assignedBy }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Assigned To</label>
            <p>{{ dataModel.data.assignedTo }}</p>
        </div> -->
  </div>
  <hr />

  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Works Location Description</label>
      <p>
        {{ showMoreWork ? (dataModel.data.worksLocationDescription | slice : 0 : 35) :
        dataModel.data.worksLocationDescription }}
        <span class="more-text" (click)="showMoreWorkText()">{{
          getWorkText()
          }}</span>
      </p>
    </div>
    <!-- <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">USRN Details</label>
            <p>{{ dataModel.data.usrnDetails }}</p>
        </div> -->
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Charge type</label>
      <p>{{ dataModel.data.chargeType }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Activity Type</label>
      <p>{{ dataModel.data.activityType }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Inspection Type</label>
      <p>{{ dataModel.data.inspectionType }}</p>
    </div>
    <!--         <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Charge Sub type</label>
            <p>{{ dataModel.data.charge_sub_type }}</p>
        </div> -->
    <!--         <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">FPN Offence Details</label>
            <div>
                <p>
                    {{ showMore ? (dataModel.data.fpnOffenceDetails | slice : 0 : 35) : dataModel.data.fpnOffenceDetails
                    }}
                    <span class="more-text" (click)="showMoreText()">{{
                        getText()
                        }}</span>
                </p>
            </div>
        </div> -->
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Charge Details</label>
      <p>
        {{ showMoreChargeDetails ? (dataModel.data.chargeDetails | slice : 0 : 35) :
        dataModel.data.chargeDetails }}
        <span class="more-text" (click)="showMoreChargeText()">{{
          getChargeText()
          }}</span>
      </p>
    </div>

    <!--         <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Root Cause</label>
            <p>{{ dataModel.data.root_cause }}</p>
        </div> -->

    <!--         <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Root Cause Additional Information</label>
            <p>{{ dataModel.data.rootCauseDetails }}</p>
        </div> -->
  </div>
  <hr />
  <!-- <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px"
        fxLayoutAlign="flex-start">
               <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Root Cause</label>
            <p>{{ dataModel.data.root_cause }}</p>
        </div>

                <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Root Cause Additional Information</label>
            <p>{{ dataModel.data.rootCauseDetails }}</p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="" *ngIf="dataModel.data.is_admin">Reason Invalid</label>
            <p *ngIf="dataModel.data.is_admin">{{ dataModel.data.reason_invalid }}</p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Assigned By</label>
            <p>{{ dataModel.data.assignedBy }}</p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Assigned To</label>
            <p>{{ dataModel.data.assignedTo }}</p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Road Category</label>
            <p>{{ dataModel.data.roadCategoryType1 }}</p>
        </div>


    </div>
    <hr /> -->

  <!--     <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start"
        *ngIf="dataModel.filterValue == 'MANUAL_SECTION_74'">

        <div *ngIf="dataModel.data.chargeType !== 'Section 74 [Manual]'" class="info-element" fxFlex="25"
            fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Status</label>
            <p>{{ dataModel.data.ha_status }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)"
            *ngIf="dataModel.filterValue != 'MANUAL_SECTION_74'">
            <label for="">Promoter Status</label>
            <p>{{ dataModel.data.promoter_status }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Overrun Warning Reasons</label>
            <p>
                {{ showMoreOverrunWarningReasons ? (dataModel.data.overrun_warning_reasons | slice : 0 : 35) :
                dataModel.data.overrun_warning_reasons }}
                <span class="more-text" (click)="showMoreOverrunWarningText()">{{
                    getOverrunWarningText()
                    }}</span>
            </p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Overrun Warning Details</label>
            <p>
                {{ showMoreOverrunWarningDetails ? (dataModel.data.overrun_warning_details | slice : 0 : 35) :
                dataModel.data.overrun_warning_details }}
                <span class="more-text" (click)="showMoreOverrunText()">{{
                    getOverrunText()
                    }}</span>
            </p>
        </div>

    </div> -->

  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Highest Traffic Management</label>
      <p>
        {{
        dataModel.data.trafficManagementType
        }}
      </p>
    </div>

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Highway Authority</label>
      <p>{{ dataModel.data.highwayAuthority }}</p>
    </div>

    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="dataModel.data.is_admin">
      <label for="">Liability Organisation</label>
      <p>{{ dataModel.data.liabilityOrganisation }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Liability Assigned By</label>
      <p>{{ dataModel.data.liabAssignedBy }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Liability Status</label>
      <p>
        {{ dataModel.data.date_of_invoice }}
      </p>
    </div>

  </div>
  <hr />

  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="" *ngIf="dataModel.data.is_admin">Reason Invalid</label>
      <p *ngIf="dataModel.data.is_admin">{{ dataModel.data.reason_invalid }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Inspection Category</label>
      <p>{{ dataModel.data.inspectionCategory }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Inspection Outcome</label>
      <p>{{ dataModel.data.inspectionOutcome }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Failure Reasons</label>
      <p>{{ dataModel.data.failureReasons }}</p>
    </div>

  </div>

  <hr />

  <!-- <div
  class="detail-container"
  fxLayout="row"
  fxLayout.lt-sm="row wrap"
  fxLayoutGap="32px"
  fxLayoutAlign="flex-start"
  *ngIf="
    (dataModel.filterValue == 'MISCELLANEOUS' ||
      dataModel.filterValue == 'SECTION_74') &&
    dataModel.data.is_admin
  "
>
  <div
    class="info-element"
    fxFlex="33.33"
    fxFlex.lt-sm="0 1 calc(50% - 32px)"
    *ngIf="
      dataModel.filterValue == 'MISCELLANEOUS' ||
      dataModel.filterValue == 'SECTION_74'
    "
  >
    <label for="">DOA Assigned By</label>
    <p>{{ dataModel.data.doa_approved_by }}</p>
  </div>
  <div
    class="info-element"
    fxFlex="33.33"
    fxFlex.lt-sm="0 1 calc(50% - 32px)"
    *ngIf="
      dataModel.filterValue == 'MISCELLANEOUS' ||
      dataModel.filterValue == 'SECTION_74'
    "
  >
    <label for="">DOA Approver</label>
    <p>{{ dataModel.data.doa_approved_to }}</p>
  </div>
  <div
    class="info-element"
    fxFlex="33.33"
    fxFlex.lt-sm="0 1 calc(50% - 32px)"
  >
    <label for="">Approved By</label>
    <p>{{ dataModel.data.approved_by }}</p>
  </div>
  <div
    class="info-element"
    fxFlex="33.33"
    fxFlex.lt-sm="0 1 calc(50% - 32px)"
  >
    <label for="">Approved on behalf</label>
    <p>{{ dataModel.data.approved_on_behalf }}</p>
  </div>
</div>
<hr
  *ngIf="
    (dataModel.filterValue == 'MISCELLANEOUS' ||
      dataModel.filterValue == 'SECTION_74') &&
    dataModel.data.is_admin
  "
/> -->
  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Approved By</label>
      <p>{{ dataModel.data.approved_by }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Approved on behalf of</label>
      <p>{{ dataModel.data.approved_on_behalf }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Date of Recharge Agreed</label>
      <p>
        {{ dataModel.data.date_of_recharge_agreed }}
      </p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <!-- <label for="">Network</label>
            <p>{{ dataModel.data.network }}</p> -->
    </div>

  </div>
  <hr />

  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <!-- <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">COAM</label>
            <p>{{ dataModel.data.coam }}</p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Workstream</label>
            <p>{{ dataModel.data.work_stream }}</p>
        </div> -->

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Granted Date</label>
      <p>{{ dataModel.data.grantedDate | transformDate }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Response Due Date</label>
      <p>{{ dataModel.data.response_due_date | transformDate }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <!-- <label for="">Date Final Invoice Paid</label>
            <p>{{ dataModel.data.finalInvoicePaidDate | transformDate }}</p> -->
    </div>

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <!-- <label for="">Issued Date</label>
            <p>{{ dataModel.data.issuedDate | transformDate }}</p> -->
    </div>

  </div>
  <hr />

  <!-- <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="dataModel.filterValue != 'MANUAL_SECTION_74'">
            <label for="">Date Draft Invoice Received</label>
            <p>{{ dataModel.data.draftInvoiceReceivedDate | transformDate }}</p>
        </div>


        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="dataModel.filterValue != 'MANUAL_SECTION_74'">
            <label for="">Date Final Invoice Received</label>
            <p>{{ dataModel.data.finalInvoiceReceivedDate | transformDate }}</p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="dataModel.filterValue != 'MANUAL_SECTION_74'">
            <label for="">Date Final Invoice Paid</label>
            <p>{{ dataModel.data.finalInvoicePaidDate | transformDate }}</p>
        </div>
    </div>

    <hr *ngIf="dataModel.filterValue != 'MANUAL_SECTION_74'"/> -->
  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">COAM</label>
      <p>{{ dataModel.data.coam }}</p>
    </div>

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Workstream</label>
      <p>{{ dataModel.data.work_stream }}</p>
    </div>

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Work Type</label>
      <p>{{ dataModel.data.workType }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Spend Type</label>
      <p>{{ dataModel.data.spendType }}</p>
    </div>

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Cost Code</label>
      <p>{{ dataModel.data.costcode }}</p>
    </div>

  </div>

  <hr />
  <!--     <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Cost Code</label>
            <p>{{ dataModel.data.costcode }}</p>
        </div>

    </div>

    <hr /> -->
  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start"
    *ngIf="dataModel.data.is_admin">

    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">BACs Reference</label>
      <p> {{ this.dataModel.data.bacs_ref }} </p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">SAP Payment Date</label>
      <p>{{ dataModel.data.paymentDate | transformDate }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Upload Failure Reason</label>
      <p>{{ dataModel.data.upload_failure_reason }}</p>
    </div>

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <!-- <label for="">Project Reference Number</label>
   <p>{{ dataModel.data.projectReferenceNumber }}</p> -->
    </div>

    <!-- <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Activity Type</label>
   <p>{{ dataModel.data.activityType }}</p>
  </div> -->


  </div>
  <hr />
</div>
