import { Component, Input, OnInit } from '@angular/core';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { AttachmentsListDataModel, AttachmentsListConfigModel } from './attachment-list';

@Component({
  selector: 'app-attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.css']
})
export class AttachmentListComponent extends WidgetComponentBase implements OnInit {
  @Input() dataModel: AttachmentsListDataModel;
  @Input() configModel: AttachmentsListConfigModel;

  panelOpenState: boolean[] = [];
  attachmentList: any[] = []

  constructor(
    private _serverApiBase: ServerApiInterfaceServiceService,
    private _validationService: ValidationService
  ) {
    super(_serverApiBase, _validationService);
  }

  ngOnInit(): void {
    for (let i = 0; i < this.dataModel.data.length; i++) {
      // this.panels.push( this.dataModel.data[i] );
      if (i == 0) this.panelOpenState.push(true);
      else {
        this.panelOpenState.push(false);
      }
    }
    this.wgOnInit();
  }

  ngAfterViewInit() {
    if(this.dataModel.isSelfDataLoad == false){
      // this.convertData(this.dataModel.content)
    }
  }

  setFieldData() {}

  getControlData() {
    return this.wgAPIMethodGet(this.dataModel.WidgetApi, this.dataModel.apireqdata);
  }

  convertData(response: any) {
    if(response) {
      this.attachmentList = []
      let tempObj: any = {}
      response.content.forEach(ele => {
        tempObj.file_name = ele.file_name;
        tempObj.imageSrc = this.getExtension(ele.file_extension);
        tempObj.file_uuid = ele.file_uuid;
        tempObj.create_date = ele.create_date;
        tempObj.created_by = ele.created_by;
        tempObj.owner_reference = ele.owner_reference;
        this.attachmentList.push(tempObj)
        tempObj = {};
      });
      console.log('Attachment', this.attachmentList)
      this.dataModel.attachmentList = this.attachmentList
      this.dataModel.data.forEach(ele => {
        ele.count = this.attachmentList.length
      })
    }
  }

  setMode(responseDataModel: any) {}

  setValue(responseDataModel: any) {}

  resetExpands() {
    this.panelOpenState = [];
    for (let i = 0; i < this.dataModel.data.length; i++) {
      // this.panels.push( this.dataModel.data[i] );
      if (i == 0) this.panelOpenState.push(true);
      else {
        this.panelOpenState.push(false);
      }
    }
  }
  openPanelHandler(index: number) {
    this.panelOpenState = [];
    for (let i = 0; i < this.dataModel.data.length; i++) {
      this.panelOpenState.push(false);
    }
    this.panelOpenState[index] = true;
  }

  onDownloadFile(data) {
    console.log(data)
    this.emitEvent('onClickDownloadFile', data)
  }

  onDeleteFile(data) {
    this.emitEvent('onClickDeleteFile', data)
  }

  getExtension(fileType) {
    switch (fileType) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        return 'assets/images/jpeg-file.png';
      case 'xls':
      case 'xlsx':
        return 'assets/images/xlsx-file.png';
      case 'pdf':
        return 'assets/images/pdf-file.png';
      case 'docx':
      case 'doc':
        return 'assets/images/doc-file.png';
      case 'csv':
        return 'assets/images/csv-file.png';
      default:
        return;
    }
  }

}
