<!-- <div fxFlex="100" fxLayoutAlign="center center">
  <div fxFlex="98"> -->
<ul class="list-inline">
    <li class="list-inline-item">
        <nav mat-tab-nav-bar>
          <ng-container *ngFor="let item of newLinks">
              <a [ngClass]="{'disable-link': item.isDisabled}" mat-tab-link *ngIf="item.subitems.length == 0" (click)="redirectToPage(item.navigationRout, item.link)" [active]="activeLink == item.link">
              {{item.name}} </a>

              <a mat-tab-link *ngIf="item.subitems.length > 0" [active]="activeLink == item.link" [matMenuTriggerFor]="menu"> {{item.name}} </a>

                <mat-menu #menu="matMenu">
                  <ng-container *ngFor="let subitem of item.subitems">
                    <button mat-menu-item (click)="navigateSubMenu(subitem.navigationRout, item.link)">{{subitem.label}}</button>
                  </ng-container>
                </mat-menu>

          </ng-container>
        </nav>
        <!-- <button mat-button [matMenuTriggerFor]="menu">Dashboard</button> -->
    </li>
    <!-- <li class="list-inline-item" *ngFor="let item of navTitlesData"> -->
    <li class="list-inline-item">
        <!-- <div class="main-menu-color dropdown">
      <ul>
        <li class="li-class row" (click)="redirectToPage(item.navigationRout)">
          <span class="menu-name">{{ item.title }}</span>
        </li>
        <div id="myDropdown" class="dropdown-content">
          <a (click)="redirectToPage(subitem.navigationRout)" *ngFor="let subitem of item.subNavControls">{{
            subitem.subTitle }}</a>
        </div>
      </ul>
    </div> -->


        <!-- <nav mat-tab-nav-bar>
      <a mat-tab-link *ngFor="let item of newLinks" (click)="redirectToPage(item.navigationRout, item.link)"
        [active]="activeLink == item.link">
        {{item.name}} </a>
    </nav> -->

    </li>
</ul>
<!-- </div>

</div> -->

<app-dialog [dialogModel]="workReferencePopUp">
  <ng-container class="dialog-content-slot" [ngSwitch]="workReferenceStep">

    <div fxLayout="column" fxLayoutGap="20px" *ngSwitchCase="0">
      <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
        <div class="info-element p-3" style="display: flex; align-items: center;" fxFlex="30" fxFlex.lt-sm="0 1 calc(50% - 32px)">
          <label for="" style="font-size: 1.5rem;">Work Reference</label>
          <!-- <p>{{ chargeStatus}}</p> -->
        </div>
        <mat-form-field appearance="fill" fxFlex="100">
          <input matInput placeholder="Enter Work Reference" [formControl]="workRefNumber">
          <mat-error>Field must be valid</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <button (click)="submitWorkRef()" mat-raised-button class="primary-button mr-2"
          color="primary">Submit</button>
        <button (click)="cancelWorkRef()" mat-stroked-button class="primary-button-outline">Cancel</button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngSwitchCase="1">
      <p><strong>Message :</strong></p>
      <p class="mb-0" style="white-space: pre-line;">{{workrefMessage}}</p>
    </div>

  </ng-container>
</app-dialog>
