<!-- <h2>Column List</h2> -->
<div [formGroup]="columnNameFormGroup" class="column-name-list">
  <mat-list role="list" class="list-container">
    <mat-list-item *ngFor="let column of dataModel.columnList" role="listitem">
      <mat-checkbox class="example-margin" formControlName ={{column.formControlName}} >
        {{column.name}}
        </mat-checkbox>
    </mat-list-item>
  </mat-list>
 </div>
