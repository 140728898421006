<div class="table-responsive">
    <div class="table-head mb-3">
        <div class="table-heading">{{dataModel.header}}</div>
    </div>

    <table class="w-100">
        <tr class="head-row">
            <th *ngFor="let item of dataModel.headers">
                {{item}}
            </th>
        </tr>
        <tr *ngFor="let items of dataModel.rowData" class="row-span">
            <td *ngFor="let item of dataModel.keys;" class="td-content" style="cursor: pointer;" [routerLink]="['/admin/pcp-charges-dashboard']">
                <p [ngClass]="item =='col_count'? 'bg-grey': '' || item=='amount' ? 'bg-blue': ''">
                    {{ item == 'amount' ? '&pound;' + (items[item]) : items[item] }}
                </p>
            </td>
        </tr>
        <tr class="final-row">
            <td class="th-heading">
                Total
            </td>
            <td class="th-content">
                <p class="bg-grey">
                    {{getTotalCount()}}
                </p>
            </td>
            <td class="th-content">
                <p class="bg-blue">
                    {{'&pound;'+ getTotalAmount()}}
                </p>
            </td>
        </tr>
        <div>
        </div>
    </table>
    <!-- <table mat-table [dataSource]="dataModel.rowData" class=" demo-table">

    <ng-container matColumnDef="col_name">
      <th mat-header-cell *matHeaderCellDef> Charge </th>
      <td mat-cell *matCellDef="let element"> {{element.col_name}} </td>
    </ng-container>


    <ng-container matColumnDef="col_count">
      <th mat-header-cell *matHeaderCellDef> Count </th>
      <td mat-cell *matCellDef="let element"> {{element.col_count}} </td>
    </ng-container>


    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> Amount </th>
      <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="dataModel.keys"></tr>
    <tr mat-row *matRowDef="let row; columns: dataModel.keys"></tr>

    <tr class="final-row">
      <td>
        Total
      </td>
      <td>
        {{getTotalCount()}}
      </td>
      <td>
        {{'&pound;'+ getTotalAmount()}}
      </td>
    </tr>


  </table> -->

</div>
