import {
  AssignLiabilityInnerModel,
  AssignLiabilityInnerConfigModel,
} from './assign-liability-form-inner.model';
import { CHARGE_FORMS_NAME, GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { MatDialog } from '@angular/material/dialog';
import { PERMIT_ACTIONS_CONFIG } from 'src/app/constants/db.constants';
import { SaveFileService } from 'src/app/services/save-file.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { invalid } from 'moment';
import { DatePipe } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-assign-liability-form-inner',
  templateUrl: './assign-liability-form-inner.component.html',
  styleUrls: ['./assign-liability-form-inner.component.css'],
})
export class AssignLiabilityFormInnerComponent
  extends WidgetComponentBase
  implements OnInit, AfterViewInit
{
  @Input() dataModel: AssignLiabilityInnerModel;
  @Input() configModel: AssignLiabilityInnerConfigModel;

  AssignLiabilityForm: FormGroup;

  arrLiabilityOrg: any[] = [
    {
      key: 1,
      displayText: 'A',
    },
    {
      key: 2,
      displayText: 'B',
    },
    {
      key: 3,
      displayText: 'C',
    },
  ];
  filteredArrLiabilityOrg: any[] = [];
  arrLiabilityOwner: any[] = [];
  allArrLiabilityOrg: any[] = [];
  arrSecondaryLiabilityOrg: any[] = [];
  allSecondaryLiabilityOrg: any[] = [];
  arrUsers: any[] = [];
  notesMessage: string = 'Max 500 characters.';

  arr: FormArray;
  amountValidator: boolean = false;
  totalValidator: boolean = false;
  chargeWarning: boolean = false;
  showSecondaryDropdown: boolean = false;
  showSecondaryText: boolean = false;

  searchPrimaryOrg: any;
  searchSecondaryOrg: any;

  @ViewChild('searchSecondary')
  searchSecondary: ElementRef;

  @ViewChild('searchPrimary')
  searchPrimary: ElementRef;

  @Input() min: any;
  mindate = new Date();
  previousId: any;
  currentId: any;
  previousCode: any;
  currentCode: any;

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _validationService: ValidationService,
    private _saveFileService: SaveFileService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) {
    super(_serverApi, _validationService);
    this.mindate.setDate(this.mindate.getDate() - 0);
  }

  ngOnInit(): void {
    super.wgOnInit();

    this.createFormGroup();
  }
  OnDestroy() {
    this.AssignLiabilityForm.reset();
  }
  ngAfterViewInit() {
    this.setArrLiablity(this.dataModel.arrLiablityOrg);
    this.patchValues(this.dataModel.values);
    this.cd.detectChanges();
  }
  createFormGroup(): void {
    this.AssignLiabilityForm = this.fb.group({
      // liabilityOrg: [''],
      // liabilityOwner: [''],
      // notes: ['', [Validators.minLength(10)]],
      // fileUp: [''],
      // amount: [''],
      // responsedueDate: [''],
      // arr: this.fb.array([this.createItem()]),
    });
    this.AssignLiabilityForm = this.createItem();
  }
  setFieldData() {}
  stopPropagation(event) {
    event.stopPropagation();
  }
  setArrLiablity(item: any) {
    this.arrLiabilityOrg = item;
    this.filteredArrLiabilityOrg = item;
    this.allArrLiabilityOrg = item;
  }
  setMode(responseDataModel: any) {}
  setValue(responseDataModel: any) {}
  getControlData() {
    // let id = this.dataModel.globalParameters.get('applicationId');
    // // INFO: ServerAPI
    let url = `/api/v1/cpm/actions/getDetails`;
    return this.wgAPIMethodGet(url, this.dataModel.apireqdata);
    // return new Promise((resolve, reject) => {
    //   let permitform: any = this.dataModel.globalParameters.get(
    //     GLOBAL_PARAM_KEY.VIEW_PERMIT_FORM
    //   );
    //   permitform = permitform ? permitform : new Object();
    //   if (permitform) {
    //     resolve(permitform);
    //   } else {
    //     reject(permitform);
    //   }
    // });
  }
  convertData(response: any) {
    if (!response) return;

    console.log('respoine of liability Form', response);

    this.dataModel.data.permitType = response.permit_type;
    this.dataModel.data.chargeDesc = response.charge_description;
    this.dataModel.data.chargeNumber = response.charge_number;

    this.dataModel.data.chargeAmount = response.charge_amount;

    this.dataModel.data.chargeDetails = response.chargeDetails;

    this.dataModel.data.chargeType = response.charge_type;
    // this.arrLiabilityOrg = response.lst_contractor;
    const itemsArray = this.AssignLiabilityForm.get('arr') as FormArray;
    const newValues = response.lst_liability_owner_details;
    // for(let i = 0; i < newValues.length - 1; i++) {
    //   this.addItem()
    // }

    // For multiple values
    // newValues.forEach((item, i) => {
    //   if (i != 0) {
    //     this.addItem();
    //   }
    //   let itemFormGroup = itemsArray.at(i) as FormGroup;
    //   itemFormGroup.patchValue({
    //     contractor_code: item.contractor_code,
    //     notes_text: item.notes_text,
    //     amount: item.amount,
    //     due_date: item.due_date,
    //   });
    // });

    // newValues.forEach((item) => {
    //   const group = this.fb.group({
    //     contractor_id: [item.contractor_id],
    //     notes_text: [item.notes_text],
    //     amount: [item.amount],
    //     due_date: [item.due_date]
    //   });
    //   itemsArray.push(group);
    // });
    // console.log(itemsArray)

    // For filling the customm data

    // For filling the customm data

    // this.dataModel.data.worksReferenceNumber = response.works_reference_number;
    // this.dataModel.data.highwayAuthority = response.highway_authority_name;

    // this.dataModel.data.projectReferenceNumber =
    //   response.project_reference_number;
    // this.dataModel.data.worksDescription = response.works_description;
    // this.dataModel.data.preApprovalAuthoriser =
    //   response.pre_approval_authoriser;
    // not exist in api response
    // let locationtype = response?.location_types.toString();
    // this.dataModel.data.locationTypes = locationtype?.split(',');

    // this.dataModel.data.postCode = response.postcode;
    // if (response.area_name.length > 0)
    //   this.dataModel.data.usrndetails =
    //     response.usrn +
    //     ' - ' +
    //     response.street_name +
    //     ', ' +
    //     response.area_name +
    //     ', ' +
    //     response.town;
    // if (response.area_name.length == 0)
    //   this.dataModel.data.usrndetails =
    //     response.usrn + ' - ' + response.street_name + ', ' + response.town;
    // if (
    //   response.street_name.length == 0 &&
    //   response.area_name.length == 0 &&
    //   response.town.length == 0
    // )
    // this.dataModel.data.usrndetails = response.usrn;
    // this.dataModel.data.worksDescription = response.work_description;
    // this.dataModel.data.worksCategory =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.work_category
    //   );

    // this.dataModel.data.highwayAuthority = response.highway_authority_name;

    // this.dataModel.data.roadType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.road_category.toString()
    //   );

    // this.dataModel.data.promoterOrganisation = response.promoter_organisation;

    // this.dataModel.data.contact =
    //   response.secondary_contact + ' - ' + response.secondary_contact_number;
    // let workstreams: any[] =
    //   this._appRepoHelperService.getUserAccessibleWorkStream();

    // if (workstreams) {
    //   let workstream: any = workstreams.find(
    //     (c) => c.workstreamId == response.workstream_id
    //   );
    //   this.dataModel.data.workStream = workstream?.description;
    // }

    // let contractors: any[] =
    //   this._appRepoHelperService.getUserAccessibleContractors();
    // if (contractors) {
    //   let contractor: any = contractors.find(
    //     (c) => c.contractor_id == response.contractor_id
    //   );
    // this.dataModel.data.accountability = contractor?.contractor_name;
    // }
    // // not exist in api response
    // // this.dataModel.data.accountability = response.accountability;

    // this.dataModel.data.trafficSensitive = DataHelper.booleanToUserText(
    //   response.traffic_sensitive_flag
    // );
    // this.dataModel.data.footwayClosure =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.close_footway
    //   );

    // this.dataModel.data.excavationRequired = DataHelper.booleanToUserText(
    //   response.excavation_flag
    // );

    // this.dataModel.data.isLaneRentalApplicable = DataHelper.booleanToUserText(
    //   response.lane_rental_flag
    // );

    // this.dataModel.data.trafficManagementRequired =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.traffic_management_type
    //   );

    // this.dataModel.data.trafficManagementPlan = response.geometry;
    // this.dataModel.data.permitStatus = response.status_value;
    // this.dataModel.data.workStatus = response.work_status_value;

    // if (response.status == '') this.isDraftPermitView = true;
    // this.dataModel.data.actualStartDateTime = response.actual_start_date;
    // this.dataModel.data.actualEndDateTime = response.actual_end_date;

    // this.dataModel.data.proposedStartDateTime = response.proposed_start_date;
    // this.dataModel.data.proposedEndDateTime = response.proposed_end_date;

    // this.dataModel.data.proposedDuration = response.working_days;
    // this.dataModel.data.cost = response.total_cost;

    // this.dataModel.data.costRisk = response.cost_risk;
    // this.dataModel.data.customerRisk = response.customer_risk;
    // this.dataModel.data.trafficManagementRisk =
    //   response.traffic_management_risk;
    // this.dataModel.data.actions = response.actions;
    // this.dataModel.data.changeDecision = response.change_decision;
    // //     this.dataModel.data.changeDecision = [
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // // ]

    // // console.log("this.dataModel.data.changeDecision",this.dataModel.data.changeDecision)
    // this.dataModel.data.collaborativeWorkingFlag = DataHelper.booleanToUserText(
    //   response.collaborative_working_flag
    // );
    // this.dataModel.data.collaborationDetails = response.collaboration_details;
    // this.dataModel.data.collaborativeWorks = response.collaborative_works;
    // this.dataModel.data.collaborationType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.collaboration_type
    //   );
    // this.dataModel.data.ttro_requiredFlag = DataHelper.booleanToUserText(
    //   response.ttro_required_flag
    // );
    // this.dataModel.data.environmental_flag = DataHelper.booleanToUserText(
    //   response.environmental_flag
    // );
    // this.dataModel.data.is_covid19_response = DataHelper.booleanToUserText(
    //   response.is_covid19_response
    // );
    // this.dataModel.data.earlyStartPreApprovalFlag =
    //   DataHelper.booleanToUserText(response.early_start_pre_approval_flag);
    // this.dataModel.data.earlyStartReason = response.early_start_reason;
    // this.dataModel.data.preApprovalAuthoriser =
    //   response.pre_approval_authoriser;
    // this.dataModel.data.activityType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.activity_type
    //   );
    // this.dataModel.data.preApprovalDetails = response.pre_approval_details;
    // this.dataModel.data.additionalInfo = response.additional_info;
    // this.dataModel.data.additionalcontact = response.additional_contact;
    // this.dataModel.data.emergencyContact = response.additional_contact_number;
    // this.dataModel.data.emergencyNumber = response.additional_contact_email;

    // this.dataModel.data.additionalcontactemail =
    //   response.emergency_contact_name;
    // this.dataModel.data.additionalcontactemail =
    //   response.emergency_contact_number;

    // this.dataModel.data.expectedEndDateTime = response.expected_end_date;
    // this.dataModel.data.is_pmr_responded = response.is_pmr_responded;
    // this.dataModel.data.is_excavation_carried_out =
    //   DataHelper.booleanToUserText(response.excavation_carried_out);
    // if (this.dataModel.data.is_pmr_responded != null) {
    //   this.showIcon = true;
    // }

    // this.dataModel.data.dcStatus = response.dc_details.dc_status;
    // this.dataModel.data.dcReason = response.dc_details.dc_reason;
    // this.dataModel.data.dcResponseDate = response.dc_details.dc_response_date;
    // // switch (response.assessment_decision.status) {
    // //   case ASSESSMENT_STATUS.ASSESSMENT_GRANTED_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     break;
    // //   case ASSESSMENT_STATUS.ASSESSMENT_MODIFICATION_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     this.assessmentDecision.description = response.assessment_decision.modificationRequestDetails;
    // //     break;
    // //   case ASSESSMENT_STATUS.ASSESSMENT_REFUSED_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     this.assessmentDecision.description = response.assessment_decision.refusalDetails;
    // //     this.assessmentDecision.reason = response.assessment_decision.reasonForRefusal;
    // //     this.assessmentDecision.modificationRequestDetails = response.assessment_decision.modificationRequestDetails;
    // //     break;
    // //   default:
    // //     break;
    // // }
  }
  getValue() {
    // let data: any
    // data = this.dataModel.data;
    if (this.AssignLiabilityForm.valid) {
      let data: any = {};

      if (this.showSecondaryText) {
        const filterArr = this.allArrLiabilityOrg.filter(
          (item) =>
            item.contractor_id ===
            this.AssignLiabilityForm.get('contractor_code').value
        );
        const contractor_code =
          filterArr[0].cpm_contractor_dto_list[0].contractor_code;
        const ldpid = filterArr[0].cpm_contractor_dto_list[0].ldp_id;
        const contactor_id =
          filterArr[0].cpm_contractor_dto_list[0].contractor_id;
        console.log('filterArr>>>', this.AssignLiabilityForm.value);
        data.amount = this.AssignLiabilityForm.get('amount').value;
        data.contractor_code = contractor_code;
        data.contractor_id = contactor_id;
        // data.due_date = this.AssignLiabilityForm.get('due_date').value
        data.ldp_id = ldpid;
        data.notes_text = this.AssignLiabilityForm.get('notes_text').value;
        data.primary_contractor_id =
          this.AssignLiabilityForm.get('contractor_code').value;
        data.liability_assigned_to = this.AssignLiabilityForm.get(
          'liability_assigned_to'
        ).value;
      } else {
        const filterArr = this.arrSecondaryLiabilityOrg.filter(
          (ldp) =>
            ldp.contractor_code ===
            this.AssignLiabilityForm.get('secondary_code').value
        );
        const ldpid = filterArr[0].ldp_id;
        const contactor_id = filterArr[0].contractor_id;
        data.amount = this.AssignLiabilityForm.get('amount').value;
        data.contractor_code =
          this.AssignLiabilityForm.get('secondary_code').value;
        data.contractor_id = contactor_id;
        // data.due_date = this.AssignLiabilityForm.get('due_date').value
        data.ldp_id = ldpid;
        data.notes_text = this.AssignLiabilityForm.get('notes_text').value;
        data.primary_contractor_id =
          this.AssignLiabilityForm.get('contractor_code').value;
        data.liability_assigned_to = this.AssignLiabilityForm.get(
          'liability_assigned_to'
        ).value;
      }
      return data;
    }
  }

  resetForm() {
    this.AssignLiabilityForm.reset();
  }
  flagAmount() {
    // this.AssignLiabilityForm.get('amount').setErrors(invalid);
    this.AssignLiabilityForm.get('amount').markAsUntouched();
    this.AssignLiabilityForm.get('amount').markAllAsTouched();
  }
  getContractorCode() {
    return this.AssignLiabilityForm.get('contractor_code').value;
  }
  patchValues(item: assignJobOwnerReqBody) {
    if (item) {
      this.AssignLiabilityForm.get('contractor_code').setValue(
        item?.contractor_code
      );
      this.previousId = item?.contractor_code;
      const filterArr = this.allArrLiabilityOrg.filter(
        (org) => org.contractor_id === item.contractor_code
      );
      if (filterArr[0].is_manual) {
        this.showSecondaryDropdown = false;
        this.showSecondaryText = true;
        this.arrUsers = filterArr[0].cpm_contractor_dto_list[0].users_list;
        this.AssignLiabilityForm.get('liability_assigned_to').setValue(
          item.userids
        );
        this.AssignLiabilityForm.get('liability_assigned_to').setValidators([
          Validators.required,
        ]);
      } else {
        this.showSecondaryDropdown = true;
        this.showSecondaryText = false;
        const secondaryOrg = this.allArrLiabilityOrg.filter(
          (org) => org.contractor_id == item.contractor_code
        );
        this.arrSecondaryLiabilityOrg = secondaryOrg[0].cpm_contractor_dto_list;
        this.AssignLiabilityForm.get('secondary_code').setValue(
          item?.secondary_code
        );
        this.previousCode = item?.secondary_code;
        const userArr = this.arrSecondaryLiabilityOrg.filter(
          (org) => org.contractor_code === item.secondary_code
        );
        this.arrUsers = userArr[0].users_list;
        this.AssignLiabilityForm.get('liability_assigned_to').setValue(
          item.userids
        );
        this.AssignLiabilityForm.get('liability_assigned_to').setValidators([]);
      }
    }

    this.AssignLiabilityForm.get('notes_text').setValue(item?.notes_text);
    this.AssignLiabilityForm.get('amount').setValue(item?.amount);
    // this.AssignLiabilityForm.get('due_date').setValue(item?.due_date);
    const chargeAmount = this.dataModel.globalParameters.get('chargeAmount');
    if (
      chargeAmount &&
      this.AssignLiabilityForm.controls.amount.value === undefined
    )
      this.AssignLiabilityForm.controls.amount.setValue(chargeAmount);
  }

  openFileUpload(contractor_code) {
    this.AssignLiabilityForm.markAllAsTouched();
    if (this.AssignLiabilityForm.valid) {
      if (this.showSecondaryText) {
        const filterArr = this.allArrLiabilityOrg.filter(
          (item) =>
            item.contractor_id ===
            this.AssignLiabilityForm.get('contractor_code').value
        );
        contractor_code =
          filterArr[0].cpm_contractor_dto_list[0].contractor_code;
        console.log('contractor_code>>>', contractor_code);
      }
      this.dataModel.formdata = this.AssignLiabilityForm.value;
      let data: any = {};
      data.reference = this.dataModel.data.chargeNumber;
      data.objectType = CHARGE_FORMS_NAME.ASSIGN_LIABILITY;
      data.owner = contractor_code;
      this.emitEvent('openFileUploadAssignLiability', data);
    }
  }
  addNewForm() {
    const add = this.AssignLiabilityForm.get('address') as FormArray;
    add.push(
      this.fb.group({
        street: [],
        city: [],
      })
    );
  }
  createItem() {
    return this.fb.group({
      contractor_code: ['', [Validators.required]],
      secondary_code: [''],
      liability_assigned_to: [''],
      // user_text: [''],
      notes_text: ['', [Validators.maxLength(500)]],
      amount: ['', [Validators.required, Validators.min(1)]],
      // due_date: ['', [Validators.required]],
    });
  }

  addItem() {
    this.arr = this.AssignLiabilityForm.get('arr') as FormArray;
    if (this.arr.length < 5) {
      this.arr.push(this.createItem());
      this.chargeWarning = false;
    } else {
      this.chargeWarning = true;
    }
  }
  setFielCalues() {}

  onSubmit() {
    this.AssignLiabilityForm.markAllAsTouched();
    if (this.validateLiability() && this.AssignLiabilityForm.valid) {
      // this.AssignLiabilityForm.value.arr.forEach((ele) => {
      //   const isoDateString = new Date(ele.due_date).toISOString();
      //   ele.due_date = isoDateString;
      // });

      let data: any = {};
      data = {
        charge_id: this.dataModel.apireqdata.chargeId,
        charge_number: this.dataModel.data.chargeNumber ?? '',
        charge_type: this.dataModel.apireqdata.chargeType,
        lst_liability_owner_details: this.AssignLiabilityForm.value.arr,
      };

      data.lst_liability_owner_details.forEach((contractor) => {
        this.arrLiabilityOrg.forEach((org) => {
          if (org.contractor_code == contractor.contractor_code) {
            contractor.contractor_id = org.contractor_id;
          }
        });
      });

      console.log(this.arrLiabilityOrg);

      return data;
    }
  }

  areValuesEqual(array) {
    return array.every((value, index, arr) => value === arr[0]);
  }
  calculateCharge(amount, totalVal) {
    let total = amount.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    if (total == totalVal) {
      return true;
    } else {
      return false;
    }
  }
  showAmountError() {
    this.amountValidator = true;
    this.totalValidator = false;
  }

  totalAmountError() {
    this.totalValidator = true;
    this.amountValidator = false;
  }

  sendAmountEvent() {
    this.emitEvent('checkSplitAmount');
  }

  validateLiability() {
    let totalAmountArr = [];
    let totalAmount = this.dataModel.data.chargeAmount;
    this.AssignLiabilityForm.value.arr.forEach((item, index) => {
      totalAmountArr.push(item.amount);
    });

    if (!this.calculateCharge(totalAmountArr, totalAmount)) {
      this.totalAmountError();
      return false;
    } else {
      this.showAmountError();
      return true;
    }

    // if (this.AssignLiabilityForm.value.arr.length > 1) {
    //   if(!this.calculateCharge(totalAmountArr, totalAmount)) {
    //     this.totalAmountError()
    //     return false
    //   } else {
    //     this.totalValidator = false;
    //     this.amountValidator = false;
    //     return true
    //   }
    // } else if(!this.calculateCharge(totalAmountArr, totalAmount)) {
    //   this.totalAmountError()
    //   return false;
    // } else {
    //   return true;
    // }
  }

  removeUser(i: any) {
    const remove = this.AssignLiabilityForm.get('arr') as FormArray;
    remove.removeAt(i);
    this.chargeWarning = false;
  }

  onNotesChange(value) {
    if (value.length > 0) {
      let charRemaining = 500 - value.length;
      this.notesMessage = `Remaining characters ${charRemaining}.`;
    } else {
      this.notesMessage = 'Max 500 characters.';
    }
  }

  onDateChange(event) {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentSeconds = currentTime.getSeconds();

    let ele = this.AssignLiabilityForm.get('due_date').value;
    const dateObject = new Date(ele);
    dateObject.setHours(currentHours);
    dateObject.setMinutes(currentMinutes);
    dateObject.setSeconds(currentSeconds);
    let datePipe = new DatePipe('en-US');
    const utcformattedDate = datePipe.transform(
      dateObject,
      'yyyy-MM-ddTHH:mm:ss.SSSZ',
      'UTC'
    );
    ele = utcformattedDate;
  }

  onLiabiltyOrganisationChange(event) {
    const secondaryOrg = this.allArrLiabilityOrg.filter(
      (item) => item.contractor_id === event.value
    );
    this.arrSecondaryLiabilityOrg = secondaryOrg[0].cpm_contractor_dto_list;
    this.allSecondaryLiabilityOrg = secondaryOrg[0].cpm_contractor_dto_list;

    // Filtering Part
    if (secondaryOrg[0].is_manual) {
      this.showSecondaryText = true;
      this.showSecondaryDropdown = false;
      this.arrSecondaryLiabilityOrg = [];
      const userArr = this.allArrLiabilityOrg.filter(
        (item) => item.contractor_id === event.value
      );
      this.arrUsers = userArr[0].cpm_contractor_dto_list[0].users_list;
      this.AssignLiabilityForm.get('secondary_code').setValidators([]);
      this.AssignLiabilityForm.get('secondary_code').setValue(
        userArr[0].cpm_contractor_dto_list[0].contractor_code
      );
      this.AssignLiabilityForm.get('liability_assigned_to').setValidators([
        Validators.required,
      ]);
    } else {
      this.showSecondaryText = false;
      this.showSecondaryDropdown = true;
      this.AssignLiabilityForm.get('secondary_code').setValue('');
      this.AssignLiabilityForm.get('secondary_code').setValidators([
        Validators.required,
      ]);
      this.AssignLiabilityForm.get('liability_assigned_to').setValidators([]);
    }

    // Emit event to delete attachment if another contractor id is selected
    this.previousId = this.currentId;
    this.currentId = event.value;
    this.dataModel.currentContractorId = this.previousId;
    this.dataModel.formdata = this.AssignLiabilityForm.value;
    if (this.previousId) {
      this.AssignLiabilityForm.controls.amount.setValue('');
      // this.AssignLiabilityForm.controls.due_date.setValue('')
      this.AssignLiabilityForm.controls.notes_text.setValue('');
      this.AssignLiabilityForm.markAsUntouched();
    }
    let data = {
      chargeNumber: this.dataModel.data.chargeNumber,
      fileObjectType: CHARGE_FORMS_NAME.ASSIGN_LIABILITY,
      previousVal: this.previousId,
      currentVal: this.currentId,
      isManual: secondaryOrg[0].is_manual,
    };
    this.emitEvent('onChangeLiability', data);
  }

  onChangeSecondaryOrganisation(event) {
    const userArr = this.arrSecondaryLiabilityOrg.filter(
      (item) => item.contractor_code === event.value
    );
    this.arrUsers = userArr[0].users_list;
    this.previousCode = this.currentCode;
    this.currentCode = event.value;
    this.dataModel.currentContractorCode = event.value;
    this.dataModel.formdata = this.AssignLiabilityForm.value;
    // if(this.previousVal) {
    //   this.AssignLiabilityForm.controls.amount.setValue('')
    //   this.AssignLiabilityForm.controls.due_date.setValue('')
    //   this.AssignLiabilityForm.controls.notes_text.setValue('')
    //   this.AssignLiabilityForm.markAsUntouched()
    // }
    let data = {
      chargeNumber: this.dataModel.data.chargeNumber,
      fileObjectType: CHARGE_FORMS_NAME.ASSIGN_LIABILITY,
      previousVal: this.previousCode,
      currentVal: this.currentCode,
    };
    this.emitEvent('onChangeLiability', data);
  }

  onSearchTeam() {
    let filter = this.searchPrimaryOrg.toLowerCase();
    if (filter.length > 0) {
      this.filteredArrLiabilityOrg = this.arrLiabilityOrg.filter((option) =>
        option.contractor_name.toLowerCase().includes(filter)
      );
    } else {
      this.filteredArrLiabilityOrg = this.arrLiabilityOrg;
    }
  }

  onSearchSecondaryOrg() {
    let filter = this.searchSecondaryOrg.toLowerCase();
    if (filter.length > 0) {
      this.arrSecondaryLiabilityOrg = this.allSecondaryLiabilityOrg.filter(
        (option) => option.contractor_name.toLowerCase().includes(filter)
      );
    } else {
      this.arrSecondaryLiabilityOrg = this.allSecondaryLiabilityOrg;
    }
  }

  throwDuplicateContractorError() {
    this.AssignLiabilityForm.get('secondary_code').setErrors({
      customError: true,
    });
  }

  openPrimaryOrganisation(event) {
    this.searchPrimaryOrg = '';
    this.filteredArrLiabilityOrg = this.arrLiabilityOrg;
    this.searchPrimary.nativeElement.focus();
  }

  openedSecondary(event) {
    this.searchSecondaryOrg = '';
    const secondaryOrg = this.allArrLiabilityOrg.filter(
      (item) =>
        item.contractor_id ===
        this.AssignLiabilityForm.get('contractor_code').value
    );
    this.allSecondaryLiabilityOrg = secondaryOrg[0].cpm_contractor_dto_list;
    this.arrSecondaryLiabilityOrg = this.allSecondaryLiabilityOrg;
    this.searchSecondary.nativeElement.focus();
  }
}

interface assignJobOwnerReqBody {
  contractor_code: string;
  secondary_code: string;
  userids: string;
  notes_text: string;
  amount: string;
  // due_date: string;
}
