import { Component, Input, OnInit } from '@angular/core';
import { FILTER_TYPE, GLOBAL_PARAM_KEY, QUICK_FILTER_KEY } from 'src/app/app-constants';
import { HELPER_TEXT_DATA_DOMAIN } from 'src/app/constants/db.constants';
import { AppFilterModel } from 'src/app/models/common/app-filter';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from '../../../../utility/widget/widget-utility/widget-component-base';
import { PermitQuickFilterConfigModel } from '../../permit-quick-filter/permit-quick-filter.model';
import {
  IssuedChargesQuickFilterDataModel,
  IssuedChargesQuickFilterConfigModel,
} from './issued-charges-quick-filter';

@Component({
  selector: 'app-issued-charges-quick-filter',
  templateUrl: './issued-charges-quick-filter.component.html',
  styleUrls: ['./issued-charges-quick-filter.component.css'],
})
export class IssuedChargesQuickFilterComponent
  extends WidgetComponentBase
  implements OnInit
{
  @Input() dataModel: IssuedChargesQuickFilterDataModel;
  @Input() configModel: IssuedChargesQuickFilterConfigModel;

  quickFilterSelectedValue: string = 'PERMIT_CHARGES';
  quickFilterHelperData: any[] = [];

  constructor(
    private _serverApiBase: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _appRepoService: AppRepoService,
    private _validationService: ValidationService
  ) {
    super(_serverApiBase, _validationService);
  }

  ngOnInit(): void {
    this.wgOnInit();
  }
  ngAfterViewInit() {
    this.wgRefreshData();
  }

  setFieldData() {
    this.quickFilterHelperData = [
      ...this._appRepoService.appRepo.helperTextDataById.values(),
    ].filter((item) => item.domain === HELPER_TEXT_DATA_DOMAIN.QUICK_FILTER);
  }

  getControlData() {
    let url = '/api/v1/cpm/issued-charges/count';
    // console.log('apidata', this.dataModel.apireqdata);
    return this.wgAPIMethodGet(
      this.dataModel.apiUrl,
      this.dataModel.apireqdata
    );
  }

  convertData(response: any) {
    // console.log('Quick filter response', response);
    var i = 0;

    this.dataModel.quickFilters[i++].count = response.permit_charges;
    this.dataModel.quickFilters[i++].count = response.fpn;
    this.dataModel.quickFilters[i++].count = response.section74;
    this.dataModel.quickFilters[i++].count = response.defect;
    this.dataModel.quickFilters[i++].count = response.miscellaneous_charges;
    this.dataModel.quickFilters[i++].count = response.fpn_manual;
    this.dataModel.quickFilters[i++].count = response.section74_manual;
    this.dataModel.quickFilters[i++].count = response.permit_charges_manual;
    this.dataModel.quickFilters[i++].count = response.defects_manual;

    return this.dataModel;
  }

  setMode(responseDataModel: any) {}

  setValue(responseDataModel: any) {

    switch (this.dataModel.listName) {
      case QUICK_FILTER_KEY.ALL_WORK_LIST:
        this.quickFilterSelectedValue = this._appRepoHelperService.getGlobalPersistantData(QUICK_FILTER_KEY.ALL_WORK_LIST)
        break;
      case QUICK_FILTER_KEY.MY_WORK_LIST:
        this.quickFilterSelectedValue = this._appRepoHelperService.getGlobalPersistantData(QUICK_FILTER_KEY.MY_WORK_LIST)
        break;
      case QUICK_FILTER_KEY.MY_TEAMS_WORK_LIST:
        this.quickFilterSelectedValue = this._appRepoHelperService.getGlobalPersistantData(QUICK_FILTER_KEY.MY_TEAMS_WORK_LIST)
        break;
      default:
        break;
    }
    if(this.quickFilterSelectedValue == undefined) {
      this.quickFilterSelectedValue = 'PERMIT_CHARGES'

      switch (this.dataModel.listName) {
        case QUICK_FILTER_KEY.ALL_WORK_LIST:
          this._appRepoHelperService.setGlobalPersistantData(QUICK_FILTER_KEY.ALL_WORK_LIST, this.quickFilterSelectedValue)
          break;
        case QUICK_FILTER_KEY.MY_WORK_LIST:
          this._appRepoHelperService.setGlobalPersistantData(QUICK_FILTER_KEY.MY_WORK_LIST, this.quickFilterSelectedValue)
          break;
        case QUICK_FILTER_KEY.MY_TEAMS_WORK_LIST:
          this._appRepoHelperService.setGlobalPersistantData(QUICK_FILTER_KEY.MY_TEAMS_WORK_LIST, this.quickFilterSelectedValue)
          break;
        default:
          break;
      }
    };

    // console.log('SELECTED_VALUE', this.quickFilterSelectedValue)
    // console.log('PAGE', this.dataModel.listName)

    this.emitEvent(
      IssuedChargesQuickFilterConfigModel.COMP_TO_CALLER_APPLY_FILTER
    );
  }

  getValue() {
    let data = {
      quickFilter: this.quickFilterSelectedValue,
    };
    return data;
  }

  onQuickFilterSelectionChange(value) {
    console.log('value', value);
    this.quickFilterSelectedValue = value.value;

    switch (this.dataModel.listName) {
      case QUICK_FILTER_KEY.ALL_WORK_LIST:
        this._appRepoHelperService.setGlobalPersistantData(QUICK_FILTER_KEY.ALL_WORK_LIST, this.quickFilterSelectedValue)
        break;
      case QUICK_FILTER_KEY.MY_WORK_LIST:
        this._appRepoHelperService.setGlobalPersistantData(QUICK_FILTER_KEY.MY_WORK_LIST, this.quickFilterSelectedValue)
        break;
      case QUICK_FILTER_KEY.MY_TEAMS_WORK_LIST:
        this._appRepoHelperService.setGlobalPersistantData(QUICK_FILTER_KEY.MY_TEAMS_WORK_LIST, this.quickFilterSelectedValue)
        break;
      default:
        break;
    }

    this.emitEvent(
      IssuedChargesQuickFilterConfigModel.COMP_TO_CALLER_APPLY_FILTER
    );
  }

  reset() {
    this.quickFilterSelectedValue = '';
  }

  getHelpTextData() {
    return {
      helpTextId: Math.floor(Math.random() * (100 - 1 + 1) + 1),
    };
  }
}
