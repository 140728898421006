<div class="form-group col">
  <form form [formGroup]="AssignLiabilityForm" id="filter-form" class="d-flex flex-column p-3" appInvalidControlScroll>
    <!-- <div formArrayName="arr" *ngFor="let a of AssignLiabilityForm.get('arr')['controls']; let i = index;"> -->
    <!-- <div [formGroupName]="i" style="margin-bottom: 10px; " class="d-flex flex-column"> -->

    <!-- <ng-container *ngIf="i != 0">
          <hr>
          <div class="d-flex justify-content-start align-items-center" style="font-weight: bold;"> <span>Remove
              charge</span>
            <mat-icon style="color: var(--theme-color); margin-left: 5px; cursor: pointer;"
              (click)="removeUser(i)">remove_circle
            </mat-icon>
          </div>
        </ng-container> -->

    <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <div fxFlex="100" style="position: relative;">
        <mat-icon class="clear-icon" *ngIf="AssignLiabilityForm.get('contractor_code').value"
          (click)="AssignLiabilityForm.get('contractor_code').setValue(null)">clear</mat-icon>
        <mat-form-field appearance="fill" fxFlex="100">
          <mat-label>Select Primary Organisation</mat-label>
          <mat-select (openedChange)="openPrimaryOrganisation($event)" [disableOptionCentering]="true"
            (selectionChange)="onLiabiltyOrganisationChange($event)" formControlName="contractor_code">
            <mat-form-field class="searchHA">
              <input #searchPrimary matInput placeholder="Search" [(ngModel)]="searchPrimaryOrg"
                (ngModelChange)="onSearchTeam()" [ngModelOptions]="{standalone: true}"
                (keydown)="$event.stopPropagation()">
            </mat-form-field>
            <mat-option [value]="permitStatus.contractor_id" *ngFor="let permitStatus of filteredArrLiabilityOrg">
              {{permitStatus.contractor_name}}</mat-option>
          </mat-select>
          <mat-error>Field must be valid</mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="100" style="position: relative;" *ngIf="showSecondaryDropdown">
        <mat-icon class="clear-icon" *ngIf="AssignLiabilityForm.get('secondary_code').value"
          (click)="AssignLiabilityForm.get('secondary_code').setValue(null)">clear</mat-icon>
        <mat-form-field appearance="fill" fxFlex="100" *ngIf="showSecondaryDropdown">
          <mat-label>Select Secondary Organisation</mat-label>
          <mat-select formControlName="secondary_code" (openedChange)="openedSecondary($event)"
            (selectionChange)="onChangeSecondaryOrganisation($event)">
            <mat-form-field class="searchHA">
              <input #searchSecondary matInput placeholder="Search" [(ngModel)]="searchSecondaryOrg"
                (ngModelChange)="onSearchSecondaryOrg()" [ngModelOptions]="{standalone: true}"
                (keydown)="$event.stopPropagation()">
            </mat-form-field>
            <mat-option [value]="permitStatus.contractor_code" *ngFor="let permitStatus of arrSecondaryLiabilityOrg">
              {{permitStatus.contractor_name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="AssignLiabilityForm.get('secondary_code').hasError('required')">Field must be
            valid</mat-error>
          <mat-error *ngIf="AssignLiabilityForm.get('secondary_code').hasError('customError')">Contractor is already
            selected</mat-error>
        </mat-form-field>
      </div>

      <!-- <div fxFlex="100" style="position: relative;" *ngIf="showSecondaryDropdown || showSecondaryText">
        <mat-icon class="clear-icon" *ngIf="AssignLiabilityForm.get('user_code').value" (click)="AssignLiabilityForm.get('user_code').setValue(null)">clear</mat-icon>
      <mat-form-field appearance="fill" fxFlex="100" *ngIf="showSecondaryDropdown || showSecondaryText">
        <mat-label>Select User/Team</mat-label>
        <mat-select multiple formControlName="user_code">
          <mat-option [value]="permitStatus.user_id" *ngFor="let permitStatus of arrUsers">
            {{permitStatus.user_name}}</mat-option>
        </mat-select>
        <mat-error>Field must be valid</mat-error>

      </mat-form-field>
      </div> -->

      <mat-form-field appearance="fill" fxFlex="100" *ngIf="showSecondaryText">
        <mat-label>Assign To</mat-label>
        <input matInput placeholder="Assign To" autocomplete="off" formControlName="liability_assigned_to">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>

    </div>

    <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">

      <mat-form-field appearance="fill" fxFlex="50">
        <mat-label>Enter Amount</mat-label>
        <input type="number" matInput placeholder="Amount" autocomplete="off" formControlName="amount"
          (focusout)="sendAmountEvent()">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>

      <!-- <mat-form-field appearance="fill" fxFlex="50">
        <mat-label>Response due by</mat-label>
        <input matInput [matDatepicker]="chargeReceiptDate" [min]="mindate" (click)="chargeReceiptDate.open()"
        <input matInput [matDatepicker]="chargeReceiptDate" (click)="chargeReceiptDate.open()"
          (dateChange)="onDateChange($event.value)" (focus)="chargeReceiptDate.open()" formControlName="due_date">
        <mat-datepicker-toggle matSuffix [for]="chargeReceiptDate"></mat-datepicker-toggle>
        <mat-datepicker #chargeReceiptDate></mat-datepicker>
        <mat-error>Field must be valid</mat-error>

      </mat-form-field> -->
    </div>

    <div class="fixed-underline mt-0 pt-0" fxFlex="100" fxLayoutGap="10px">
      <!-- <div fxFlex="33.33"></div> -->
      <div fxFlex="50" *ngIf="totalValidator">
        <label class="text-danger" style="font-size: 12px;"> Split amount total should match charge amount. </label>
      </div>
      <div fxFlex="50"></div>
    </div>

    <div class="fixed-underline" class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="100" hintLabel={{notesMessage}}>
        <mat-label>Notes</mat-label>
        <textarea matInput placeholder="Notes" [maxLength]="500" rows="4" style="resize: none;" autocomplete="off"
          formControlName="notes_text" (keyup)="onNotesChange($event.target.value)"></textarea>
        <mat-error>Max character limit 500</mat-error>
      </mat-form-field>
    </div>

    <div class="fixed-underline">
      <mat-form-field appearance="fill" fxFlex="100">
        <input matInput placeholder="Upload Document" autocomplete="off" readonly value="Upload Evidence (Optional)">
        <mat-icon class="upload" matSuffix
          (click)="openFileUpload(AssignLiabilityForm.get('secondary_code').value)">attach_file</mat-icon>
        <!-- <mat-icon class="upload" matSuffix
              (click)="openFileUpload(a.get('contractor_code').value)">attach_file</mat-icon> -->
      </mat-form-field>

    </div>


    <!-- </div>
    </div> -->
    <!-- <span style="color: red; font-weight: bold;" *ngIf="amountValidator">Split amount is Not Matching</span> -->
    <!-- <span style="color: red; font-size: 12px;" *ngIf="totalValidator">Split amount total should match charge amount.</span> -->
  </form>



</div>