<table mat-table [dataSource]="dataSource">

    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>User Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Claimed Date </th>
        <td mat-cell *matCellDef="let element"> {{element.claim_date | transformDateTime }} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> {{element.claim_status}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef> Comments </th>
        <td mat-cell *matCellDef="let element"> {{element.comments}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="dataModel.displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: dataModel.displayedColumns;"></tr>
</table>
