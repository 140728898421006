import { FILTER_TYPE, JOURNEY_TYPE } from 'src/app/app-constants';

import { EventEmitter } from 'events';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';

export class InvoiceChargesListConfigModel {
  static getInstance<T>(): InvoiceChargesListConfigModel {
    let model = new InvoiceChargesListConfigModel();
    return model;
  }

  public static readonly CALLER_TO_COMP_REFRESH_DATA = 'REFRESH_DATA';

  CompToCaller = new EventEmitter();
  CallerToComp = new EventEmitter();

  EventAction = new Map();
}

export class InvoiceChargesListDataModel {
  isGlobalParams: any;
  isSelfDataLoad: any;
  columnsToDisplay: any;
  globalParamterKeys: any;
  globalParameters: Map<any, any>;
  apireqdata: any;
  highlightRow: any;
  filterType: any;
  journeyType: any;
  apiUrls: InvoiceChargesListingAPIURL = new InvoiceChargesListingAPIURL();
  static getInstance<T>(): InvoiceChargesListDataModel {
    let model = new InvoiceChargesListDataModel();
    model.isSelfDataLoad = false;
    model.columnsToDisplay = undefined;
    model.highlightRow = undefined;
    model.globalParameters = new Map<any, any>();
    model.apireqdata = new InvoiceChargesListRequestModel();
    model.filterType = FILTER_TYPE.ADVANCE_FILTER;
    model.journeyType = JOURNEY_TYPE.NONE;
    return model;
  }
}

export class InvoiceChargesListRequestModel {
  skip: number = 0;
  top: number = MatTableHelper.PAGE_SIZE;
  pageno: number = 0;
  sortOrder: string;
  sortField: string;

  constructor() {
    this.skip = this.pageno * this.top;
  }
}

export interface ChargesRow {
  ChargeId: number;
  InvoiceId: number;
  WorkId: number;
  ObjectReference: string;
  ChargeNumber: string;
  GrantedDate: string;
  Amount: string;
  DiscountAmount: string;
  ActualAmount: string;
  ishighlight: string;
  Status: string;
  StatusMessage: string;
  LastUpdatedDate: string;
  ChargeTypeBreakdown: string;
  DraftInvoiceNumber: string;
  InvoiceType: string;
  AuthorityName: string;
}

export class InvoiceChargesListingAPIURL {
  advanceFilter: string = '';
  quickFilter: string = '';
  dynamicFilter: string = '';
}
