import { NotificationService } from 'src/app/services/notification.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DialogModel } from 'src/app/modules/shared/dialog/dialog-model';
import { PermitRow } from 'src/app/modules/widget-app/permit-list/permit-list.model';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { SaveFileService } from 'src/app/services/save-file.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportLogsListConfigModel, ExportLogsListDataModel } from './export-logs-record-list.model';
@Component({
  selector: 'app-export-logs-records-list',
  templateUrl: './export-logs-records-list.component.html',
  styleUrls: ['./export-logs-records-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ExportLogsRecordsListComponent extends WidgetComponentBase implements OnInit {

  @Input() dataModel: ExportLogsListDataModel;
  @Input() configModel: ExportLogsListConfigModel;

  dialogDeleteFileModel: DialogModel = new DialogModel();
  dataSource = new MatTableDataSource<PermitRow>();
  MAT_HELPER = MatTableHelper;
  DATA_HELPER = DataHelper;
  length: any;
  exportId: any;
  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private _baseSpinner: NgxSpinnerService,
    private _appRepoHelperService: AppRepoHelperService,
    private _router: Router,
    private _validationService: ValidationService,
    private _appRepoService: AppRepoService,
    private _saveFileService: SaveFileService
  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit(): void {
    this.wgOnInit();
    //  this.loadExportLogs();
  };

  /*   loadExportLogs() {
      this._baseSpinner.show();
      this._serverApi.get('/api/v1/export-data/get-cpm-export-logs-records', '').subscribe((response) => {
        this._baseSpinner.hide();
        this.convertData(response);
      }, (err) => {
        console.log('error', err);
        this._baseSpinner.hide();
      });
    } */

  convertData(response: any) {
    this._baseSpinner.hide();
    let items: any[] = [];
    response.data?.forEach((element) => {
      let item: any = {};
      item.export_id = element.export_id;
      item.export_name = element.export_name;
      item.no_of_records = element.no_of_records;
      item.file_name = element.file_name;
      item.object_ref_id = element.object_ref_id;
      item.file_size = element.file_size;
      item.status = element.status;
      item.create_date = element.create_date;
      items.push(item);
    });
    this.dataSource = new MatTableDataSource(items);
    this.length = response.totalElements;
  }

  // popups to be implemented in parent
  onDownloadClick(e, item) {
    e.stopPropagation();
    let url = `/api/v1/cpm/export-data/download-cpm-file/${item.object_ref_id}`;
    this._serverApi.download(url).subscribe((response) => {
      console.log(response);
      this._saveFileService.saveFile(response,item.file_name);
    }, (err) => { console.log('error', err) });
  }

  onDeleteIconClick(e, item) {
    e.stopPropagation();
    this.emitEvent('onDeleteIconClick', item.export_id);
  //  this.exportId = item.export_id;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  setFieldData() {

  }
  getControlData() {
    this._baseSpinner.show();
    return this.wgAPIMethodGet('/api/v1/cpm/export-data/get-cpm-export-logs-records', '');
  }
  setMode(responseDataModel: any) {

  }
  setValue(responseDataModel: any) {

  }
}
