<div class="detail-container mb-4" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Number </label>
    <p class="font-weight-bold">{{this.dataModel.data.chargeNumber}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Amount Assigned</label>
    <p>&pound;{{this.dataModel.data.chargeAmount}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Permit Type </label>
    <p>{{this.dataModel.data.permitType}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Response Due Date </label>
    <p>{{this.dataModel.data.dueDate | transformDateTime}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Type </label>
    <p class="status-pill">{{this.dataModel.data.chargeType}}</p>
  </div>
</div>
<hr>
<div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
  <div class="info-element" fxFlex="70" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Description </label>
    <p>{{this.dataModel.data.chargeDesc}}</p>
  </div>
  <!-- <div class="info-element" fxFlex="30" style="position: relative;">
    <mat-icon class="clear-icon" *ngIf="action.value" (click)="action.setValue(null)">clear</mat-icon>
    <mat-form-field>
      <mat-label>Action Liability</mat-label>
      <mat-select [formControl]="action" (selectionChange)="onChangeAction($event)">
        <mat-option required [value]="permitStatus.value" *ngFor="let permitStatus of arrActionLiability">
          {{permitStatus.display_text}}</mat-option>
      </mat-select>
      <mat-error>Field must be valid</mat-error>

    </mat-form-field>
  </div> -->
</div>
<hr>

<div class="form-group col">
  <form id="filter-form" class="d-flex flex-column p-3">


    <div class="fixed-underline" class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <div class="info-element" style="position: relative;" fxFlex="50" *ngIf="arrLiability.length > 0">
        <mat-icon class="clear-icon" *ngIf="liability.value" (click)="liability.setValue(null)">clear</mat-icon>
        <mat-form-field>
          <mat-label>Liability Owner</mat-label>
          <mat-select [disableOptionCentering]="true" [formControl]="liability" (selectionChange)="onChangeLiability($event)">
            <mat-option required [value]="permitStatus.liability_reference_id" *ngFor="let permitStatus of arrLiability">
              {{permitStatus.contractor_name}}</mat-option>
          </mat-select>
          <mat-error>Field must be valid</mat-error>

        </mat-form-field>
      </div>
      <div class="info-element" style="position: relative;" fxFlex="50">
        <mat-icon class="clear-icon" *ngIf="action.value" (click)="action.setValue(null)">clear</mat-icon>
        <mat-form-field>
          <mat-label>Action Liability</mat-label>
          <mat-select [formControl]="action" (selectionChange)="onChangeAction($event)">
            <mat-option required [value]="permitStatus.value" *ngFor="let permitStatus of arrActionLiability">
              {{permitStatus.display_text}}</mat-option>
          </mat-select>
          <mat-error>Field must be valid</mat-error>

        </mat-form-field>
      </div>
    </div>

    <div class="fixed-underline" class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="100" hintLabel={{notesMessage}}>
        <mat-label>Notes</mat-label>
        <textarea matInput placeholder="Notes" [maxLength]="500" rows="4" style="resize: none;" autocomplete="off" [formControl]="notes_text" (keyup)="onNotesChange($event.target.value)"></textarea>
        <mat-error>Max character limit 500</mat-error>
      </mat-form-field>
    </div>

    <div class="fixed-underline">
      <mat-form-field appearance="fill" fxFlex="100">
        <input matInput placeholder="Upload Document" autocomplete="off" readonly
          value="Upload Evidence (Optional)">
        <mat-icon class="upload" matSuffix (click)="openFileUpload()">attach_file</mat-icon>
      </mat-form-field>
    </div>
    <!-- <span style="color: red; font-weight: bold;" *ngIf="amountValidator">Split amount is Not Matching</span> -->
    <!-- <span style="color: red; font-size: 12px;" *ngIf="totalValidator">Split amount total should match charge amount.</span> -->
  </form>

  <hr>
</div>

