import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { GLOBAL_PERSISTANT_DATA } from 'src/app/constants/app-repo.constants';
import { NewReportingFilterConfigModel, NewReportingFilterDataModel } from './new-reporting-global-filter';

import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { Console } from 'console';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { MASTER_DATA_DOMAIN } from 'src/app/constants/db.constants';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { PrimeNGConfig } from 'primeng/api';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';
import { logging } from 'protractor';

@Component({
  selector: 'app-new-reporting-global-filter',
  templateUrl: './new-reporting-global-filter.component.html',
  styleUrls: ['./new-reporting-global-filter.component.css']
})
export class NewReportingGlobalFilterComponent extends WidgetComponentBase
implements OnInit, AfterViewInit {
  @Input() dataModel: NewReportingFilterDataModel;
  @Input() configModel: NewReportingFilterConfigModel;
  arrPromoterOrgnisation: any[] = [];
  arrContractors: any[] = [];
  allMap: any[] = [];
  allArrContractors: any[] = [];
  arrWorkstream: any[] = [];
  arrActions: any[] = []
  allArrActions: any[] = []
  allArrWorkstram: any[] = [];
  allArrHighwayAuthority: any[] = [];
  arrHighwayAuthority: any[] = [];
  selectedcontractor: any[] = [];
  selectedworkstream: any[] = [];
  selectedHighwayAuthority: any[] = [];
  selectedPromoterOrgnisation: any[] = [];
  contractorData = [];
  workstreamData = [];
  actionData = []
  highwayAuthorityData = [];
  promoterOrgnisationData = [];
  minDate: any = new Date();
  isSapphire: boolean = false;
  cities = [
    { name: 'Workstream 1', code: 'NY' },
    { name: 'Workstream 2', code: 'RM' },
    { name: 'Workstream 3', code: 'LDN' },
    { name: 'Workstream 4', code: 'IST' },
    { name: 'Workstream 5', code: 'PRS' },
  ];
  isvalidationset: boolean = true;

  constructor(
    private _serverApiBase: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private _validationService: ValidationService,
    private datePipe: DatePipe,
    private primengConfig: PrimeNGConfig,
    private _notificationService: NotificationService
  ) {
    super(_serverApiBase, _validationService);
    this.primengConfig.ripple = true;
  }

  ngOnInit(): void {
    this.wgFormData = new Object();
    this.createFormGroup();
    this.wgOnInit();
    // this.getActivityData()
  }

  ngAfterViewInit() {
   let elename = document.getElementsByClassName('picker')
    for (let i = 0; i < elename.length; i++) {
      const slide = elename[i] as HTMLElement;
      slide.style.visibility = "hidden";
  }
  }

  getActivityData() {
    this._serverApiBase.get<any>(`/api/v1/master-data/filter-map`).subscribe(
      response => {
        try {
          if (response) {
           console.log('Activity',response)
           this.arrPromoterOrgnisation = []
           this.allArrContractors = []
           this.allArrWorkstram = []
           this.arrContractors = []
           this.arrWorkstream = []
           this.arrHighwayAuthority = []
           response.data.forEach(item => {
            this.allArrActions.push(item)
           })

           this.arrActions = this.allArrActions.filter((thing, i, arr) => {
            return (
              arr.indexOf(
                arr.find((t: any) => t.activity === thing.activity)
              ) === i
            );
          });

          this.arrPromoterOrgnisation = this.allArrActions.filter((thing, i, arr) => {
            return (
              arr.indexOf(
                arr.find((t: any) => t.orgId === thing.orgId)
              ) === i
            );
          })

          this.arrPromoterOrgnisation.sort((a, b) => a.network.localeCompare(b.network));

          this.allArrContractors = this.allArrActions.filter((thing, i, arr) => {
            return (
              arr.indexOf(
                arr.find((t: any) => t.contractorId === thing.contractorId)
              ) === i
            );
          })

          this.allArrContractors.sort((a, b) => a.area.localeCompare(b.area));

          this.arrContractors = this.allArrContractors

          this.allArrWorkstram = this.allArrActions.filter((thing, i, arr) => {
            return (
              arr.indexOf(
                arr.find((t: any) => t.workstreamId === thing.workstreamId)
              ) === i
            );
          })

          this.allArrWorkstram.sort((a, b) => a.workstream.localeCompare(b.workstream));

          this.arrWorkstream = this.allArrWorkstram
          console.log(this.allArrActions)

           this.allArrHighwayAuthority = this.allArrActions.filter((thing, i, arr) => {
            return (
              arr.indexOf(
                arr.find((t: any) => t.authorityId === thing.authorityId)
              ) === i
            );
          })

          this.allArrHighwayAuthority.sort((a, b) => a.authorityName.localeCompare(b.authorityName));

          this.arrHighwayAuthority = this.allArrHighwayAuthority
          }
        } catch (e) {
          console.log(e)
        }
      }, error => {
        console.log('Activity Error:');
      }
    )
  }


  createFormGroup() {
    this.wgFormGroup = this.fb.group({
      fromDate: [''],
      toDate: [''],
      promoterOrganisation: [''],
      activity: [''],
      contractor: [''],
      workstream: [''],
      highwayAuthority: [''],
    });
  }

  setFieldData() {
    // this.arrWorkstream = this._appRepoHelperService.getMDataByDomain(
    //   MASTER_DATA_DOMAIN.WORKSTREAM
    // );
    // this.arrWorkstream = this.arrWorkstream.filter((thing, i, arr) => {
    //   arr.indexOf(
    //     arr.find((t: any) => t.workstream_id === thing.worksteram_id) === i
    //   );
    // });
    // this.allArrContractors = this._appRepoHelperService.getMDataByDomain(
    //   MASTER_DATA_DOMAIN.USER_ACCESS_CONTRACTOR
    // );

    // this.allArrContractors.sort((a, b) => a.contractor_name.localeCompare(b.contractor_name));

    // this.arrContractors = this.allArrContractors.filter((thing, i, arr) => {
    //   return (
    //     arr.indexOf(
    //       arr.find((t: any) => t.contractor_id === thing.contractor_id)
    //     ) === i
    //   );
    // });

    // this.allArrWorkstram = this._appRepoHelperService.getMDataByDomain(
    //   MASTER_DATA_DOMAIN.USER_ACCESS_WROK_STREAM
    // );

    // this.allArrWorkstram.sort((a, b) => a.description.localeCompare(b.description));
    // this.arrWorkstream = this.allArrWorkstram.filter((thing, i, arr) => {
    //   return (
    //     arr.indexOf(
    //       arr.find((t: any) => t.workstreamId === thing.workstreamId)
    //     ) === i
    //   );
    // });

    // this.arrPromoterOrgnisation = this._appRepoHelperService.getMDataByDomain(
    //   MASTER_DATA_DOMAIN.USER_ACCESS_ORG
    // );
    // this.arrPromoterOrgnisation.sort((a, b) => a.name.localeCompare(b.name));

    // this.arrHighwayAuthority = this._appRepoHelperService.getMDataByDomain(
    //   MASTER_DATA_DOMAIN.HIGHWAY_AUTORITIES
    // );
    // this.arrHighwayAuthority.sort((a, b) => a.name.localeCompare(b.name));

    this.allArrActions = this._appRepoHelperService.getAllFilterMap()
    // console.log('Filter data',this.allArrActions)

    this.arrActions = this.allArrActions.filter((thing, i, arr) => {
      return (
        arr.indexOf(
          arr.find((t: any) => t.activity === thing.activity)
        ) === i
      );
    });

    this.arrPromoterOrgnisation = this.allArrActions.filter((thing, i, arr) => {
      return (
        arr.indexOf(
          arr.find((t: any) => t.orgId === thing.orgId)
        ) === i
      );
    })

    this.allArrContractors = this.allArrActions.filter((thing, i, arr) => {
      return (
        arr.indexOf(
          arr.find((t: any) => t.contractorId === thing.contractorId)
        ) === i
      );
    })

    this.arrContractors = this.allArrContractors

    this.allArrWorkstram = this.allArrActions.filter((thing, i, arr) => {
      return (
        arr.indexOf(
          arr.find((t: any) => t.workstreamId === thing.workstreamId)
        ) === i
      );
    })

    this.arrWorkstream = this.allArrWorkstram

     this.allArrHighwayAuthority = this.allArrActions.filter((thing, i, arr) => {
      return (
        arr.indexOf(
          arr.find((t: any) => t.authorityId === thing.authorityId)
        ) === i
      );
    })

    this.arrHighwayAuthority = this.allArrHighwayAuthority

    this.ascendOrder()
    // console.log(this.arrHighwayAuthority)

  }

  ascendOrder() {
    this.arrPromoterOrgnisation.sort((a, b) => a.network.localeCompare(b.network));
    this.arrActions.sort((a, b) => a.activity.localeCompare(b.activity));
    this.arrWorkstream.sort((a, b) => a.workstream.localeCompare(b.workstream));
    this.arrContractors.sort((a, b) => a.area.localeCompare(b.area));
    this.arrHighwayAuthority.sort((a, b) => a.authorityName.localeCompare(b.authorityName));
  }

  getControlData() {
    return new Promise((resolve, rejects) => {
      resolve(null);
    });
  }
  convertData(response: any) {}
  setMode(responseDataModel: any) {}
  setValue() {
    let contracotr: any = this._appRepoHelperService.getGlobalPersistantData(
      GLOBAL_PERSISTANT_DATA.SELECTED_CONTRACTOR
    );
    let workStream: any = this._appRepoHelperService.getGlobalPersistantData(
      GLOBAL_PERSISTANT_DATA.SELECTED_WORKSTREAM
    );
    let organisation: any = this._appRepoHelperService.getGlobalPersistantData(
      GLOBAL_PERSISTANT_DATA.SELECTED_PROMOTER_ORGNISATION
    );
    let highwayAuthority: any =
      this._appRepoHelperService.getGlobalPersistantData(
        GLOBAL_PERSISTANT_DATA.SELECTED_HIGHWAY_AUTHORITY
      );

    // let startDate: any = this._appRepoHelperService.getGlobalPersistantData(
    //   GLOBAL_PERSISTANT_DATA.START_DATE
    // );

    // let toDate: any = this._appRepoHelperService.getGlobalPersistantData(
    //   GLOBAL_PERSISTANT_DATA.TO_DATE
    // );

    // let actions: any = this._appRepoHelperService.getGlobalPersistantData(
    //   GLOBAL_PERSISTANT_DATA.SELECTED_ACTIVITY
    // )

    if (organisation) {
      this.promoterOrgnisationData = [];
      this.promoterOrgnisationData.length = 0;
      organisation.forEach((item) => {
        this.promoterOrgnisationData.push(item);
      });
      this.onChangePromoterOrgnisation(this.promoterOrgnisationData)
    }

    if (contracotr) {
      this.contractorData = [];
      this.contractorData.length = 0;
      contracotr.forEach((item) => {
        this.contractorData.push(item);
      });

      this.onChangeContractor(this.contractorData)
    }
    if (workStream) {
      this.workstreamData = [];
      this.workstreamData.length = 0;
      workStream.forEach((item) => {
        this.workstreamData.push(item);
      });
      this.onChangeWorkstream(this.workstreamData)
    }
    // if (actions) {
    //   this.actionData = [];
    //   this.actionData.length = 0;
    //   actions.forEach((item) => {
    //     this.actionData.push(item);
    //   });
    // }

    if (highwayAuthority) {
      this.highwayAuthorityData = [];
      this.highwayAuthorityData.length = 0;
      highwayAuthority.forEach((item) => {
        this.highwayAuthorityData.push(item);
      });
      this.onChangeHighwayAuthority(this.highwayAuthorityData)
    }

    // let sDate = startDate ? startDate : null;
    // let eDate = toDate ? toDate : null;

    // if (sDate != null)
    //   this.wgFormGroup.controls.fromDate.setValue(new Date(sDate));
    //   this.checkValueDate(sDate , 'fromDate')
    // if (eDate != null)
    //   this.wgFormGroup.controls.toDate.setValue(new Date(eDate));
    //   this.checkValueDate(eDate , 'toDate')

    // if (sDate != null && eDate != null) this.isvalidationset = false;/

    this.wgFormGroup.controls.contractor.setValue(contracotr);
    this.wgFormGroup.controls.workstream.setValue(workStream);
    this.wgFormGroup.controls.promoterOrganisation.setValue(organisation);
    this.wgFormGroup.controls.highwayAuthority.setValue(highwayAuthority);
    // this.wgFormGroup.controls.activity.setValue(actions);

    // if(organisation != null) {
    //   this.checkValue(organisation, 'Org')
    // }
    // if(contracotr != null) {
    //   this.checkValue(contracotr, 'con')
    // }
    // if(highwayAuthority != null) {
    //   this.checkValue(highwayAuthority, 'ha')
    // }
    // if(workStream != null) {
    //   this.checkValue(workStream, 'work')
    // }


    // console.log("organisation",organisation)

    // this.selectedPromoterOrgnisation = [];

    // this.selectedPromoterOrgnisation = this.arrPromoterOrgnisation.filter((d: any) => { return organisation.indexOf(d.orgId) > -1 });

    // this.selectedcontractor = [];
    // this.selectedcontractor = this.allArrContractors.filter((d: any) => { return contracotr.indexOf(d.contractor_id) > -1 });

    // this.selectedHighwayAuthority = [];
    // this.selectedHighwayAuthority = this.arrHighwayAuthority.filter((d: any) => { return highwayAuthority.indexOf(d.authorityId) > -1 });
  }

  getValue() {
    // const contractor = this.wgFormGroup.controls.contractor.value ?
    //   this.wgFormGroup.controls.contractor.value : null;
    // this.wgFormData.contractor = contractor;
    const fromDate = this.wgFormGroup.get('fromDate').value;
    this.wgFormData.fromDate = this.datePipe.transform(fromDate, 'shortDate');
    const toDate = this.wgFormGroup.get('toDate').value;
    this.wgFormData.toDate = this.datePipe.transform(toDate, 'shortDate');

    const contractor =
      this.contractorData.length > 0 ? this.contractorData : null;
    this.wgFormData.contractor = contractor;

    const workstream =
      this.workstreamData.length > 0 ? this.workstreamData : null;
    this.wgFormData.workstream = workstream;

    const actions =
      this.actionData.length > 0 ? this.actionData : null;
    this.wgFormData.activity = actions;
    // const promoterOrganisation = this.wgFormGroup.controls.promoterOrganisation.value ?
    //   this.wgFormGroup.controls.promoterOrganisation.value : null;
    // this.wgFormData.promoterOrganisation = promoterOrganisation;

    const promoterOrganisation =
      this.promoterOrgnisationData.length > 0
        ? this.promoterOrgnisationData
        : null;
    this.wgFormData.promoterOrganisation = promoterOrganisation;

    // const highwayAuthority = this.wgFormGroup.controls.highwayAuthority.value ?
    //   this.wgFormGroup.controls.highwayAuthority.value : null;
    // this.wgFormData.highwayAuthority = highwayAuthority;

    const highwayAuthority =
      this.highwayAuthorityData.length > 0 ? this.highwayAuthorityData : null;
    this.wgFormData.highwayAuthority = highwayAuthority;

    return this.wgFormData;
  }

  onIndividualClear(event, value) {
    event.stopPropagation();
      this.wgFormGroup.controls[value].setValue(null);
      switch (value) {
        case 'promoterOrganisation':
          this.onChangePromoterOrgnisation([]);
        break;
        case 'workstream':
          this.onChangeWorkstream([]);
        break;
        case 'contractor':
          this.onChangeContractor([]);
        break;
        case 'highwayAuthority':
          this.onChangeHighwayAuthority([]);
        break;
        default:
          break;
      }
  }

  onClear() {
    this.wgFormGroup.reset()
    this.promoterOrgnisationData = []
    this.workstreamData = []
    this.contractorData = []
    this.actionData = []
    this.highwayAuthorityData = []
    this.reserFilterValues()
    this.emitEvent('ON_CLOSURE_FILTER_CHANGE')
  }

  reserFilterValues() {
    this.arrWorkstream = this.allArrWorkstram.filter((thing, i, arr) => {
      return (
        arr.indexOf(
          arr.find((t: any) => t.workstreamId === thing.workstreamId)
        ) === i
      );
    });

    this.arrContractors = this.allArrContractors.filter((thing, i, arr) => {
      return (
        arr.indexOf(
          arr.find((t: any) => t.contractorId === thing.contractorId)
        ) === i
      );
    });

    this.arrActions = this.allArrActions.filter((thing, i, arr) => {
      return (
        arr.indexOf(
          arr.find((t: any) => t.activity === thing.activity)
        ) === i
      );
    });

    this.arrHighwayAuthority = this.allArrHighwayAuthority.filter((thing, i, arr) => {
      return (
        arr.indexOf(
          arr.find((t: any) => t.authorityId === thing.authorityId)
        ) === i
      );
    });

    this.arrPromoterOrgnisation = this.allArrActions.filter((thing, i, arr) => {
      return (
        arr.indexOf(
          arr.find((t: any) => t.orgId === thing.orgId)
        ) === i
      );
    });

    this.ascendOrder()

  }

  checkValue(value: any, id){
    if (typeof value == 'object') {
      console.log(typeof value)
      console.log(value)
      if(value.length >= 1) {
        document.getElementById(id).style.visibility = 'visible'
      }else {
        document.getElementById(id).style.visibility = 'hidden'
      }
    } else {
      if(value != null) {
        document.getElementById(id).style.visibility = 'visible'
      }else {
        document.getElementById(id).style.visibility = 'hidden'
      }
    }
  }

  checkValueDate(value: any, id) {
    if(value != null) {
      document.getElementById(id).style.visibility = 'visible'
    }else {
      document.getElementById(id).style.visibility = 'hidden'
    }
  }

  onChangePromoterOrgnisation(value: any) {
    // console.log("value",typeof value)
    // this.wgFormGroup.controls.contractor.setValue(null);
    // this.wgFormGroup.controls.workstream.setValue(null);
    // this.checkValue(value, 'Org')
    // this.checkValue(this.wgFormGroup.controls.contractor.setValue(null), 'con')
    // this.checkValue(this.wgFormGroup.controls.workstream.setValue(null), 'work')

    this.promoterOrgnisationData = [];
    this.promoterOrgnisationData.length = 0;
    value.forEach((item) => {
      this.promoterOrgnisationData.push(item);
    });
    this.onChangeOptions('Promoter')
    return;

    // console.log("promoterOrgnisationData",this.promoterOrgnisationData)

    if (this.promoterOrgnisationData.length > 0) {
      let filterArr = []

      filterArr = this.allArrActions.filter((d: any) => {
        return this.promoterOrgnisationData.indexOf(d.orgId) > -1;
      });

      if(this.contractorData.length == 0) {

        this.arrContractors = filterArr.filter((thing, i, arr) => {
          return (
            arr.indexOf(
              arr.find((t: any) => t.contractorId === thing.contractorId)
            ) === i
          );
        });
      }

      if(this.workstreamData.length == 0) {

        this.arrWorkstream = filterArr.filter((thing, i, arr) => {
          return (
            arr.indexOf(
              arr.find((t: any) => t.workstreamId === thing.workstreamId)
            ) === i
          );
        });
      }


      if(this.actionData.length == 0) {

        this.arrActions = filterArr.filter((thing, i, arr) => {
          return (
            arr.indexOf(
              arr.find((t: any) => t.activity === thing.activity)
              ) === i
              );
        });
      }

    if(this.highwayAuthorityData.length == 0) {

      this.arrHighwayAuthority = this.arrHighwayAuthority.filter(filter =>
        filterArr.some(t => filter.authorityId === t.authorityId)
      )

      this.arrHighwayAuthority = this.arrHighwayAuthority.filter((thing, i, arr) => {
        return (
          arr.indexOf(
            arr.find((t: any) => t.authorityId === thing.authorityId)
            ) === i
            );
      });
    }

    } else {
      this.reserFilterValues()
    }

    if(this.promoterOrgnisationData.length == 0) {

      //commented code is for filter back all values are selected in workstream and contractor

      // let workstreamId = []
      // let contractorId = []
      // this.allArrWorkstram.forEach(item => {
      //   workstreamId.push(item.workstreamId)
      // })
      // this.allArrContractors.forEach(item => {
      //   contractorId.push(item.contractor_id)
      // })
      this.workstreamData = []
      this.contractorData = []
      this.actionData = []
    }
    // console.log('org', this.arrPromoterOrgnisation)
    // console.log('action', this.arrActions)
    // console.log('ws', this.arrWorkstream)
    // console.log('con', this.arrContractors)
    // console.log('Ha', this.arrHighwayAuthority)
    this.ascendOrder()
    // this.emitEvent('ON_CLOSURE_FILTER_CHANGE', this.promoterOrgnisationData);
  }

  onChangeActions(value: any) {
    this.actionData = [];
    this.actionData.length = 0;
    value.forEach((item) => {
      this.actionData.push(item);
    });
    this.onChangeOptions('Actions')
    return;

    let filterArr = []

    if(this.actionData.length > 0) {

      filterArr = this.allArrActions.filter((d: any) => {
        return this.actionData.find(ele => ele == d.activity)
      });

      if(this.promoterOrgnisationData.length > 0) {

        if(this.workstreamData.length == 0) {
          this.arrWorkstream = filterArr.filter((d: any) => {
          return this.promoterOrgnisationData.indexOf(d.orgId) > -1;
        });
        }

        if(this.contractorData.length == 0) {
          this.arrContractors = filterArr.filter((d: any) => {
            return this.promoterOrgnisationData.indexOf(d.orgId) > -1;
          });
        }

        if(this.highwayAuthorityData.length == 0) {
          this.arrHighwayAuthority = filterArr.filter((d: any) => {
            return this.promoterOrgnisationData.indexOf(d.orgId) > -1;
          });
        }

      } else {
        if(this.workstreamData.length == 0) {
          this.arrWorkstream = filterArr.filter((d: any) => {
            return this.actionData.indexOf(d.activity) > -1;
          });
        }

        if(this.contractorData.length == 0) {
          this.arrContractors = filterArr.filter((d: any) => {
            return this.actionData.indexOf(d.activity) > -1;
          });
        }

        if(this.highwayAuthorityData.length == 0) {
          this.arrHighwayAuthority = this.arrHighwayAuthority.filter((d: any) => {
            return this.actionData.indexOf(d.activity) > -1;
          });
        }
      }

    if(this.workstreamData.length == 0) {
      this.arrWorkstream = this.arrWorkstream.filter((thing, i, arr) => {
        return (
          arr.indexOf(
            arr.find((t: any) => t.workstreamId === thing.workstreamId)
            ) === i
            );
      });
    }

    if(this.contractorData.length == 0) {

      this.arrContractors = this.arrContractors.filter((thing, i, arr) => {
        return (
          arr.indexOf(
            arr.find((t: any) => t.contractorId === thing.contractorId)
            ) === i
            );
      });
    }

    // if(this.promoterOrgnisationData.length == 0) {

    //   this.arrPromoterOrgnisation = filterArr.filter((thing, i, arr) => {
    //     return (
    //       arr.indexOf(
    //         arr.find((t: any) => t.orgId === thing.orgId)
    //         ) === i
    //         );
    //   });
    // }

    if(this.highwayAuthorityData.length == 0) {
      // this.arrHighwayAuthority = this.arrHighwayAuthority.filter(contractor =>
      //   filterArr.some(filter => filter.authorityId === contractor.authorityId)
      // );

      this.arrHighwayAuthority = this.arrHighwayAuthority.filter((thing, i, arr) => {
        return (
          arr.indexOf(
            arr.find((t: any) => t.authorityId === thing.authorityId)
            ) === i
            );
      });
    }

    }

    if(this.actionData.length == 0) {
      this.arrWorkstream = this.allArrWorkstram.filter((thing, i, arr) => {
        return (
          arr.indexOf(
            arr.find((t: any) => t.workstreamId === thing.workstreamId)
          ) === i
        );
      });

      this.arrContractors = this.allArrContractors.filter((thing, i, arr) => {
        return (
          arr.indexOf(
            arr.find((t: any) => t.contractorId === thing.contractorId)
          ) === i
        );
      });

      this.arrHighwayAuthority = this.allArrHighwayAuthority.filter((thing, i, arr) => {
        return (
          arr.indexOf(
            arr.find((t: any) => t.authorityId === thing.authorityId)
          ) === i
        );
      });
    }
    // console.log('org', this.arrPromoterOrgnisation)
    // console.log('action', this.arrActions)
    // console.log('ws', this.arrWorkstream)
    // console.log('con', this.arrContractors)
    // console.log('Ha', this.arrHighwayAuthority)
    this.ascendOrder()
  }

  onFromDateSelect() {
    const fromDate = this.wgFormGroup.get('fromDate').value;
    const toDate = this.wgFormGroup.get('toDate').value;
    // this.checkValueDate(fromDate, 'fromDate')
    // console.log('todate', toDate);
    if (toDate !== '' && toDate != undefined) {
      this.compareDates(fromDate, toDate);
    }
  }
  onToDateSelect() {
    const fromDate = this.wgFormGroup.get('fromDate').value;
    const toDate = this.wgFormGroup.get('toDate').value;
    // this.checkValueDate(toDate, 'toDate')
    if (fromDate !== '') {
      this.compareDates(fromDate, toDate);
    }
  }

  compareDates(toDateCtrl, fromDateCtrl) {
    let fromDateValue = new Date(fromDateCtrl).getTime();
    let toDateValue = new Date(toDateCtrl).getTime();
    if (fromDateValue >= toDateValue) {
      // this.emitEvent('ON_CLOSURE_FILTER_CHANGE');
    } else {
      this._notificationService.error('From Date should be less than To Date');
    }

  }

  onChangeContractor(value) {
    // this.checkValue(value, 'con')
    this.contractorData = [];
    this.contractorData.length = 0;
    value.forEach((item) => {
      this.contractorData.push(item);
    });
    this.onChangeOptions('Contractor')
    return;

    if(this.contractorData.length > 0) {

      let filterArr = []

      filterArr = this.allArrActions.filter((d: any) => {
        return this.contractorData.indexOf(d.contractorId) > -1;
      });

      if(this.workstreamData.length == 0) {
        this.arrWorkstream = filterArr.filter((thing, i, arr) => {
          return (
            arr.indexOf(
              arr.find((t: any) => t.workstreamId === thing.workstreamId)
              ) === i
              );
        });
      }

      if(this.actionData.length == 0) {
        this.arrActions = filterArr.filter((thing, i, arr) => {
          return (
            arr.indexOf(
              arr.find((t: any) => t.activity === thing.activity)
              ) === i
              );
        });
      }

      if(this.promoterOrgnisationData.length == 0) {
        this.arrPromoterOrgnisation = filterArr.filter((thing, i, arr) => {
          return (
            arr.indexOf(
              arr.find((t: any) => t.orgId === thing.orgId)
              ) === i
              );
        });
      }

      if(this.highwayAuthorityData.length == 0) {
        this.arrHighwayAuthority = this.arrHighwayAuthority.filter((thing, i, arr) => {
          return (
            arr.indexOf(
              arr.find((t: any) => t.authorityId === thing.authorityId)
              ) === i
              );
        });
      }
    }
    // console.log('org', this.arrPromoterOrgnisation)
    // console.log('action', this.arrActions)
    // console.log('ws', this.arrWorkstream)
    // console.log('con', this.arrContractors)
    // console.log('Ha', this.arrHighwayAuthority)
    this.ascendOrder()
    // this.emitEvent('ON_CLOSURE_FILTER_CHANGE', this.contractorData);
  }

  onChangeWorkstream(value) {
    // this.checkValue(value, 'work')
    this.workstreamData = [];
    this.workstreamData.length = 0;
    value.forEach((item) => {
      this.workstreamData.push(item);
    });
    this.onChangeOptions('Workstream')
    return;

    let filterArr = []

    if(this.workstreamData.length > 0) {

      filterArr = this.allArrActions.filter((d: any) => {
        return this.workstreamData.indexOf(d.workstreamId) > -1;
      });

      if(this.contractorData.length == 0) {
        this.arrContractors = filterArr.filter((thing, i, arr) => {
          return (
            arr.indexOf(
              arr.find((t: any) => t.contractorId === thing.contractorId)
              ) === i
              );
        });
      }

      if(this.actionData.length == 0) {
        this.arrActions = filterArr.filter((thing, i, arr) => {
          return (
            arr.indexOf(
              arr.find((t: any) => t.activity === thing.activity)
              ) === i
              );
        });
      }

      if(this.promoterOrgnisationData.length == 0) {
        this.arrPromoterOrgnisation = filterArr.filter((thing, i, arr) => {
          return (
            arr.indexOf(
              arr.find((t: any) => t.orgId === thing.orgId)
              ) === i
              );
        });
      }

      if(this.highwayAuthorityData.length == 0) {
        this.arrHighwayAuthority = filterArr.filter((thing, i, arr) => {
          return (
            arr.indexOf(
              arr.find((t: any) => t.authorityId === thing.authorityId)
              ) === i
              );
        });
      }
    }
    // console.log('org', this.arrPromoterOrgnisation)
    // console.log('action', this.arrActions)
    // console.log('ws', this.arrWorkstream)
    // console.log('con', this.arrContractors)
    // console.log('Ha', this.arrHighwayAuthority)
    this.ascendOrder()
    // this.emitEvent('ON_CLOSURE_FILTER_CHANGE', this.workstreamData);
  }

  onChangeHighwayAuthority(value) {
    // this.checkValue(value, 'ha')
    this.highwayAuthorityData = [];
    this.highwayAuthorityData.length = 0;
    value.forEach((item) => {
      this.highwayAuthorityData.push(item);
    });
    this.onChangeOptions('HighwayAuthority')
    return

    let filterArr = []

    if(this.highwayAuthorityData.length > 0) {
      filterArr = this.allArrActions.filter((d: any) => {
        return this.highwayAuthorityData.indexOf(d.authorityId) > -1;
      });

      if(this.contractorData.length == 0) {
        this.arrContractors = this.allArrContractors.filter(contractor =>
          filterArr.some(filter => filter.contractorId === contractor.contractorId)
        );
      }

      if(this.actionData.length == 0) {
        this.arrActions = this.allArrActions.filter((thing, i, arr) => {
          return (
            arr.indexOf(
              arr.find((t: any) => t.activity === thing.activity)
              ) === i
              );
        });
      }

      if(this.promoterOrgnisationData.length == 0) {
        this.arrPromoterOrgnisation = this.arrPromoterOrgnisation.filter(contractor =>
          filterArr.some(filter => filter.orgId === contractor.orgId)
        );
      }

      if(this.workstreamData.length == 0) {
        this.arrWorkstream = this.allArrWorkstram.filter(contractor =>
          filterArr.some(filter => filter.workstreamId === contractor.workstreamId)
        );
      }

    }
    //   console.log('org', this.arrPromoterOrgnisation)
    // console.log('action', this.arrActions)
    // console.log('ws', this.arrWorkstream)
    // console.log('con', this.arrContractors)
    // console.log('Ha', this.arrHighwayAuthority)
    this.ascendOrder()
    // this.emitEvent('ON_CLOSURE_FILTER_CHANGE', this.highwayAuthorityData);
  }

  onChangeOptions(filterName) {
    let filterArr = []

    filterArr = this.allArrActions

    if(this.promoterOrgnisationData.length > 0) {
      filterArr = filterArr.filter((d: any) => {
        return this.promoterOrgnisationData.indexOf(d.orgId) > -1;
      });
    }
    if(this.workstreamData.length > 0) {
      filterArr = filterArr.filter((d: any) => {
        return this.workstreamData.indexOf(d.workstreamId) > -1;
      });
    }
    if(this.contractorData.length > 0) {
      filterArr = filterArr.filter((d: any) => {
        return this.contractorData.indexOf(d.contractorId) > -1;
      });
    }
    if(this.actionData.length > 0) {
      filterArr = filterArr.filter((d: any) => {
        return this.actionData.indexOf(d.ldp) > -1;
      });
    }
    if(this.highwayAuthorityData.length > 0) {
      filterArr = filterArr.filter((d: any) => {
        return this.highwayAuthorityData.indexOf(d.authorityId) > -1;
      });
    }
    if(this.promoterOrgnisationData.length == 0) {
      this.arrPromoterOrgnisation = filterArr.filter((thing, i, arr) => {
        return (
          arr.indexOf(
            arr.find((t: any) => t.orgId === thing.orgId)
          ) === i
        );
      });
    }
    if(this.workstreamData.length == 0) {
      this.arrWorkstream = filterArr.filter((thing, i, arr) => {
        return (
          arr.indexOf(
            arr.find((t: any) => t.workstreamId === thing.workstreamId)
          ) === i
        );
      });
    }
    if(this.contractorData.length == 0) {
      this.arrContractors = filterArr.filter((thing, i, arr) => {
        return (
          arr.indexOf(
            arr.find((t: any) => t.contractorId === thing.contractorId)
          ) === i
        );
      });
    }
    if(this.actionData.length == 0) {
      this.arrActions = filterArr.filter((thing, i, arr) => {
        return (
          arr.indexOf(
            arr.find((t: any) => t.ldp === thing.ldp)
          ) === i
        );
      });
    }
    if(this.highwayAuthorityData.length == 0) {
      this.arrHighwayAuthority = filterArr.filter((thing, i, arr) => {
        return (
          arr.indexOf(
            arr.find((t: any) => t.authorityId === thing.authorityId)
          ) === i
        );
      });
    }
    this.ascendOrder()
  }

  onSearchGlobalFilter() {
    this.emitEvent('ON_CLOSURE_FILTER_CHANGE')
  }
}
