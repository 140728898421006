import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { SapActivityLogDataModel, SapActivityLogConfigModel, SapActivityLogRow, SapActivityLogRequestModel } from './sap-activity-log-model';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-sap-activity-log',
  templateUrl: './sap-activity-log.component.html',
  styleUrls: ['./sap-activity-log.component.css']
})
export class SapActivityLogComponent extends WidgetComponentBase implements OnInit {
  @Input() dataModel: SapActivityLogDataModel;
  @Input() configModel: SapActivityLogConfigModel;

  dataSource = new MatTableDataSource<SapActivityLogRow>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  searchInput = new FormControl('');
  pageChangeSubscription: any;
  length: any;
  MAT_HELPER = MatTableHelper;
  DATA_HELPER = DataHelper;
  sortFieldLabel: string = '';

  expandedElement: SapActivityLogRow;

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _router: Router,
    public dialog: MatDialog,
    private _notificationService: NotificationService,
    private _validationService: ValidationService
  ) {
    super(_serverApi, _validationService);
    // this.searchInput.valueChanges.subscribe(this.onSearchValueChange);
  }
  getControlData() {
    //let id = this.dataModel.globalParameters.get('applicationId');
    return this.wgAPIMethodGet(this.dataModel.widgetApiUrl, this.dataModel.apireqdata);
  }
  convertData(response: any) {
    if (response) {
      console.log('api response interface tab', response);
      let items: SapActivityLogRow[] = [];
      response.content.forEach((element) => {
        let item: any = {};
        item.interface_name = element.interface_name;
        item.response = element.response;
        item.request = element.request;
        item.status = element.status;
        item.created_by = element.created_by;
        item.created_date = element.created_date;
        item.isSliced = true;
        items.push(item);
      });
      this.dataSource = new MatTableDataSource(items);
      this.length = response.totalElements;
    }
  }
  setFieldData() {}

  setMode(responseDataModel: any) {}

  setValue(responseDataModel: any) {}

  applyFilter(filterValue: string) {}

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.pageChangeSubscription = this.paginator.page.subscribe(() => {
      this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
      this.dataModel.apireqdata.top = this.paginator.pageSize;
      this.dataModel.apireqdata.skip =
        this.dataModel.apireqdata.pageno *
        this.dataModel.apireqdata.top;
      //  TODO: will update this
      this.wgRefreshData();
    });

    // TODO: attach local handlers
    this.wgOnInit();
    // console.log('onInit', this.dataSource);
  }
  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      if (this.sort.direction) {
        this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();

        switch (this.sort.active) {
          case 'InterfaceName':
            this.sortFieldLabel = 'INTERFACE_NAME';
            break;
          case 'Status':
            this.sortFieldLabel = 'STATUS';
            break;
          // case 'CreatedBy':
          //   this.sortFieldLabel = 'UPDATED_BY';
          //   break;
          case 'Date':
            this.sortFieldLabel = 'CREATE_DATE';
            break;
          default:
            break;
        }

        this.dataModel.apireqdata.sortField = this.sortFieldLabel;

        this.wgRefreshData();
      }
      else {
        try {
          delete this.dataModel.apireqdata.sortOrder;
          delete this.dataModel.apireqdata.sortField;
        }
        catch (err) {
        }
      }
    });
  }
  openDialog(rowData) {
    this.emitEvent('onViewSapActivityLog', rowData);
  }

  onAddComment() {
    this.emitEvent('onAddComment');
  }

  search() {
    let searchString = this.searchInput.value;
    this.dataModel.apireqdata.searchString = searchString;
    this.wgRefreshData()
  }

  ngOnDestroy(): void {
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
  }

  clearSearch() {
    this.searchInput.reset();
    this.dataModel.apireqdata = new SapActivityLogRequestModel()
    this.wgRefreshData()
  }

  onSearchValueChange(searchVal) {
    let clearSearch = document.getElementsByClassName('clear-search');
    let slide: any;
    for (let i = 0; i < clearSearch.length; i++) {
      slide = clearSearch[i] as HTMLElement;
    }
    if (searchVal) {
      slide.style.visibility = 'visible';
    } else {
      slide.style.visibility = 'hidden';
    }
  }

    // Minimize Functionaltity
    expandRow(rowData, i) {
      if (rowData.isSliced == true) {
        this.dataSource.data[i].isSliced = false;
      } else {
        this.dataSource.data[i].isSliced = true;
      }
    }

    showMore(value, isSliced) {
      if (value) {
        if (value.length > 100 && !!isSliced) {
          return '...more';
        } else if (value.length < 100 && !!isSliced) {
          return;
        } else {
          return '...less';
        }
      }
    }

    getStatusCssClass(element) {
      switch (element) {
        case 'SUCCESS':
          return 'success-status';
        case 'Success with Warnings':
          return 'successwithwarnings-status';
        case 'FAILED':
          return 'failed-status';
        case 'DELETED':
          return 'deleted-status';
        default:
          return '';
      }
    }
}
