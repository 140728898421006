import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  ApproveChargeFormConfigModel,
  ApproveChargeFormModel,
} from './approve-charge-form.model';
import { MatDialog } from '@angular/material/dialog';
import { CHARGE_FORMS_NAME, GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { PERMIT_ACTIONS_CONFIG } from 'src/app/constants/db.constants';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { SaveFileService } from 'src/app/services/save-file.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';

@Component({
  selector: 'app-approve-charge-form',
  templateUrl: './approve-charge-form.component.html',
  styleUrls: ['./approve-charge-form.component.css'],
})
export class ApproveChargeFormComponent
  extends WidgetComponentBase
  implements OnInit
{
  @Input() dataModel: ApproveChargeFormModel;
  @Input() configModel: ApproveChargeFormConfigModel;

  ApproveChargeForm: FormGroup;
  notesMessage: string = 'Max 500 characters.';

  arrLiabilityOrg: any[] = [];
  arrLiabilityOwner: any[] = [];
  arrApprovalReqFrom: any[] = [];
  maxDate: any;
  allApprovalReqFrom: any[] = [];

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _validationService: ValidationService,
    private _saveFileService: SaveFileService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit(): void {
    super.wgOnInit();

    this.createFormGroup();
  }
  OnDestroy() {
    this.ApproveChargeForm.reset();
  }
  createFormGroup(): void {
    this.ApproveChargeForm = this.fb.group({
      draftInvoiceAmount: ['', [Validators.required]],
      // chargeAmount: ['', [Validators.required]],
      finalInvoiceAmount: ['', [Validators.required]],
      invoiceStatusValidated: ['', [Validators.required]],
      notes: ['', [Validators.maxLength(500)]],
      approvalReqFrom: [''],
      approvalDate: ['', [Validators.required]],
      approvalNotes: [''],
      fileUp: ['']
    });
  }
  setFieldData() {}
  stopPropagation(event) {
    event.stopPropagation();
  }
  setMode(responseDataModel: any) {}
  setValue(responseDataModel: any) {}
  getControlData() {
    // let id = this.dataModel.globalParameters.get('applicationId');
    // // INFO: ServerAPI
    let url = `/api/v1/cpm/actions/getDetails`;
    return this.wgAPIMethodGet(url, this.dataModel.apireqdata);
    // return new Promise((resolve, reject) => {
    //   let permitform: any = this.dataModel.globalParameters.get(
    //     GLOBAL_PARAM_KEY.VIEW_PERMIT_FORM
    //   );
    //   permitform = permitform ? permitform : new Object();
    //   if (permitform) {
    //     resolve(permitform);
    //   } else {
    //     reject(permitform);
    //   }
    // });
  }
  onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file) {
      const formData = new FormData();

      formData.append('thumbnail', file);

      console.log(file);
    }
  }
  convertData(response: any) {
    if (!response) return;

    console.log('respoine of overview', response);

    this.dataModel.data.permitType = response.permitType;
    this.dataModel.data.chargeDesc = response.chargeDesc;
    this.dataModel.data.chargeNumber = response.charge_number;

    this.dataModel.data.chargeAmount = response.chargeAmount;

    this.dataModel.data.chargeDetails = response.chargeDetails;

    this.dataModel.data.chargeType = response.charge_type;
    this.arrApprovalReqFrom = response.lst_cpm_doa_users;

    if(this.arrApprovalReqFrom.length == 0) {
      this.ApproveChargeForm.controls.approvalReqFrom.clearValidators()
    } else {
      this.ApproveChargeForm.controls.approvalReqFrom.setValidators([Validators.required])
    }

    if(response.logged_in_user_id && response.lst_cpm_doa_users.length > 0) {
      const loggedInUserIndex = this.arrApprovalReqFrom.findIndex(obj => obj.user_id === response.logged_in_user_id.user_id)

      let user = this.arrApprovalReqFrom.splice(loggedInUserIndex, 1)
      this.arrApprovalReqFrom.unshift(user[0]);

      this.ApproveChargeForm.controls.approvalReqFrom.setValue(
        response.logged_in_user_id.user_id
      );
    }

    this.allApprovalReqFrom = this.arrApprovalReqFrom

    this.dataModel.data.approved_by = response.logged_in_user_id
    // this.dataModel.data.liabilityOrganisation = response.liability_organisation;

    this.ApproveChargeForm.controls.draftInvoiceAmount.setValue(
      response.draft_invoice_amount
    );
    this.ApproveChargeForm.controls.finalInvoiceAmount.setValue(
      response.final_invoice_amount
    );
    this.ApproveChargeForm.controls.invoiceStatusValidated.setValue(
      response.invoice_status
    );

    this.dataModel.data.assignedBy = response.assignedBy;
    this.maxDate = new Date()
    this.ApproveChargeForm.controls.approvalDate.setValue(this.maxDate);

    // For filling the customm data

    // For filling the customm data

    this.dataModel.data.worksReferenceNumber = response.works_reference_number;
    this.dataModel.data.highwayAuthority = response.highway_authority_name;

    this.dataModel.data.projectReferenceNumber =
      response.project_reference_number;
    this.dataModel.data.worksDescription = response.works_description;
    this.dataModel.data.preApprovalAuthoriser =
      response.pre_approval_authoriser;
    // not exist in api response
    // let locationtype = response?.location_types.toString();
    // this.dataModel.data.locationTypes = locationtype?.split(',');

    // this.dataModel.data.postCode = response.postcode;
    // if (response.area_name.length > 0)
    //   this.dataModel.data.usrndetails =
    //     response.usrn +
    //     ' - ' +
    //     response.street_name +
    //     ', ' +
    //     response.area_name +
    //     ', ' +
    //     response.town;
    // if (response.area_name.length == 0)
    //   this.dataModel.data.usrndetails =
    //     response.usrn + ' - ' + response.street_name + ', ' + response.town;
    // if (
    //   response.street_name.length == 0 &&
    //   response.area_name.length == 0 &&
    //   response.town.length == 0
    // )
    // this.dataModel.data.worksDescription = response.work_description;
    // this.dataModel.data.worksCategory =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.work_category
    //   );

    // this.dataModel.data.contact =
    //   response.secondary_contact + ' - ' + response.secondary_contact_number;
    // let workstreams: any[] =
    //   this._appRepoHelperService.getUserAccessibleWorkStream();

    // if (workstreams) {
    //   let workstream: any = workstreams.find(
    //     (c) => c.workstreamId == response.workstream_id
    //   );
    //   this.dataModel.data.workStream = workstream?.description;
    // }

    // let contractors: any[] =
    //   this._appRepoHelperService.getUserAccessibleContractors();
    // if (contractors) {
    //   let contractor: any = contractors.find(
    //     (c) => c.contractor_id == response.contractor_id
    //   );
    // this.dataModel.data.accountability = contractor?.contractor_name;
    // }
    // // not exist in api response
    // // this.dataModel.data.accountability = response.accountability;

    // this.dataModel.data.trafficSensitive = DataHelper.booleanToUserText(
    //   response.traffic_sensitive_flag
    // );
    // this.dataModel.data.footwayClosure =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.close_footway
    //   );

    // this.dataModel.data.excavationRequired = DataHelper.booleanToUserText(
    //   response.excavation_flag
    // );

    // this.dataModel.data.isLaneRentalApplicable = DataHelper.booleanToUserText(
    //   response.lane_rental_flag
    // );

    // this.dataModel.data.trafficManagementRequired =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.traffic_management_type
    //   );

    // this.dataModel.data.trafficManagementPlan = response.geometry;
    // this.dataModel.data.permitStatus = response.status_value;
    // this.dataModel.data.workStatus = response.work_status_value;

    // if (response.status == '') this.isDraftPermitView = true;
    // this.dataModel.data.actualStartDateTime = response.actual_start_date;
    // this.dataModel.data.actualEndDateTime = response.actual_end_date;

    // this.dataModel.data.proposedStartDateTime = response.proposed_start_date;
    // this.dataModel.data.proposedEndDateTime = response.proposed_end_date;

    // this.dataModel.data.proposedDuration = response.working_days;
    // this.dataModel.data.cost = response.total_cost;

    // this.dataModel.data.costRisk = response.cost_risk;
    // this.dataModel.data.customerRisk = response.customer_risk;
    // this.dataModel.data.trafficManagementRisk =
    //   response.traffic_management_risk;
    // this.dataModel.data.actions = response.actions;
    // this.dataModel.data.changeDecision = response.change_decision;
    // //     this.dataModel.data.changeDecision = [
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // // ]

    // // console.log("this.dataModel.data.changeDecision",this.dataModel.data.changeDecision)
    // this.dataModel.data.collaborativeWorkingFlag = DataHelper.booleanToUserText(
    //   response.collaborative_working_flag
    // );
    this.dataModel.data.collaborationDetails = response.collaboration_details;
    // this.dataModel.data.collaborativeWorks = response.collaborative_works;
    // this.dataModel.data.collaborationType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.collaboration_type
    //   );
    // this.dataModel.data.ttro_requiredFlag = DataHelper.booleanToUserText(
    //   response.ttro_required_flag
    // );
    // this.dataModel.data.environmental_flag = DataHelper.booleanToUserText(
    //   response.environmental_flag
    // );
    // this.dataModel.data.is_covid19_response = DataHelper.booleanToUserText(
    //   response.is_covid19_response
    // );
    // this.dataModel.data.earlyStartPreApprovalFlag =
    //   DataHelper.booleanToUserText(response.early_start_pre_approval_flag);
    // this.dataModel.data.earlyStartReason = response.early_start_reason;
    // this.dataModel.data.preApprovalAuthoriser =
    //   response.pre_approval_authoriser;
    // this.dataModel.data.activityType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.activity_type
    //   );
    // this.dataModel.data.preApprovalDetails = response.pre_approval_details;
    // this.dataModel.data.additionalInfo = response.additional_info;
    // this.dataModel.data.additionalcontact = response.additional_contact;
    // this.dataModel.data.emergencyContact = response.additional_contact_number;
    // this.dataModel.data.emergencyNumber = response.additional_contact_email;

    // this.dataModel.data.additionalcontactemail =
    //   response.emergency_contact_name;
    // this.dataModel.data.additionalcontactemail =
    //   response.emergency_contact_number;

    // this.dataModel.data.expectedEndDateTime = response.expected_end_date;
    // this.dataModel.data.is_pmr_responded = response.is_pmr_responded;
    // this.dataModel.data.is_excavation_carried_out =
    //   DataHelper.booleanToUserText(response.excavation_carried_out);
    // if (this.dataModel.data.is_pmr_responded != null) {
    //   this.showIcon = true;
    // }

    // this.dataModel.data.dcStatus = response.dc_details.dc_status;
    // this.dataModel.data.dcReason = response.dc_details.dc_reason;
    // this.dataModel.data.dcResponseDate = response.dc_details.dc_response_date;
    // // switch (response.assessment_decision.status) {
    // //   case ASSESSMENT_STATUS.ASSESSMENT_GRANTED_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     break;
    // //   case ASSESSMENT_STATUS.ASSESSMENT_MODIFICATION_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     this.assessmentDecision.description = response.assessment_decision.modificationRequestDetails;
    // //     break;
    // //   case ASSESSMENT_STATUS.ASSESSMENT_REFUSED_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     this.assessmentDecision.description = response.assessment_decision.refusalDetails;
    // //     this.assessmentDecision.reason = response.assessment_decision.reasonForRefusal;
    // //     this.assessmentDecision.modificationRequestDetails = response.assessment_decision.modificationRequestDetails;
    // //     break;
    // //   default:
    // //     break;
    // // }
  }
  getValue() {
      this.ApproveChargeForm.markAllAsTouched();
      if (this.ApproveChargeForm.valid) {
        let data: any = {};
        data = {

          approved_date: new Date(
            this.ApproveChargeForm.get('approvalDate').value
          ).toISOString(),
          charge_id: this.dataModel.apireqdata.chargeId,
          charge_number: this.dataModel.data.chargeNumber ?? '',
          charge_type: this.dataModel.apireqdata.chargeType,
          notes_text: this.ApproveChargeForm.get('approvalNotes').value,
          approved_by: this.dataModel.data.approved_by.user_id,
          approved_on_behalf: this.ApproveChargeForm.get('approvalReqFrom').value,
        };

        console.log('Approve Charge Form >', data);

        return data;
      }

  }

  removeUploadValidation() {
    console.log('Remove Validation')
    this.ApproveChargeForm.controls.fileUp.clearValidators()
    this.ApproveChargeForm.controls.fileUp.updateValueAndValidity()
    this.ApproveChargeForm.markAllAsTouched()
  }

  onCancelClick() {
    this.ApproveChargeForm.reset();
    this.emitEvent('cancel');
  }
  onSaveClick() {
    if (this.ApproveChargeForm.valid) {
      console.log(this.getValue());
    }
    this.emitEvent('saveJobOnwer', this.ApproveChargeForm.value);
  }
  resetForm() {
    this.ApproveChargeForm.reset();
  }
  openFileUpload() {
    let data: any = {};
    data.reference = this.dataModel.data.chargeNumber ?? '';
    data.objectType = CHARGE_FORMS_NAME.APPROVE_CHARGE;
    this.emitEvent('openFileUploadDialogApproveCharge', data);
  }

  onNotesChange(value) {
    if (value.length > 0) {
      let charRemaining = 500 - value.length;
      this.notesMessage = `Remaining characters ${charRemaining}.`;
    } else {
      this.notesMessage = 'Max 500 characters.';
    }
  }

  onChangeApprovalRequired(event) {
    if(this.dataModel.data.approved_by.user_id !== event.value) {
      this.ApproveChargeForm.controls.fileUp.setValidators([Validators.required])
      this.ApproveChargeForm.controls.fileUp.updateValueAndValidity()
    } else {
      this.ApproveChargeForm.controls.fileUp.clearValidators()
      this.ApproveChargeForm.controls.fileUp.updateValueAndValidity()
    }
    this.ApproveChargeForm.markAllAsTouched()
  }

  onSearchApprovalBy(searchVal) {
    let filter = searchVal.toLowerCase();
    console.log(filter)
    if(filter.length > 0) {
      this.arrApprovalReqFrom = this.allApprovalReqFrom.filter(option => option.doa_user_name.toLowerCase().includes(filter));
    } else {
      this.arrApprovalReqFrom =  this.allApprovalReqFrom
    }
  }
}
