import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdateChargeStatusFormConfigModel, UpdateChargeStatusFormModel } from './update-charge-status-form';

import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { I } from '@angular/cdk/keycodes';
import { MASTER_DATA_DOMAIN } from 'src/app/constants/db.constants';
import { MatDialog } from '@angular/material/dialog';
import { SaveFileService } from 'src/app/services/save-file.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';

@Component({
  selector: 'app-update-charge-status-form',
  templateUrl: './update-charge-status-form.component.html',
  styleUrls: ['./update-charge-status-form.component.css']
})
export class UpdateChargeStatusFormComponent extends WidgetComponentBase implements OnInit {
  @Input() dataModel: UpdateChargeStatusFormModel;
  @Input() configModel: UpdateChargeStatusFormConfigModel;

  UpdateChargeForm: FormGroup;
  notesMessage: string = 'Max 500 characters.';
  arrCharge: any[] = [];
  arrFPNRootCause: any[] = [];
  arrReasonInvlid: any[] = [];
  chargeStatus: any;
  chargeStatusEnum: any;
  showRootCause: boolean = false;
  showReasonInvlid: boolean = false;
  showPermitType: boolean = true;
  showNegotiatedAmount: boolean = false;
  showNegotiatedAmountCheckbox: boolean;
  isNegotiationAmountChkBoxChecked: boolean;
  apiResponse: any;
  constructor (
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _validationService: ValidationService,
    private _saveFileService: SaveFileService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit(): void {
    super.wgOnInit();
    this.createFormGroup();
  }
  OnDestroy() {
    this.UpdateChargeForm.reset();
  }
  createFormGroup(): void {
    this.UpdateChargeForm = this.fb.group({
      // chargeStatus: [''],
      charge: [''],
      fpnRootCause: [''],
      reasonInvlid: [''],
      recordNegotiatedAmount: [''],
      additionComment: [''],
      // fileUp: [''],
      notes: ['', [Validators.maxLength(500)]],
    });
  }
  setFieldData() {

  }
  stopPropagation(event) {
    event.stopPropagation();
  }
  setMode(responseDataModel: any) { }
  setValue(responseDataModel: any) { }
  getControlData() {
    // let id = this.dataModel.globalParameters.get('applicationId');
    // // INFO: ServerAPI
    let url = `/api/v1/cpm/actions/getDetails`;
    return this.wgAPIMethodGet(url, this.dataModel.apireqdata);
    // return new Promise((resolve, reject) => {
    //   let permitform: any = this.dataModel.globalParameters.get(
    //     GLOBAL_PARAM_KEY.VIEW_PERMIT_FORM
    //   );
    //   permitform = permitform ? permitform : new Object();
    //   if (permitform) {
    //     resolve(permitform);
    //   } else {
    //     reject(permitform);
    //   }
    // });
  }
  onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file) {
      const formData = new FormData();

      formData.append('thumbnail', file);

      console.log(file);
    }
  }
  convertData(response: any) {
    if (!response) return;
    this.apiResponse = response;
    console.log('respoine of accept-charge', response);
    this.dataModel.data.permitType = response.permit_type;
    this.dataModel.data.chargeDesc = response.charge_description;
    this.dataModel.data.chargeNumber = response.charge_number;

    this.dataModel.data.chargeAmount = response.charge_amount;

    this.dataModel.data.chargeDetails = response.chargeDetails;
    switch (response.charge_type) {
      case 'Permit Charges [Manual]':
      case 'Permit [Manual]':
        this.showPermitType = false;
        break;
      default:
        break;
    }


    this.dataModel.data.chargeType = response.charge_type;
    if (response.charge_type == 'FPN' || response.charge_type == 'FPN [Manual]' || response.charge_type == 'Section 74' || response.charge_type == 'Section 74 [Manual]' || response.charge_status == "Withdrawn") {
      this.showRootCause = true;
      this.showNegotiatedAmount = false;
      this.showNegotiatedAmountCheckbox = true;
      if (response.charge_type == 'FPN' || response.charge_type == 'FPN [Manual]') {
        this.arrFPNRootCause = this._appRepoHelperService.getMDataByDomain(
          MASTER_DATA_DOMAIN.FPN_ROOT_CAUSE.toString()
        );

        this.arrReasonInvlid = this._appRepoHelperService.getMDataByDomain(
          MASTER_DATA_DOMAIN.FPN_INVALID_REASON.toString()
        );

      }

      else {
        this.arrFPNRootCause = this._appRepoHelperService.getMDataByDomain(
          MASTER_DATA_DOMAIN.S74_ROOT_CAUSE.toString()
        );

        this.arrReasonInvlid = this._appRepoHelperService.getMDataByDomain(
          MASTER_DATA_DOMAIN.S74_INVALID_REASON.toString()
        );

      }
    }

    if (response.charge_type == 'Permit Charges' || response.charge_type == 'Permit Charges [Manual]') {
      this.arrReasonInvlid = this._appRepoHelperService.getMDataByDomain(
        MASTER_DATA_DOMAIN.PERMIT_INVALID_REASON.toString()
      );
    }

    if (response.charge_type == 'INSPECTION' || response.charge_type == 'Defects' || response.charge_type == 'Defect' || response.charge_type == 'Defect [Manual]') {
      this.arrReasonInvlid = this._appRepoHelperService.getMDataByDomain(
        MASTER_DATA_DOMAIN.INSPECTION_INVALID_REASON.toString()
      );
    }

    if (response.charge_status == "Challenged") {
      this.showReasonInvlid = true;
      this.showRootCause = false;
    }

    this.arrCharge = response.lst_charge_status;
    this.chargeStatus = response.charge_status;
    this.chargeStatusEnum = response.charge_status_enum;
    this.arrCharge.filter((d: any) => {
      if (d.display_text === this.chargeStatus) {
        this.UpdateChargeForm.controls.charge.setValue(d.value);
      }
    });

    if (response.lst_charge_status.length == 0) {
      this.UpdateChargeForm.controls.charge.disable();
    }

    if (this.showNegotiatedAmount) {
      if (response.negotiated_charge_amount) {
        this.UpdateChargeForm.controls.recordNegotiatedAmount.setValue(response.negotiated_charge_amount);
      }
    }

    if (this.showRootCause) {
      // if(response.negotiated_charge_amount) {
      //   this.UpdateChargeForm.controls.recordNegotiatedAmount.setValue(response.negotiated_charge_amount)
      // }
      if (response.root_cause) {
        this.arrFPNRootCause.filter(d => {
          if (d.displayText == response.root_cause) {
            this.UpdateChargeForm.controls.fpnRootCause.setValue(d.value);
          }
        });
      }
    }

    if (response.reason_invalid) {
      this.arrReasonInvlid.filter(d => {
        if (d.displayText == response.reason_invalid) {
          this.UpdateChargeForm.controls.reasonInvlid.setValue(d.value);
        }
      });
    }

    if (this.dataModel.data.chargeType === 'Permit Charges') {
      this.showNegotiatedAmount = false;
      this.showNegotiatedAmountCheckbox = false;
    }

    if (this.dataModel.isSMAccepted) {
      this.UpdateChargeForm.controls.charge.disable();
    }
    // this.UpdateChargeForm.controls.chargeStatus.setValue(response.charge_status)


    // For filling the customm data

    this.dataModel.data.worksReferenceNumber = response.works_reference_number;
    this.dataModel.data.highwayAuthority = response.highway_authority_name;

    this.dataModel.data.projectReferenceNumber =
      response.project_reference_number;
    this.dataModel.data.worksDescription = response.works_description;
    this.dataModel.data.preApprovalAuthoriser =
      response.pre_approval_authoriser;
    // not exist in api response
    // let locationtype = response?.location_types.toString();
    // this.dataModel.data.locationTypes = locationtype?.split(',');

    // this.dataModel.data.postCode = response.postcode;
    // if (response.area_name.length > 0)
    //   this.dataModel.data.usrndetails =
    //     response.usrn +
    //     ' - ' +
    //     response.street_name +
    //     ', ' +
    //     response.area_name +
    //     ', ' +
    //     response.town;
    // if (response.area_name.length == 0)
    //   this.dataModel.data.usrndetails =
    //     response.usrn + ' - ' + response.street_name + ', ' + response.town;
    // if (
    //   response.street_name.length == 0 &&
    //   response.area_name.length == 0 &&
    //   response.town.length == 0
    // )
    this.dataModel.data.usrndetails = response.usrn;
    // this.dataModel.data.worksDescription = response.work_description;
    // this.dataModel.data.worksCategory =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.work_category
    //   );

    // this.dataModel.data.highwayAuthority = response.highway_authority_name;

    // this.dataModel.data.roadType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.road_category.toString()
    //   );

    // this.dataModel.data.promoterOrganisation = response.promoter_organisation;

    // this.dataModel.data.contact =
    //   response.secondary_contact + ' - ' + response.secondary_contact_number;
    // let workstreams: any[] =
    //   this._appRepoHelperService.getUserAccessibleWorkStream();

    // if (workstreams) {
    //   let workstream: any = workstreams.find(
    //     (c) => c.workstreamId == response.workstream_id
    //   );
    //   this.dataModel.data.workStream = workstream?.description;
    // }

    // let contractors: any[] =
    //   this._appRepoHelperService.getUserAccessibleContractors();
    // if (contractors) {
    //   let contractor: any = contractors.find(
    //     (c) => c.contractor_id == response.contractor_id
    //   );
    // this.dataModel.data.accountability = contractor?.contractor_name;
    // }
    // // not exist in api response
    // // this.dataModel.data.accountability = response.accountability;

    // this.dataModel.data.trafficSensitive = DataHelper.booleanToUserText(
    //   response.traffic_sensitive_flag
    // );
    // this.dataModel.data.footwayClosure =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.close_footway
    //   );

    // this.dataModel.data.excavationRequired = DataHelper.booleanToUserText(
    //   response.excavation_flag
    // );

    // this.dataModel.data.isLaneRentalApplicable = DataHelper.booleanToUserText(
    //   response.lane_rental_flag
    // );

    // this.dataModel.data.trafficManagementRequired =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.traffic_management_type
    //   );

    // this.dataModel.data.trafficManagementPlan = response.geometry;
    // this.dataModel.data.permitStatus = response.status_value;
    // this.dataModel.data.workStatus = response.work_status_value;

    // if (response.status == '') this.isDraftPermitView = true;
    // this.dataModel.data.actualStartDateTime = response.actual_start_date;
    // this.dataModel.data.actualEndDateTime = response.actual_end_date;

    // this.dataModel.data.proposedStartDateTime = response.proposed_start_date;
    // this.dataModel.data.proposedEndDateTime = response.proposed_end_date;

    // this.dataModel.data.proposedDuration = response.working_days;
    // this.dataModel.data.cost = response.total_cost;

    // this.dataModel.data.costRisk = response.cost_risk;
    // this.dataModel.data.customerRisk = response.customer_risk;
    // this.dataModel.data.trafficManagementRisk =
    //   response.traffic_management_risk;
    // this.dataModel.data.actions = response.actions;
    // this.dataModel.data.changeDecision = response.change_decision;
    // //     this.dataModel.data.changeDecision = [
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // // ]

    // // console.log("this.dataModel.data.changeDecision",this.dataModel.data.changeDecision)
    // this.dataModel.data.collaborativeWorkingFlag = DataHelper.booleanToUserText(
    //   response.collaborative_working_flag
    // );
    this.dataModel.data.collaborationDetails = response.collaboration_details;
    // this.dataModel.data.collaborativeWorks = response.collaborative_works;
    // this.dataModel.data.collaborationType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.collaboration_type
    //   );
    // this.dataModel.data.ttro_requiredFlag = DataHelper.booleanToUserText(
    //   response.ttro_required_flag
    // );
    // this.dataModel.data.environmental_flag = DataHelper.booleanToUserText(
    //   response.environmental_flag
    // );
    // this.dataModel.data.is_covid19_response = DataHelper.booleanToUserText(
    //   response.is_covid19_response
    // );
    // this.dataModel.data.earlyStartPreApprovalFlag =
    //   DataHelper.booleanToUserText(response.early_start_pre_approval_flag);
    // this.dataModel.data.earlyStartReason = response.early_start_reason;
    // this.dataModel.data.preApprovalAuthoriser =
    //   response.pre_approval_authoriser;
    // this.dataModel.data.activityType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.activity_type
    //   );
    // this.dataModel.data.preApprovalDetails = response.pre_approval_details;
    // this.dataModel.data.additionalInfo = response.additional_info;
    // this.dataModel.data.additionalcontact = response.additional_contact;
    // this.dataModel.data.emergencyContact = response.additional_contact_number;
    // this.dataModel.data.emergencyNumber = response.additional_contact_email;

    // this.dataModel.data.additionalcontactemail =
    //   response.emergency_contact_name;
    // this.dataModel.data.additionalcontactemail =
    //   response.emergency_contact_number;

    // this.dataModel.data.expectedEndDateTime = response.expected_end_date;
    // this.dataModel.data.is_pmr_responded = response.is_pmr_responded;
    // this.dataModel.data.is_excavation_carried_out =
    //   DataHelper.booleanToUserText(response.excavation_carried_out);
    // if (this.dataModel.data.is_pmr_responded != null) {
    //   this.showIcon = true;
    // }

    // this.dataModel.data.dcStatus = response.dc_details.dc_status;
    // this.dataModel.data.dcReason = response.dc_details.dc_reason;
    // this.dataModel.data.dcResponseDate = response.dc_details.dc_response_date;
    // // switch (response.assessment_decision.status) {
    // //   case ASSESSMENT_STATUS.ASSESSMENT_GRANTED_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     break;
    // //   case ASSESSMENT_STATUS.ASSESSMENT_MODIFICATION_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     this.assessmentDecision.description = response.assessment_decision.modificationRequestDetails;
    // //     break;
    // //   case ASSESSMENT_STATUS.ASSESSMENT_REFUSED_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     this.assessmentDecision.description = response.assessment_decision.refusalDetails;
    // //     this.assessmentDecision.reason = response.assessment_decision.reasonForRefusal;
    // //     this.assessmentDecision.modificationRequestDetails = response.assessment_decision.modificationRequestDetails;
    // //     break;
    // //   default:
    // //     break;
    // // }
  }


  onNegotiationAmountChkBoxChange(event) {
    this.showNegotiatedAmount = event.checked;
    this.isNegotiationAmountChkBoxChecked = event.checked;
    this.UpdateChargeForm.controls.recordNegotiatedAmount.setValue(null);
  }

  onChargeStatusSelectionChange(event) {
    console.log("event", event);

    if (event.value == "VALID_BY_USER" || event.value === "AWAITING_FINAL_INVOICE") {
      this.showReasonInvlid = false;
      this.UpdateChargeForm.get('reasonInvlid').setValue('');
      if (this.dataModel.data.chargeType == 'FPN' || this.dataModel.data.chargeType == 'Section 74') {
        this.showRootCause = true;
      }
    }

    if (event.value == "challenged" || event.value == 'CHALLENGED') {
      this.showReasonInvlid = true;
      this.showRootCause = false;
      this.UpdateChargeForm.get('fpnRootCause').setValue('');
    }

    if (event.value == 'WITHDRAWN' || event.value == 'withdrawn') {
      this.showReasonInvlid = false;
      this.showRootCause = false;
      this.UpdateChargeForm.get('reasonInvlid').setValue('');
      this.UpdateChargeForm.get('fpnRootCause').setValue('');
      this.showNegotiatedAmount = false;
    }
    if (
      (['FPN',
        'Section 74 [Manual]',
        'FPN [Manual]',
        'Section 74'].includes(this.dataModel.data.chargeType) ||
        this.chargeStatus === 'WITHDRAWN') &&
      !['CHALLENGED', 'WITHDRAWN'].includes(event.value)) {
      this.showNegotiatedAmountCheckbox = true;
    }
    else {
      this.showNegotiatedAmountCheckbox = false;
      this.isNegotiationAmountChkBoxChecked = false;
      this.UpdateChargeForm.get('recordNegotiatedAmount').setValue('');
    }
  }

  getValue() {
    this.UpdateChargeForm.markAllAsTouched();
    if (this.UpdateChargeForm.valid) {
      let data: any;
      console.log(this.UpdateChargeForm.value);
      data = {
        charge_id: this.dataModel.apireqdata.chargeId,
        charge_number: this.dataModel.data.chargeNumber || '',
        charge_type: this.dataModel.apireqdata.chargeType,
        notes_text: this.UpdateChargeForm.controls.notes.value,
        charge_status: this.UpdateChargeForm.controls.charge.value || this.chargeStatusEnum,
        reason_invalid: this.UpdateChargeForm.controls.reasonInvlid.value.length > 0 ? this.UpdateChargeForm.controls.reasonInvlid.value : null,
        negotiated_charge_amount: ((this.UpdateChargeForm.controls.recordNegotiatedAmount.value !== null) || (this.UpdateChargeForm.controls.recordNegotiatedAmount.value !== '')) ? this.UpdateChargeForm.controls.recordNegotiatedAmount.value : null,
        root_cause: this.UpdateChargeForm.controls.fpnRootCause.value.length > 0 ? this.UpdateChargeForm.controls.fpnRootCause.value : null,
        root_cause_details: this.UpdateChargeForm.controls.additionComment.value.length > 0 ? this.UpdateChargeForm.controls.additionComment.value : null
      };
      // if(this.dataModel.data.chargeType == 'Section 74' || this.dataModel.data.chargeType == 'FPN') {
      //   data.negotiated_charge_amount = this.UpdateChargeForm.controls.recordNegotiatedAmount.value ? this.UpdateChargeForm.controls.recordNegotiatedAmount.value : null;
      //   data.root_cause = this.UpdateChargeForm.controls.fpnRootCause.value
      //   data.root_cause_details = this.UpdateChargeForm.controls.additionComment.value
      // }

      // if(this.dataModel.data.chargeType == 'Section 74' || this.dataModel.data.chargeType == 'FPN' || this.dataModel.data.chargeType == 'Permit Charges' || this.dataModel.data.chargeType == 'Defect') {
      //   data.reason_invalid = this.UpdateChargeForm.controls.reasonInvlid.value;
      // }

      // if(data.charge_status == "WITHDRAWN") {
      //   data.negotiated_charge_amount = null;
      //   data.reason_invalid = null;
      //   data.root_cause = null
      // }

      return data;
    }
  }

  isNegotiatiatedChargeChecked() {
    console.log(this.isNegotiationAmountChkBoxChecked);
    return this.isNegotiationAmountChkBoxChecked && this.showNegotiatedAmount;
  }

  resetForm() {
    this.UpdateChargeForm.reset();
  }
  openFileUpload() {
    let data: any = {};
    data.reference = this.dataModel.data.chargeNumber;
    data.objectType = 'UPDATE_CHARGE';
    this.emitEvent('openFileUploadDialog', data);
  }

  onNotesChange(value) {
    if (value.length > 0) {
      let charRemaining = (500 - value.length);
      this.notesMessage = `Remaining characters ${charRemaining}.`;
    } else {
      this.notesMessage = 'Max 500 characters.';
    }
  }
}
