import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  AssignJobOwnerConfigModel,
  AssignJobOwnerModel,
} from './assign-job-owner.model';
import { MatDialog } from '@angular/material/dialog';
import { CHARGE_FORMS_NAME, GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { PERMIT_ACTIONS_CONFIG } from 'src/app/constants/db.constants';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { SaveFileService } from 'src/app/services/save-file.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { ErrorDialogService } from 'src/app/services/error.dialog.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-assign-job-owner-form',
  templateUrl: './assign-job-owner-form.component.html',
  styleUrls: ['./assign-job-owner-form.component.css'],
})
export class AssignJobOwnerFormComponent
  extends WidgetComponentBase
  implements OnInit
{
  @Input() dataModel: AssignJobOwnerModel;
  @Input() configModel: AssignJobOwnerConfigModel;

  assignJobOwnerForm: FormGroup;

  allarrJobOwners: any[] = []
  arrJobOwner: any[] = [];
  allArrTeams: any[] = []
  arrTeams: any[] = []
  selectedUser: any = {}
  selectedteam: any = {}
  searchUser = new FormControl()
  teamData: any[] = []
  userData: any[] = []
  notesMessage: string = 'Max 500 characters.';
  files: any[];

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _notificationService: NotificationService,
    private _errorDialogService: ErrorDialogService,
    private _validationService: ValidationService,
    private _saveFileService: SaveFileService,
    private _spinner: NgxSpinnerService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    super(_serverApi, _validationService);
    // this.searchUser.valueChanges.subscribe(this.onSearchValueChange);
  }

  ngOnInit(): void {
    super.wgOnInit();

    this.createFormGroup();
  }

  createFormGroup(): void {
    this.assignJobOwnerForm = this.fb.group({
      teams: ['', Validators.required],
      users: ['', Validators.required],
      sub_team: [''],
      notes: ['', Validators.maxLength(500)],
    });
  }
  setFieldData() {}
  stopPropagation(event) {
    event.stopPropagation();
  }
  setMode(responseDataModel: any) {}
  setValue(responseDataModel: any) {}
  getControlData() {
    let id = this.dataModel.globalParameters.get('applicationId');
    // INFO: ServerAPI
    let url = `/api/v1/cpm/actions/getDetails`
    return this.wgAPIMethodGet(url, this.dataModel.apireqdata);
    // return new Promise((resolve, reject) => {
    //   let permitform: any = this.dataModel.globalParameters.get(
    //     GLOBAL_PARAM_KEY.VIEW_PERMIT_FORM
    //   );
    //   permitform = permitform ? permitform : new Object();
    //   if (permitform) {
    //     resolve(permitform);
    //   } else {
    //     reject(permitform);
    //   }
    // });
  }
  convertData(response: any) {
    if (!response) return;

    console.log('respoine of overview', response);

    this.dataModel.data.permitType = response.permit_type;
    this.dataModel.data.chargeDesc = response.charge_description;
    this.dataModel.data.chargeNumber = response.charge_number;

    this.dataModel.data.chargeAmount = response.charge_amount;

    this.dataModel.data.chargeDetails = response.chargeDetails;

    this.dataModel.data.chargeType = response.charge_type;
    // this.dataModel.data.liabilityOrganisation = response.liability_organisation;

    this.dataModel.data.assignedBy = response.assignedBy;
    let teamsArr = response.lst_cpm_teams
    this.allArrTeams = teamsArr
    this.arrTeams = this.allArrTeams
    teamsArr.forEach(element => {
      element.lst_cpm_users.forEach(user => {
        this.allarrJobOwners.push(user)
      })
    });
    this.arrJobOwner = this.allarrJobOwners
    this.arrJobOwner = this.arrJobOwner.filter((thing, i, arr) => {
      return (
        arr.indexOf(
          arr.find((t: any) => t.user_id === thing.user_id)
        ) === i
      );
    });
    // For filling the customm data

    // For filling the customm data

    // this.dataModel.data.worksReferenceNumber = response.works_reference_number;
    // this.dataModel.data.highwayAuthority = response.highway_authority_name;

    // this.dataModel.data.projectReferenceNumber =
    //   response.project_reference_number;
    // this.dataModel.data.worksDescription = response.works_description;
    // this.dataModel.data.preApprovalAuthoriser =
    //   response.pre_approval_authoriser;
    const selectedUser = response.user_id
    const selectedTeam = response.team_id
    const selectedSubTeam = response.sub_team_id

    if(selectedUser) {
      this.assignJobOwnerForm.controls.users.setValue(selectedUser)
    }
    if(selectedTeam) {
      const userArr = this.allArrTeams.filter((d: any) => d.sub_team_id === selectedSubTeam);
      this.assignJobOwnerForm.controls.teams.setValue(userArr[0].team_name)
    }
    if(selectedSubTeam) {
      this.assignJobOwnerForm.controls.sub_team.setValue(selectedSubTeam)
    }
    // not exist in api response
    // let locationtype = response?.location_types.toString();
    // this.dataModel.data.locationTypes = locationtype?.split(',');

    // this.dataModel.data.postCode = response.postcode;
    // if (response.area_name.length > 0)
    //   this.dataModel.data.usrndetails =
    //     response.usrn +
    //     ' - ' +
    //     response.street_name +
    //     ', ' +
    //     response.area_name +
    //     ', ' +
    //     response.town;
    // if (response.area_name.length == 0)
    //   this.dataModel.data.usrndetails =
    //     response.usrn + ' - ' + response.street_name + ', ' + response.town;
    // if (
    //   response.street_name.length == 0 &&
    //   response.area_name.length == 0 &&
    //   response.town.length == 0
    // )
    this.dataModel.data.usrndetails = response.usrn;
    // this.dataModel.data.worksDescription = response.work_description;
    // this.dataModel.data.worksCategory =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.work_category
    //   );

    // this.dataModel.data.highwayAuthority = response.highway_authority_name;

    // this.dataModel.data.roadType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.road_category.toString()
    //   );

    // this.dataModel.data.promoterOrganisation = response.promoter_organisation;

    // this.dataModel.data.contact =
    //   response.secondary_contact + ' - ' + response.secondary_contact_number;
    // let workstreams: any[] =
    //   this._appRepoHelperService.getUserAccessibleWorkStream();

    // if (workstreams) {
    //   let workstream: any = workstreams.find(
    //     (c) => c.workstreamId == response.workstream_id
    //   );
    //   this.dataModel.data.workStream = workstream?.description;
    // }

    // let contractors: any[] =
    //   this._appRepoHelperService.getUserAccessibleContractors();
    // if (contractors) {
    //   let contractor: any = contractors.find(
    //     (c) => c.contractor_id == response.contractor_id
    //   );
    // this.dataModel.data.accountability = contractor?.contractor_name;
    // }
    // // not exist in api response
    // // this.dataModel.data.accountability = response.accountability;

    // this.dataModel.data.trafficSensitive = DataHelper.booleanToUserText(
    //   response.traffic_sensitive_flag
    // );
    // this.dataModel.data.footwayClosure =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.close_footway
    //   );

    // this.dataModel.data.excavationRequired = DataHelper.booleanToUserText(
    //   response.excavation_flag
    // );

    // this.dataModel.data.isLaneRentalApplicable = DataHelper.booleanToUserText(
    //   response.lane_rental_flag
    // );

    // this.dataModel.data.trafficManagementRequired =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.traffic_management_type
    //   );

    // this.dataModel.data.trafficManagementPlan = response.geometry;
    // this.dataModel.data.permitStatus = response.status_value;
    // this.dataModel.data.workStatus = response.work_status_value;

    // if (response.status == '') this.isDraftPermitView = true;
    // this.dataModel.data.actualStartDateTime = response.actual_start_date;
    // this.dataModel.data.actualEndDateTime = response.actual_end_date;

    // this.dataModel.data.proposedStartDateTime = response.proposed_start_date;
    // this.dataModel.data.proposedEndDateTime = response.proposed_end_date;

    // this.dataModel.data.proposedDuration = response.working_days;
    // this.dataModel.data.cost = response.total_cost;

    // this.dataModel.data.costRisk = response.cost_risk;
    // this.dataModel.data.customerRisk = response.customer_risk;
    // this.dataModel.data.trafficManagementRisk =
    //   response.traffic_management_risk;
    // this.dataModel.data.actions = response.actions;
    // this.dataModel.data.changeDecision = response.change_decision;
    // //     this.dataModel.data.changeDecision = [
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // // ]

    // // console.log("this.dataModel.data.changeDecision",this.dataModel.data.changeDecision)
    // this.dataModel.data.collaborativeWorkingFlag = DataHelper.booleanToUserText(
    //   response.collaborative_working_flag
    // );
    // this.dataModel.data.collaborationDetails = response.collaboration_details;
    // this.dataModel.data.collaborativeWorks = response.collaborative_works;
    // this.dataModel.data.collaborationType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.collaboration_type
    //   );
    // this.dataModel.data.ttro_requiredFlag = DataHelper.booleanToUserText(
    //   response.ttro_required_flag
    // );
    // this.dataModel.data.environmental_flag = DataHelper.booleanToUserText(
    //   response.environmental_flag
    // );
    // this.dataModel.data.is_covid19_response = DataHelper.booleanToUserText(
    //   response.is_covid19_response
    // );
    // this.dataModel.data.earlyStartPreApprovalFlag =
    //   DataHelper.booleanToUserText(response.early_start_pre_approval_flag);
    // this.dataModel.data.earlyStartReason = response.early_start_reason;
    // this.dataModel.data.preApprovalAuthoriser =
    //   response.pre_approval_authoriser;
    // this.dataModel.data.activityType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.activity_type
    //   );
    // this.dataModel.data.preApprovalDetails = response.pre_approval_details;
    // this.dataModel.data.additionalInfo = response.additional_info;
    // this.dataModel.data.additionalcontact = response.additional_contact;
    // this.dataModel.data.emergencyContact = response.additional_contact_number;
    // this.dataModel.data.emergencyNumber = response.additional_contact_email;

    // this.dataModel.data.additionalcontactemail =
    //   response.emergency_contact_name;
    // this.dataModel.data.additionalcontactemail =
    //   response.emergency_contact_number;

    // this.dataModel.data.expectedEndDateTime = response.expected_end_date;
    // this.dataModel.data.is_pmr_responded = response.is_pmr_responded;
    // this.dataModel.data.is_excavation_carried_out =
    //   DataHelper.booleanToUserText(response.excavation_carried_out);
    // if (this.dataModel.data.is_pmr_responded != null) {
    //   this.showIcon = true;
    // }

    // this.dataModel.data.dcStatus = response.dc_details.dc_status;
    // this.dataModel.data.dcReason = response.dc_details.dc_reason;
    // this.dataModel.data.dcResponseDate = response.dc_details.dc_response_date;
    // // switch (response.assessment_decision.status) {
    // //   case ASSESSMENT_STATUS.ASSESSMENT_GRANTED_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     break;
    // //   case ASSESSMENT_STATUS.ASSESSMENT_MODIFICATION_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     this.assessmentDecision.description = response.assessment_decision.modificationRequestDetails;
    // //     break;
    // //   case ASSESSMENT_STATUS.ASSESSMENT_REFUSED_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     this.assessmentDecision.description = response.assessment_decision.refusalDetails;
    // //     this.assessmentDecision.reason = response.assessment_decision.reasonForRefusal;
    // //     this.assessmentDecision.modificationRequestDetails = response.assessment_decision.modificationRequestDetails;
    // //     break;
    // //   default:
    // //     break;
    // // }
  }
  getValue() {
    this.assignJobOwnerForm.markAllAsTouched()
    if (this.assignJobOwnerForm.valid) {
      let data: any;
      data = {
        charge_id: this.dataModel.apireqdata.chargeId,
        charge_number: this.dataModel.data.chargeNumber ?? '',
        charge_type: this.dataModel.apireqdata.chargeType,
        notes_text: this.assignJobOwnerForm.controls.notes.value,
        team_id: this.assignJobOwnerForm.controls.teams.value,
        user_id: this.assignJobOwnerForm.controls.users.value,
        sub_team_id: this.assignJobOwnerForm.controls.sub_team.value
      };

      const userArr = this.allArrTeams.filter((d: any) => d.team_name === data.team_id);
      data.team_id = userArr[0].team_id
      return data;
    }
  }

  resetForm() {
    this.assignJobOwnerForm.reset();
  }

  onTeamChange(value) {
    this.teamData = []
    this.teamData.push(value)
    // if(this.userData.length == 0) {
      // this.arrTeams.filter(ele => {
      //   if(ele.team_id == value) {
      //     ele.lst_cpm_users.forEach(user => {
      //       this.teamData.push(user.user_id)
      //     })
      //   }
      // })

      const userArr = this.allArrTeams.filter((d: any) => {
        return this.teamData.indexOf(d.team_name) > -1;
      });
      // console.log('userArr>>>', userArr)
      this.arrJobOwner = userArr[0].lst_cpm_users;
      // this.arrJobOwner = this.arrJobOwner.filter((thing, i, arr) => {
      //   return (
      //     arr.indexOf(
      //       arr.find((t: any) => t.user_id === thing.user_id)
      //     ) === i
      //   );
      // });
      this.assignJobOwnerForm.controls.sub_team.setValue(userArr[0].sub_team_id);
      // this.assignJobOwnerForm.controls.teams.setValue(userArr[0].team_id);
      if(this.teamData.length === 0) this.arrJobOwner = this.allarrJobOwners
      // console.log('users>>>', this.arrJobOwner)
    // }
  }

  onUserChange(value) {
    this.userData.push(value)

    if(this.teamData.length == 0) {
    let i = 0;
    this.arrTeams = this.allArrTeams.filter((d: any) => {
      return this.userData.indexOf(d.lst_cpm_users[i].user_id) > -1;
    })
    i++

    this.arrTeams = this.arrTeams.filter((thing, i, arr) => {
      return (
        arr.indexOf(
          arr.find((t: any) => t.team_id === thing.team_id)
        ) === i
      );
    });
    console.log(this.arrTeams)

    // if(this.arrTeams.length == 1) {
    //   this.assignJobOwnerForm.controls.teams.setValue(this.arrTeams[0].team_id)
    // }
    }
  }

  onSearchValueChange(searchVal) {
    let filter = searchVal.toLowerCase();
    if(filter.length > 0) {
      this.arrJobOwner = this.allarrJobOwners.filter(option => option.user_name.toLowerCase().includes(filter));
    } else {
      this.arrJobOwner =  this.allarrJobOwners
    }
  }

  onSearchTeam(searchVal) {
    let filter = searchVal.toLowerCase();
    if(filter.length > 0) {
      this.arrTeams = this.allArrTeams.filter(option => option.team_name.toLowerCase().includes(filter));
    } else {
      this.arrTeams =  this.allArrTeams
    }
  }

  onNotesChange(value) {
    if(value.length > 0) {
      let charRemaining = (500 - value.length)
      this.notesMessage = `Remaining characters ${charRemaining}.`
    } else {
      this.notesMessage = 'Max 500 characters.'
    }
  }

  openFileUpload() {
    let data: any = {}
    data.reference = this.dataModel.data.chargeNumber
    data.objectType = CHARGE_FORMS_NAME.ASSIGN_JOB_OWNER
    this.emitEvent('openFileUploadDialog', data)
  }

  clearSelection(event: Event) {
    event.stopPropagation();
    this.assignJobOwnerForm.controls.teams.setValue(null)
  }

  onClearTeam() {
    this.assignJobOwnerForm.controls.teams.setValue(null)
    this.arrTeams = this.allArrTeams
    this.arrJobOwner = this.allarrJobOwners
  }

  onClearUser() {
    this.assignJobOwnerForm.controls.users.setValue(null)
    this.arrJobOwner = this.allarrJobOwners
  }
}
