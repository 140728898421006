<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-md-12">
            <div class="" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="page-top-left" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                    <div class="page-title">
                        <h1>All Users</h1>
                    </div>
                    <div class="page-actions" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                        <button (click)="openDialog()" mat-stroked-button color="primary">
                            <mat-icon>add_box</mat-icon>Create User
                        </button>
                    </div>
                </div>
                <div class="page-inputs page-top-right">
                    <mat-form-field appearance="outline">
                        <mat-label>Search
                        </mat-label>
                        <input matInput placeholder="enter search term" autocomplete="off">
                        <mat-error>Field must be valid</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="inspection-list custom-table ">
                <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
                    <!-- Position Column -->
                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let element" style="padding-left: 20px !important;"> {{element.Name}}
                        </td>
                    </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="Username">
                        <th mat-header-cell *matHeaderCellDef>Username</th>
                        <td mat-cell *matCellDef="let element"> {{element.Username}} </td>
                    </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="Email">
                        <th mat-header-cell *matHeaderCellDef>Email</th>
                        <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
                    </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="Phone">
                        <th mat-header-cell *matHeaderCellDef>Phone</th>
                        <td mat-cell *matCellDef="let element"> {{element.Phone}} </td>
                    </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="Workstream">
                        <th mat-header-cell *matHeaderCellDef>Workstream</th>
                        <td mat-cell *matCellDef="let element"> {{element.Workstream}} </td>
                    </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="Organization">
                        <th mat-header-cell *matHeaderCellDef>Organization</th>
                        <td mat-cell *matCellDef="let element"> {{element.Organization}} </td>
                    </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="Role">
                        <th mat-header-cell *matHeaderCellDef>Role</th>
                        <td mat-cell *matCellDef="let element"> {{element.Role}} </td>
                    </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="UserStatus">
                        <th mat-header-cell *matHeaderCellDef>UserStatus</th>
                        <td mat-cell *matCellDef="let element"> {{element.UserStatus}} </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;">Actions </th>
                        <td mat-cell *matCellDef="let row">
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <button mat-icon-button matTooltip="Click to view" class="iconbutton" color="primary">
                                    <mat-icon aria-label="Edit">visibility</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Click to view" class="iconbutton" color="primary">
                                    <mat-icon aria-label="Edit">edit</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>

        </div>
    </div>

</div>