<div class="detail-container mb-4" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px"
  fxLayoutAlign="flex-start">
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Number </label>
    <p class="font-weight-bold">{{this.dataModel.data.chargeNumber}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Amount </label>
    <p>&pound;{{this.dataModel.data.chargeAmount}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Permit Type </label>
    <p>{{this.dataModel.data.permitType}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Type </label>
    <p class="status-pill">{{this.dataModel.data.chargeType}}</p>
  </div>
</div>
<hr>
<div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
  <div class="info-element" fxFlex="100" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Description </label>
    <p>{{this.dataModel.data.chargeDesc}}</p>
  </div>
</div>
<hr>


  <!-- <form form [formGroup]="AssignPotentialForm" id="filter-form" class="d-flex flex-column p-3" >
    <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <div fxFlex="50" style="position: relative;">
        <mat-icon *ngIf="AssignPotentialForm.get('team_id').value" class="clear-search" (click)="AssignPotentialForm.get('team_id').setValue(null)">clear</mat-icon>
        <mat-form-field appearance="fill" fxFlex="100">
          <mat-label>Team</mat-label>
          <mat-select [disableOptionCentering]="true" formControlName="team_id">
            <input placeholder="Search Team" class="search-input forSearch" (keyup)="onSearchTeam($event.target.value)">
            <mat-option [value]="permitStatus.team_id" *ngFor="let permitStatus of arrPotentialTeams">
              {{permitStatus.team_name}}</mat-option>
          </mat-select>
          <mat-error>Field must be valid</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" style="position: relative;">
        <mat-icon *ngIf="AssignPotentialForm.get('user_id').value" class="clear-search" (click)="AssignPotentialForm.get('user_id').setValue(null)">clear</mat-icon>
        <mat-form-field appearance="fill" fxFlex="100">
          <mat-label>User</mat-label>
          <mat-select [disableOptionCentering]="true" formControlName="user_id">
            <input placeholder="Search User" class="search-input forSearch" (keyup)="onSearchUser($event.target.value)">
            <mat-option [value]="permitStatus.user_id" *ngFor="let permitStatus of arrPotentialUser">
              {{permitStatus.user_name}}</mat-option>
          </mat-select>
          <mat-error>Field must be valid</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form> -->
<!-- <div class="detail-container mt-3" fxLayout="row" fxLayoutAlign="flex-start">
  <div class="d-flex justify-content-center align-items-center" style="font-weight: bold;"> <span>Split charge</span>
    <mat-icon style="color: var(--theme-color); margin-left: 5px; cursor: pointer;" (click)="addItem()">add_box
    </mat-icon>
  </div>
</div> -->
<!--
<div class="form-group col">
  <form form [formGroup]="AssignLiabilityForm" id="filter-form" class="d-flex flex-column p-3" appInvalidControlScroll>
    <div formArrayName="arr" *ngFor="let a of AssignLiabilityForm.get('arr')['controls']; let i = index;">
      <div [formGroupName]="i" style="margin-bottom: 10px; " class="d-flex flex-column">

        <ng-container *ngIf="i != 0">
          <hr>
          <div class="d-flex justify-content-start align-items-center" style="font-weight: bold;"> <span>Remove
              charge</span>
            <mat-icon style="color: var(--theme-color); margin-left: 5px; cursor: pointer;"
              (click)="removeUser(i)">remove_circle
            </mat-icon>
          </div>
        </ng-container>

        <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
          <mat-form-field appearance="fill" fxFlex="33.33">
            <mat-label>Select Liability Organisation</mat-label>
            <mat-select formControlName="contractor_code">
              <mat-option [value]="permitStatus.contractor_code" *ngFor="let permitStatus of arrLiabilityOrg">
                {{permitStatus.contractor_name}}</mat-option>
            </mat-select>
            <mat-error>Field must be valid</mat-error>

          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex="33.33">
            <mat-label>Enter Amount</mat-label>
            <input type="number" matInput placeholder="Amount" autocomplete="off" formControlName="amount">
            <mat-error>Field must be valid</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex="33.33">
            <mat-label>Response due by</mat-label>
            <input matInput [matDatepicker]="chargeReceiptDate" [min]="mindate" (click)="chargeReceiptDate.open()"
              (dateChange)="onDateChange($event.value)" (focus)="chargeReceiptDate.open()" formControlName="due_date">
            <mat-datepicker-toggle matSuffix [for]="chargeReceiptDate"></mat-datepicker-toggle>
            <mat-datepicker #chargeReceiptDate></mat-datepicker>
            <mat-error>Field must be valid</mat-error>

          </mat-form-field>
        </div>

        <div class="fixed-underline mt-0 pt-0" fxFlex="100" fxLayoutGap="10px">
          <div fxFlex="33.33"></div>
          <div fxFlex="33.33" *ngIf="totalValidator">
            <label class="text-danger" style="font-size: 12px;"> Split amount total should match charge amount. </label>
          </div>
          <div fxFlex="33.33"></div>
        </div>

        <div class="fixed-underline" class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
          <mat-form-field appearance="fill" fxFlex="100" hintLabel={{notesMessage}}>
            <mat-label>Notes</mat-label>
            <textarea matInput placeholder="Notes" [maxLength]="500" rows="4" style="resize: none;" autocomplete="off"
              formControlName="notes_text" (keyup)="onNotesChange($event.target.value)"></textarea>
            <mat-error>Max character limit 500.</mat-error>
          </mat-form-field>
        </div>

        <div class="fixed-underline">
          <mat-form-field appearance="fill" fxFlex="100">
            <input matInput placeholder="Upload Document" autocomplete="off" readonly
              value="Upload Evidence (Optional)">
            <mat-icon class="upload" matSuffix
              (click)="openFileUpload(a.get('contractor_code').value)">attach_file</mat-icon>
          </mat-form-field>

        </div>
        <div fxFlex="100" fxLayoutGap="10px">
          <textarea matInput placeholder="Notes" [maxLength]="500" rows="4" style="resize: none;" autocomplete="off"
            formControlName="content" (keyup)="onNotesChange($event.target.value)" style="display: none;"></textarea>
          <mat-error>Max character limit 500.</mat-error>
          <ng-container [ngTemplateOutlet]="templateRef"></ng-container>

        </div>

      </div>
    </div>

  </form>


  <hr>
</div>

<ng-template #templateRef>
  <ng-content select=".attach-list"></ng-content>
</ng-template> -->
<!-- <div class="detail-container mx-4" fxLayout="row" fxLayoutAlign="start center">
  <div class="d-flex justify-content-center align-items-center" style="font-weight: bold;"> <span>Split charge</span>
    <mat-icon style="color: var(--theme-color); margin-left: 5px; cursor: pointer;" (click)="addItem()">add_box
    </mat-icon>
  </div>
  <div class=" mx-4 d-flex justify-content-center align-items-center">
    <label class="text-danger fs-5" *ngIf="chargeWarning"> Note: Charge can be split maximum 5 times.</label>
  </div>
</div> -->
