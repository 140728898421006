<div class="col-md-12 mt-3">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div fxFlex="40">

      <div style="padding:5px 10px;" fxLayout="row" fxLayoutAlign="left" fxLayoutGap="15px">

        <div>
          <mat-icon *ngIf="searchInput.value" class="clear-search" (click)="clearSearch()">clear</mat-icon>
          <input type="text" placeholder="Search" class="search-input" [formControl]="searchInput">
        </div>
        <button type="button" fxflex="100" mat-stroked-button class="blue-btn">GO</button>
        <button (click)="openFileUpload()" type="button" fxflex="100" mat-stroked-button class="blue-btn"><mat-icon>file_upload</mat-icon></button>
      </div>

    </div>

    <!-- <button>
      <mat-icon class="upload" matSuffix (click)="openFileUpload()">attach_file</mat-icon>
    </button> -->

  </div>

</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">

      <div class="comments-list custom-table">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

          <!-- Position Column -->
          <ng-container matColumnDef="FileName" fxFlex="60">
            <th mat-header-cell *matHeaderCellDef>File name</th>
            <td mat-cell *matCellDef="let element" style="padding-left: 20px !important;"> {{element.file_name}} </td>
          </ng-container>
          <!-- Position Column -->
          <!-- <ng-container matColumnDef="CommentType">
            <th mat-header-cell *matHeaderCellDef>Comment Type</th>
            <td mat-cell *matCellDef="let element"> {{element.CommentType}} </td>
          </ng-container> -->
          <!-- Position Column -->
          <ng-container matColumnDef="CreatedBy">
            <th mat-header-cell *matHeaderCellDef>Created By</th>
            <td mat-cell *matCellDef="let element"> {{element.createdby}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element"> {{element.createdate | transformDate}} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Actions </th>
            <td mat-cell *matCellDef="let row">
              <div fxLayout="row" fxLayoutAlign="center center">
                <button [routerLink]="['/admin/permit-view']" (click)="openDialog(row)" mat-icon-button
                  matTooltip="Click to view" class="iconbutton" color="primary">
                  <mat-icon aria-label="Edit">download</mat-icon>
                </button>
              </div>

            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
      </div>

    </div>
  </div>
</div>
