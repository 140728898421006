import { ClaimWorkTableData, ClaimedWorkTableDataConfigModel, ClaimedWorkTableDataDataModel } from './claimed-work-table-data.model';
import { Component, Input, OnInit } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';

export interface PeriodicElement {
  name: string;

  date: any;
  status:any;
  comments: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {name: 'Selva',  date: '2021-11-22T04:49:54.000Z', status: 'Pending',comments:'testing'},

];



@Component({
  selector: 'app-claimed-work-table-data',
  templateUrl: './claimed-work-table-data.component.html',
  styleUrls: ['./claimed-work-table-data.component.css']
})
export class ClaimedWorkTableDataComponent extends WidgetComponentBase implements OnInit {


  @Input() dataModel: ClaimedWorkTableDataDataModel;
  @Input() configModel: ClaimedWorkTableDataConfigModel;
  dataSource = new MatTableDataSource<ClaimWorkTableData>();
  ELEMENT_DATA = [
    {name: 'Selva', claim_date: '2021-11-22T04:49:54.000Z', claim_status: 'Pending',comments:'testing'},

  ];

  constructor(
    private _serverApiBase: ServerApiInterfaceServiceService,

    private _validationService: ValidationService,
    private _spinner: NgxSpinnerService,


  ) {
    super(_serverApiBase, _validationService);

  }



  ngOnInit(): void {
  //  this.dataSource = new MatTableDataSource(this.ELEMENT_DATA) ;
console.log("datamodel.column",this.dataModel.displayedColumns)
    this.wgOnInit();
  }

  ngAfterViewInit() {
this.wgRefreshData();
  }

  setFieldData() {
  }

  getControlData() {
    let url = this.dataModel.widgetApiUrl;
    return this.wgAPIMethodPost(url, this.dataModel.apireqdata);
  }

  convertData(response: any) {


    this._spinner.hide();
console.log("response table data",response)

let items: ClaimWorkTableData[] = [];
if(response.claim_history_details.length > 0){
  response.claim_history_details.forEach(element => {
    let item: any = {};
  item.name= element.user_name;
  item.email = element.email_id;
  item.comments = element.comments;
  item.claim_status = element.cpm_claim_status;
  item.claim_date = element.claim_date;

    items.push(item);
  });
  this.dataSource = new MatTableDataSource(items);
}

// this.length = response.totalElements;

console.log("Datasource table",this.dataSource)
  }
  setMode(responseDataModel: any) {

  }


  setValue(responseDataModel: any) {
    // throw new Error('Method not implemented.');
  }



}
