<div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Number </label>
    <p class="font-weight-bold">{{this.dataModel.data.chargeNumber}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Amount </label>
    <p>&pound;{{this.dataModel.data.chargeAmount}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Permit Type </label>
    <p>{{this.dataModel.data.permitType}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Type </label>
    <p class="status-pill">{{this.dataModel.data.chargeType}}</p>
  </div>
</div>
<hr>
<div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
  <div class="info-element" fxFlex="100" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Description </label>
    <p>{{this.dataModel.data.chargeDesc}}</p>
  </div>
</div>
<hr>

<div class="form-group">
  <form form [formGroup]="UpdateChargeForm" id="filter-form" class="d-flex flex-column p-3" appInvalidControlScroll>

    <div class="fixed-underline d-flex align-items-center" class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
     <!-- <mat-form-field appearance="fill" fxFlex="50">
        <mat-label>Charge Status</mat-label>
        <input matInput placeholder="Charge Status" autocomplete="off" formControlName="chargeStatus">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field> -->
      <div class="info-element p-3" fxFlex="30" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Current Charge Status</label>
        <p>{{ chargeStatus}}</p>
      </div>
      <mat-form-field appearance="fill" fxFlex="50">
        <mat-label>Select Charge Status</mat-label>
        <mat-select formControlName="charge">
          <!-- <input placeholder="Search User" class="search-input" (keyup)="onSearchValueChange($event.target.value)"> -->
          <mat-option [value]="permitStatus.value" *ngFor="let permitStatus of arrCharge">
            {{permitStatus.display_text}}</mat-option>
        </mat-select>
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
      <div fxFlex="20">

      </div>
    </div>
    <div class="fixed-underline" class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="100" hintLabel={{notesMessage}}>
        <mat-label>Notes</mat-label>
        <textarea matInput placeholder="Notes" [maxLength]="500" rows="4" style="resize: none;" autocomplete="off" formControlName="notes" (keyup)="onNotesChange($event.target.value)"></textarea>
        <mat-error>Max character limit 500</mat-error>
      </mat-form-field>
    </div>
    <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="100">
        <!-- <mat-label>Upload Evidence(Optional)</mat-label> -->
        <input matInput placeholder="Upload Evidence(Optional)" autocomplete="off"
          value="Upload Evidence (Optional)">
        <mat-icon class="upload" matSuffix (click)="openFileUpload()">attach_file</mat-icon>
      </mat-form-field>
      <!-- <mat-form-field appearance="fill">
        <mat-label>Basic fill input</mat-label>
       <ngx-mat-file-input formControlName="fill" placeholder="Basic fill placeholder"></ngx-mat-file-input>
        <mat-icon matSuffix>folder</mat-icon>
      </mat-form-field> -->
    </div>
    <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <!-- <button (click)="openFileUpload()" mat-stroked-button color="primary">
        <mat-icon>publish</mat-icon>Upload Images
      </button> -->

    </div>
  </form>
</div>
