import { EventEmitter } from 'events';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';

export class RateCardListDataModel {
  isGlobalParams: any;
  isSelfDataLoad: any;
  globalParameters: Map<any, any>;
  apireqdata: any = {};
  widgetApiUrl: any;
  columnsToDisplay: any[]
  static getInstance<T>(): RateCardListDataModel {
    let model = new RateCardListDataModel();
    model.apireqdata = new RateCardListRequestModel()
    model.isSelfDataLoad = true;
    model.globalParameters = new Map<any, any>();
    return model;
  }
}

export class RateCardListConfigModel {
  static getInstance<T>(): RateCardListConfigModel {
    let model = new RateCardListConfigModel();
    return model;
  }

  public static readonly CALLER_TO_COMP_REFRESH_DATA = 'REFRESH_DATA';

  CompToCaller = new EventEmitter();
  CallerToComp = new EventEmitter();

  EventAction = new Map();
}

export class RateCardListRequestModel {
  skip: number = 0;
  top: number = MatTableHelper.PAGE_SIZE;
  pageno: number = 0;
  sortOrder: string;
  sortField: string;
  searchString: string;
  constructor() {
    this.skip = this.pageno * this.top;
  }
}

export interface RateCardListRow {
  work_type: string;
  paa_cat_012_nts: string;
  permit_cat_012_nts: string;
  variation_cat_012_nts: string;
  paa_cat_34_nts: string;
  permit_cat_34_nts: string;
  variation_cat_34_nts: string;
  paa_cat_012_stts: string;
  permit_cat_012_stts: string;
  variation_cat_012_stts: string;
  paa_cat_34_stts: string;
  permit_cat_34_stts: string;
  variation_cat_34_stts: string;
  created_by: any;
  created_date: any;
  updated_by: any;
  updated_date: any;
  isSliced: boolean;
}
