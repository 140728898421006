import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Input, ViewChild } from '@angular/core';
//import { PermitFPNModel, PermitFPNRow } from '..//permit-fpn-tab/permit-fpn-tab.model';

import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FPN_STATUS } from 'src/app/constants/db.constants';
import { GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { MASTER_DATA_DOMAIN } from 'src/app/constants/app-repo.constants';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { SaveFileService } from 'src/app/services/save-file.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { TransformDateTimePipe } from 'src/app/pipe/transform-date-time/transform-date-time.pipe';
import { ViewPermitFpnDataModel, ViewPermitFpnConfigModel, ViewPermitFpnRow } from './view-permit-fpn-popup.module';


@Component({
  selector: 'app-view-permit-fpn-popup',
  templateUrl: './view-permit-fpn-popup.component.html',
  styleUrls: ['./view-permit-fpn-popup.component.css']
})
export class ViewPermitFpnPopupComponent extends WidgetComponentBase implements OnInit {

  @Input() dataModel: ViewPermitFpnDataModel;
  @Input() configModel: ViewPermitFpnConfigModel;

  actionList: any[] = [];
  showForm: boolean = true;
  showComments: boolean = true;
  applicationId: any;
  fpnId: any;
  notesMessage: string = 'Max 500 characters.';


  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _saveFileService: SaveFileService,
    private _appRepoHelperService: AppRepoHelperService,
    private _router: Router,
    private fb: FormBuilder,
    private _notificationService: NotificationService,
    private _validationService: ValidationService,
    private sanitizer: DomSanitizer,

  ) {
    super(_serverApi, _validationService);

  }

  ngOnInit() {
    this.createFormGroup();
    this.wgOnInit();
  }

  createFormGroup() {
    this.wgFormGroup = this.fb.group({
      'action': ['', [Validators.required]],
      'comment': ['']
    });
  }



  getControlData() {
    let id = this.dataModel.globalParameters.get('applicationId');
    return this.wgAPIMethodGet(this.dataModel.apiDataUrl);
  }

  convertData(response: any)
  {
    try {
      if (response) {

          this.dataModel.data.FPNId = response.fpn_id;
          this.dataModel.data.FPNNumber = response.fpn_reference_number;
          this.dataModel.data.WorksReferenceNumber = response.work_reference_number;
          this.dataModel.data.FPNStatus = response.fpn_status;
          this.dataModel.data.Offencecode = response.offence_type;
          this.dataModel.data.OffenceDate = response.offence_date;
          this.dataModel.data.OffenceDetails = response.offence_details;

          this.dataModel.data.AuthorisedOfficerName = response.authorised_officer;
          this.dataModel.data.HAOrganisation = response.ha_organisation;
          this.dataModel.data.IssueDateAndTime = response.issue_date;
          this.dataModel.data.Location = response.location;

          this.dataModel.data.OfficerContactDetails = response.officer_contact_details;
          this.dataModel.data.PromoterOrganisation = response.promoter_organisation;

          this.dataModel.data.FPNCost = response.fpn_cost;
          this.dataModel.data.applicationId = this.applicationId;

          this.dataModel.data.FpnFiles = []//element.fpn_files;
          let getfpnId = this.dataModel.globalParameters.get('fpnId');

          // if(response.fpn_id == getfpnId){
            if (response.fpn_files) {
              for (let i = 0; i < response.fpn_files.length; i++) {
                let Imgitems: any = [];
                Imgitems.FileId = response.fpn_files[i].file_id;
                Imgitems.FPNFileId = response.fpn_files[i].fpn_file_id;
                Imgitems.FileName = response.fpn_files[i].file_name;
                Imgitems.CreatedBy = response.fpn_files[i].created_by;
                Imgitems.UpdatedBy = response.fpn_files[i].updated_by;
                Imgitems.CreatedDate = response.fpn_files[i].create_date;
                Imgitems.UpdatedDate = response.fpn_files[i].update_date;


                // if(!element.file_name.includes('docx') && !element.file_name.includes('pdf') ){
                  this.getImage(response.fpn_id,response.fpn_files[i].fpn_file_id,response.fpn_files[i].file_name).then((response: any) => {
                    Imgitems.ImageBlob = new Blob([response], { type: "image/jpeg" });
                    let unsafeImageUrl = URL.createObjectURL(Imgitems.ImageBlob);
                    Imgitems.ImageSrc = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
                  });
                // }
                this.dataModel.data.FpnFiles.push(Imgitems);
              }

            // }
          }

          let tempActionList = this._appRepoHelperService.getMDataByDomain(MASTER_DATA_DOMAIN.FPN_STATUS.toString());
          console.log(tempActionList)
          tempActionList.forEach(fpnStatus => {
            // if (this.isFPNStatusAcceptedOrWithdrawn(fpnStatus.key)) {
              this.actionList.push(fpnStatus)
            // }
          });
          console.log("data model data",this.dataModel.data)
          if(this.dataModel.data.FPNStatus=="Disputed"){
            //remove actionlist key as "Disputed"
            this.actionList.forEach( (item, index) => {
              if(item.key === this.dataModel.data.FPNStatus) this.actionList.splice(this.actionList.findIndex(item => item.key === 'Disputed'), 1);
            });
          }
          if (this.isFPNStatusAcceptedOrWithdrawn(this.dataModel.data.FPNStatus)) {
            this.wgFormGroup.disable();
          }
           if(this.dataModel.data.FPNStatus == FPN_STATUS.WITHDRAWN){
            this.showForm = false;
          }else{
            this.showForm = true;
          }
          // Validated [User] - Awaiting Approval
          // Validated [User]
          // Challenged
          // if(this.dataModel.data.chargeStatus === 'Validated [User]' || this.dataModel.data.chargeStatus === 'Validated [User]-Awaiting Approval') {
          //   if(this.actionList.includes(item => item.key === 'Disputed')) {
          //     this.actionList.splice(this.actionList.findIndex(item => item.key === 'Disputed'), 1)
          //   }
          // }
          // if(this.dataModel.data.chargeStatus === 'Challenged') {
          //   this.actionList.splice(this.actionList.findIndex(item => item.key === 'Accepted'), 1)
          // }
          console.log('action>>>', this.actionList)

      }
    } catch (e) {
    }
  }

  setFieldData() {
  }

  setMode(responseDataModel: any) {
  }

  setValue(responseDataModel: any) {
  }

  applyFilter(filterValue: string) {
  }

  // ngAfterViewInit() {
  //  this.wgRefreshData();
  // }

  getFileApiData(fileId, filename) {
    // console.log("FPNId",this.dataModel.data.FPNId)
    this.fpnId = this.dataModel.data.FPNId;
    // console.log("applicationId",this.dataModel.data.applicationId)
    this.applicationId = this.dataModel.data.applicationId;
    // console.log("fileId",fileId)
    this._serverApi.downloadZipFile(`/api/v1/applications/fpn/${this.applicationId}/${this.fpnId}/${fileId}`).subscribe(

    // this._serverApi.downloadZipFile(`/api/v1/file/${fileId}`).subscribe(
      // this._serverApi.downloadZipFile(`/api/v1/file/${fileId}`).subscribe(
      response => {

        try {
          if (response) {
            this._saveFileService.saveZipFile(response, filename)
          }
        } catch (e) {
        }
      }, error => {
      }
    );
  }

  savePdf(type) {
    let fpnId = this.dataModel.data.FPNId;
console.log("fpnId",fpnId)
    // this.showSpinner();
    this._serverApi
      .download(`/api/v1/pdf/generate-pdf/${type}/${fpnId}`)
      .subscribe(
        (response) => {
          try {

            if (response) {
              console.log('pdf file', response);
                        let filename = type + '-list';
              this._saveFileService.saveFile(response, filename);
            }
          } catch (e) {
          }
        },
        (error) => {
          console.log('Permit save as draft error :');
        }
      );
  }


  getImage(fpnId,fpnFileId,fileName) {

    return new Promise((resolve, reject) => {
      if(fileName?.includes('docx') || fileName?.includes('pdf')){
        // reject("error")
        return;
      }
      else{
        this._serverApi.downloadZipFile(`/api/v1/applications/fpn/${this.applicationId}/${fpnId}/${fpnFileId}`).subscribe(
          response => {
            try {
              if (response) {
                resolve(response)
              }
              reject("error")
            } catch (e) {
            }
          }, error => {
            reject(error)
          }
        );
      }

    });
  }

  onUpdateBtnClick() {
    this.wgFormGroup.markAllAsTouched();
    if (this.wgFormGroup.valid) {
      let fpnStatusRequest = {
        fpn_reason: this.wgFormGroup.controls.comment.value,
        fpn_status: this.wgFormGroup.controls.action.value,
      }
      let appId = this.dataModel.globalParameters.get('chargeId');
      this.wgAPIMethodPost(`/api/v1/cpm/actions/actionFPN/${appId}`, fpnStatusRequest).then(resp => {
        this.emitEvent('onViewFPNUpdate', resp);
      });
    }
  }

  onchangeAction(item) {
    if(item.value == "disputed"){
      console.log("onchangeAction",item)
      // this.wgFormGroup.get('comment').addValidators(Validators.required);
      // this.wgFormGroup.get('comment').addValidators(this.noWhitespaceValidator);
      this.wgFormGroup.get('comment').setValidators([Validators.required, this.noWhitespaceValidator]);
      this.wgFormGroup.get('comment').updateValueAndValidity();
      this.showComments = true;
    }
    else{
      this.showComments = false;
      this.wgFormGroup.get('comment').clearValidators();
      this.wgFormGroup.controls.comment.setValue('');
      this.wgFormGroup.get('comment').updateValueAndValidity();
    }
  }

  onOkBtnClick() {
    this.emitEvent('onViewFPNOkClick');
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

  // isFPNStatusAcceptedOrDisputed
  isFPNStatusAcceptedOrWithdrawn(fpnStatus) {
    return (fpnStatus == 'Accepted'  || fpnStatus == 'Withdrawn');
    // return false;
  }

  onFileClick(fileSrc) {
    if(!fileSrc.FileName.includes('doc') && !fileSrc.FileName.includes('pdf')){
      let file = fileSrc.ImageSrc;
      window.open(file.changingThisBreaksApplicationSecurity, '_blank');
    }

  }

  onNotesChange(value) {
    if(value.length > 0) {
      let charRemaining = (500 - value.length)
      this.notesMessage = `Remaining characters ${charRemaining}.`
    } else {
      this.notesMessage = 'Max 500 characters.'
    }
  }

}
