<div class="row">
    <div class="col-md-12 tableColor">
        <div class="permit-list custom-table low-border-padding pb-3 my-3 mx-2">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
                <ng-container matColumnDef="UploadDate">
                    <th mat-header-cell *matHeaderCellDef class="word-break text-center" mat-sort-header> Upload Date </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        {{element.UploadDate | transformDateTime}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="ChargeType">
                    <th mat-header-cell *matHeaderCellDef class="word-break text-center"> Charge Type </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        {{element.ChargeType}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="UploadType">
                    <th mat-header-cell *matHeaderCellDef class="word-break text-center" mat-sort-header> Upload Type </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        {{element.UploadType}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="FileName">
                    <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> File Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.FileName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="AuthorityName">
                    <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Highway
                        Authority
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.AuthorityName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="InvoiceReference">
                    <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Invoice
                        Reference
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.InvoiceReference}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="FinalInvoiceReference">
                    <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Final Invoice
                        Reference
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.FinalInvoiceReference}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="ChargesUploaded">
                    <th mat-header-cell *matHeaderCellDef class="word-break text-center" mat-sort-header> Charges
                        Uploaded
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        {{element.ChargesUploaded}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="UploadMessage">
                    <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Message
                    </th>
                    <td mat-cell *matCellDef="let element; let i = dataIndex" class="last-processed-result">
                      {{element.isSliced ? (element.UploadMessage | slice:0:100) : element.UploadMessage }}
                      <span class="more-text" (click)="expandRow(element, i)">{{showMore(element.UploadMessage,
                        element.isSliced)}}
                      </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="UploadedBy">
                    <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Uploaded by
                    </th>
                    <td mat-cell *matCellDef="let element" >
                        {{element.UploadedBy}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="UploadStatus">
                    <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Upload Status
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        <span class="status-pill"
                            [ngClass]="getUploadStatusCssClass(element.UploadStatus)">{{element.UploadStatus}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="S3FileLocation">
                    <th mat-header-cell *matHeaderCellDef class="word-break"> File Download
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button matTooltip="Download" class="iconbutton" (click)="downloadFile(element)"
                            *ngIf="displayFileDownload(element.FileUuid)"><mat-icon
                                aria-label="Download">get_app</mat-icon></button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="dataModel.columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: dataModel.columnsToDisplay;"></tr>
            </table>

            <mat-paginator [pageSize]="MAT_HELPER.PAGE_SIZE" [pageSizeOptions]="MAT_HELPER.PAGE_SIZE_OPTIONS"
                showFirstLastButtons [length]="length"></mat-paginator>

        </div>
    </div>
</div>
