<div class="container-fluid">
    <div class="row ">
        <div class="info-element col-12 col-md-4">
            <label>Charge Type</label>
            <p>INSP-123456</p>
        </div>

        <div class="info-element col-12 col-md-4 ">
            <label>Charge Amount</label>
            <p>&pound; 123456</p>
        </div>

        <div class="info-element col-12 col-md-4 ">
            <label>Recorded By</label>
            <p>Admin</p>
        </div>


    </div>


    <div class="info-element">
        <label>Charge Description</label>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>

    </div>

    <hr/>


    <form [formGroup]="wgFormGroup" class="form-container">




        <div fxLayout="row">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Write-off amount</mat-label>
                <input matInput autocomplete="off" formControlName="writeoffamount">
            </mat-form-field>
        </div>
        <div fxLayout="row">



            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Agreed by HA Contact Name</mat-label>
                <textarea matInput required formControlName="agreedbyhacontactname" rows="4"></textarea>

            </mat-form-field>
        </div>

    </form>


</div>