import {
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import {
  UploadsListConfigModel,
  UploadsListDataModel,
  UploadsRow,
} from './uploads-list.model';
import { FILTER_TYPE } from 'src/app/app-constants';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { PERMIT_ACTIONS_CONFIG } from 'src/app/constants/db.constants';
import { Router } from '@angular/router';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { SaveFileService } from 'src/app/services/save-file.service';

@Component({
  selector: 'app-uploads-list',
  templateUrl: './uploads-list.component.html',
  styleUrls: ['./uploads-list.component.css']
})
export class UploadsListComponent extends WidgetComponentBase {
  @Input() dataModel: UploadsListDataModel;
  @Input() configModel: UploadsListConfigModel;

  PERMIT_ACTIONS_CONFIG = PERMIT_ACTIONS_CONFIG;
  dataSource = new MatTableDataSource<UploadsRow>();
  expandedElement: UploadsRow;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  pageChangeSubscription: any;
  sortFieldLabel: string = '';
  length: any;
  MAT_HELPER = MatTableHelper;
  DATA_HELPER = DataHelper;

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _saveFileService: SaveFileService,
    private _router: Router,
    public dialog: MatDialog,
    private _validationService: ValidationService,
    private _appRepoService: AppRepoService
  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.pageChangeSubscription = this.paginator.page.subscribe(() => {
      this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
      this.dataModel.apireqdata.top = this.paginator.pageSize;
      this.dataModel.apireqdata.skip =
        this.dataModel.apireqdata.pageno * this.dataModel.apireqdata.top;
      this.wgRefreshData();
    });

    this.wgOnInit();
    this.wgRefreshData();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      if (this.sort.direction) {
        this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();

        switch (this.sort.active) {
          case 'UploadDate':
            this.sortFieldLabel = 'UPLOAD_DATE';
            break;
          case 'UploadType':
            this.sortFieldLabel = 'UPLOAD_TYPE';
            break;
          case 'FileName':
            this.sortFieldLabel = 'FILE_NAME';
            break;
          case 'AuthorityName':
            this.sortFieldLabel = 'AUTHORITY_NAME';
            break;
          case 'UploadStatus':
            this.sortFieldLabel = 'UPLOAD_STATUS';
            break;
          case 'S3FileLocation':
            this.sortFieldLabel = 'S3_FILE_LOCATION';
            break;
          case 'InvoiceReference':
            this.sortFieldLabel = 'INVOICE_REFERENCE';
            break;
          case 'FinalInvoiceReference':
            this.sortFieldLabel = 'FINAL_INVOICE_REFERENCE';
            break;
          case 'ChargesUploaded':
            this.sortFieldLabel = 'CHARGES_UPLOADED';
            break;
          case 'UploadMessage':
            this.sortFieldLabel = 'UPLOAD_MESSAGE';
            break;
          case 'UploadedBy':
            this.sortFieldLabel = 'UPLOADED_BY';
            break;
          default:
            break;
        }

        this.dataModel.apireqdata.sortField = this.sortFieldLabel;

        this.wgRefreshData();
      }
      else {
        try {
          delete this.dataModel.apireqdata.sortOrder;
          delete this.dataModel.apireqdata.sortField;
        }
        catch (err) {
        }
      }
    });
  }

  getControlData() {
    let url: string = null;

    if (this.sort.direction) {
      this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();
      this.dataModel.apireqdata.sortField = this.sortFieldLabel;
    }
    else {
      try {
        delete this.dataModel.apireqdata.sortOrder;
        delete this.dataModel.apireqdata.sortField;
      }
      catch (err) { }
    }

    switch (this.dataModel.filterType) {
      case FILTER_TYPE.ADVANCE_FILTER:
        url = this.dataModel.apiUrls.advanceFilter;
        break;
      case FILTER_TYPE.QUICK_FILTER:
        url = this.dataModel.apiUrls.quickFilter;
        break;
      case FILTER_TYPE.DYNAMIC_FILTER:
        url = this.dataModel.apiUrls.dynamicFilter;
        break;
      default:
        break;
    }

    return this.wgAPIMethodGet(url, this.dataModel.apireqdata);
  }

  convertData(response: any) {
    let items: UploadsRow[] = [];

    response.content.forEach((element) => {
      let item: any = {};

      item.InvoiceUploadHistoryId = element.invoice_upload_history_id;
      item.UploadDate = element.upload_date;
      item.ChargeType = element.charge_type;
      item.UploadType = element.upload_type;
      item.FileName = element.file_name;
      item.FileUuid = element.file_uuid;
      item.AuthorityName = element.authority_name;
      item.UploadStatus = element.upload_status_description;
      item.S3FileLocation = element.s3_file_location;
      item.InvoiceReference = element.invoice_reference;
      item.FinalInvoiceReference = element.final_invoice_reference;
      item.ChargesUploaded = element.charges_uploaded;
      item.UploadMessage = element.upload_message;
      item.UploadedBy = element.uploaded_by;
      item.isSliced = true;

      items.push(item);
    });

    this.dataSource = new MatTableDataSource(items);
    this.length = response.totalElements;
  }

  setFieldData() { }

  setMode(responseDataModel: any) { }

  setValue(responseDataModel: any) { }

  ngOnDestroy(): void {
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
  }

  resetPagination() {
    this.paginator.pageIndex = 0;
    this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
    this.dataModel.apireqdata.skip = 0;
    this.dataModel.apireqdata.top = this.paginator.pageSize;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  downloadFile(element) {
    this._serverApi.download(`/api/v1/cpm/files/${element.FileUuid}/download`).subscribe(
      response => {
        try {
          if (response) {
            this._saveFileService.saveFile(response, element.FileName);
          }
        } catch (e) {
        }
      }, error => {
      }
    );
  }

  displayFileDownload(fileUuid) {
    if (fileUuid) return true;
    return false;
  }

  expandRow(rowData, i) {
    if (rowData.isSliced == true) {
      this.dataSource.data[i].isSliced = false;
    } else {
      this.dataSource.data[i].isSliced = true;
    }
  }

  showMore(value, isSliced) {
    if (value) {
      if (value.length > 100 && !!isSliced) {
        return '...more';
      } else if (value.length < 100 && !!isSliced) {
        return;
      } else {
        return '...less';
      }
    }
  }

  getUploadStatusCssClass(element) {
    switch (element) {
      case 'Success':
        return 'success-status';
      case 'Success with Warnings':
        return 'successwithwarnings-status';
      case 'Failed':
        return 'failed-status';
      case 'Deleted':
        return 'deleted-status';
      default:
        return '';
    }
  }
}
