<div fxFlex="100" fxLayout="column" fxLayoutGap="10px" class="p-4">

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="dataModel.showFiles">
      <div fxFlex="40">

        <div style="padding:5px;" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">

          <!-- <div>
            <mat-icon *ngIf="searchInput.value" class="clear-search" (click)="clearSearch()">clear</mat-icon>
            <input type="text" placeholder="Search" class="search-input" [formControl]="searchInput">
          </div>
          <button type="button" fxflex="100" mat-stroked-button class="blue-btn">GO</button> -->
          <button (click)="openFileUpload()" type="button" fxflex="100" mat-stroked-button class="blue-btn" matTooltip="Click to upload files and attachments." [matTooltipPosition]="'right'"><mat-icon>file_upload</mat-icon></button>
        </div>

      </div>

    </div>

  <div class="d-flex justify-content-between align-items-center customBackground"
    *ngFor="let item of noteArr; first as isFirst; let i = index">

    <mat-accordion class="w-100">
      <mat-expansion-panel (click)="openPanelHandler(i)"
        [expanded]="panelOpenState[i]" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title class="d-flex align-items-center">
            {{item.action_type}} <span style="font-size:90%; margin-left: 1rem">({{item.count}})</span>
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon *ngIf="!panelOpenState[i]">add</mat-icon>
            <mat-icon *ngIf="panelOpenState[i]">remove</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div (click)="stopPropagation($event)">
            <table class="table-borderless w-100" *ngIf="dataModel.showNotes">
              <thead *ngIf="!showNotesFirst">
                <tr>
                  <td scope="col" >User</td>
                  <td scope="col">Date</td>
                  <td scope="col" *ngIf="item.action_type=='Assign Liability Owner'" >Primary Liability Organisation</td>
                  <td scope="col" *ngIf="item.action_type=='Assign Liability Owner'" >Secondary Liability Organisation</td>
                  <td scope="col" >Notes</td>
                </tr>
              </thead>

              <thead *ngIf="showNotesFirst" >
                <tr>
                  <td scope="col" >Notes</td>
                  <td scope="col" >User</td>
                  <td scope="col">Date</td>
                  <td scope="col" *ngIf="item.action_type=='Assign Liability Owner'" >Primary Liability Organisation</td>
                  <td scope="col" *ngIf="item.action_type=='Assign Liability Owner'" >Secondary Liability Organisation</td>
                </tr>
              </thead>
              <tbody *ngIf="!showNotesFirst">
                <tr *ngFor="let data of item.lst_cpm_notes; let ind = index" >
                  <td scope="row">{{data.job_owner}}</td>
                  <td scope="row">{{data.date | transformDateTime }}</td>
                  <td scope="row" *ngIf="item.action_type=='Assign Liability Owner'" > {{ data.primary }} </td>
                  <td scope="row" *ngIf="item.action_type=='Assign Liability Owner'" > {{ data.secondary }} </td>
                  <td scope="row" style="max-width: 50%;">
                    <pre class="note"> {{getText(data)}} <span class="more-text" (click)="expandRow(data, i, ind)">{{showMore(data.note, data.isSliced)}}</span> </pre>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="showNotesFirst" >
                <tr *ngFor="let data of item.lst_cpm_notes; let ind = index" >
                  <td scope="row">
                    <pre class="note" style="width: 60%;"> {{getText(data)}} <span class="more-text" (click)="expandRow(data, i, ind)">{{showMore(data.note, data.isSliced)}}</span> </pre>
                  </td>
                  <td scope="row">{{data.job_owner}}</td>
                  <td scope="row">{{data.date | transformDateTime }}</td>
                  <td scope="row" *ngIf="item.action_type=='Assign Liability Owner'" > {{ data.primary }} </td>
                  <td scope="row" *ngIf="item.action_type=='Assign Liability Owner'" > {{ data.secondary }} </td>
                </tr>
              </tbody>
            </table>

            <div class="my-3" *ngIf="dataModel.showFiles" [ngClass]="item.lst_cpm_files.length > 8 ? 'file-content' : 'file-content-1' ">
              <div class="d-flex justify-content-between flex-column detail-container" style="border-radius: 5px; padding: 10px; background-color: #f6f8fb; margin: 5px;" *ngFor="let item of item.lst_cpm_files">
                  <div class="d-flex flex-row">
                      <span>
                        <img [src]="item.imageSrc" alt="" style="height: 25px;">
                      </span>
                      <span class="ml-2">{{item.file_name}}</span>
                  </div>
                  <div class="comment-details d-flex flex-row justify-content-between m-2">
                      <div class="comment-details d-flex flex-column">
                        <span class="list-date">{{item.created_by}}</span>
                        <span class="list-date">{{item.create_date | transformDateTime}}</span>
                      </div>
                      <div class="d-flex align-items-end">
                          <span class="material-icons icon-position" style=" font-size: 20px; color: #3D6EB5; cursor: pointer;" (click)="onDownloadFile(item)">file_download </span>
                          <span class="material-icons icon-position" style=" font-size: 20px; color: #3D6EB5; cursor: pointer;" (click)="onDeleteFile(item)">delete </span>
                      </div>
                  </div>
              </div>

            </div>

          <!-- Old Design -->
            <!-- <div class="my-3" [ngClass]="item.lst_cpm_files.length > 6 ? 'file-content' : 'file-content-1' " *ngIf="dataModel.showFiles">
              <div class="d-flex justify-content-between flex-column detail-container" style="width: 195px;" *ngFor="let item of item.lst_cpm_files">
                  <div class="d-flex flex-column">
                      <span class="material-icons-outlined" style=" font-size: 20px; color: black;">
                        description
                      </span>
                      <span class="text-wrapp">{{item.file_name}}</span>
                  </div>
                  <div class="comment-details d-flex flex-column">
                      <span class="list-date">{{item.created_by}}</span>
                      <span class="list-date">{{item.create_date | transformDateTime}}</span>
                      <div class="d-flex justify-content-end m-2" (click)="stopPropagation($event)">
                          <span class="material-icons icon-position" style=" font-size: 20px; color: #3D6EB5; cursor: pointer;" (click)="onDownloadFile(item)">file_download </span>
                          <span class="material-icons icon-position" style=" font-size: 20px; color: #3D6EB5; cursor: pointer;" (click)="onDeleteFile(item)">delete </span>
                      </div>
                  </div>
              </div>

          </div> -->

          </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
