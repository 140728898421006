<div class="container-fluid">
  <div class="row">

      <div class="permit-list custom-table low-border-padding pb-3 my-3 px-2 w-100" style="overflow: auto;">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

          <!-- Position Column -->
          <ng-container matColumnDef="WorkType">
            <th mat-header-cell *matHeaderCellDef >Work Type</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="splitString(element.work_type)" >
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="PAACat012NTS">
            <th mat-header-cell *matHeaderCellDef class="text-center minWidth120">PAA Cat 012 NTS</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.paa_cat_012_nts}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="PermitCat012NTS">
            <th mat-header-cell *matHeaderCellDef class="text-center minWidth120">Permit Cat 012 NTS</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.permit_cat_012_nts}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="VariationCat012NTS">
            <th mat-header-cell *matHeaderCellDef class="text-center minWidth120">Variation Cat 012 NTS</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.variation_cat_012_nts}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="PAACat34NTS">
            <th mat-header-cell *matHeaderCellDef class="text-center minWidth120">PAA Cat 34 NTS</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.paa_cat_34_nts}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="PermitCat34NTS">
            <th mat-header-cell *matHeaderCellDef class="text-center minWidth120">Permit Cat 34 NTS</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.permit_cat_34_nts}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="VariationCat34NTS">
            <th mat-header-cell *matHeaderCellDef class="text-center minWidth120">Variation Cat 34 NTS</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.variation_cat_34_nts}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="PAACat012STTS">
            <th mat-header-cell *matHeaderCellDef class="text-center minWidth120">PAA Cat 012 STTS</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.paa_cat_012_stts}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="PermitCat012STTS">
            <th mat-header-cell *matHeaderCellDef class="text-center minWidth120">Permit Cat 012 STTS</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.permit_cat_012_stts}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="VariationCat012STTS">
            <th mat-header-cell *matHeaderCellDef class="text-center minWidth120">Variation Cat 012 STTS</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.variation_cat_012_stts}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="PAACat34STTS">
            <th mat-header-cell *matHeaderCellDef class="text-center minWidth120">PAA Cat 34 STTS</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.paa_cat_34_stts}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="PermitCat34STTS">
            <th mat-header-cell *matHeaderCellDef class="text-center minWidth120">Permit Cat 34 STTS</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.permit_cat_34_stts}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="VariationCat34STTS">
            <th mat-header-cell *matHeaderCellDef class="text-center minWidth120">Variation Cat 34 STTS</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.variation_cat_34_stts}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="Response">
            <th mat-header-cell *matHeaderCellDef>Response</th>
            <td mat-cell *matCellDef="let element; let i = dataIndex" class="maxWidth200">
              {{element.isSliced ? (element.response | slice:0:100) : element.response }}
              <span class="more-text" (click)="expandRow(element, i)">{{showMore(element.response,
                element.isSliced)}}
              </span>
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="Request">
            <th mat-header-cell *matHeaderCellDef>Request</th>
            <td mat-cell *matCellDef="let element; let i = dataIndex" class="maxWidth200">
              {{element.isSliced ? (element.request | slice:0:100) : element.request }}
              <span class="more-text" (click)="expandRow(element, i)">{{showMore(element.request,
                element.isSliced)}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef >Status</th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <span class="status-pill"
                [ngClass]="getStatusCssClass(element.status)">{{element.status}}
              </span>
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="CreatedBy">
            <th mat-header-cell *matHeaderCellDef class="text-center">Created by</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.created_by}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="CreatedDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
            <td mat-cell *matCellDef="let element" class="text-center">{{element.created_date | transformDateTime}}  </td> <!--{{element.Date | transformDate}} -->
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="UpdatedBy">
            <th mat-header-cell *matHeaderCellDef class="text-center">Updated by</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.updated_by}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="UpdatedDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated Date</th>
            <td mat-cell *matCellDef="let element" class="text-center">{{element.updated_date | transformDateTime}}  </td> <!--{{element.Date | transformDate}} -->
          </ng-container>

          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Actions </th>
            <td mat-cell *matCellDef="let row">
              <div fxLayout="row" fxLayoutAlign="center center">
                <!-- <button mat-icon-button
                  matTooltip="Click to view" (click)="onViewClick(row)" class="iconbutton" color="primary">
                  <mat-icon aria-label="Edit">visibility</mat-icon>
                </button> -->
                <button mat-icon-button
                  matTooltip="Click to edit" (click)="onEditClick(row)" class="iconbutton" color="primary">
                  <mat-icon aria-label="Edit">edit</mat-icon>
                </button>
              </div>

            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="dataModel.columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: dataModel.columnsToDisplay; let i = dataIndex"
            class="detail-element-row" [class.example-expanded-row]="expandedElement === element">
          </tr>
        </table>
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
        <!-- <mat-paginator [pageSize]="MAT_HELPER.PAGE_SIZE" [pageSizeOptions]="MAT_HELPER.PAGE_SIZE_OPTIONS"
        showFirstLastButtons [length]="length"></mat-paginator> -->
      </div>

    </div>
</div>
