<div *ngIf="dataModel.isStatusShows">
    <div class="status-container" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="space-between end">
            <div class="status-label mb-1" fxFlex="60">Reconciliation Status </div>
            <span class="status-label mb-1" fxFlex="40" class="status-1">Open</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
            <div fxFlex="60">Payment Status</div>
            <span fxFlex="40" class="status-2">Paid</span>
        </div>
    </div>
    <hr>
</div>

<div class="stepper-cost-container">
    <div class="row">
        <div class="col-12">
            <ul class="ml-0 pl-0">
                <li *ngFor="let item of dataModel.StepperCostfilterModel">
                    <div [ngClass]="{'not-allow': item.display == '1' }" [ngStyle]="{'display':item.display=='2'?'none':''}">
                        <div class="a pointer cost-count" [ngClass]="{
              a: item.color == '#3e6fb6',
              b: item.color == '#67308f',
              c: item.color == '#838296',
              c1: item.color == '#4d4f5c',
              d: item.display=='1',
              overlay: item.display == '1'
            }" [ngStyle]="{ 'pointer-events': item.display == '1' ? 'none' : '', 'backgroundColor': item.display=='1' ? '#b6b7b6':'item.color',
            'opacity':item.display=='1'?'1':'item.color'}">
                            <span class="long-and-truncated" [matTooltip]="item.cost ">{{ item.cost | transformCurrencyOrg }}</span>
                        </div>
                        <div class="status_tittle" [ngStyle]="{'color':item.color}">
                            {{ item.name }}
                            <br />
                            <div class="status_tittle1" *ngIf="item.countvisible == '1'" (click)="onCountClick(item)">
                                <p *ngIf=" item.isValueVisible == 'true'">
                                    {{ item.count }}
                                </p> &nbsp;
                                <p *ngIf=" item.isPercentageVisible == 'true'">
                                    ({{ item.countpercentage | number: "1.0-2" }}%)

                                </p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- <hr /> -->
