<div class="row no-gutters mt-4">
    <div class="m-4 w-100" fxLayout="row" fxLayoutAlign="space-between center">
      <button (click)="refreshList()" mat-raised-button color="primary">
          <mat-icon>refresh</mat-icon> Refresh
      </button>
      <button (click)="downloadDailySnapshot()" mat-raised-button color="primary">
         Daily Snapshot Download
      </button>
    </div>
    <div class="col-md-12">

        <app-export-logs-records-list [dataModel]="exportViewListDataModel"
            [configModel]="exportViewListConfigModel"></app-export-logs-records-list>
    </div>
</div>

<app-dialog [dialogModel]="dialogDeleteFileModel">
    <ng-container class="dialog-content-slot">
        <p class="py-3">Are you sure you want to delete Export Id <b> {{exportId}}</b> ?</p>

        <div class="float-right pull-right">
            <button (click)="confirmDelete()" mat-raised-button class="primary-button m-2" color="warn">Confirm</button>
            <button (click)="cancelDelete()" mat-stroked-button class="primary-button-outline">Cancel</button>
        </div>

    </ng-container>
</app-dialog>
