<div class="row">
    <div class="col-md-12 tableColor">
        <div class="permit-list custom-table low-border-padding pb-3 my-3 mx-2">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

                <ng-container matColumnDef="FileName">
                    <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header>File Name</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.FileName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="FileObjectTypeDescription">
                    <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header>File Type</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.FileObjectTypeDescription}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="CreatedBy">
                    <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header>Created by</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.CreatedBy}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="CreateDate">
                    <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header>Created Date</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.CreateDate | transformDateTime}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Download">
                    <th mat-header-cell *matHeaderCellDef class="word-break"> File Download
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button matTooltip="Download" class="iconbutton"
                            (click)="downloadFile(element)"><mat-icon aria-label="Download">get_app</mat-icon></button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="dataModel.columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: dataModel.columnsToDisplay;"></tr>
            </table>

            <mat-paginator [pageSize]="MAT_HELPER.PAGE_SIZE" [pageSizeOptions]="MAT_HELPER.PAGE_SIZE_OPTIONS"
                showFirstLastButtons [length]="length"></mat-paginator>

        </div>
    </div>
</div>