import { FILTER_TYPE, JOURNEY_TYPE } from 'src/app/app-constants';

import { EventEmitter } from 'events';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';

export class ExportLogsListConfigModel {

    static getInstance<T>(): ExportLogsListConfigModel {
        let model = new ExportLogsListConfigModel();
        return model
    }

    public static readonly CALLER_TO_COMP_REFRESH_DATA = "REFRESH_DATA"


    CompToCaller = new EventEmitter();
    CallerToComp = new EventEmitter();

    EventAction = new Map();

}

export class ExportLogsListDataModel {
    isGlobalParams: any;
    isSelfDataLoad: any;
    columnsToDisplay: any;
    globalParamterKeys: any;
    globalParameters: Map<any, any>;
    apireqdata: any;
    filterType: any;
    journeyType: any;
    apiUrls: ExportLogsListingAPIURL = new ExportLogsListingAPIURL();
    static getInstance<T>(): ExportLogsListDataModel {
        let model = new ExportLogsListDataModel();
        model.isSelfDataLoad = true;
        model.columnsToDisplay = undefined;
        model.globalParameters = new Map<any, any>()
        model.apireqdata = new ExportLogsListRequestModel();
        model.filterType = FILTER_TYPE.ADVANCE_FILTER;
        model.journeyType = JOURNEY_TYPE.NONE;
        return model;
    }
}

export class ExportLogsListRequestModel {
    skip: number = 0;
    top: number = MatTableHelper.PAGE_SIZE;
    permitReferenceNumber: string;
    street: string;
    town: string;
    area: string;
    costFilter: string;
    pageno: number = 0;
    sortOrder: string;
    sortField: string;

    constructor() {
        this.skip = this.pageno * this.top;
    }
}

export interface ExportLogsRow {
    create_date: string;
    file_size: string;
    object_ref_id: string;
    file_name: string;
    no_of_records: string;
    export_name: string;
    status: string;
    export_id: string;
    action: string;
}


export class ExportLogsListingAPIURL {
    advanceFilter: string = '';
    quickFilter: string = '';
    dynamicFilter: string = '';
}
