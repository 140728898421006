import {
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import {
  ActivityLogListTabConfigModel,
  ActivityLogListTabDataModel,
  ActivityLogListTabRow,
} from './activity-log-list-tab.model';
import { FILTER_TYPE } from 'src/app/app-constants';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { PERMIT_ACTIONS_CONFIG } from 'src/app/constants/db.constants';
import { Router } from '@angular/router';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { SaveFileService } from 'src/app/services/save-file.service';
import { FormControl } from '@angular/forms';
import { ActivityLogListRequestModel } from '../Lists/activity-log-list/activity-log-list.module';

@Component({
  selector: 'app-activity-log-list-tab',
  templateUrl: './activity-log-list-tab.component.html',
  styleUrls: ['./activity-log-list-tab.component.css']
})
export class ActivityLogListTabComponent extends WidgetComponentBase {
  @Input() dataModel: ActivityLogListTabDataModel;
  @Input() configModel: ActivityLogListTabConfigModel;

  PERMIT_ACTIONS_CONFIG = PERMIT_ACTIONS_CONFIG;
  dataSource = new MatTableDataSource<ActivityLogListTabRow>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pageChangeSubscription: any;
  sortFieldLabel: string = '';
  length: any;

  searchInput = new FormControl('');

  MAT_HELPER = MatTableHelper;
  DATA_HELPER = DataHelper;

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _saveFileService: SaveFileService,
    private _router: Router,
    public dialog: MatDialog,
    private _validationService: ValidationService,
    private _appRepoService: AppRepoService
  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit() {
    this.searchInput.valueChanges.subscribe(this.onSearchValueChange)

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.pageChangeSubscription = this.paginator.page.subscribe(() => {
      this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
      this.dataModel.apireqdata.top = this.paginator.pageSize;
      this.dataModel.apireqdata.skip =
        this.dataModel.apireqdata.pageno * this.dataModel.apireqdata.top;
      this.wgRefreshData();
    });

    this.wgOnInit();
    this.wgRefreshData();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      if (this.sort.direction) {
        this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();

        switch (this.sort.active) {
          case 'ActivityTopic':
            this.sortFieldLabel = 'ACTIVITY_TOPIC';
            break;
          case 'ActivityType':
            this.sortFieldLabel = 'ACTIVITY_TYPE';
            break;
          case 'UpdatedBy':
            this.sortFieldLabel = 'UPDATED_BY';
            break;
          case 'UpdatedDate':
            this.sortFieldLabel = 'CREATE_DATE';
            break;
          default:
            break;
        }

        this.dataModel.apireqdata.sortField = this.sortFieldLabel;

        this.wgRefreshData();
      }
      else {
        try {
          delete this.dataModel.apireqdata.sortOrder;
          delete this.dataModel.apireqdata.sortField;
        }
        catch (err) {
        }
      }
    });
  }

  getControlData() {
    let url: string = null;

    if (this.sort.direction) {
      this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();
      this.dataModel.apireqdata.sortField = this.sortFieldLabel;
    }
    else {
      try {
        delete this.dataModel.apireqdata.sortOrder;
        delete this.dataModel.apireqdata.sortField;
      }
      catch (err) { }
    }

    switch (this.dataModel.filterType) {
      case FILTER_TYPE.ADVANCE_FILTER:
        url = this.dataModel.apiUrls.advanceFilter;
        break;
      case FILTER_TYPE.QUICK_FILTER:
        url = this.dataModel.apiUrls.quickFilter;
        break;
      case FILTER_TYPE.DYNAMIC_FILTER:
        url = this.dataModel.apiUrls.dynamicFilter;
        break;
      default:
        break;
    }

    return this.wgAPIMethodGet(url, this.dataModel.apireqdata);
  }

  convertData(response: any) {
    let items: ActivityLogListTabRow[] = [];

    response.content.forEach((element) => {
      let item: any = {};

      item.ActivityTopic = element.activity_topic;
      item.ActivityType = element.activity_type;
      item.ActivityUpdatedBy = element.activity_updated_by;
      item.ActivityUpdatedDate = element.activity_updated_date;
      item.LogType = element.log_type;
      item.ObjectReferenceNumber = element.object_reference_number;
      item.WorkReferenceNumber = element.work_reference_number;
      item.PermitReferenceNumber = element.permit_reference_number;
      item.LogDetails = element.log_details;

      items.push(item);
    });

    this.dataSource = new MatTableDataSource(items);
    this.length = response.totalElements;
  }

  setFieldData() { }

  setMode(responseDataModel: any) { }

  setValue(responseDataModel: any) { }

  applyFilter(filterValue: string) { }

  ngOnDestroy(): void {
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
  }

  resetPagination() {
    this.paginator.pageIndex = 0;
    this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
    this.dataModel.apireqdata.skip = 0;
    this.dataModel.apireqdata.top = this.paginator.pageSize;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  search() {
    let searchString = this.searchInput.value;
    let params = {
      searchString: searchString
    }
    Object.assign(this.dataModel.apireqdata, params);
    this.wgRefreshData();
  }

  clearSearch() {
    this.searchInput.reset();
    this.dataModel.apireqdata = new ActivityLogListRequestModel();
    this.wgRefreshData();
  }

  onSearchValueChange(searchValue) {

  }
}
