import { Component, Input, ViewChild } from '@angular/core';
import {
  InvoicesListConfigModel,
  InvoicesListDataModel,
  InvoicesRow,
} from './invoices-list.model';
import { DYNAMIC_FILTER_KEY, FILTER_TYPE, GLOBAL_PARAM_KEY, QUICK_FILTER_KEY } from 'src/app/app-constants';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { MASTER_DATA, PERMIT_ACTIONS_CONFIG } from 'src/app/constants/db.constants';
import { Router } from '@angular/router';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { WidgetEvents } from 'src/app/models/common/widget-events';
import { QuickFilter } from '../invoices-quick-filter/invoices-quick-filter.model';

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.css'],
})
export class InvoicesListComponent extends WidgetComponentBase {
  @Input() dataModel: InvoicesListDataModel;
  @Input() configModel: InvoicesListConfigModel;

  PERMIT_ACTIONS_CONFIG = PERMIT_ACTIONS_CONFIG;
  dataSource = new MatTableDataSource<InvoicesRow>();
  expandedElement: InvoicesRow;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pageChangeSubscription: any;
  sortFieldLabel: string = '';
  length: any;
  pageIndex: number = 0;
  MAT_HELPER = MatTableHelper;
  DATA_HELPER = DataHelper;
  selectAllCheckbox: boolean = false;
  checkboxlabel: any;

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _router: Router,
    public dialog: MatDialog,
    private _validationService: ValidationService,
    private _appRepoService: AppRepoService
  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.pageChangeSubscription = this.paginator.page.subscribe(() => {
      this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
      this.dataModel.apireqdata.top = this.paginator.pageSize;
      this.dataModel.apireqdata.skip =
        this.dataModel.apireqdata.pageno * this.dataModel.apireqdata.top;
      this.wgRefreshData();
    });

    this.wgOnInit();
    this.wgRefreshData();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      if (this.sort.direction) {
        this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();

        switch (this.sort.active) {
          case 'Reference':
            this.sortFieldLabel = 'REFERENCE';
            break;
          case 'FinalInvoiceReference':
            this.sortFieldLabel = 'FINAL_INVOICE_REFERENCE';
            break;
          case 'UploadDate':
            this.sortFieldLabel = 'UPLOAD_DATE';
            break;
          case 'AuthorityName':
            this.sortFieldLabel = 'AUTHORITY_NAME';
            break;
          case 'Status':
            this.sortFieldLabel = 'STATUS';
            break;
          case 'StatusMessage':
            this.sortFieldLabel = 'STATUS_MESSAGE';
            break;
          case 'ChargeType':
            this.sortFieldLabel = 'CHARGE_TYPE';
            break;
          case 'ChargesCount':
            this.sortFieldLabel = 'CHARGES_COUNT';
            break;
          case 'ValidChargesCount':
            this.sortFieldLabel = 'VALID_CHARGES_COUNT';
            break;
          case 'InvalidChargesCount':
            this.sortFieldLabel = 'INVALID_CHARGES_COUNT';
            break;
          case 'PeriodFrom':
            this.sortFieldLabel = 'PERIOD_FROM';
            break;
          case 'PeriodTo':
            this.sortFieldLabel = 'PERIOD_TO';
            break;
          case 'ResponseDueDate':
            this.sortFieldLabel = 'RESPONSE_DUE_DATE';
            break;
          case 'TotalAmount':
            this.sortFieldLabel = 'TOTAL_AMOUNT';
            break;
          case 'HaPaymentDueDate':
            this.sortFieldLabel = 'HA_PAYMENT_DUE_DATE';
            break;
          case 'PaidDate':
            this.sortFieldLabel = 'PAID_DATE';
            break;
          default:
            break;
        }

        this.dataModel.apireqdata.sortField = this.sortFieldLabel;

      } else {
        try {
          delete this.dataModel.apireqdata.sortOrder;
          delete this.dataModel.apireqdata.sortField;
        } catch (err) { }
      }
      this.wgRefreshData();
    });
  }

  getControlData() {
    let url: string = null;

    // if (this.sort.direction) {
    //   this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();
    //   this.dataModel.apireqdata.sortField = this.sortFieldLabel;
    // } else {
    //   try {
    //     delete this.dataModel.apireqdata.sortOrder;
    //     delete this.dataModel.apireqdata.sortField;
    //   } catch (err) { }
    // }

    switch (this.dataModel.filterType) {
      case FILTER_TYPE.ADVANCE_FILTER:
        url = this.dataModel.apiUrls.advanceFilter;
        break;
      case FILTER_TYPE.QUICK_FILTER:
        url = this.dataModel.apiUrls.quickFilter;
        break;
      case FILTER_TYPE.DYNAMIC_FILTER:
        url = this.dataModel.apiUrls.dynamicFilter;
        break;
      default:
        break;
    }

    switch (this.dataModel.listName) {
      case DYNAMIC_FILTER_KEY.INVOICE_ALL_WORK_LIST:
        this.dataModel.apireqdata.quickFilter = this._appRepoHelperService.getGlobalPersistantData(QUICK_FILTER_KEY.INVOICE_ALL_WORK_LIST)
        break;
      case DYNAMIC_FILTER_KEY.INVOICE_MY_WORK_LIST:
        this.dataModel.apireqdata.quickFilter = this._appRepoHelperService.getGlobalPersistantData(QUICK_FILTER_KEY.INVOICE_MY_WORK_LIST)
        break;
      case DYNAMIC_FILTER_KEY.INVOICE_MY_TEAMS_WORK_LIST:
        this.dataModel.apireqdata.quickFilter = this._appRepoHelperService.getGlobalPersistantData(QUICK_FILTER_KEY.INVOICE_MY_TEAMS_WORK_LIST)
        break;
      default:
        break;
    }

    if(this.dataModel.apireqdata.quickFilter == undefined || this.dataModel.apireqdata.quickFilter.length == 0) delete this.dataModel.apireqdata.quickFilter

    return this.wgAPIMethodGet(url, this.dataModel.apireqdata);
  }

  convertData(response: any) {
    let items: InvoicesRow[] = [];

    response.content.forEach((element) => {
      let item: any = {};

      item.InvoiceId = element.invoice_id;
      item.BaseReference = element.base_reference;
      item.Reference = element.reference;
      item.FinalInvoiceReference = element.final_invoice_reference;
      item.UploadDate = element.upload_date;
      item.AuthorityName = element.authority_name;
      item.Status = element.status;
      item.StatusMessage = element.status_message;
      item.ChargeType = element.charge_type;
      item.ChargesCount = element.charges_count;
      item.ValidChargesCount = element.valid_charges_count;
      item.InvalidChargesCount = element.invalid_charges_count;
      item.PeriodFrom = element.period_from;
      item.PeriodTo = element.period_to;
      item.ResponseDueDate = element.response_due_date;
      item.TotalAmount = element.total_amount;
      item.HaPaymentDueDate = element.ha_payment_due_date;
      item.PaidDate = element.paid_date;

      item.liabilityStatus = element.liabilityStatus;
      item.assignedBy = element.assignedBy;
      item.liabilityOrganization = element.liabilityOrganization;
      item.liabilityAssignedDate = element.liabilityAssignedDate;
      item.responseRecievedDate = element.responseRecievedDate;
      item.rechargeDate = element.rechargeDate;
      item.rechargeAmount = element.rechargeAmount;
      item.system_claim_status = element.system_claim_status;
      item.isChecked = false;

      items.push(item);
    });

    this.dataSource = new MatTableDataSource(items);
    this.dataModel.data = this.dataSource.data;
    this.length = response.totalElements;
    this.selectAllCheckbox = false;
    this.pageIndex = this.dataModel.apireqdata.pageno;


    if(this.selectAllCheckbox) this.checkboxlabel = 'Unselect All'
    else this.checkboxlabel = 'Select All'
  }

  setFieldData() { }

  setMode(responseDataModel: any) { }

  setValue(responseDataModel: any) { }

  applyFilter(filterValue: string) {
    // if (filterValue == "all") {
    //   this.dataSource.filter = "";
    // } else {
    //   filterValue = filterValue.trim();
    //   filterValue = filterValue.toLowerCase();
    //   this.dataSource.filter = filterValue;
    // }
  }

  // getApplicationsDetails(element) {
  //   super
  //     .wgAPIMethodGet(`/api/v1/applications/${element.ApplicationId}`, null)
  //     .then((response: any) => {
  //       if (response) {
  //         element.inactive_app_list = [];
  //         element.WorksReferenceNumber = response.work_reference_number;
  //         element.HighwayAuthority = response.highway_authority_name;
  //         element.Workstream = response.department_name;
  //         element.PromoterPrefix = response.promoter_prefix;
  //         element.PromoterOrganization = response.promoter_organisation;
  //         element.ProjectReferenceNumber = response.project_reference_number;
  //         element.Contact = response.secondary_contact;
  //         // not pressent in api response
  //         element.ProposedDuration = response.working_days;
  //         element.Cost = response.total_cost;

  //         //  element.RoadType = this._appRepoHelperService.getMDataDisplayTextByValue(element.road_category.toString());
  //         element.TrafficSensitive = response.traffic_sensitive_flag;
  //         element.FootwayClosure = response.close_footway;

  //         element.ExcavationRequired = response.excavation_flag;
  //         element.IsLaneRentalApplicable = response.lane_rental_flag;
  //         element.TrafficManagementRequired = response.traffic_management_type;
  //         element.WorkDescription = response.work_description;

  //         // this.arrWorkIdentifier.forEach(element1 => {
  //         //   if (element1.workIdentifierId == response.work_description) {
  //         //     element.WorkDescription = element1.description;
  //         //   }
  //         // });

  //         // this.arrRoadCategori.forEach((element1) => {
  //         //   if (element1.value == response.road_category) {
  //         //     element.RoadType = element1.displayText;
  //         //   }
  //         // });

  //         // response.inactive_app_list.forEach((element11) => {
  //         //   let item1: any = {};
  //         //   item1.ApplicationId = element11.application_id;
  //         //   item1.permitRefNo = element11.permit_reference_number;
  //         //   item1.applistStatus = element11.status;

  //         //   element.inactive_app_list.push(item1);
  //         // });

  //         // // this.inactiveAppList =temp;

  //         // // element.inactive_app_list = this.inactiveAppList;

  //         // element.FootwayClosureDisplayText =
  //         //   this._appRepoHelperService.getMDataDisplayTextByValue(
  //         //     element.FootwayClosure
  //         //   );
  //         // element.TrafficManagReqDisplayText =
  //         //   this._appRepoHelperService.getMDataDisplayTextByValue(
  //         //     element.TrafficManagementRequired
  //         //   );
  //       }
  //     });
  // }

  checkInvoiceActionAllowed(actionName, row) {
    // if (this._appRepoHelperService.hasPermitActionPermission(actionName)) {
    if ('Status' in row) {
      if (row.Status === 'Open') {
        return true;
      } else {
        return false;
      }
    }
    // }
  }

  onViewClick(row) {
    this.emitEvent(WidgetEvents.VIEW_CLICK, row);
  }

  onViewRecharge(row) {
    this.emitEvent('viewRecharge', row);
  }

  onDeleteInvoiceIconClick($event, rowData) {
    $event.stopPropagation();
    this.emitEvent('deleteInvoice', rowData);
  }

  setPagination() {
    let page = this._appRepoHelperService.getGlobalPersistantData('PaginationDetails');
    console.log(page)
    if (page) {
      this.pageIndex = page.pageno
    }
  }

  ngOnDestroy(): void {
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
  }

  resetPagination() {
    this.paginator.pageIndex = 0;
    this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
    this.dataModel.apireqdata.skip = 0;
    this.dataModel.apireqdata.top = this.paginator.pageSize;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  getInvoiceStatusCssClass(element) {
    switch (element) {
      case 'Valid':
      case 'Assigned':
      case 'Awaiting Final Invoice':
      case 'Approved Draft':
      case 'Revised Draft Valid':
      case 'DOA Approved':
      case 'Accepted':
      case 'Approved':
      case 'Validated [User]':
      case 'Awaiting Final Invoice (Valid)':
      case 'Validated [CPM]':
      case 'Potential Liability Assigned':
      case 'Validated [User] - Awaiting Approval':
        return 'completed';

      case 'Under Investigation':
      // case 'Not Assigned':
      case 'Assigned For Review':
      case 'Assigned for Review':
      case 'Revised Draft Under Investigation':
      case 'Awaiting DOA Approval':
      case 'Assigned As Liability':
      case 'Awaiting Approval (Valid)':
      case 'Awaiting Revised Draft Invoice':
      case 'Requested':
        return 'in_progress';

      case 'Challenged':
      case 'Disputed':
      case 'Revised Draft Challenged':
      case 'Final Challenged':
      case 'Cancelled':
      case 'Error Sending To SAP':
        return 'cancelled';

      // case 'Open':
      case 'Withdrawn':
      case 'Paid':
      case 'Paid (SAP)':
      case 'Sent To SAP':
      case 'Sent To SAP':
      case 'Ready To Send To SAP':
      case 'Ready To Send to SAP':
        // case 'Sent To SAP (Partial)':
      case 'Written Off':
        case 'Invoice Paid':
          case 'Paid (Not SAP)':
          case 'Paid (IQMS)':
        return 'open';

      case 'Issued':
      // case 'Validated[User]':
      // case 'Validated[CPM]':
      case 'Not Assigned':
      case 'Not Requested':
      case 'Open':
        // case 'Validated [User] - Awaiting Approval':
        return 'grey'


        case 'Sent To SAP (Partial)':
          return 'partial'

      case 'Not open':
        return 'black';

      case 'null':
        return 'null';

      default:
        return 'grey';
    }
  }

  claimIconClass(element) {
    let userInfo: any = this._appRepoHelperService.getMDataByKey(
      MASTER_DATA.USER_INFO
    );

    if (element.system_claim_status == 'Claimed') return 'sameUserIcon';
  }

  openclaimeDialog(row) {
    if (row.ChargeType == 'Miscellaneous' || row.ChargeType == 'FPN' || row.ChargeType == 'Section 74' || row.ChargeType == 'Permit Charges [Manual]' || row.ChargeType == 'Defect [Manual]' || row.ChargeType == 'FPN [Manual]' || row.ChargeType == 'Section 74 [Manual]') return;
    console.log('row claim', row);

    this.emitEvent('claimWork', row);
  }
  openunclaimeDilog(row) {
    if (row.ChargeType == 'Miscellaneous' || row.ChargeType == 'FPN' || row.ChargeType == 'Section 74' || row.ChargeType == 'Permit Charges [Manual]' || row.ChargeType == 'Defect [Manual]' || row.ChargeType == 'FPN [Manual]' || row.ChargeType == 'Section 74 [Manual]') return;
    this.emitEvent('unclaimWork', row);
  }

  selectAll() {
    this.selectAllCheckbox = !this.selectAllCheckbox;
    if(this.selectAllCheckbox) this.checkboxlabel = 'Unselect All'
    else this.checkboxlabel = 'Select All'
    this.dataSource.data.forEach((row) => (row.isChecked = this.selectAllCheckbox));
    this.dataSource.data.forEach(row => {
      if (row.ChargeType == 'Permit Charges [Manual]' || row.ChargeType == 'Defect [Manual]' || row.ChargeType == 'FPN [Manual]' || row.ChargeType == 'Section 74 [Manual]' || row.ChargeType == 'Miscellaneous' || row.ChargeType == 'FPN' || row.ChargeType == 'Section 74') {
        row.isChecked = false;
      }
    })
    this.dataModel.data = this.dataSource.data
    this.sendEvent()
  }


  onCheckboxClick(index, checked: boolean) {
    this.dataSource.data[index].isChecked = checked;
    let isChecked = this.dataSource.data.some(
      (item) => item.isChecked === true
    );
    let selectAll = this.dataSource.data.every(
      (item) => item.isChecked === true
    );
    this.selectAllCheckbox = selectAll;
    if(this.selectAllCheckbox) this.checkboxlabel = 'Unselect All'
    else this.checkboxlabel = 'Select All'
    this.dataModel.data = this.dataSource.data
    this.sendEvent()
  }

  sendEvent() {
    const filteredCharges = this.dataModel.data.filter(charge => charge.isChecked);

    // check miscellaneous charges
    const miscellaneous = filteredCharges.filter(charge => charge.ChargeType == 'Miscellaneous' || charge.ChargeType == 'FPN' || charge.ChargeType == 'Section 74' || charge.ChargeType == 'Permit Charges [Manual]' || charge.ChargeType == 'Defect [Manual]' || charge.ChargeType == 'FPN [Manual]' || charge.ChargeType == 'Section 74 [Manual]')

    let unClaimed
    let claimed
    if (filteredCharges.length > 0) {
      unClaimed = filteredCharges.every(
        (item) => item.system_claim_status === 'Unclaimed' || item.system_claim_status === null
      );
      claimed = filteredCharges.every(
        (item) => item.system_claim_status === 'Claimed'
      );
    } else {
      unClaimed = false
      claimed = false
    }

    if (miscellaneous.length > 0) {
      claimed = false;
      unClaimed = false;
    }

    if (unClaimed) {
      this.emitEvent('enableClaimBtn')
    } else if (claimed) {
      this.emitEvent('enableUnclaimBtn')
    } else {
      this.emitEvent('disableBothBtn')
    }
  }

  checkChargeType(element) {
    switch (element.ChargeType) {
      case 'Permit Charges [Manual]':
      case 'Defect [Manual]':
      case 'FPN [Manual]':
      case 'Section 74 [Manual]':
      case 'Miscellaneous':
      case 'FPN':
      case 'Section 74':
        return false;
      default:
        return true;
    }
  }
}
