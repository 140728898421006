<div class="form-group">
  <form form [formGroup]="EmailInvoiceForm" id="filter-form" class="d-flex flex-column p-3" appInvalidControlScroll>
    <div class="fixed-underline" class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="100">
        <!-- <mat-label>To</mat-label> -->
        <input matInput placeholder="To" autocomplete="off" formControlName="emailTo">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
    </div>
  </form>
</div>