<div class="permit-list custom-table border-padding">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="InvoiceNumber">
            <th mat-header-cell *matHeaderCellDef>Invoice Number</th>
            <td mat-cell *matCellDef="let element">{{element.invoiceNumber}}</td>
        </ng-container>
        <ng-container matColumnDef="InvoiceDate">
            <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
            <td mat-cell *matCellDef="let element">{{element.invoiceDate | transformDate}}</td>
        </ng-container>
        <ng-container matColumnDef="InvoiceDescription">
            <th mat-header-cell *matHeaderCellDef>Invoice Description</th>
            <td mat-cell *matCellDef="let element">{{element.invoiceDesc}}</td>
        </ng-container>
        <ng-container matColumnDef="CostType">
            <th mat-header-cell *matHeaderCellDef>Cost Type</th>
            <td mat-cell *matCellDef="let element"> {{element.costType}}</td>
        </ng-container>
        <ng-container matColumnDef="ChargeDescription">
            <th mat-header-cell *matHeaderCellDef>Charge Description</th>
            <td mat-cell *matCellDef="let element"> {{element.chargesDesc}} </td>
        </ng-container>
        <ng-container matColumnDef="InvoiceAmount">
            <th mat-header-cell *matHeaderCellDef>Invoice Amount</th>
            <td mat-cell *matCellDef="let element"> {{element.invoiceAmount | transformCurrency}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="detail-element-row"></tr>
    </table>
</div>