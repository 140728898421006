<!-- <div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 matDialogTitle>Penalty Notice Details</h2>
  <button mat-icon-button class="close-button icon-outside" [mat-dialog-close]="true">
    <mat-icon class="close-icon material-icons color_white">close</mat-icon>
  </button>
</div>
<hr > -->
<!-- <mat-dialog-content  class="mat-typography" > -->
  <div class="mat-typography change-request-info" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="0">
    <div class="left-side" fxFlex="70">
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Permit Reference Number</label>
                <p>{{dataModel.data.WorksReferenceNumber}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">FPN Number</label>
                <p>{{dataModel.data.FPNNumber}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">FPN Status</label>
                <p>{{dataModel.data.FPNStatus}}</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="0 1 calc(66.6% + 20px)">
                <label for="">Offence Code</label>
                <p>{{dataModel.data.Offencecode}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="0 1 calc(33.3%)">
                <label for="">Offence Date</label>
                <p>{{dataModel.data.OffenceDate | transformDateTime}}</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="0 1 calc(66.6% + 20px)">
                <label for="">Offence Details</label>
                <p>{{dataModel.data.OffenceDetails}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="0 1 calc(33.3%)">
                <label for="">Issue Date and Time</label>
                <p>{{dataModel.data.IssueDateAndTime | transformDateTime}}</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Issuing Authority</label>
                <p>{{dataModel.data.IssuingAuthority}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Authorised Officer Name</label>
                <p>{{dataModel.data.AuthorisedOfficerName}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Officer Contact Details</label>
                <p>{{dataModel.data.OfficerContactDetails}}</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Works Promoter</label>
                <p>{{dataModel.data.WorksPromoter}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Promoter Prefix</label>
                <p>{{dataModel.data.PromoterPrefix}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Workstream</label>
                <p>{{dataModel.data.WorkStream}}</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Street Name</label>
                <p>{{dataModel.data.StreetName}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Town</label>
                <p>{{dataModel.data.Town}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Post Code</label>
                <p>{{dataModel.data.PostCode}}</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Accountability</label>
                <p>{{dataModel.data.Accountability}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Area Name</label>
                <p>{{dataModel.data.AreaName}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">USRN</label>
                <p>{{dataModel.data.USRN}}</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="100">
                <label for="">Cost</label>
                <p>{{dataModel.data.FPNCost | transformCurrency}}</p>
            </div>
        </div>

        <div *ngIf="showForm">
            <form *ngIf="wgFormGroup" [formGroup]="wgFormGroup" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label>Select Action</mat-label>
                    <mat-select required formControlName="action" (selectionChange)="onchangeAction($event)">
                        <mat-option *ngFor="let action of actionList" [value]="action.value">{{action.displayText}}</mat-option>
                    </mat-select>
                    <mat-error>Field must be valid</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="50" *ngIf="showComments" hintLabel={{notesMessage}}>
                    <mat-label>Comment
                    </mat-label>
                    <!-- <input matInput placeholder="Enter your comment" [maxLength]="500" autocomplete="off" formControlName="comment"> -->
                    <textarea matInput placeholder="Enter your comment" rows="1" style="resize: none;" [maxLength]="500" formControlName="comment" (keyup)="onNotesChange($event.target.value)"></textarea>
                    <mat-error>Max character limit 500</mat-error>
                </mat-form-field>
            </form>
        </div>
    </div>
    <div class="image-container" fxFlex="30">

        <!-- <div class="all-images-container" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
      <div class="single-image-container" *ngFor="let i of dataModel.data.FpnFiles" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="0 1 calc(25% - 32px)"
      fxFlex.lt-md="0 1 calc(50% - 32px)"
      fxFlex.lt-sm="100%">

          <div class="image-wrapper"  fxLayout="column" fxLayoutAlign="center center">
              <img class="fileuplod" [src]="i.ImageSrc" alt="">

          </div>
          <div class="image-info-wrapper"  fxLayout="row" fxLayoutAlign="space-between center">
              <div class="image-info">
                  <h3>{{i?.FileName}}</h3>
                  <p>Created By: {{i?.CreatedBy}}</p>
                  <p>Created on: {{i?.CreatedDate | transformDateTime}}</p>
              </div>
              <div class="image-action">
                  <button mat-icon-button matTooltip="Click to Download" class="iconbutton"
                  (click)="getFileApiData(i.FileId, i.FileName)">
                      <mat-icon aria-label="Download">get_app</mat-icon>
                    </button>
              </div>
          </div>
      </div>

  </div> -->

        <div fxLayout="row wrap" fxLayoutAlign="space-evenly center" *ngFor="let i of dataModel.data.FpnFiles" fxLayoutGap="50px" (click)="onFileClick(i)">

            <div [ngClass]="(i.FileName.includes('.docx')  || i.FileName.includes('.pdf')) ? 'docWrapper' : 'image-wrapper'" fxLayout="row" fxLayoutAlign="center center">
                <img *ngIf="(!i.FileName.includes('.docx') && !i.FileName.includes('.pdf'))" class="fileuplod" [src]="i.ImageSrc" alt="">


                <div *ngIf="(i.FileName.includes('.docx')  || i.FileName.includes('.pdf'))" class="fileuplod text-center" (click)="getFileApiData(i.FPNFileId, i.FileName)">
                    <button mat-icon-button matTooltip="Click to Download" class="iconbutton">
                <mat-icon aria-label="Download">get_app</mat-icon>
              </button>
                    <div class="doc-element fileDownload">
                        <label for="doc">{{i.FileName}}</label>
                    </div>



                </div>

            </div>



            <!-- <div *ngIf="i.FileName.includes('.docx')" class="image-info-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="image-info">
                    <div class="image-action">
                        <button mat-icon-button matTooltip="Click to Download" class="iconbutton" (click)="getFileApiData(i.FPNFileId, i.FileName)">
              <mat-icon aria-label="Download">get_app</mat-icon>
            </button>
                    </div>
                </div>

            </div> -->



        </div>
    </div>
</div>
<!-- </mat-dialog-content> -->
<hr>
<!-- <mat-dialog-actions align="end"> -->
<div class="row">
    <div class="col-6">
        <!-- <div class="text-start">
            <mat-icon class="pointer pdfdownload me-4" matTooltip="Download FPN" (click)="savePdf('FPN_DETAILS')">picture_as_pdf</mat-icon>

        </div> -->
    </div>
    <div class="col-6">
        <div class="d-flex justify-content-end">
            <button *ngIf="!isFPNStatusAcceptedOrWithdrawn(dataModel.data.FPNStatus); else showOkBtn" mat-raised-button class="primary-button mb-2" color="primary" (click)="onUpdateBtnClick()">Update</button>
            <ng-template #showOkBtn>
                <button mat-raised-button class="primary-button mb-2" color="primary" (click)="onOkBtnClick()">Ok</button>
            </ng-template>
        </div>
    </div>


</div>
<!-- </mat-dialog-actions> -->
