<div fxLayout="column" fxLayoutAlign="start space-between" fxLayoutGap="5px">
    <div class="traffic-managemet-cost dialog-container">
        <hr>
        <div fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div class="info-element" fxFlex="50">
                    <label>Charge Type</label>
                    <p>{{dataModel.data.chargeType}}</p>
                </div>
                <div class="info-element" fxFlex="50">
                    <label>Logged By</label>
                    <p>{{dataModel.data.loggedBy}}</p>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div class="info-element" fxFlex="50">
                    <label>Charge Amount</label>
                    <p>{{dataModel.data.chargeAmount | transformCurrency}}</p>
                </div>
                <div class="info-element" fxFlex="50">
                    <label>Total Traffic Management Cost</label>
                    <p>{{dataModel.data.totalCost | transformCurrency}}</p>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div class="info-element" fxFlex="100">
                    <label>Charge Description</label>
                    <p>{{dataModel.data.chargeDescription}}</p>
                </div>
            </div>
        </div>
        <hr>
    </div>
</div>