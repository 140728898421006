<!--

<div id="modal-content-wrapper">
    <section id="modal-body">
        <div class="row">
            <div class="col-12">

                <div class="info-element" fxFlex="100">
                    <label for="">User Name</label>
                    <p>Selva</p>
                </div>
                <div class="info-element" fxFlex="100">
                    <label for="">Email</label>
                    <p>selva@xyz.com</p>
                </div>
                <div class="info-element" fxFlex="100">
                    <label for="">Contact No. </label>
                    <p>9856567890</p>
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-12">
                <div class="info-element" fxFlex="100">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Leave a comment</mat-label>
                        <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </section>
    <footer id="modal-footer" class="text-right">
        <button mat-raised-button id="modal-action-button">Claim</button>
        <button mat-raised-button id="modal-cancel-button">Cancel</button>
    </footer>


</div> -->





<div id="modal-content-wrapper">


    <section id="modal-body">
        <div class="row">
            <div class="col-12">

                <div class="info-element" fxFlex="100">
                    <label for="">User Name</label>
                    <p class="font-weight-bold">{{userInfo.display_name}}</p>
                </div>
                <div class="info-element" fxFlex="100">
                    <label for="">Email</label>
                    <p class="font-weight-bold">{{userInfo.contact_email_id}}</p>
                </div>
                <div class="info-element" fxFlex="100">
                    <label for="">Contact No. </label>
                    <p class="font-weight-bold">{{userInfo.contact_no}}</p>
                </div>
                <div class="info-element" fxFlex="100" *ngIf="!claimedbysameuser">
                  <div fxFlex="80" style="position: relative;">
                    <mat-icon *ngIf="users.value && !dataModel.disableDropdown" class="clear-search" style="top: 25px; right: 20px;" (click)="users.setValue(null)">clear</mat-icon>
                    <mat-form-field fxFlex="100">
                      <mat-label>Assign To</mat-label>
                      <mat-select [disableOptionCentering]="true" [formControl]="users" >
                          <input placeholder="Search User" class="search-input"
                              (keyup)="onSearchUser($event.target.value)">
                        <mat-option [value]="user.user_id" *ngFor="let user of arrUsers">
                          {{user.user_name}}</mat-option>
                      </mat-select>
                      <mat-error>Field must be valid</mat-error>

                    </mat-form-field>
                  </div>
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-12">
                <div class="info-element" fxFlex="100">
                    <form #formComponent="ngForm" [formGroup]="startPermitFormGroup">
                        <div class="form-field">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Leave a comment</mat-label>

                                <textarea matInput placeholder="Enter comment" formControlName="startPermitComment"></textarea>
                                <!-- <input matInput placeholder="Enter comment" formControlName="startPermitComment" [ngClass]="{ 'is-invalid': submitted && f.startPermitComment.errors }" /> -->
                                <mat-error>Comment is required</mat-error>
                            </mat-form-field>
                        </div>

                        <!-- <p *ngIf="!claimedbysameuser &&  !freshClaim"> This permit is already claimed by <em>{{claimedby}}</em>. Would you like to claim it? </p> -->

                        <footer id="modal-footer" class="d-flex justify-content-end">
                            <button *ngIf="!claimedbysameuser" mat-raised-button id="modal-action-button" (click)="claimWork()" [ngStyle]="{display: disableUnclaimBtn ? 'none' : ''}" >Claim</button>
                            <button *ngIf="claimedbysameuser" mat-raised-button id="modal-action-button" (click)="unClaimWork()">Unclaim</button>
                            <!-- <button mat-raised-button id="modal-cancel-button" (click)="cancelStartPermit()">Cancel</button> -->
                            <button type="button" mat-flat-button style="background-color: grey; color: white" (click)="cancelStartPermit()">
 Cancel
                          </button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>
    </section>



</div>
