import { ExportLogsRecordsListComponent } from './../export-logs-records-list/export-logs-records-list.component';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { EventActionService } from 'src/app/services/event-action.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { WidgetPageBase } from 'src/app/utility/widget/widget-utility/widget-page-base';
import { ExportLogsListConfigModel, ExportLogsListDataModel } from '../export-logs-records-list/export-logs-record-list.model';
import { DialogModel } from '../../shared/dialog/dialog-model';
import { SaveFileService } from 'src/app/services/save-file.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-export-logs-records-listing',
  templateUrl: './export-logs-records-listing.component.html',
  styleUrls: ['./export-logs-records-listing.component.css']
})
export class ExportLogsRecordsListingComponent extends WidgetPageBase implements OnInit {
  exportViewListDataModel: ExportLogsListDataModel;
  exportViewListConfigModel: ExportLogsListConfigModel;
  dialogDeleteFileModel: DialogModel = new DialogModel();
  @ViewChild(ExportLogsRecordsListComponent) exportList: ExportLogsRecordsListComponent
  exportId: any;
  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _eventActionServiceBase: EventActionService,
    private _sessionStorageService: SessionStorageService,
    private _router: Router,
    private _spinner: NgxSpinnerService,
    private changeDetector: ChangeDetectorRef,
    private _appRepoHelperService: AppRepoHelperService,
    private _serverApiBase: ServerApiInterfaceServiceService,
    private _notificationService: NotificationService,
    private _eventActionService: EventActionService,
    private _appRepoService: AppRepoService,
    private _saveFileService: SaveFileService,
  ) {
    super(_serverApi,
      _eventActionServiceBase,
      _sessionStorageService,
      _router,
      _spinner);
    this.exportViewListDataModel = ExportLogsListDataModel.getInstance();
    this.exportViewListConfigModel = ExportLogsListConfigModel.getInstance();
  }

  ngOnInit(): void {
    this.bindPermitListDataModel();
    console.log(this.exportViewListDataModel);
  };

  bindPermitListDataModel() {
    // abstract methid for setGlobalParams

    this.exportViewListDataModel.columnsToDisplay =
      [
        'export_id',
        'export_name',
        'create_date',
        'progress',
        'status',
        'file_size',
        'no_of_records',
        'actions',
      ];



    let eventActions: any = [
      [
        "onDeleteIconClick",
        [
          {
            "action": "executePageMethod",
            "params": [{
              "methodname": "page_onDeleteIconClick"
            }]
          }
        ]
      ]
    ];

    super.addEventListener(eventActions, this.exportViewListDataModel, this.exportViewListConfigModel)
  }
  page_onDeleteIconClick(data) {
    this.exportId = data.dataContext;
    this.openConfirmDeletePopup();
  }

  openConfirmDeletePopup() {
    this.dialogDeleteFileModel.visible = true;
    this.dialogDeleteFileModel.width = '40';
    this.dialogDeleteFileModel.header = 'Delete Export Data';
  }

  confirmDelete() {
    let url = `/api/v1/cpm/export-data/cpm-export-delete-file/${this.exportId}`;
    this.dialogDeleteFileModel.visible = false;
    this._serverApi.delete(url, '').subscribe((response) => {

      this._notificationService.success('Exported File has been Deleted Succesfully');
      this.refreshList();
    }, (err) => {
      console.log('error', err);
    });
  }

  cancelDelete() {
    this.dialogDeleteFileModel.visible = false;
  }

  refreshList() {
    this.exportList.wgRefreshData();
  }

  downloadDailySnapshot() {
    const url = '/api/v1/cpm/export-data/cpm-export-daily-snapshot/'
    let date = this.formatDate(new Date())
    let file_name =  `CPM_SNAPSHOT_${date}`
    this._serverApi.downloadFile(url, '').subscribe((response) => {
      console.log(response)
      this._saveFileService.saveZipFile(response, file_name);
    }, (err) => {
      console.log('error', err);
    });
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('');
  }

}
