<div class="row">
  <div class="col-md-12 tableColor">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="20px"
      class="mt-3"
    >
      <div fxFlex="40">
        <div
          style="padding: 5px 10px"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="15px"
        >
          <div>
            <mat-icon
              *ngIf="searchInput.value"
              class="clear-search"
              (click)="clearSearch()"
              >clear</mat-icon
            >
            <input
              type="text"
              placeholder="Search"
              class="search-input"
              [formControl]="searchInput"
            />
          </div>
          <button
            type="button"
            fxflex="100"
            mat-stroked-button
            class="blue-btn"
            (click)="search()"
          >
            GO
          </button>
        </div>
      </div>
    </div>
    <div class="permit-list custom-table low-border-padding pb-3 mb-3 mx-2">
      <div>
        <table
          mat-table
          [dataSource]="dataSource"
          multiTemplateDataRows
          matSort
        >
          <ng-container matColumnDef="ClaimFunction">
            <th mat-header-cell *matHeaderCellDef class="text-center">Claim</th>
            <td mat-cell [ngClass]="{'first_child': element.ishighlight}" *matCellDef="let element" class="text-center">
              <div>
                <mat-icon class="icon-color" [ngClass]="claimIconClass(element)"
                  >person</mat-icon
                >
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="ObjectReference">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="pRNColWidth120 word-break"
              mat-sort-header
            >
              Object Reference
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ element.ObjectReference }}
            </td>
          </ng-container>

          <ng-container matColumnDef="chargeNumber">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="pRNColWidth120 word-break"
              mat-sort-header
            >
              Charge Number
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ element.ChargeNumber }}
            </td>
          </ng-container>

          <ng-container matColumnDef="WorkType">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="pRNColWidth120 word-break"
              mat-sort-header
            >
              Work Type
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">{{ element.WorkType }}</td>
          </ng-container>

          <ng-container matColumnDef="ChargeTypeBreakdown">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="pRNColWidth120 word-break text-center"
              mat-sort-header
            >
              Charge Type Breakdown
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ element.ChargeTypeBreakdown }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ChargeStatus">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="pRNColWidth120 word-break"
              mat-sort-header
            >
              Charge Status
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <span
                class="status-pill"
                [ngClass]="getInvoiceChargeStatusCssClass(element.Status)"
                >{{ element.Status }}</span
              >
            </td>
          </ng-container>

          <ng-container matColumnDef="StatusMessage">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="pRNColWidth120 word-break"
              mat-sort-header
            >
              Status Message
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.StatusMessage }}
            </td>
          </ng-container>

          <ng-container matColumnDef="DoaStatus">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="pRNColWidth120 word-break"
              mat-sort-header
            >
              DOA Status
            </th>
            <td mat-cell *matCellDef="let element"></td>
          </ng-container>

          <ng-container matColumnDef="LatestUpdateDate">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="word-break"
              mat-sort-header
            >
              Latest Update Date
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ element.LastUpdatedDate | transformDateTime }}
            </td>
          </ng-container>

          <ng-container matColumnDef="DraftChargeAmount">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="word-break"
              mat-sort-header
            >
              Draft Amount
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ element.Amount | transformCurrency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="DraftChargeDiscountAmount">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="word-break"
              mat-sort-header
            >
              Draft Adjustment Amount
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ element.DiscountAmount | transformCurrency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="View">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="word-break"
            >
              Action
            </th>
            <td mat-cell [ngClass]="{'last_child': element.ishighlight}" *matCellDef="let element">
              <div
                (click)="stopPropagation($event)"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <button
                  (click)="onViewClick(element)"
                  mat-icon-button
                  matTooltip="Click to view"
                  class="iconbutton"
                  color="primary"
                >
                  <mat-icon aria-label="Edit">visibility</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="DraftChargeActualAmount">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="word-break"
              mat-sort-header
            >
              Draft Actual Amount
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ element.ActualAmount | transformCurrency }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="dataModel.columnsToDisplay"></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: dataModel.columnsToDisplay"
            [ngClass]="{'row-highlight': element.ishighlight}"
          ></tr>
        </table>
      </div>
      <mat-paginator
        [pageSize]="MAT_HELPER.PAGE_SIZE"
        [pageSizeOptions]="MAT_HELPER.PAGE_SIZE_OPTIONS"
        showFirstLastButtons
        [length]="length"
        [pageIndex]="pageIndex"
      ></mat-paginator>
    </div>
  </div>
</div>
