import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { SaveFileService } from 'src/app/services/save-file.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { HAExcelMapFormModel, HAExcelMapFormConfigModel } from './ha-excel-map-form-model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-ha-excel-map-form',
  templateUrl: './ha-excel-map-form.component.html',
  styleUrls: ['./ha-excel-map-form.component.css']
})
export class HaExcelMapFormComponent extends WidgetComponentBase implements OnInit {
  @Input() dataModel: HAExcelMapFormModel;
  @Input() configModel: HAExcelMapFormConfigModel;

  HAExcelMapForm: FormGroup;

  arrChargeType: any[] = [
    {
      displayText: 'Permit Charges',
      value: 'PERMIT_CHARGES'
    },
    {
      displayText: 'Defects',
      value: 'DEFECT'
    },
  ]
  arrIsStandardTemplate = [
    {
      displayText: '',
      value: null
    },
    {
      displayText: 'Yes',
      value: true
    },
    {
      displayText: 'No',
      value: false
    },
  ]
  displayFields: any = {}
  haSpreadSheetId: any;

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _validationService: ValidationService,
    private _saveFileService: SaveFileService,
    private _spinner: NgxSpinnerService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit(): void {
    this.wgOnInit()
    this.createFormGroup();
  }

  ngAfterViewInit() {
    // this.wgRefreshData();
  }

  OnDestroy() {
    this.HAExcelMapForm.reset();
  }
  createFormGroup(): void {
    this.HAExcelMapForm = this.fb.group({
      start_row_index: [''],
      permit_reference: [''],
      alteration_reference: [''],
      work_prefix: [''],
      prepend_work_prefix: [''],
      granted_date: [''],
      charge_amount: [''],
      discount_amount: [''],
      actual_amount: [''],
      charge_type: [''],
      charge_type_identifiers: [''],
      charge_type_variation_identifiers: [''],
      sheet_index_to_imports: [''],
      is_standardised_template: [''],
    });
  }
  setFieldData() {}

  stopPropagation(event) {
    event.stopPropagation();
  }
  setMode(responseDataModel: any) {}
  setValue(responseDataModel: any) {}
  getControlData() {
    let url = `/api/v1/cpm/getHASpreadSheet`;
    this._spinner.show()
    this.getHAMapFields(this.dataModel.apireqdata.chargeType)
    return this.wgAPIMethodGet(url, this.dataModel.apireqdata);
  }

  convertData(response: any) {
    if (!response) return;
    this._spinner.hide()
    this.HAExcelMapForm.get('start_row_index').setValue(response.start_row_index)
    this.HAExcelMapForm.get('permit_reference').setValue(response.permit_reference)
    this.HAExcelMapForm.get('alteration_reference').setValue(response.alteration_reference)
    this.HAExcelMapForm.get('work_prefix').setValue(response.work_prefix)
    this.HAExcelMapForm.get('prepend_work_prefix').setValue(response.prepend_work_prefix)
    this.HAExcelMapForm.get('granted_date').setValue(response.granted_date)
    this.HAExcelMapForm.get('charge_amount').setValue(response.charge_amount)
    this.HAExcelMapForm.get('discount_amount').setValue(response.discount_amount)
    this.HAExcelMapForm.get('actual_amount').setValue(response.actual_amount)
    this.HAExcelMapForm.get('charge_type').setValue(response.charge_type)
    this.HAExcelMapForm.get('charge_type_identifiers').setValue(response.charge_type_granted_identifiers)
    this.HAExcelMapForm.get('charge_type_variation_identifiers').setValue(response.charge_type_variation_identifiers)
    this.HAExcelMapForm.get('sheet_index_to_imports').setValue(response.sheet_index_to_imports)
    this.HAExcelMapForm.get('is_standardised_template').setValue(response.is_standardised_template)


    this.haSpreadSheetId = response.cpm_haspread_sheet_map_id;
  }
  getValue() {
    this.HAExcelMapForm.markAllAsTouched;
    if(this.HAExcelMapForm.valid) {
      let data: any = {}
      data.haSpreadSheetId = this.haSpreadSheetId;
      data.formValue = this.HAExcelMapForm.value;
      return data;
    }
  }

  resetForm() {
    this.HAExcelMapForm.reset();
  }

  onSearchApprovalBy(searchVal) {
    let filter = searchVal.toLowerCase();
  }


  getHAMapFields(chargetype?) {

    this.displayFields = {
      start_row_index: true,
      permit_reference: true,
      alteration_reference: true,
      work_prefix: true,
      prepend_work_prefix: true,
      granted_date: true,
      charge_amount: true,
      discount_amount: true,
      actual_amount: true,
      charge_type: true,
      charge_type_identifiers: true,
      charge_type_variation_identifiers: true,
      sheet_index_to_imports: true,
      is_standardised_template: true,
    }

    if(chargetype === 'PERMIT_CHARGES') {
      this.displayFields.start_row_index = false;
      this.displayFields.charge_type_identifiers = false;
      this.displayFields.charge_type_variation_identifiers = false;
      this.displayFields.is_standardised_template = false;
    }

    if(chargetype === 'DEFECT') {
      this.displayFields.start_row_index = false;
      this.displayFields.permit_reference = false;
      this.displayFields.charge_amount = false;
      this.displayFields.discount_amount = false;
      this.displayFields.charge_type = false;
      this.displayFields.charge_type_identifiers = false;
      this.displayFields.charge_type_variation_identifiers = false;
      this.displayFields.is_standardised_template = false;
    }

  }
}
