<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-md-12">
            <div class="" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="page-top-left" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                    <div class="page-title">
                        <h1>All Organisations</h1>
                    </div>
                    <div class="page-actions" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                        <button (click)="openDialog()" mat-stroked-button color="primary">
                            <mat-icon>add_box</mat-icon>Create Organisation
                        </button>
                    </div>
                </div>
                <div class="page-inputs page-top-right">
                    <mat-form-field appearance="outline">
                        <mat-label>Search
                        </mat-label>
                        <input matInput placeholder="enter search term" autocomplete="off">
                        <mat-error>Field must be valid</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="inspection-list custom-table ">
                <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
                    <!-- Position Column -->
                    <ng-container matColumnDef="OrganizationName">
                        <th mat-header-cell *matHeaderCellDef>Organization Name</th>
                        <td mat-cell *matCellDef="let element" style="padding-left: 20px !important;">
                            <div [ngStyle]="{ 'padding-left': element.OrganizationName[0] * 15 + 'px' }" fxLayout="row" fxLayoutAlign="start center"
                            fxLayoutGap="15px">
                            <div *ngIf="element.OrganizationName[0] > 0">
                              <mat-icon color="primary">subdirectory_arrow_right</mat-icon>
                            </div>
                            <span>{{ element.OrganizationName[1] }}</span>
                          </div>
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;">Actions </th>
                        <td mat-cell *matCellDef="let row">
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <button mat-icon-button matTooltip="Click to view" class="iconbutton" color="primary">
                                    <mat-icon aria-label="Edit">visibility</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Click to view" class="iconbutton" color="primary">
                                    <mat-icon aria-label="Edit">edit</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>

        </div>
    </div>

</div>