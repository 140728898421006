import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { NavControlModel } from './nav-control.model';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { QUICK_FILTER_KEY } from 'src/app/app-constants';
import { environment } from 'src/environments/environment';
import { element } from 'protractor';
import { DialogModel } from '../../shared/dialog/dialog-model';
import { FormControl, Validators } from '@angular/forms';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-nav-control',
  templateUrl: './nav-control.component.html',
  styleUrls: ['./nav-control.component.css'],
})
export class NavControlComponent extends WidgetComponentBase implements OnInit {
  navTitlesData: NavControlModel[] = [];
  workReferencePopUp: DialogModel = new DialogModel();
  workRefNumber = new FormControl('', [Validators.required]);
  workReferenceStep: number = 0;
  workrefMessage: any;
  links = ['Dashboard'];
  activeLink = this.links[0];
  isTabsDisabled: boolean = false;
  newLinks = [
    // {
    // name: 'Dashboard',
    // link: 'dashboard',
    // },
    // {
    //   name: 'Cost & Penalty Workbench',
    //   link: 'cost-penalty-workbench',
    //   navigationRout: '/admin/pcp-workbench',
    // },
    // {
    //   name: 'Management Dashboard',
    //   link: 'management-dashboard',
    //   navigationRout: '/admin/pcp-management-dashboard',
    // },
    // {
    //   name: 'Finance Dashboard',
    //   link: 'finance-dashboard',
    //   navigationRout: '/admin/pcp-finance-dashboard',
    // },
    {
      name: 'Dashboard',
      link: 'dashboard',
      isDisabled: false,
      navigationRout: '/admin/charges-performance',
      subitems: [
        {
          label: 'Charges Performance at Glance',
          navigationRout: '/admin/charges-performance',
        },
        {
          label: 'Operational Dashboard',
          navigationRout: '/admin/pcp-operational-dashboard',
        },
      ],
    },
    {
      name: 'Invoices',
      link: 'invoices',
      isDisabled: false,
      // navigationRout: '',
      navigationRout: '/admin/pcp-invoices-listing',
      subitems: [],
    },
    {
      name: 'Charges',
      link: 'charges',
      isDisabled: false,
      navigationRout: '/admin/pcp-charges-dashboard',
      subitems: [],
    },
    {
      name: 'Invoice Uploads',
      link: 'uploads',
      isDisabled: false,
      navigationRout: '/admin/pcp-uploads-dashboard',
      subitems: [],
    },
    // {
    //   name: 'SAP Interface Log',
    //   link: 'sap-interface-log',
    //   navigationRout: '/admin/pcp-sap-interface-log',
    //   subitems: [
    //     // {
    //     //   label: 'SAP Interface Log',
    //     //   navigationRout: '/admin/pcp-sap-interface-log',
    //     // },
    //     // {
    //     //   label: 'Approved Invoices',
    //     //   navigationRout: '/admin/pcp-sap-approved-invoices',
    //     // },
    //     // {
    //     //   label: 'Paid Invoices',
    //     //   navigationRout: '/admin/pcp-sap-paid-invoices',
    //     // },
    //   ],
    // },
    {
      name: 'Administration',
      link: 'admin',
      isDisabled: false,
      navigationRout: '/admin/pcp-sap-interface-log',
      subitems: [
        {
          label: 'SAP Interface Log',
          navigationRout: '/admin/pcp-sap-interface-log'
        },
        {
          label: 'HA Invoice Map',
          navigationRout: '/admin/ha-invoice-map'
        },
        {
          label: 'Work Reference Migration',
          navigationRout: 'workRefPopUp'
        },
        {
          label: 'Rate Card',
          navigationRout: '/admin/rate-card'
        },
      ]
    },
    {
      name: 'Download',
      link: 'download',
      isDisabled: false,
      navigationRout: '/admin/export-logs-records',
      subitems: [],
    },
    // {
    //   name: 'Generate Recharge Charge',
    //   link: 'generate-charges',
    //   navigationRout: '/admin/pcp-generate-charge-dashboard',
    // }
  ];

  constructor(
    private _serverApiBase: ServerApiInterfaceServiceService,
    private _validationService: ValidationService,
    private router: Router,
    private _sessionStorageService: SessionStorageService,
    private _appRepoHeplerService: AppRepoHelperService,
    private _appRepoService: AppRepoService,
    private _spinner: NgxSpinnerService,
  ) {
    super(_serverApiBase, _validationService);
  }

  ngOnInit(): void {
    // this.wgOnInit();
    this.getActiveTab();
    this.getNavigationData();
    if (JSON.parse(this._appRepoService.appRepo.commercialView))
      this.isTabsDisabled = true;
    else this.isTabsDisabled = false;

    this.setTabsInView();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // this.activeLink = event.url;
        // this.setActiveLink(event.url);
        // console.log('event url>>>>>', event.url)
        switch (event.url) {
          case '/admin/charge-view':
          case '/admin/pcp-charges-dashboard':
          case '/admin/charge-details':
          case '/admin/charge-detail':
          case '/admin/pcp-estimated-dashboard':
            this.activeLink = 'charges';
            break;

          case '/admin/invoice-view':
          case '/admin/pcp-invoices-listing':
            this.activeLink = 'invoices';
            break;

          case '/admin/charges-performance':
          case '/admin/pcp-operational-dashboard':
            this.activeLink = 'dashboard';
            break;

          // case '/admin/pcp-sap-interface-log':
          // case '/admin/pcp-sap-approved-invoices':
          // case '/admin/pcp-sap-paid-invoices':
          //   this.activeLink = 'sap-interface-log';
          //   break;

          case '/admin/export-logs-records':
            this.activeLink = 'download';
            break;

          case '/admin/ha-invoice-map':
          case '/admin/pcp-sap-interface-log':
            this.activeLink = 'admin'
            break;

          default:
            break;
        }
      }
    });
    // console.log('activelink>>', this.activeLink)
    if (this.activeLink == null) this.activeLink = 'invoices';
  }
  setTabsInView() {
    let mainTabs = this._appRepoHeplerService.getMainMenuTabs('actionList');

    // !mainTabs.includes('DASHBOARD') ? (this.links = []) : '';
    if (!mainTabs.includes('DASHBOARD')) {
      this.newLinks.splice(
        this.newLinks.findIndex((ele) => {
          return ele.name === 'Dashboard';
        }),
        1
      );
    }
    if (!mainTabs.includes('INVOICES')) {
      this.newLinks.splice(
        this.newLinks.findIndex((ele) => {
          return ele.name === 'Invoices';
        }),
        1
      );
    }
    if (!mainTabs.includes('CHARGES')) {
      this.newLinks.splice(
        this.newLinks.findIndex((ele) => {
          return ele.name === 'Charges';
        }),
        1
      );
    }
    if (!mainTabs.includes('INVOICE_UPLOADS')) {
      this.newLinks.splice(
        this.newLinks.findIndex((ele) => {
          return ele.name === 'Invoice Uploads';
        }),
        1
      );
    }
    if (!mainTabs.includes('SAP_ACTIVITY_LOG')) {
      this.newLinks.splice(
        this.newLinks.findIndex((ele) => {
          return ele.name === 'SAP Interface Log';
        }),
        1
      );
    }
    if (!mainTabs.includes('ADMIN')) {
      this.newLinks.splice(
        this.newLinks.findIndex((ele) => {
          return ele.name === 'Administration';
        }),
        1
      );
    }
    if (!mainTabs.includes('DOWNLOAD')) {
      this.newLinks.splice(
        this.newLinks.findIndex((ele) => {
          return ele.name === 'Download';
        }),
        1
      );
    }
    if (!mainTabs.includes('INVOICES')) {
      this.router.navigate(['/admin/pcp-charges-dashboard']);
      this.activeLink = this.newLinks[0].link;
      this._sessionStorageService.setObject('isLoggedIn', { isfirst: true });
    } else {
      this._sessionStorageService.setObject('isLoggedIn', { isfirst: false });
    }

    if (this.isTabsDisabled) {
      this.newLinks.forEach((ele) => {
        if (ele.name === 'Charges') {
          ele.isDisabled = false;
          this.activeLink = ele.link;
        } else {
          ele.isDisabled = true;
        }
      });
      this.router.navigate(['/admin/pcp-charges-dashboard']);
    }
  }
  getActiveTab() {
    this.activeLink = this._sessionStorageService.getObject('TabIndex');
  }
  setActiveTab() {
    this._sessionStorageService.setObject('TabIndex', this.activeLink);
  }
  setFieldData() {}

  getControlData() {}

  convertData(response: any) {}

  setMode(responseDataModel: any) {}

  setValue(responseDataModel: any) {}

  setActiveLink(url: string) {
    let route = this.newLinks.find((x) => x.navigationRout === url);
    console.log(route);
  }
  getNavigationData() {
    this.navTitlesData = [
      {
        titleIconName: 'dashboard',
        titleIconColor: '',
        showTitleIcon: 'true',
        title: 'Dashboard',
        navigationRout: 'admin/pcp-operational-dashboard',
        subNavControls: [
          {
            subTitle: 'Performance at a Glance',
            subTitleIconName: '',
            showSubTitleIcon: '',
            subTitleIconColor: '',
            navigationRout: '/admin/performance-at-glance',
          },
          {
            subTitle: 'Operational Risk Dashboard',
            subTitleIconName: '',
            showSubTitleIcon: '',
            subTitleIconColor: '',
            navigationRout: '/admin/pcp-operational-dashboard',
          },
        ],
      },
      {
        titleIconName: 'dns',
        titleIconColor: '',
        showTitleIcon: 'true',
        title: 'Cost & Penalty Workbench',
        navigationRout: '/admin/pcp-workbench',
        subNavControls: [],
      },
      {
        titleIconName: 'dashboard',
        titleIconColor: '',
        showTitleIcon: 'true',
        title: 'Management Dashboard',
        navigationRout: '/admin/pcp-management-dashboard',
        subNavControls: [],
      },
      {
        titleIconName: 'dashboard',
        titleIconColor: '',
        showTitleIcon: 'true',
        title: 'Finance Dashboard',
        navigationRout: '/admin/pcp-finance-dashboard',
        subNavControls: [],
      },
    ];
  }

  redirectToPage(navigationRout: string, link: string) {
    this.activeLink = link;
    this.setActiveTab();
    if (navigationRout) {
      this.router.navigate([navigationRout]);
    }
  }

  navigateSubMenu(navigationRout: string, link: string) {
    this.activeLink = link;
    this.setActiveTab();
    switch (navigationRout) {
      case 'workRefPopUp':
        this.showWorkRefPopUp()
        break;

      default:
        this.router.navigate([navigationRout]);
        break;
    }
  }

  showWorkRefPopUp() {
    this.workReferencePopUp.visible = true;
    this.workReferencePopUp.width = '20%';
    this.workReferencePopUp.header = 'Work Reference Migration';
    this.workReferencePopUp.minWidth = '500px';
    this.workRefNumber.setValue(null)
    this.workRefNumber.markAsUntouched();
    this.workReferenceStep = 0;
  }

  submitWorkRef() {
    this.workRefNumber.markAllAsTouched();
    if(this.workRefNumber.valid) {
      const workReference = this.workRefNumber.value.trim();
      this._spinner.show()
      this._serverApiBase
      .post(`/api/v1/migration/${workReference}`, null)
      .subscribe(
        (response: any) => {
          if (response) {
            this._spinner.hide();
            this.workReferenceStep = 1;
            this.workrefMessage = response.Status;
          }
        },
        (err) => {
          console.log('error from export', err);
        }
      );
    }
  }

  cancelWorkRef() {
    this.workReferencePopUp.visible = false;
  }
}
