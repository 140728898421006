import {
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import {
  FilesListConfigModel,
  FilesListDataModel,
  FilesRow,
} from './files-list.model';
import { FILTER_TYPE } from 'src/app/app-constants';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { PERMIT_ACTIONS_CONFIG } from 'src/app/constants/db.constants';
import { Router } from '@angular/router';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { SaveFileService } from 'src/app/services/save-file.service';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.css']
})
export class FilesListComponent extends WidgetComponentBase {
  @Input() dataModel: FilesListDataModel;
  @Input() configModel: FilesListConfigModel;

  PERMIT_ACTIONS_CONFIG = PERMIT_ACTIONS_CONFIG;
  dataSource = new MatTableDataSource<FilesRow>();
  expandedElement: FilesRow;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pageChangeSubscription: any;
  sortFieldLabel: string = '';
  length: any;

  MAT_HELPER = MatTableHelper;
  DATA_HELPER = DataHelper;

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _saveFileService: SaveFileService,
    private _router: Router,
    public dialog: MatDialog,
    private _validationService: ValidationService,
    private _appRepoService: AppRepoService
  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.pageChangeSubscription = this.paginator.page.subscribe(() => {
      this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
      this.dataModel.apireqdata.top = this.paginator.pageSize;
      this.dataModel.apireqdata.skip =
        this.dataModel.apireqdata.pageno * this.dataModel.apireqdata.top;
      this.wgRefreshData();
    });

    this.wgOnInit();
    this.wgRefreshData();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      if (this.sort.direction) {
        this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();

        switch (this.sort.active) {
          case 'FileName':
            this.sortFieldLabel = 'FILE_NAME';
            break;
          case 'FileObjectTypeDescription':
            this.sortFieldLabel = 'FILE_OBJECT_TYPE_DESCRIPTION';
            break;
          case 'CreatedBy':
            this.sortFieldLabel = 'CREATED_BY';
            break;
          case 'CreateDate':
            this.sortFieldLabel = 'CREATE_DATE';
            break;
          default:
            break;
        }

        this.dataModel.apireqdata.sortField = this.sortFieldLabel;

        this.wgRefreshData();
      }
      else {
        try {
          delete this.dataModel.apireqdata.sortOrder;
          delete this.dataModel.apireqdata.sortField;
        }
        catch (err) {
        }
      }
    });
  }

  getControlData() {
    let url: string = null;

    if (this.sort.direction) {
      this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();
      this.dataModel.apireqdata.sortField = this.sortFieldLabel;
    }
    else {
      try {
        delete this.dataModel.apireqdata.sortOrder;
        delete this.dataModel.apireqdata.sortField;
      }
      catch (err) { }
    }

    switch (this.dataModel.filterType) {
      case FILTER_TYPE.ADVANCE_FILTER:
        url = this.dataModel.apiUrls.advanceFilter;
        break;
      case FILTER_TYPE.QUICK_FILTER:
        url = this.dataModel.apiUrls.quickFilter;
        break;
      case FILTER_TYPE.DYNAMIC_FILTER:
        url = this.dataModel.apiUrls.dynamicFilter;
        break;
      default:
        break;
    }

    return this.wgAPIMethodGet(url, this.dataModel.apireqdata);
  }

  convertData(response: any) {
    let items: FilesRow[] = [];

    response.content.forEach((element) => {
      let item: any = {};

      item.FileId = element.file_id;
      item.FileUuid = element.file_uuid;
      item.FileReference = element.file_reference;
      item.FileObjectTypeDescription = element.file_object_type_description;
      item.FileName = element.file_name;
      item.FileExtension = element.file_extension;
      item.FileSize = element.file_size;
      item.CreatedBy = element.created_by;
      item.CreateDate = element.create_date;

      items.push(item);
    });

    this.dataSource = new MatTableDataSource(items);
    this.length = response.totalElements;
  }

  setFieldData() { }

  setMode(responseDataModel: any) { }

  setValue(responseDataModel: any) { }

  applyFilter(filterValue: string) { }

  ngOnDestroy(): void {
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
  }

  resetPagination() {
    this.paginator.pageIndex = 0;
    this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
    this.dataModel.apireqdata.skip = 0;
    this.dataModel.apireqdata.top = this.paginator.pageSize;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  downloadFile(element) {
    this._serverApi.download(`/api/v1/cpm/files/${element.FileUuid}/download`).subscribe(
      response => {
        try {
          if (response) {
            this._saveFileService.saveFile(response, element.FileName);
          }
        } catch (e) {
        }
      }, error => {
      }
    );
  }
}
