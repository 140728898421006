<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 matDialogTitle>Change Request Details</h2>
  <button mat-icon-button class="close-button icon-outside" [mat-dialog-close]="true">
    <mat-icon class="close-icon material-icons color_white">close</mat-icon>
  </button>
</div>
<hr>
<mat-dialog-content class="mat-typography">
  <div class="change-request-info" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="0">
    <div class="left-side" fxFlex="70">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Change Request Reference Number</label>
          <p>A1001ENV701-01-CR-01</p>
        </div>
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Permit Reference Number</label>
          <p>A1001ENV701-01</p>
        </div>
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Change Request Type</label>
          <p>Work extension</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Change Status</label>
          <p>Refused</p>
        </div>
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Work Status</label>
          <p>Works in progress</p>
        </div>
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Assessment Discount</label>
          <p>Not Available</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Accountability</label>
          <p>MUA</p>
        </div>
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Work Location</label>
          <p>Outside Bell and Bucket pub</p>
        </div>
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Street Name</label>
          <p>Norfolk Street</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Town</label>
          <p>North Shields</p>
        </div>
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Area name</label>
          <p>North Shields</p>
        </div>
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Works Type</label>
          <p>Standard</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Highway Authority Name</label>
          <p>North Tyneside</p>
        </div>
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Promoter Name</label>
          <p>Murphy Power Distribution Limited</p>
        </div>
        <div class="info-element left-strip" fxFlex="33.3">
          <label for="">Promoter Prefix</label>
          <p>MUA</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div class="info-element left-strip" fxFlex="0 1 calc(66.6% + 20px)">
          <label for="">Change Reason</label>
          <p>Due to the hot box operator going for a Covid test our contactors are stretched on the prem reistating, we
            would be looking to have this notice extended to the 14th to allow us to get this one cleared.</p>
        </div>
        <div class="info-element left-strip" fxFlex="0 1 calc(33.3%)">
          <label for="">WorkStream</label>
          <p>A1001</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div class="info-element left-strip" fxFlex="100">
          <label for="">Assessment Comment</label>
          <p>RC50 Other: Extension not granted until 14th, extension ok until the 12th.Please amend your extension
            request and resubmit. Rachel 0191 643 6990</p>
        </div>
      </div>

    </div>
    <div class="right-side" fxFlex="30">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div class="info-element left-strip" fxFlex="50">
          <label for="">Submission Date</label>
          <p>07/10/20 9:43</p>
        </div>
        <div class="info-element left-strip" fxFlex="50">
          <label for="">Deem by Date</label>
          <p>09/10/20 16:30</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div class="info-element left-strip" fxFlex="100">
          <label for="">Reasonable Period End Date</label>
          <p>14/10/2020 16:30</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div class="info-element left-strip" fxFlex="50">
          <label for="">PA Proposed Start Date</label>
          <p>01/10/2020</p>
        </div>
        <div class="info-element left-strip" fxFlex="50">
          <label for="">PA Proposed end Date</label>
          <p>14/10/2020</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div class="info-element left-strip" fxFlex="50">
          <label for="">PA Actual Start Date</label>
          <p>01/10/20 10:00</p>
        </div>
        <div class="info-element left-strip" fxFlex="50">
          <label for="">PA Actual End Date</label>
          <p>14/10/2020</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="100">
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Select Action</mat-label>
            <mat-select>
              <mat-option *ngFor="let action of actionList" [value]="action">{{action}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Comment
            </mat-label>
            <textarea matInput placeholder="Enter your comment"></textarea>
            <mat-error>Field must be valid</mat-error>
          </mat-form-field>
        </div>

      </div>

    </div>
  </div>
</mat-dialog-content>
<hr>
<mat-dialog-actions align="end">
  <button mat-raised-button class="primary-button mb-2" color="primary" [mat-dialog-close]="true">Update</button>

</mat-dialog-actions>
