import { AppList, ChargesRow } from '../charges-list/charges-list.model';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  FILTER_TYPE,
  GLOBAL_PARAM_KEY,
  JOURNEY_TYPE,
} from 'src/app/app-constants';
import {
  MASTER_DATA_DOMAIN,
  PERMIT_ACTIONS_CONFIG,
} from 'src/app/constants/db.constants';
import {
  RechargeListConfigModel,
  RechargesListDataModel,
  RechargesRow,
} from './recharge-list';

import { AppFilterModel } from 'src/app/models/common/app-filter';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { Router } from '@angular/router';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { WidgetEvents } from 'src/app/models/common/widget-events';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-recharge-list',
  templateUrl: './recharge-list.component.html',
  styleUrls: ['./recharge-list.component.css'],
})
export class RechargeListComponent
  extends WidgetComponentBase
  implements OnInit
{
  @Input() dataModel: RechargesListDataModel;
  @Input() configModel: RechargeListConfigModel;
  PERMIT_ACTIONS_CONFIG = PERMIT_ACTIONS_CONFIG;
  dataSource = new MatTableDataSource<RechargesRow>();
  expandedElement: ChargesRow;
  inactiveAppList: AppList[] = [];
  filterValue: any;
  selectAllCheckbox: boolean;
  // color-code:any="red";
  // @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  @ViewChild('htmlData') htmlData!: ElementRef;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  pageChangeSubscription: any;
  sortFieldLabel: string = '';
  length: any;
  MAT_HELPER = MatTableHelper;
  DATA_HELPER = DataHelper;
  arrWorkIdentifier: any = [];
  arrRoadCategori: any = [];
  arrPermitStatus: any[] = [];
  preapplicationid = 0;
  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _router: Router,
    public dialog: MatDialog,
    private _validationService: ValidationService,
    private _appRepoService: AppRepoService
  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit() {
    // console.log('ChargesListComponent ngOnInit');
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.pageChangeSubscription = this.paginator.page.subscribe(() => {
      this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
      this.dataModel.apireqdata.top = this.paginator.pageSize;
      this.dataModel.apireqdata.skip =
        this.dataModel.apireqdata.pageno * this.dataModel.apireqdata.top;
      // TODO: will update this
      this.setValue();
      this.wgRefreshData();
    });

    // TODO: attach local handlers
    this.wgOnInit();
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe(() => {
    //if (this.dataModel.filterType == FILTER_TYPE.QUICK_FILTER) {
    this.paginator.pageIndex = 0;
    this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
    this.dataModel.apireqdata.skip = 0;
    // if (this.sort.direction) {
    //   this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();
    //   switch (this.sort.active) {
    //     case 'WorksType':
    //       this.sortFieldLabel = 'WORK_CATEGORY';
    //       break;
    //     case 'StartDate':
    //       this.sortFieldLabel = 'START_DATE';
    //       break;
    //     case 'EndDate':
    //       this.sortFieldLabel = 'END_DATE';
    //       break;
    //     case 'PermitStatus':
    //       this.sortFieldLabel = 'PERMIT_STATUS';
    //       break;
    //     case 'WorkStatus':
    //       this.sortFieldLabel = 'WORK_STATUS';
    //       break;
    //     case 'DeemedDate':
    //       this.sortFieldLabel = 'DEEMED_DATE';
    //       break;
    //     default:
    //       break;
    //   }
    //   this.dataModel.apireqdata.sortField = this.sortFieldLabel;
    // } else {
    // try {
    //   delete this.dataModel.apireqdata.sortOrder;
    //   delete this.dataModel.apireqdata.sortField;
    // } catch (err) {
    // }
    // }
    if (this.dataModel.isSelfDataLoad) {
      this.wgRefreshData();
    }
    //}
    // });
  }

  getControlData() {
    // INFO: ServerAPI
    let url: string = null;
    if (this.sort.direction) {
      this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();
      this.dataModel.apireqdata.sortField = this.sortFieldLabel;
    } else {
      try {
        delete this.dataModel.apireqdata.sortOrder;
        delete this.dataModel.apireqdata.sortField;
      } catch (err) {}
    }

    if (this.dataModel.journeyType != JOURNEY_TYPE.NONE) {
      switch (this.dataModel.journeyType) {
        case JOURNEY_TYPE.SSM_START:
          url = '/api/v1/start/permit-list';
          break;
        case JOURNEY_TYPE.SSM_STOP:
          url = '/api/v1/stop/permit-list';
          break;
        case JOURNEY_TYPE.SSM_REGISTER:
          url = '/api/v1/register/permit-list';
          break;
        case JOURNEY_TYPE.IDM_2_4:
          url = '/api/v1/applications';
          break;
        case JOURNEY_TYPE.IDM_VISUAL_CORING:
          url = '/api/v1/applications';
          break;
        default:
          break;
      }
    } else {
      switch (this.dataModel.filterType) {
        case FILTER_TYPE.ADVANCE_FILTER:
          url = this.dataModel.apiUrls.advanceFilter;
          break;
        case FILTER_TYPE.QUICK_FILTER:
          url = this.dataModel.apiUrls.quickFilter;
          break;
        case FILTER_TYPE.DYNAMIC_FILTER:
          url = this.dataModel.apiUrls.dynamicFilter;
          break;
        default:
          break;
      }
    }

    // console.log('url from chargeslist', this.dataModel.apireqdata);
    return this.wgAPIMethodGet(url, this.dataModel.apireqdata);
  }

  convertData(response: any) {
    // console.log('response in charges list', response);
    let items: RechargesRow[] = [];
    response.content.forEach((element) => {
      let item: any = {};

      item.draft_invoice_reference_number =
        element.draft_invoice_reference_number;
      item.ApplicationId = element.cpm_est_cost_id
        ? element.cpm_est_cost_id
        : element.application_id;
      item.charge_number = element.charge_number;
      item.charge_type = element.charge_type;
      item.cost_type = element.cost_type;
      item.chargeId = element.charge_id;
      item.final_invoice_reference_number =
        element.final_invoice_reference_number;
      item.WorksLocationDescription = element.location;
      item.highway_authority = element.highway_authority;
      item.PermitRefNumber = element.permit_reference_number;
      item.chargeNumber = element.charge_number;
      item.chargeType = element.charge_type;
      item.WorksLocationDescription = element.location;
      item.agreedCharges = element.agreed_charges;
      item.costType = element.cost_type;
      item.draftInvoiveCharges = element.draft_invoice_charges;
      item.draftInvoiveRefNum = element.draft_invoice_reference_number;
      item.estimatedCharges = element.estimated_charges;
      item.finalInvoiceRefNum = element.final_invoice_reference_number;
      item.highwayAuthority = element.highway_authority;
      item.network = element.network;
      item.systemClaimStatus = element.system_claim_status;
      item.workId = element.work_id;
      item.WorksReferenceNumber = element.work_reference_number;
      item.costFpnId = element.id;
      item.costS74Id = element.id;
      item.WorkOrder = element.work_order_no;
      item.createddate = element.created_date;
      item.estimated_charges = element.estimated_charges;
      item.draft_invoice_charges = element.draft_invoice_charges;
      item.agreed_charges = element.agreed_charges;
      item.system_claim_status = element.system_claim_status;
      item.network = element.network;
      item.WorksType = element.work_category;
      item.CostRisk = element.cost_risk;
      item.CustomerRisk = element.customer_risk;
      item.TrafficManagement = element.traffic_management_risk;
      item.DeemedDate = element.deadline_date;
      item.StartDate = element.start_date;
      item.EndDate = element.end_date;
      item.PermitStatus = element.charge_status;
      item.status = element.status;
      item.WorkStatusValue = element.work_status_value;
      item.WorkStatus = element.work_status;
      // item.CollaborativeWorkingFlag = element.collaborative_working_flag;
      item.tag_matrix = element.tag_matrix;
      item.alteration_status = element.alteration_status;
      item.cpmS74Id = element.id;
      item.overrunWarningReason = element.overrun_warning_details;
      item.offenceCode = element.offence_type;
      item.offenceDetails = element.offence_details;
      // item.issuedDate = element.issued_date;
      item.issuedDate = '';
      item.chargeValidity = element.charge_validity;

      // For Liability Assignments:
      item.Reference = element.invoice_reference;
      item.AuthorityName = element.highway_authority;
      item.ChargeType = element.charge_type;
      item.rechargeAmount = element.liability_amount_assigned;
      item.liabilityAssignedDate = element.liability_assigned_date;
      item.liabilityAcceptedDate = element.liability_accepted_date;
      item.liabilityOrganization = element.liability_organisation;
      item.primary_liability_organization = element.primary_liability_organization;
      this.dataModel.LiablityListObj.primaryLiabilityOrg = element.primary_liability_organization;
      item.liabilityStatus = element.liability_status;
      // item.rechargeAmount = element.recharge_amount;
      item.rechargeDate = element.recharge_date;
      item.responseDueDate = element.response_due_date;
      item.assignedBy = element.assigned_by;
      item.liabilityRef = element.liability_reference_id;
      item.responseRecievedDate = element.response_received_date;
      item.chargeReference = element.charge_reference;
      item.liabilityAssignedAmount = element.liability_amount_assigned;
      item.invoicePeriod = element.invoice_period;
      item.isChecked = true;

      // Minimize Functionaltity
      item.isSliced = true;

      let temp: AppList[] = [];

      // element.inactive_app_list.forEach(element1 => {
      //  let item1: any = {};
      //     item1.appId= element1.application_id;
      //     item1.permitRefNo= element1.permit_reference_number;
      //     item1.applistStatus= element1.status;
      //     temp.push(item1);

      // });

      // this.inactiveAppList =temp;

      items.push(item);
    });
    this.dataSource = new MatTableDataSource(items);
    this.dataModel.data = this.dataSource;
    this.length = response.totalElements;
    let data: any = {};
    if (this.length != 0) {
      data.isChecked = true;
      this.selectAllCheckbox = true;
    } else {
      data.isChecked = false;
      this.selectAllCheckbox = false;
    }
    this.sendEnableBtnEvent(data.isChecked);
  }

  setFieldData() {
    this.arrWorkIdentifier = this._appRepoHelperService.getMDataByDomain(
      MASTER_DATA_DOMAIN.WORK_IDENTIFIER.toString()
    );
    this.arrRoadCategori = this._appRepoHelperService.getMDataByDomain(
      MASTER_DATA_DOMAIN.ROAD_CATEGORY.toString()
    );
    this.arrPermitStatus = this._appRepoHelperService.getMDataByDomain(
      MASTER_DATA_DOMAIN.PERMIT_STATUS.toString()
    );
  }

  setMode(responseDataModel: any) {}

  setValue() {
    // this.isFpnChargview = this.globalParameters.get('isFpnChargview');
    // this.fpnChargeOverviewModel.isFpnChargview = this.isFpnChargview;
    // this.filterValue = this.globalParameters.get('chargesFilterValue');
    // this.dataModel.filterValue = this.filterValue
  }

  // setValue(responseDataModel: any) {}

  applyFilter(filterValue: string) {
    // if (filterValue == "all") {
    //   this.dataSource.filter = "";
    // } else {
    //   filterValue = filterValue.trim();
    //   filterValue = filterValue.toLowerCase();
    //   this.dataSource.filter = filterValue;
    // }
  }

  onExpandCollapse(element) {
    if (element.ApplicationId != this.preapplicationid) {
      this.preapplicationid = element.ApplicationId;
      this.expandedElement = element;
      return this.getApplicationsDetails(element);
    }
  }

  getApplicationsDetails(element) {
    super
      .wgAPIMethodGet(`/api/v1/applications/${element.ApplicationId}`, null)
      .then((response: any) => {
        if (response) {
          element.inactive_app_list = [];
          element.WorksReferenceNumber = response.work_reference_number;
          element.HighwayAuthority = response.highway_authority_name;
          element.Workstream = response.department_name;
          element.PromoterPrefix = response.promoter_prefix;
          element.PromoterOrganization = response.promoter_organisation;
          element.ProjectReferenceNumber = response.project_reference_number;
          element.Contact = response.secondary_contact;
          // not pressent in api response
          element.ProposedDuration = response.working_days;
          element.Cost = response.total_cost;

          //  element.RoadType = this._appRepoHelperService.getMDataDisplayTextByValue(element.road_category.toString());
          element.TrafficSensitive = response.traffic_sensitive_flag;
          element.FootwayClosure = response.close_footway;

          element.ExcavationRequired = response.excavation_flag;
          element.IsLaneRentalApplicable = response.lane_rental_flag;
          element.TrafficManagementRequired = response.traffic_management_type;
          element.WorkDescription = response.work_description;

          // this.arrWorkIdentifier.forEach(element1 => {
          //   if (element1.workIdentifierId == response.work_description) {
          //     element.WorkDescription = element1.description;
          //   }
          // });

          this.arrRoadCategori.forEach((element1) => {
            if (element1.value == response.road_category) {
              element.RoadType = element1.displayText;
            }
          });

          response.inactive_app_list.forEach((element11) => {
            let item1: any = {};
            item1.ApplicationId = element11.application_id;
            item1.permitRefNo = element11.permit_reference_number;
            item1.applistStatus = element11.status;

            element.inactive_app_list.push(item1);
          });

          // this.inactiveAppList =temp;

          // element.inactive_app_list = this.inactiveAppList;

          element.FootwayClosureDisplayText =
            this._appRepoHelperService.getMDataDisplayTextByValue(
              element.FootwayClosure
            );
          element.TrafficManagReqDisplayText =
            this._appRepoHelperService.getMDataDisplayTextByValue(
              element.TrafficManagementRequired
            );
        }
      });
  }
  checkEditPermitEnable(actionName, row) {
    // if (this._appRepoHelperService.hasPermitActionPermission(actionName)) {
    if ('status' in row) {
      if (row.status == 'draft') {
        return true;
      } else {
        return false;
      }
    }
    // }
  }

  onViewClick(row, index) {
    // console.log("row", row);
    console.log('index', index);
    console.log(row);
    row.index = index;
    // let obj = { row: row, index: index}
    // console.log(this.emitEvent(WidgetEvents.VIEW_CLICK, row));
    this.emitEvent(WidgetEvents.VIEW_CLICK, row);
  }

  onInactivePermitViewClick(row) {
    this.emitEvent('inActivePermitView', row);
  }

  onEditPermitIconClick($event, rowData) {
    $event.stopPropagation();
    this.emitEvent('editPermit', rowData);
  }

  onDeletePermitIconClick($event, rowData) {
    $event.stopPropagation();
    this.emitEvent('deletePermit', rowData);
  }

  ngOnDestroy(): void {
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
  }

  resetPagination() {
    this.paginator.pageIndex = 0;
    this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
    this.dataModel.apireqdata.skip = 0;
    this.dataModel.apireqdata.top = this.paginator.pageSize;
  }
  stopPropagation(event) {
    event.stopPropagation();
  }

  getHelpTextData(data) {
    // switch (data) {
    //   case 'CostRisk':
    //     return 1001;
    //     case 'CustomerRisk':
    //     return 1002;
    //     case 'TrafficManagement':
    //     return 1003;
    //   default:
    //     return null;
    // }
    return null;
  }
  downloadPdf() {
    console.log('pdf');
    let DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('Estimated-work-list.pdf');
    });
  }

  // Minimize Functionaltity
  expandRow(rowData, i) {
    if (rowData.isSliced == true) {
      this.dataSource.data[i].isSliced = false;
    } else {
      this.dataSource.data[i].isSliced = true;
    }
  }

  showMore(value, isSliced) {
    if (value) {
      if (value.length > 25 && !!isSliced) {
        return '...more';
      } else if (value.length < 25 && !!isSliced) {
        return;
      } else {
        return '...less';
      }
    }
  }

  selectAll(checked: boolean) {
    this.selectAllCheckbox = checked;
    this.dataSource.data.forEach((row) => (row.isChecked = checked));
    this.sendEnableBtnEvent(checked);
  }

  onCheckboxClick(index, checked: boolean) {
    this.dataSource.data[index].isChecked = checked;

    let isChecked = this.dataSource.data.some(
      (item) => item.isChecked === true
    );
    let selectAll = this.dataSource.data.every(
      (item) => item.isChecked === true
    );
    this.selectAllCheckbox = selectAll;
    this.sendEnableBtnEvent(isChecked);
  }

  sendEnableBtnEvent(checked) {
    console.log(this.dataSource.data);
    let data: any = {
      isChecked: checked,
    };
    this.emitEvent('enableSave', data);
  }
  onClickEmailInvoice() {
    let data: any = {};
    data.rechargeInvoiceId = this.dataModel.LiablityListObj.rechargeInvoiceId;
    console.log(data);
    this.emitEvent('onClickEmailInvoice', data);
  }
}
