import { EVENT_NAME as DB_EVENT_NAME } from './constants/db.constants';

export enum SESSION_STORAGE_DATA_KEY {
  USERINFO = 'userinfo',
  SESSION_TOKEN = 'sessionToken',
  NAVIGATION_DATA = 'PAGE_NAVIGATION_DATA',
  USER_NAME = 'username',
}

export enum LOCAL_STORAGE_DATA_KEY {
  USERINFO = 'userinfo',
  SESSION_TOKEN = 'sessionToken',
}

export enum NAVIGATION_DATA {
  SESSION_TOKEN = 'elginSessionToken',
  // REVERSE_GEOCODE = 'reverseGeocode',
  // CREATE_PERMIT = 'createPermit'
}

export enum NAVIGATION_DATA {
  CREATE_PERMIT = 'createPermit',
  EDIT_PERMIT = 'editPermit',
}

export enum FILTER_TYPE {
  ADVANCE_FILTER = 0,
  QUICK_FILTER = 1,
  DYNAMIC_FILTER = 2,
}

export enum JOURNEY_TYPE {
  NONE = 'none',
  SSM_START = 'ssm_start',
  SSM_STOP = 'ssm_stop',
  SSM_REGISTER = 'ssm_register',
  IDM_2_4 = 'idm_2_4',
  IDM_VISUAL_CORING = 'idm_visual_coring',
}

export enum GLOBAL_PARAM_KEY {
  PERMIT = 'Permit',
  ELGIN_MAP = 'ELGIN_MAP',
  CONDITIONS = 'CONDITIONS',
  PERMIT_FORM = 'PERMIT_FORM',
  PERMIT_CONFIRMATION = 'PERMIT_CONFIRMATION',

  REINSTATEMENT_ELGIN_MAP = 'REINSTATEMENT_ELGIN_MAP',
  REINSTATEMENT_FORM = 'REINSTATEMENT_FORM',
  REINSTATEMENT__CONFIRMATION = 'REINSTATEMENT_CONFIRMATION',

  VIEW_ELGIN_MAP = 'VIEW_ELGIN_MAP',
  VIEW_CONDITIONS = 'VIEW_CONDITIONS',
  VIEW_PERMIT_FORM = 'VIEW_PERMIT_FORM',
  VIEW_INVOICE_FORM = 'VIEW_INVOICE_FORM',

  NEW_PERMIT_CONDITIONS = 'NEW_PERMIT_CONDITIONS',
  NEW_PERMIT_PERMIT_FORM = 'NEW_PERMIT_PERMIT_FORM',
  APP_FILTER = 'App_Filters',
  FILTERED_LIST_TITLE = 'FILTERED_LIST_TITLE',

  UPLOAD_METADATA = 'UPLOAD_METADATA',
  ATTACHMENT_ID = 'ATTACHMENT_ID'
}

export enum DYNAMIC_FILTER_KEY {
  ALL_WORK_LIST = 'ALL_WORK_LIST',
  MY_WORK_LIST = 'MY_WORK_LIST',
  MY_TEAMS_WORK_LIST = 'MY_TEAMS_WORK_LIST',
  INVOICE_ALL_WORK_LIST = 'INVOICE_ALL_WORK_LIST',
  INVOICE_MY_WORK_LIST = 'INVOICE_MY_WORK_LIST',
  INVOICE_MY_TEAMS_WORK_LIST = 'INVOICE_MY_TEAMS_WORK_LIST',
  LIABILITY_ASSIGNMENT = 'LIABILITY_ASSIGNMENT',
  ESTIMATED_ALL_WORK_LIST = 'ESTIMATED_ALL_WORK_LIST',
  ESTIMATED_MY_TEAMS_WORK_LIST = 'ESTIMATED_MY_TEAMS_WORK_LIST',
}
export enum QUICK_FILTER_KEY {
  ALL_WORK_LIST = 'QUICK_FILTER_ALL_WORK_LIST',
  MY_WORK_LIST = 'QUICK_FILTER_MY_WORK_LIST',
  MY_TEAMS_WORK_LIST = 'QUICK_FILTER_MY_TEAMS_WORK_LIST',
  INVOICE_ALL_WORK_LIST = 'QUICK_FILTER_INVOICE_ALL_WORK_LIST',
  INVOICE_MY_WORK_LIST = 'QUICK_FILTER_INVOICE_MY_WORK_LIST',
  INVOICE_MY_TEAMS_WORK_LIST = 'QUICK_FILTER_INVOICE_MY_TEAMS_WORK_LIST',
  ESTIMATED_ALL_WORK_LIST = 'ESTIMATED_QUICK_FILTER_ALL_WORK_LIST',
  ESTIMATED_MY_TEAMS_WORK_LIST = 'ESTIMATED_QUICK_FILTER_MY_TEAMS_WORK_LIST',
}

export enum GLOBAL_PARAM_MODE {
  PERMIT_VIEW = 'PERMIT_VIEW',
  NEW_PERMIT = 'NEW_PERMIT',
}

export enum CHARGE_FORMS_TYPE {
  ASSIGN_JOB_OWNER = 1,
  ASSIGN_LIABILITY = 2,
  ACCEPT_CHARGE = 3,
  DISPUTE_CHARGE = 4,
  RECORD_NEGOTIATED_CHARGE = 5,
  WRITE_OFF_CHARGE = 6,
  RECORD_PAYMENT = 7,
  APPROVE_CHARGE = 8,
  AUDIT_CHARGE = 9,
  ADD_COMMENT = 10,
  REQUEST_APPROVAL = 11,
  ACTION_FPN = 12,
  ACTION_S74 = 13,
  UPDATE_CHARGE_STATUS = 14,
  ACTION_LIABILITY = 16,
  UPDATE_AGREED_CHARGE_STATUS = 17,
  SHOW_PROGRESS_BAR = 20
}

export enum CHARGE_FORMS_NAME {
  ASSIGN_JOB_OWNER = 'ASSIGN_JOB_OWNER',
  ASSIGN_LIABILITY = 'ASSIGN_LIABILITY_OWNER',
  ACCEPT_CHARGE = 'ACCEPT_CHARGE',
  DISPUTE_CHARGE = 'DISPUTE_CHARGE',
  RECORD_NEGOTIATED_CHARGE = 'RECORD_NEGOTIATED_CHARGE',
  WRITE_OFF_CHARGE = "WRITE_OFF_CHARGE",
  RECORD_PAYMENT = 'RECORD_PAYMENT',
  APPROVE_CHARGE = 'APPROVE_CHARGE',
  AUDIT_CHARGE = 'AUDIT_CHARGE',
  ADD_COMMENT = 'ADD_COMMENT',
  REQUEST_APPROVAL = 'REQUEST_APPROVAL',
  ACTION_FPN = 'ACTION_FPN',
  ACTION_S74 = 'ACTION_S74',
  UPDATE_CHARGE_STATUS = 'UPDATE_CHARGE',
  ACTION_LIABILITY = 'ACTION_LIABILITY',
  UPDATE_AGREED_CHARGE_STATUS = 'UPDATE_AGREED_CHARGE_STATUS'
}

export enum INVOICE_FORMS_TYPE {
  EMAIL_INVOICE = 1,
  EMAIL_INVOICE_2 = 2,
  SPLIT_INVOICE = 3,
  RECORD_INVOICE_AMOUNT = 4,
  RAISE_PO = 5,
}
export const REMEMBER_ME_DATA = 'pwb-rm-data';
