<div class="cpm-filter">
  <div class="row">
    <div class="col-md-12">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div fxLayout="row" fxLayoutAlign="left">
                <div class="theme-color">FILTER</div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-md-12">
              <div class="filter-title-container ">
                <form [formGroup]="wgFormGroup" id="filter-form">
                  <div class="row">
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>From Date</mat-label>
                        <input matInput [matDatepicker]="fromDate" (click)="fromDate.open()" (focus)="fromDate.open()"
                          formControlName="fromDate">
                        <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                        <mat-datepicker #fromDate></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>To Date</mat-label>
                        <input matInput [matDatepicker]="toDate" (click)="toDate.open()" (focus)="toDate.open()"
                          formControlName="toDate">
                        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                        <mat-datepicker #toDate></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Charge Number</mat-label>
                        <input matInput placeholder="Charge Number" autocomplete="off" formControlName="chargeNumber">
                        <!-- <mat-error>Field must be valid</mat-error> -->
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Invoice Number</mat-label>
                        <input matInput placeholder="Invoice Number" autocomplete="off" formControlName="invoiceNumber">
                        <!-- <mat-error>Field must be valid</mat-error> -->
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Charge Type</mat-label>
                        <mat-select multiple formControlName="chargeType">
                          <mat-option [value]="workStatus.key"
                            *ngFor="let workStatus of arrChargeType">{{workStatus.displayText}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Charge Status</mat-label>
                        <mat-select multiple formControlName="status">
                          <mat-option [value]="permitStatus.key" *ngFor="let permitStatus of arrStatus">
                            {{permitStatus.displayText}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Network</mat-label>
                        <mat-select multiple formControlName="network">
                          <!-- <mat-option [value]="highWayAuthority.authorityId"
                                                        *ngFor="let highWayAuthority of arrHighWayAuthorities">
                                                        {{highWayAuthority.name}}</mat-option> -->
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Workstream</mat-label>
                        <mat-select multiple formControlName="workstream">
                          <!-- <mat-option [value]="highWayAuthority.authorityId"
                                                        *ngFor="let highWayAuthority of arrHighWayAuthorities">
                                                        {{highWayAuthority.name}}</mat-option> -->
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Area</mat-label>
                        <mat-select multiple formControlName="area">
                          <!-- <mat-option [value]="highWayAuthority.authorityId"
                                                        *ngFor="let highWayAuthority of arrHighWayAuthorities">
                                                        {{highWayAuthority.name}}</mat-option> -->
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Highway Authority</mat-label>
                        <mat-select multiple formControlName="highwayAuthority">
                          <mat-option [value]="highWayAuthority.authorityId"
                            *ngFor="let highWayAuthority of arrHighWayAuthorities">
                            {{highWayAuthority.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Liability Owner</mat-label>
                        <mat-select multiple formControlName="liabilityOwner">
                          <!-- <mat-option [value]="workType.key" *ngFor="let workType of arrLiabilityOwner">
                                                            {{workType.displayText}}</mat-option> -->
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Job Owner</mat-label>
                        <mat-select multiple formControlName="jobOwner">
                          <!-- <mat-option [value]="workType.key" *ngFor="let workType of arrLiabilityOwner">
                                                          {{workType.displayText}}</mat-option> -->
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Date of Offence</mat-label>
                        <input matInput [matDatepicker]="dateOfOffence" (click)="dateOfOffence.open()"
                          (focus)="dateOfOffence.open()" formControlName="dateOfOffence">
                        <mat-datepicker-toggle matSuffix [for]="dateOfOffence"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfOffence></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2" *ngIf="!dataModel.isLiabilityValuesReq">
                      <mat-form-field appearance="fill">
                        <mat-label>Date of Potential Charge</mat-label>
                        <input matInput [matDatepicker]="dateOfPotentialCharge" (click)="dateOfPotentialCharge.open()"
                          (focus)="dateOfPotentialCharge.open()" formControlName="dateOfPotentialCharge">
                        <mat-datepicker-toggle matSuffix [for]="dateOfPotentialCharge"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfPotentialCharge></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2" *ngIf="dataModel.isLiabilityValuesReq">
                      <mat-form-field appearance="fill">
                        <mat-label>Liability Assigned By</mat-label>
                        <input matInput placeholder="Charge Number" autocomplete="off"
                          formControlName="liabilityAssignedBy">
                        <!-- <mat-error>Field must be valid</mat-error> -->
                      </mat-form-field>
                    </div>
                    <div class="col-md-2" *ngIf="dataModel.isLiabilityValuesReq">
                      <mat-form-field appearance="fill">
                        <mat-label>Liability Status</mat-label>
                        <input matInput placeholder="Charge Number" autocomplete="off"
                          formControlName="liabilityStatus">
                        <!-- <mat-error>Field must be valid</mat-error> -->
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Date of Invoice</mat-label>
                        <input matInput [matDatepicker]="dateOfInvoice" (click)="dateOfInvoice.open()"
                          (focus)="dateOfInvoice.open()" formControlName="dateOfInvoice">
                        <mat-datepicker-toggle matSuffix [for]="dateOfInvoice"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfInvoice></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2" *ngIf="!dataModel.isLiabilityValuesReq">
                      <mat-form-field appearance="fill">
                        <mat-label>Date of PO Raised</mat-label>
                        <input matInput [matDatepicker]="dateofPORaised" (click)="dateofPORaised.open()"
                          (focus)="dateOfPayment.open()" formControlName="dateofPORaised">
                        <mat-datepicker-toggle matSuffix [for]="dateofPORaised"></mat-datepicker-toggle>
                        <mat-datepicker #dateofPORaised></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2" *ngIf="!dataModel.isLiabilityValuesReq">
                      <mat-form-field appearance="fill">
                        <mat-label>Date of Payment</mat-label>
                        <input matInput [matDatepicker]="dateOfPayment" (click)="dateOfPayment.open()"
                          (focus)="dateOfPayment.open()" formControlName="dateOfPayment">
                        <mat-datepicker-toggle matSuffix [for]="dateOfPayment"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfPayment></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Date of Payment</mat-label>
                        <input matInput [matDatepicker]="dateOfPayment" (click)="dateOfPayment.open()"
                          (focus)="dateOfPayment.open()" formControlName="dateOfPayment">
                        <mat-datepicker-toggle matSuffix [for]="dateOfPayment"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfPayment></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field appearance="fill">
                        <mat-label>Date of Recharge Agreed</mat-label>
                        <input matInput [matDatepicker]="dateOfRecharge" (click)="dateOfRecharge.open()"
                          (focus)="dateOfRecharge.open()" formControlName="dateOfRecharge">
                        <mat-datepicker-toggle matSuffix [for]="dateOfRecharge"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfRecharge></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </form>

                <div *ngIf="wgFormGroup.errors" class="text-center mt-3 pl-3 pr-3">
                  <div class="is-invalid"></div>
                  <div class="invalid-feedback">
                    <ng-container *ngFor="let item of wgFormGroup.errors | keyvalue">
                      <div *ngIf="wgFormGroup.errors[item.key].errorMessage">
                        {{wgFormGroup.errors[item.key].errorMessage}}
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-2 offset-md-10 mb-5">
                    <div class="pull-right">
                      <span class="mr-3">
                        <button type="button" mat-stroked-button (click)="onResetFilterClick()">RESET</button>
                      </span>
                      <span>
                        <button type="button" mat-stroked-button class="blue-btn" (click)="applyFilter()">APPLY</button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>