
<form [formGroup]="wgFormGroup" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" class="px-5">
  <div fxLayout="row" fxLayoutGap="10px">


    <!-- <mat-form-field appearance="outline">
      <mat-label>From Date</mat-label>
      <input matInput [matDatepicker]="filterFromDatepicker" (click)="filterFromDatepicker.open()"
        (focus)="filterFromDatepicker.open()" formControlName="fromDate">
      <mat-datepicker-toggle matSuffix [for]="filterFromDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #filterFromDatepicker></mat-datepicker>
    </mat-form-field> -->


    <!-- <span class="p-float-label date" *ngIf="!(dataModel.permission.toDate== 'n')">
    <span class="warning-message"
    *ngIf="this.wgFormGroup.get('toDate').untouched && this.wgFormGroup.get('fromDate').dirty && isvalidationset">Field
    Required!</span>
    <p-calendar #toDate (onSelect)="onToDateSelect()" placeholder="To Date" formControlName="toDate" dateFormat="dd/mm/yy" [ngClass]="this.wgFormGroup.get('toDate').untouched && this.wgFormGroup.get('fromDate').dirty && isvalidationset ? 'warning-border': ''"></p-calendar>
  </span> -->

    <!-- <mat-form-field appearance="outline">
      <mat-label>To Date</mat-label>
      <input matInput [matDatepicker]="filterToDatepicker" (click)="filterToDatepicker.open()"
        (focus)="filterToDatepicker.open()" formControlName="toDate">
      <mat-datepicker-toggle matSuffix [for]="filterToDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #filterToDatepicker></mat-datepicker>
    </mat-form-field> -->


    <!-- <span class="p-float-label" *ngIf="!(dataModel.permission.network == 'n')">
    <p-multiSelect [options]="arrPromoterOrgnisation" [(ngModel)]="selectedPromoterOrgnisation" optionValue="orgId"
    optionLabel="network" formControlName="promoterOrganisation"
    (onChange)="onChangePromoterOrgnisation($event.value)"></p-multiSelect>
    <label for="float-input">{{checkBaseKey() ? 'Network' : 'Promoter Organisation'}}</label>
  </span> -->

    <div style="position: relative;">
      <mat-icon *ngIf="wgFormGroup.get('promoterOrganisation').value" class="clear-search" style="top: 25px; right: 25px;" (click)="onIndividualClear($event, 'promoterOrganisation')">clear</mat-icon>
      <mat-form-field appearance="outline">
        <mat-label>Promoter Organisation</mat-label>
        <mat-select (selectionChange)="onChangePromoterOrgnisation($event.value)" formControlName="promoterOrganisation"
          multiple [disableOptionCentering]="true">
          <mat-option [value]="d.orgId" *ngFor="let d of arrPromoterOrgnisation">{{d.network}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- <span class="p-float-label" *ngIf="!(dataModel.permission.activity == 'n')">
    <p-multiSelect [options]="arrActions" optionValue="activity"
    optionLabel="activity" formControlName="activity"
    (onChange)="onChangeActions($event.value)"></p-multiSelect>
    <label for="float-input">Activity</label>
  </span> -->


    <!-- <mat-form-field appearance="outline">
      <mat-label>Activity</mat-label>
      <mat-select (selectionChange)="onChangeActions($event.value)" formControlName="activity" multiple>
        <mat-option [value]="d.activity" *ngFor="let d of arrContractors">{{d.activity}}</mat-option>
      </mat-select>
    </mat-form-field> -->

    <div style="position: relative;">
      <mat-icon *ngIf="wgFormGroup.get('contractor').value" class="clear-search" style="top: 25px; right: 25px;" (click)="onIndividualClear($event, 'contractor')">clear</mat-icon>
      <mat-form-field appearance="outline">
        <mat-label>Area</mat-label>
        <mat-select multiple formControlName="contractor" (selectionChange)="onChangeContractor($event.value)" [disableOptionCentering]="true">
          <mat-option [value]="workstreams.contractorId" *ngFor="let workstreams of arrContractors">{{workstreams.area}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div style="position: relative;">
      <mat-icon *ngIf="wgFormGroup.get('workstream').value" class="clear-search" style="top: 25px; right: 25px;" (click)="onIndividualClear($event, 'workstream')">clear</mat-icon>
      <mat-form-field appearance="outline">
        <mat-label>Workstream</mat-label>
        <mat-select multiple formControlName="workstream" (selectionChange)="onChangeWorkstream($event.value)" [disableOptionCentering]="true">
          <mat-option [value]="workstreams.workstreamId"
            *ngFor="let workstreams of arrWorkstream">{{workstreams.workstream}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div style="position: relative;">
      <mat-icon *ngIf="wgFormGroup.get('highwayAuthority').value" class="clear-search" style="top: 25px; right: 25px;" (click)="onIndividualClear($event, 'highwayAuthority')">clear</mat-icon>
      <mat-form-field appearance="outline">
        <mat-label>Highway Authority</mat-label>
        <mat-select (selectionChange)="onChangeHighwayAuthority($event.value)" formControlName="highwayAuthority"
          multiple [disableOptionCentering]="true">
          <mat-option [value]="highWayAuthority.authorityId" *ngFor="let highWayAuthority of arrHighwayAuthority">
            {{highWayAuthority.authorityName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- <mat-icon id="ha"  class="picker" (click)="onClear($event, 'highwayAuthority')">clear</mat-icon> -->
    <!-- <span class="p-float-label">
    <p-multiSelect [options]="arrHighwayAuthority" [(ngModel)]="selectedHighwayAuthority" optionValue="authorityId"
      optionLabel="authorityName" formControlName="highwayAuthority"
      (onChange)="onChangeHighwayAuthority($event.value)">
    </p-multiSelect>
    <label for="float-input">Highway Authority</label>
  </span> -->

  </div>

  <div fxLayout="row" fxLayoutGap="10px" class="mb-4">
    <button mat-raised-button class="primary-button" color="primary" (click)="onSearchGlobalFilter()">
      Apply </button>

    <button mat-raised-button class="primary-button" color="primary" (click)="onClear()">
      Clear </button>
  </div>

</form>
