import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FPN_STATUS, MASTER_DATA_DOMAIN } from 'src/app/constants/db.constants';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { EventActionService } from 'src/app/services/event-action.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SaveFileService } from 'src/app/services/save-file.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from '../../../../../utility/widget/widget-utility/widget-component-base';
import { ViewPermitFpnRow } from '../view-permit-fpn/view-permit-fpn-popup.module';
import { ViewSection74sDataModel, ViewSection74sConfigModel } from './view-section74s.model';

@Component({
  selector: 'app-view-section74s',
  templateUrl: './view-section74s.component.html',
  styleUrls: ['./view-section74s.component.css']
})
export class ViewSection74sComponent extends WidgetComponentBase implements OnInit {

  @Input() dataModel: ViewSection74sDataModel;
  @Input() configModel: ViewSection74sConfigModel;
  actionList: any[] = [];
  showForm: boolean = true;
  applicationId: any;
  fpnId: any;
  changeActionValue: any = null;
  isShowadditional_details: boolean = false;
  isShowsite_visited_date: boolean = false;
  isShowdispute_details: boolean = false;
  hideElement: any;
  showElement: boolean = false;


  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _saveFileService: SaveFileService,
    private _appRepoHelperService: AppRepoHelperService,
    private _router: Router,
    private fb: FormBuilder,
    private _notificationService: NotificationService,
    private _validationService: ValidationService,
    private sanitizer: DomSanitizer,

  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit() {
    this.createFormGroup();
    this.wgOnInit();

  }

  createFormGroup() {
    this.wgFormGroup = this.fb.group({
      'status': ['', [Validators.required]],
      'additional_details': [''],
      'reason_for_dispute': [''],
      'site_visited_date': ['']
    });
  }



  getControlData() {
    let id = this.dataModel.globalParameters.get('applicationId');
    return this.wgAPIMethodGet(this.dataModel.apiDataUrl);

  }

  convertData(response: any)
  {
    try {
      if (response) {

          let item: any = {};

          this.dataModel.data.FPNId = response.section74_id;
          this.dataModel.data.PermitReferenceNumber = response.permit_reference_number
          this.dataModel.data.section74_reference_number = response.section74_reference_number;
          this.dataModel.data.ha_status = response.ha_status;
          this.dataModel.data.promoter_status = response.promoter_status;
          this.dataModel.data.ha_authority = response.ha_authority;
          this.dataModel.data.overrun_warning_reasons_display = response.overrun_warning_reasons_display;
          this.dataModel.data.overrun_warning_details = response.overrun_warning_details;
          this.dataModel.data.actual_end_date = response.actual_end_date;
          this.dataModel.data.proposed_end_date = response.proposed_end_date;
          this.dataModel.data.inspection_date = response.inspection_date;
          this.dataModel.data.ha_authority = response.ha_authority;
          this.dataModel.data.officer_name = response.officer_name;
          this.dataModel.data.officer_contact_details = response.officer_contact_details;
          this.dataModel.data.officer_name = response.officer_name;
          this.dataModel.data.Promoter = response.promoter;
          this.dataModel.data.PromoterPrefix = response.promoter_prefix;
          this.dataModel.data.road_type = response.road_type;
          this.dataModel.data.location_description = response.location_description;
          this.dataModel.data.Town = response.town;
          this.dataModel.data.traffic_sensitivity = response.traffic_sensitive_flag;
          this.dataModel.data.usrn = response.usrn;
          this.dataModel.data.location_types = response.location_types_display;
          this.dataModel.data.section74_evidence = response.section74_evidence;
          this.dataModel.data.latest_ha_response_date = response.latest_ha_response_date;
          this.dataModel.data.latest_promoter_response_date = response.latest_promoter_response_date;
          this.dataModel.data.site_visited_date = response.site_visited_date;
          this.dataModel.data.ha_reason_for_dispute = response.ha_reason_for_dispute;
          this.dataModel.data.promoter_reason_for_dispute = response.promoter_reason_for_dispute;
          this.dataModel.data.ha_response_number_of_days_overrun = response.ha_response_number_of_days_overrun;
          this.dataModel.data.draft_invoice_reference = response.draft_invoice_reference;
          this.dataModel.data.invoice_reference = response.invoice_reference;
          this.dataModel.data.draft_invoice_amount = response.draft_invoice_amount;
          this.dataModel.data.latest_ha_additional_details = response.latest_ha_additional_details;
          this.dataModel.data.latest_promoter_additional_details = response.latest_promoter_additional_details;
          this.dataModel.data.final_agreed_amount = response.final_agreed_amount;
          this.dataModel.data.site_cleared_date = response.site_cleared_date;

          this.dataModel.data.FpnFiles = []//element.fpn_files;
          let getfpnId = this.dataModel.globalParameters.get('fpnId');

          if(response.fpn_id == getfpnId){
            if (response.fpn_files) {
              for (let i = 0; i < response.fpn_files.length; i++) {
                let Imgitems: any = [];
                Imgitems.FileId = response.fpn_files[i].file_id;
                Imgitems.FPNFileId = response.fpn_files[i].fpn_file_id;
                Imgitems.FileName = response.fpn_files[i].file_name;
                Imgitems.CreatedBy = response.fpn_files[i].created_by;
                Imgitems.UpdatedBy = response.fpn_files[i].updated_by;
                Imgitems.CreatedDate = response.fpn_files[i].create_date;
                Imgitems.UpdatedDate = response.fpn_files[i].update_date;


                // if(!element.file_name.includes('docx') && !element.file_name.includes('pdf') ){
                  this.getImage(response.fpn_id,response.fpn_files[i].fpn_file_id,response.fpn_files[i].file_name).then((response: any) => {
                    Imgitems.ImageBlob = new Blob([response], { type: "image/jpeg" });
                    let unsafeImageUrl = URL.createObjectURL(Imgitems.ImageBlob);
                    Imgitems.ImageSrc = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
                  });
                // }
                this.dataModel.data.FpnFiles.push(Imgitems);
              }
            }

          }
          this.dataModel.data.actionList=[];
          this.dataModel.data.actionList.lenght = 0;
          response.ui_meta_data.control_data.forEach(actions => {
            this.dataModel.data.actionList.push(actions);
          })

          // items.push(item);

        // this.dataSource = new MatTableDataSource(items);
        // this.length = items.length;///TO Do get lenght from API

      }
    } catch (e) {
    }
  }

  setFieldData() {
  }

  setMode(responseDataModel: any) {
  }

  setValue(responseDataModel: any) {
  }

  applyFilter(filterValue: string) {
  }

  // ngAfterViewInit() {
  //  this.wgRefreshData();
  // }

  getFileApiData(fileId, filename) {
    // console.log("FPNId",this.dataModel.data.FPNId)
    this.fpnId = this.dataModel.data.FPNId;
    // console.log("applicationId",this.dataModel.data.applicationId)
    this.applicationId = this.dataModel.data.applicationId;
    // console.log("fileId",fileId)
    this._serverApi.downloadZipFile(`/api/v1/applications/fpn/${this.applicationId}/${this.fpnId}/${fileId}`).subscribe(

    // this._serverApi.downloadZipFile(`/api/v1/file/${fileId}`).subscribe(
      // this._serverApi.downloadZipFile(`/api/v1/file/${fileId}`).subscribe(
      response => {

        try {
          if (response) {
            this._saveFileService.saveZipFile(response, filename)
          }
        } catch (e) {
        }
      }, error => {
      }
    );
  }

  savePdf(type) {
    let fpnId = this.dataModel.data.FPNId;
console.log("fpnId",fpnId)
    // this.showSpinner();
    this._serverApi
      .download(`/api/v1/pdf/generate-pdf/${type}/${fpnId}`)
      .subscribe(
        (response) => {
          try {

            if (response) {
              console.log('pdf file', response);
                        let filename = type + '-list';
              this._saveFileService.saveFile(response, filename);
            }
          } catch (e) {
          }
        },
        (error) => {
          console.log('Permit save as draft error :');
        }
      );
  }


  getImage(fpnId,fpnFileId,fileName) {

    return new Promise((resolve, reject) => {
      if(fileName?.includes('docx') || fileName?.includes('pdf')){
        // reject("error")
        return;
      }
      else{
        this._serverApi.downloadZipFile(`/api/v1/applications/fpn/${this.applicationId}/${fpnId}/${fpnFileId}`).subscribe(
          response => {
            try {
              if (response) {
                resolve(response)
              }
              reject("error")
            } catch (e) {
            }
          }, error => {
            reject(error)
          }
        );
      }

    });
  }

  onUpdateBtnClick() {
    this.wgFormGroup.markAllAsTouched();
    if (this.wgFormGroup.valid) {
    const status = this.wgFormGroup.controls.status.value.value;
    const comments = this.wgFormGroup.controls.additional_details.value;
    const disputeReason = this.wgFormGroup.controls.reason_for_dispute.value;
    const siteVisitedDate = this.wgFormGroup.controls.site_visited_date.value;
    const fpnStatusRequest: any = {}

    fpnStatusRequest.status = status ? status : null;
    fpnStatusRequest.additional_details = comments ? comments : null;
    fpnStatusRequest.reason_for_dispute = disputeReason ? disputeReason : null;
    fpnStatusRequest.site_visited_date = siteVisitedDate ? siteVisitedDate.toISOString() : null;

      let appId = this.dataModel.globalParameters.get('chargeId');
      this.wgAPIMethodPost(`/api/v1/cpm/actions/actionS74/${appId}`, fpnStatusRequest).then(resp => {
        this.emitEvent('onViewFPNUpdate');
      });
    }
  }



  onchangeAction(event) {
   event.value.event.forEach((action) => {
      if ( typeof this[action.action_name] === 'function') {
          // objectOfA.func();
          this[action.action_name](action.params);
      }
    })
    this.wgFormGroup.controls.status.setValue(event.value)
  }

  onOkBtnClick() {
    this.emitEvent('onViewFPNOkClick');
  }

//   public noWhitespaceValidator(control: FormControl) {
//     const isWhitespace = (control.value || '').trim().length === 0;
//     const isValid = !isWhitespace;
//     return isValid ? null : { 'whitespace': true };
// }

  // isFPNStatusAcceptedOrDisputed
  isFPNStatusAcceptedOrWithdrawn(fpnStatus) {
    //return (fpnStatus == 'Accepted'  || fpnStatus == 'Withdrawn');
    return false;
  }

  onFileClick(fileSrc) {
    if(!fileSrc.FileName.includes('doc') && !fileSrc.FileName.includes('pdf')){
      let file = fileSrc.ImageSrc;
      window.open(file.changingThisBreaksApplicationSecurity, '_blank');
    }

  }

  show_controls_for_overrun_warning(params) {
    this.dataModel.additionalDetailsVisible = true;
    this.dataModel.siteVisitedDateVisibile = false;
    this.dataModel.disputeDetailsVisible = false;

    // this.wgFormGroup.get('additional_details').addValidators(Validators.required);
    this.wgFormGroup.get('additional_details').clearValidators();
    // this.wgFormGroup.get('site_visited_date').clearValidators();
    // this.wgFormGroup.get('site_visited_date').addValidators(null);
    // this.wgFormGroup.get('reason_for_dispute').clearValidators();
    // this.wgFormGroup.get('reason_for_dispute').addValidators(null);
    // this.wgFormGroup.get('reason_for_dispute').removeValidators(Validators.required);
    // this.wgFormGroup.get('site_visited_date').removeValidators(Validators.required);
    this.wgFormGroup.get('site_visited_date').clearValidators();
    this.wgFormGroup.controls.site_visited_date.setValue('');
    this.wgFormGroup.get('site_visited_date').updateValueAndValidity();

    this.wgFormGroup.get('reason_for_dispute').clearValidators();
    this.wgFormGroup.controls.reason_for_dispute.setValue('');
    this.wgFormGroup.get('reason_for_dispute').updateValueAndValidity();
  }

  show_controls_for_site_visited(params) {
    this.dataModel.additionalDetailsVisible = true;
    this.dataModel.siteVisitedDateVisibile = true;
    this.dataModel.disputeDetailsVisible = false;

    this.wgFormGroup.get('additional_details').setValidators(Validators.required);
    this.wgFormGroup.get('site_visited_date').setValidators(Validators.required);
    this.wgFormGroup.get('reason_for_dispute').clearValidators();
    this.wgFormGroup.controls.reason_for_dispute.setValue('');
    this.wgFormGroup.get('reason_for_dispute').updateValueAndValidity();

  }

  show_controls_for_disputed(params) {
    this.dataModel.additionalDetailsVisible = false;
    this.dataModel.siteVisitedDateVisibile = false;
    this.dataModel.disputeDetailsVisible = true;

    // this.wgFormGroup.get('additional_details').clearValidators();
    // this.wgFormGroup.get('additional_details').addValidators(null);

    // this.wgFormGroup.get('site_visited_date').clearValidators();
    // this.wgFormGroup.get('site_visited_date').addValidators(null);

    this.wgFormGroup.get('reason_for_dispute').setValidators(Validators.required);

    this.wgFormGroup.get('additional_details').clearValidators();
    this.wgFormGroup.controls.additional_details.setValue('');
    this.wgFormGroup.get('additional_details').updateValueAndValidity();
    // this.wgFormGroup.get('site_visited_date').removeValidators(Validators.required);
    this.wgFormGroup.get('site_visited_date').clearValidators();
    this.wgFormGroup.controls.site_visited_date.setValue('');
    this.wgFormGroup.get('site_visited_date').updateValueAndValidity();

  }

}
