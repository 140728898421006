<span class="custom-tooltip" [ngStyle]="{'visibility': tooltipVisible ? 'visible' : 'hidden'}">Click here to view and
  apply filters</span>
<div class="cpm-filter">
  <div class="row">
    <div class="col-md-12">
      <mat-accordion>
        <mat-expansion-panel [expanded]="expandFilter">
          <mat-expansion-panel-header (mouseenter)="showTooltip($event)" (mouseleave)="hideTooltip()">
            <mat-panel-title>
              <div fxLayout="row" fxLayoutAlign="left">
                <div class="theme-color">
                  <span> FILTER </span>
                  <mat-icon class="mx-4" [ngStyle]="{
                      'display': dataModel.showFunnel ? '' : 'none'
                    }">filter_alt</mat-icon>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row">
            <div class="col-md-12">
              <div class="filter-title-container">
                <!-- <div class="date-range w-100">
                  <mat-form-field appearance="fill">
                    <mat-label>Select Range</mat-label>
                    <mat-date-range-input
                      [formGroup]="selectRange"
                      [rangePicker]="rangePicker">
                      <input matStartDate placeholder="Start date" formControlName="start"  (dateChange)="onRangeDateChange($event)">
                      <input matEndDate placeholder="End date" formControlName="end"  (dateChange)="onRangeDateChange($event)">
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #rangePicker></mat-date-range-picker>
                  </mat-form-field>
                </div> -->
                <form [formGroup]="wgFormGroup" class="row" id="filter-form">
                  <div class="col-md-3" *ngFor="let field of dataModel.data.fields; let index = index"
                    [ngSwitch]="field.field_type">
                    <div *ngSwitchCase="'TEXTBOX'" style="position: relative;">
                      <mat-icon *ngIf="wgFormGroup.get(field.form_control_name[0]).value"
                        [id]="field.form_control_name[0]" class="picker"
                        (click)="onClearIndividualFilter(field.form_control_name[0], null)">clear</mat-icon>
                      <mat-form-field appearance="fill">
                        <mat-label>{{ field.label_text }}</mat-label>
                        <input matInput [formControlName]="field.form_control_name[0]" />
                      </mat-form-field>
                    </div>

                    <div *ngSwitchCase="'CALENDAR'" style="position: relative;">
                      <mat-icon *ngIf="wgFormGroup.get(field.form_control_name[0]).value"
                        [id]="field.form_control_name[0]" style="right: 40px;" class="picker"
                        (click)="onClearIndividualFilter(field.form_control_name[0], null)">clear</mat-icon>
                      <mat-form-field appearance="fill">
                        <mat-label>{{ field.label_text }}</mat-label>
                        <input matInput [matDatepicker]="picker" (click)="picker.open()" (focus)="picker.open()"
                          [formControlName]="field.form_control_name[0]" [min]="minDate" [max]="maxDate" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div *ngSwitchCase="'CALENDAR_WITH_RANGE'" style="position: relative;">
                      <mat-icon *ngIf="wgFormGroup.get(field.form_control_name[0]).value"
                        [id]="field.form_control_name[0]" class="picker calender"
                        (click)="onClearIndividualFilter(field.form_control_name[0], field.form_control_name[1])">clear</mat-icon>
                      <mat-form-field appearance="fill">
                        <mat-label>{{ field.label_text }}</mat-label>
                        <mat-date-range-input [rangePicker]="picker" (click)="picker.open()" (focus)="picker.open()">
                          <input matStartDate placeholder="From" [formControlName]="field.form_control_name[0]" />
                          <input matEndDate placeholder="To" [formControlName]="field.form_control_name[1]" />
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                      </mat-form-field>
                    </div>
                    <div *ngSwitchCase="'RADIOBUTTONGROUP'">
                      <label style="font-size: 15px">{{
                        field.label_text
                        }}</label>
                      <mat-radio-group [(ngModel)]="field.fieldvalue">
                        <mat-radio-button *ngFor="let data of field.field.data" [value]="data.en"
                          style="margin-left: 16px">{{ data.en }}</mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <div *ngSwitchCase="'TEXTBOX_NUMBER'">
                      <mat-form-field appearence="fill">
                        <mat-label>{{ field.label_text }}</mat-label>
                        <input type="number" matInput min="0" [(ngModel)]="field.fieldvalue" />
                      </mat-form-field>
                    </div>
                    <div *ngSwitchCase="'CHECKBOXLIST'">
                      <section>
                        <mat-checkbox *ngFor="let data of field.field.data" [(ngModel)]="field.fieldvalue">{{ data.en
                          }}</mat-checkbox>
                      </section>
                    </div>
                    <div *ngSwitchCase="'TEXTAREA'">
                      <mat-form-field appearence="fill">
                        <mat-label>{{ field.label_text }}</mat-label>
                        <textarea matInput [(ngModel)]="field.fieldvalue"></textarea>
                      </mat-form-field>
                    </div>

                    <div *ngSwitchCase="'DROPDOWN'" style="position: relative;">
                      <mat-icon [id]="field.form_control_name[0]" class="picker"
                        (click)="onClearIndividualFilter(field.form_control_name[0], null)">clear</mat-icon>
                      <mat-form-field appearance="fill">
                        <mat-label>{{ field.label_text }}</mat-label>
                        <mat-select [formControlName]="field.form_control_name[0]">
                          <mat-option *ngFor="let data of field.field_data" [value]="data.id">
                            {{ data.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div *ngSwitchCase="'MULTI_SELECT_DROPDOWN'" style="position: relative;">
                      <mat-icon *ngIf="wgFormGroup.get(field.form_control_name[0]).value"
                        [id]="field.form_control_name[0]" class="picker"
                        (click)="onClearIndividualFilter(field.form_control_name[0], null)">clear</mat-icon>
                      <mat-form-field appearance="fill">
                        <mat-label>{{ field.label_text }}</mat-label>
                        <mat-select multiple (opened)="matMultiSelectInput.focus()"
                          [formControlName]="field.form_control_name[0]" [disableOptionCentering]="true">
                          <input #matMultiSelectInput placeholder="Search" class="search-input"
                            (keyup)="onSearch($event.target.value, field.field_data, index)">
                          <mat-option
                            *ngFor="let data of field.show_filter_data ? field.filtered_data : field.field_data"
                            [value]="data">
                            {{ data.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngSwitchDefault>
                      <span>Default case</span>
                    </div>
                  </div>
                </form>

                <div class="row">
                  <div class="col-md-2 offset-md-10 mb-5">
                    <div class="pull-right">
                      <span class="mr-3">
                        <button type="button" mat-stroked-button (click)="onResetFilterClick()">
                          RESET
                        </button>
                      </span>
                      <span>
                        <button type="button" mat-stroked-button class="blue-btn" (click)="applyFilter()">
                          APPLY
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>