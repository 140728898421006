<div class="d-flex flex-column">
  <div class="detail-container" fxFlex="100" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="10px"
    fxLayoutAlign="flex-start strech">
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Invoice Reference Number </label>
      <p class="mt-2">{{this.dataModel.data.invoiceReferenceNumber}}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Cost Estimated </label>
      <p class="mt-2"><span class="black-tab">&pound;{{this.dataModel.data.estimatedCost}}</span></p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Cost Invoiced </label>
      <p class="mt-2"><span class="blue-tab">&pound;{{this.dataModel.data.invoicedCost}}</span></p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Cost Paid </label>
      <p class="mt-2"><span class="green-tab">&pound;{{this.dataModel.data.paidCost}}</span></p>
    </div>
  </div>
  <hr>
  <div class="add-charge">
    <div class="form-group">
      <form [formGroup]="createForm" id="filter-form" class="d-flex flex-column p-3">
        <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
          <mat-form-field appearance="fill" fxFlex="100">
            <mat-label>Recipent Organisation</mat-label>
            <mat-select multiple formControlName="recipentOrg">
              <mat-option [value]="permitStatus.key" *ngFor="let permitStatus of arrRecipentOrg">
                {{permitStatus.displayText}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
          <mat-form-field appearance="fill" fxFlex="100">
            <mat-label>Company Name</mat-label>
            <input matInput placeholder="Company Name" autocomplete="off" formControlName="companyName">
            <mat-error>Field must be valid</mat-error>
          </mat-form-field>
        </div>
        <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
          <mat-form-field appearance="fill" fxFlex="100">
            <mat-label>Address Line 1</mat-label>
            <input matInput placeholder="Address Line 1" autocomplete="off" formControlName="address1">
            <mat-error>Field must be valid</mat-error>
          </mat-form-field>
        </div>
        <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
          <mat-form-field appearance="fill" fxFlex="100">
            <mat-label>Address Line 2</mat-label>
            <input matInput placeholder="Address Line 2" autocomplete="off" formControlName="address2">
            <mat-error>Field must be valid</mat-error>
          </mat-form-field>
        </div>
        <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
          <mat-form-field appearance="fill" fxFlex="50">
            <mat-label>City</mat-label>
            <mat-select multiple formControlName="city">
              <mat-option [value]="permitStatus.key" *ngFor="let permitStatus of arrCity">
                {{permitStatus.displayText}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" fxFlex="50">
            <mat-label>Post Code</mat-label>
            <input matInput placeholder="Post Code" autocomplete="off" formControlName="postCode">
            <mat-error>Field must be valid</mat-error>
          </mat-form-field>
        </div>

        <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
          <mat-form-field appearance="fill" fxFlex="50">
            <mat-label>Phone</mat-label>
            <input matInput placeholder="Phone" autocomplete="off" formControlName="phone">
            <!-- <mat-error>Field must be valid</mat-error> -->
          </mat-form-field>
          <mat-form-field appearance="fill" fxFlex="50">
            <mat-label>Website</mat-label>
            <input matInput placeholder="Website" autocomplete="off" formControlName="website">
            <!-- <mat-error>Field must be valid</mat-error> -->
          </mat-form-field>
        </div>
        <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
          <mat-form-field appearance="fill" fxFlex="50">
            <mat-label>Email ID of Recipent</mat-label>
            <input matInput placeholder="Recipent's Email ID" autocomplete="off" formControlName="recipentEmail">
            <mat-error>Field must be valid</mat-error>
          </mat-form-field>
        </div>

        <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
          <mat-form-field appearance="fill" fxFlex="50">
            <mat-label>Other Charges</mat-label>
            <input matInput placeholder="Other Charges" autocomplete="off" formControlName="otherCharges">
            <!-- <mat-error>Field must be valid</mat-error> -->
          </mat-form-field>
          <mat-form-field appearance="fill" fxFlex="50">
            <mat-label>Administrative Charges</mat-label>
            <input matInput placeholder="Administrative Charges" autocomplete="off"
              formControlName="administrativeCharges">
            <!-- <mat-error>Field must be valid</mat-error> -->
          </mat-form-field>
        </div>

        <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
          <mat-form-field appearance="fill" fxFlex="100">
            <!-- <mat-label>Upload Evidence(Optional)</mat-label> -->
            <input matInput placeholder="Upload Evidence (Optional)" autocomplete="off" formControlName="fileUp" readonly
              value="Upload Evidence (Optional)" (click)="openFileUpload()">
            <mat-icon class="upload" matSuffix>attach_file</mat-icon>
          </mat-form-field>
        </div>

        <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
          <mat-form-field appearance="fill" fxFlex="100" hintLabel="Max 500 characters">
            <mat-label>Notes</mat-label>
            <textarea matInput placeholder="Notes" [maxLength]="500" rows="4" style="resize: none;" autocomplete="off" formControlName="notes"></textarea>
            <mat-error>Max character limit 500</mat-error>
          </mat-form-field>
        </div>



      </form>
    </div>
  </div>
</div>
