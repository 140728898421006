<div class="permit-list custom-table low-border-padding p-2">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>


        <!-- Position Column -->
        <ng-container matColumnDef="export_id">
            <th mat-header-cell *matHeaderCellDef class="text-center">Export Id</th>
            <!-- <td mat-cell *matCellDef="let element" class="CollaborativeCell"> -->
            <td mat-cell *matCellDef="let element" class="text-center">
                <div *ngIf="element.export_id">

                    {{element.export_id}}
                    <!-- <img src="assets/images/PastedGraphic-4.png" alt="" class="CollaborativeImg" -->
                </div>
            </td>
        </ng-container>


        <ng-container matColumnDef="no_of_records">
            <th mat-header-cell *matHeaderCellDef class="text-center maxw8"> No. of Records </th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.no_of_records}}
            </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="export_name">
            <th mat-header-cell *matHeaderCellDef> Export Name </th>
            <td mat-cell *matCellDef="let element">
                {{element.export_name}} </td>
        </ng-container>
        <!-- Position Column -->

        <!-- Position Column -->
        <ng-container matColumnDef="file_name">
            <th mat-header-cell *matHeaderCellDef> File Name </th>
            <td mat-cell *matCellDef="let element"> {{element.file_name }} </td>
        </ng-container>
        <!-- Position Column -->

        <!-- Position Column -->
        <ng-container matColumnDef="file_size">
            <th mat-header-cell *matHeaderCellDef class="text-center"> File Size </th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.file_size  | fileSize}} </td>
        </ng-container>
        <!-- Position Column -->

        <ng-container matColumnDef="create_date">
            <th mat-header-cell *matHeaderCellDef appHelperTextPopover> Created Date</th>
            <td mat-cell *matCellDef="let element">
                <!-- <img class="flag-image" [src]="DATA_HELPER.getPermitIconURL(element.CostRisk)"> -->
                {{element.create_date | transformDateTime}}
            </td>
        </ng-container>

        <ng-container matColumnDef="progress">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Progress</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex justify-content-center">
                <div class="progress w-75">
                  <div *ngIf="element.status=='Processing'" class="progress-bar" role="progressbar"
                      style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                  <div *ngIf="element.status=='Ready'" class="progress-bar" role="progressbar" style="width: 100%;"
                      aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">100%</div>
                </div>
              </div>
            </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Status </th>
            <td mat-cell *matCellDef="let element" class="text-center">
                <span [ngClass]="{'ready':element.status=='Ready','processing':element.status=='Processing'}">
                    {{element.status}}
                </span>
            </td>
        </ng-container>
        <!-- <ng-container matColumnDef="WorkStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Work Status </th>
          <td mat-cell *matCellDef="let element">
              <p [ngClass]="DATA_HELPER.getWorkStatusClass(element.WorkStatus)">{{element.WorkStatusValue}}</p>
          </td>
      </ng-container> -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Actions </th>
            <td mat-cell *matCellDef="let row" class="text-center">
                <div *ngIf="row.status=='Ready'" (click)="stopPropagation($event)" fxLayout="row"
                    fxLayoutAlign="center">
                    <button (click)="onDownloadClick($event,row)" mat-icon-button matTooltip="Click to Download"
                        class="iconbutton" color="primary">
                        <mat-icon class="iconbutton" aria-label="Edit">file_download</mat-icon>
                    </button>

                    <div>
                        <button mat-icon-button matTooltip="Click to Delete" class="iconbutton" color="warn"
                            (click)="onDeleteIconClick($event, row)">
                            <mat-icon aria-label="Delete" class="deleteicon">delete</mat-icon>
                        </button>
                    </div>
                </div>
            </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="dataModel.columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: dataModel.columnsToDisplay;">
        </tr>
    </table>


    <mat-paginator [pageSize]="MAT_HELPER.PAGE_SIZE" [pageSizeOptions]="MAT_HELPER.PAGE_SIZE_OPTIONS"
        showFirstLastButtons [length]="length"></mat-paginator>

</div>
