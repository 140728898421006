<div class="col-md-12 mt-3">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <div fxFlex="40">

      <div style="padding:5px 10px;" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">

        <div>
          <mat-icon *ngIf="searchInput.value" class="clear-search" (click)="clearSearch()">clear</mat-icon>
          <input type="text" placeholder="Search" class="search-input" [formControl]="searchInput">
        </div>
        <button type="button" fxflex="100" mat-stroked-button class="blue-btn" (click)="search()">GO</button>

      </div>

    </div>

  </div>

</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">

      <div class="permit-list custom-table low-border-padding pb-3 my-3 px-2" style="overflow: auto;">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

          <!-- Position Column -->
          <ng-container matColumnDef="InterfaceName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Interface Name</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.interface_name}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="Response">
            <th mat-header-cell *matHeaderCellDef>Response</th>
            <td mat-cell *matCellDef="let element; let i = dataIndex" class="maxWidth200">
              {{element.isSliced ? (element.response | slice:0:100) : element.response }}
              <span class="more-text" (click)="expandRow(element, i)">{{showMore(element.response,
                element.isSliced)}}
              </span>
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="Request">
            <th mat-header-cell *matHeaderCellDef>Request</th>
            <td mat-cell *matCellDef="let element; let i = dataIndex" class="maxWidth200">
              {{element.isSliced ? (element.request | slice:0:100) : element.request }}
              <span class="more-text" (click)="expandRow(element, i)">{{showMore(element.request,
                element.isSliced)}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <span class="status-pill"
                [ngClass]="getStatusCssClass(element.status)">{{element.status}}
              </span>
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="CreatedBy">
            <th mat-header-cell *matHeaderCellDef class="text-center">Created by</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.created_by}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
            <td mat-cell *matCellDef="let element" class="text-center">{{element.created_date | transformDateTime}}  </td> <!--{{element.Date | transformDate}} -->
          </ng-container>
          <!--
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Actions </th>
            <td mat-cell *matCellDef="let row">
              <div fxLayout="row" fxLayoutAlign="center center">
                <button [routerLink]="['/admin/permit-view']" (click)="openDialog(row)" mat-icon-button
                  matTooltip="Click to view" class="iconbutton" color="primary">
                  <mat-icon aria-label="Edit">visibility</mat-icon>
                </button>
              </div>

            </td>
          </ng-container> -->
          <tr mat-header-row *matHeaderRowDef="dataModel.columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: dataModel.columnsToDisplay; let i = dataIndex"
            class="detail-element-row" [class.example-expanded-row]="expandedElement === element">
          </tr>
        </table>
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
        <mat-paginator [pageSize]="MAT_HELPER.PAGE_SIZE" [pageSizeOptions]="MAT_HELPER.PAGE_SIZE_OPTIONS"
        showFirstLastButtons [length]="length"></mat-paginator>
      </div>

    </div>
  </div>
</div>
