<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">

      <div class="comments-list custom-table">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

          <!-- Position Column -->
          <ng-container matColumnDef="Comment">
            <th mat-header-cell *matHeaderCellDef>Comment</th>
            <td mat-cell *matCellDef="let element" style="padding-left: 20px !important;"> {{element.Comment}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="CommentType">
            <th mat-header-cell *matHeaderCellDef>Comment Type</th>
            <td mat-cell *matCellDef="let element"> {{element.CommentType}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="CommentBy">
            <th mat-header-cell *matHeaderCellDef>Comment By</th>
            <td mat-cell *matCellDef="let element"> {{element.CommentBy}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element"> {{element.Date | transformDate}} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Actions </th>
            <td mat-cell *matCellDef="let row">
              <div fxLayout="row" fxLayoutAlign="center center">
                <button [routerLink]="['/admin/permit-view']" (click)="openDialog(row)" mat-icon-button
                  matTooltip="Click to view" class="iconbutton" color="primary">
                  <mat-icon aria-label="Edit">visibility</mat-icon>
                </button>
              </div>

            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>

    </div>
  </div>
</div>
