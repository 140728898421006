
<!-- **************** FOR FPN MANUAL -->

<div class="permit-details"  *ngIf="dataModel.filterValue == 'MANUAL_FPN'">
    <!--  ROW 1 -->
  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Invoice Reference Number</label>
        <p>{{ dataModel.data.fpnReferenceNumber }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Permit Reference Number</label>
        <p>{{ dataModel.data.permitRefNumber }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">FPN Offence Code</label>
        <p>{{ dataModel.data.offenceCode }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <!-- <label for="">FPN Offence Code</label>
        <p>{{ dataModel.data.offenceCode }}</p> -->
    </div>
  </div>
  <hr>
  <!-- ROW 2 -->
  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Works Location Description</label>
        <p>
            {{ showMoreWork ? (dataModel.data.worksLocationDescription | slice : 0 : 35) :
            dataModel.data.worksLocationDescription }}
            <span class="more-text" (click)="showMoreWorkText()">{{
                getWorkText()
                }}</span>
        </p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Charge type</label>
        <p>{{ dataModel.data.chargeType }}</p>
    </div>

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">FPN Offence Details</label>
        <div>
            <p>
                {{ showMore ? (dataModel.data.fpnOffenceDetails | slice : 0 : 35) : dataModel.data.fpnOffenceDetails }}
                <span class="more-text" (click)="showMoreText()">{{
                    getText()
                    }}</span>
            </p>
        </div>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Charge Details</label>
        <p>
            {{ showMoreChargeDetails ? (dataModel.data.chargeDetails | slice : 0 : 35) : dataModel.data.chargeDetails }}
            <span class="more-text" (click)="showMoreChargeText()">{{
                getChargeText()
                }}</span>
        </p>
    </div>
   </div>
   <hr>
   <!-- ROW 3 -->
   <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Root Cause</label>
        <p>{{ dataModel.data.root_cause }}</p>
    </div>

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Root Cause Additional Information</label>
        <p>{{ dataModel.data.rootCauseDetails }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <!-- <label for="">Root Cause Additional Information</label>
        <p>{{ dataModel.data.rootCauseDetails }}</p> -->
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <!-- <label for="">Root Cause Additional Information</label>
        <p>{{ dataModel.data.rootCauseDetails }}</p> -->
    </div>
   </div>
   <hr>
   <!-- ROW 4 -->
   <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Highway Authority</label>
            <p>{{ dataModel.data.highwayAuthority }}</p>
        </div>

        <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="dataModel.data.is_admin">
            <label for="">Liability Organisation</label>
            <p>{{ dataModel.data.liabilityOrganisation }}</p>
        </div>
        <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Liability Assigned By</label>
            <p>{{ dataModel.data.liabAssignedBy }}</p>
        </div>
    </div>
    <hr>
    <!--  ROW 5 -->
    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start" *ngIf="dataModel.data.is_admin">

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Approved By</label>
            <p>{{ dataModel.data.approved_by }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Approved on behalf of</label>
            <p>{{ dataModel.data.approved_on_behalf }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
          <label for="" *ngIf="dataModel.data.is_admin">Date of Recharge Agreed</label>
          <p *ngIf="dataModel.data.is_admin">
              {{ dataModel.data.date_of_recharge_agreed }}
          </p>
      </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <!-- <label for="">Approved on behalf of</label>
            <p>{{ dataModel.data.approved_on_behalf }}</p> -->
        </div>
    </div>
    <hr>
    <!-- ROW 6 -->
    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Granted Date</label>
            <p>{{ dataModel.data.grantedDate | transformDate }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Issued Date</label>
            <p>{{ dataModel.data.issuedDate | transformDate }}</p>
        </div>
        <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
          <label for="" *ngIf="dataModel.data.is_admin">Liability Status</label>
          <p *ngIf="dataModel.data.is_admin">
              {{ dataModel.data.date_of_invoice }}
          </p>
      </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <!-- <label for="">Issued Date</label>
            <p>{{ dataModel.data.issuedDate | transformDate }}</p> -->
        </div>

    </div>
    <hr>
    <!--  ROW 7 -->
    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start" *ngIf="dataModel.data.is_admin">
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">COAM</label>
            <p>{{ dataModel.data.coam }}</p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Workstream</label>
            <p>{{ dataModel.data.work_stream }}</p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Work Type</label>
            <p>{{ dataModel.data.workType }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Spend Type</label>
            <p>{{ dataModel.data.spendType }}</p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Cost Code</label>
            <p>{{ dataModel.data.costcode }}</p>
        </div>
    </div>
    <hr>
    <!--  ROW 8 -->
    <div class="detail-container" *ngIf="dataModel.data.is_admin" fxLayout="row" fxLayout.lt-sm="row wrap"
        fxLayoutGap="32px" fxLayoutAlign="flex-start">
        <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">BACs Reference</label>
            <p> {{ this.dataModel.data.bacs_ref }} </p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">SAP Payment Date</label>
            <p>{{ dataModel.data.paymentDate | transformDate }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <!-- <label for="">SAP Payment Date</label>
            <p>{{ dataModel.data.paymentDate | transformDate }}</p> -->
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <!-- <label for="">SAP Payment Date</label>
            <p>{{ dataModel.data.paymentDate | transformDate }}</p> -->
        </div>
    </div>
    <hr>
</div>

<!-- *********FOR MANUAL SECTION 74 *********  -->
<div class="permit-details"  *ngIf="dataModel.filterValue == 'MANUAL_SECTION_74'">
    <!--  ROW 1 -->
    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Draft Invoice Reference Number</label>
            <p>{{ dataModel.data.draftInvoiceRefNumber }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Charge Number</label>
            <p>{{ dataModel.data.chargeNumber }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Permit Reference Number</label>
            <p>{{ dataModel.data.permitRefNumber }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <!-- <label for="">Permit Reference Number</label>
            <p>{{ dataModel.data.permitRefNumber }}</p> -->
        </div>
        <!-- <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="" *ngIf="dataModel.data.is_admin">Assigned By</label>
            <p *ngIf="dataModel.data.is_admin">{{ dataModel.data.assignedBy }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="dataModel.data.is_admin">
            <label for="">Assigned To</label>
            <p>{{ dataModel.data.assignedTo }}</p>
        </div> -->
    </div>
    <hr>
    <!--  ROW 2 -->
    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Works Location Description</label>
            <p>
                {{ showMoreWork ? (dataModel.data.worksLocationDescription | slice : 0 : 35) :
                dataModel.data.worksLocationDescription }}
                <span class="more-text" (click)="showMoreWorkText()">{{ getWorkText() }}</span>
            </p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Charge type</label>
            <p>{{ dataModel.data.chargeType }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Charge Details</label>
            <p>
                {{ showMoreChargeDetails ? (dataModel.data.chargeDetails | slice : 0 : 35) : dataModel.data.chargeDetails }}
                <span class="more-text" (click)="showMoreChargeText()">{{ getChargeText() }}</span>
            </p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
          <!-- <label for="">Charge type</label>
          <p>{{ dataModel.data.chargeType }}</p> -->
      </div>
    </div>
    <hr>
    <!--  ROW 3 -->
    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">

      <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Root Cause</label>
        <p>{{ dataModel.data.root_cause }}</p>
      </div>

      <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
          <label for="">Root Cause Additional Information</label>
          <p>{{ dataModel.data.rootCauseDetails }}</p>
      </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Overrun Warning Reasons</label>
            <p>
                {{ showMoreOverrunWarningReasons ? (dataModel.data.overrun_warning_reasons | slice : 0 : 35) :
                dataModel.data.overrun_warning_reasons }}
                <span class="more-text" (click)="showMoreOverrunWarningText()">{{ getOverrunWarningText() }}</span>
            </p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Overrun Warning Details</label>
            <p>
                {{ showMoreOverrunWarningDetails ? (dataModel.data.overrun_warning_details | slice : 0 : 35) :
                dataModel.data.overrun_warning_details }}
                <span class="more-text" (click)="showMoreOverrunText()">{{ getOverrunText() }}</span>
            </p>
        </div>

    </div>
    <hr>
    <!-- ROW 4 -->
    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Highway Authority</label>
            <p>{{ dataModel.data.highwayAuthority }}</p>
        </div>

        <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="dataModel.data.is_admin">
            <label for="">Liability Organisation</label>
            <p>{{ dataModel.data.liabilityOrganisation }}</p>
        </div>
        <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Liability Assigned By</label>
            <p>{{ dataModel.data.liabAssignedBy }}</p>
        </div>
        <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="" *ngIf="dataModel.data.is_admin">Liability Status</label>
            <p *ngIf="dataModel.data.is_admin">
                {{ dataModel.data.date_of_invoice }}
            </p>
        </div>

    </div>
    <hr>
    <!--  ROW 5 -->
    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start" *ngIf="dataModel.data.is_admin">

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Approved By</label>
            <p>{{ dataModel.data.approved_by }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Approved on behalf of</label>
            <p>{{ dataModel.data.approved_on_behalf }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
          <label for="" *ngIf="dataModel.data.is_admin">Date of Recharge Agreed</label>
          <p *ngIf="dataModel.data.is_admin">
              {{ dataModel.data.date_of_recharge_agreed }}
          </p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
          <!-- <label for="">Approved on behalf of</label>
          <p>{{ dataModel.data.approved_on_behalf }}</p> -->
      </div>
        <!-- <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                <label for="">Network</label>
                <p>{{ dataModel.data.network }}</p>
            </div> -->

    </div>
    <hr>
    <!--  ROW 6 -->
    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Granted Date</label>
            <p>{{ dataModel.data.grantedDate | transformDate }}</p>
        </div>

    </div>
    <hr>
    <!--  ROW 7 -->
    <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start"
        *ngIf="dataModel.data.is_admin">
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">COAM</label>
            <p>{{ dataModel.data.coam }}</p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Workstream</label>
            <p>{{ dataModel.data.work_stream }}</p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Work Type</label>
            <p>{{ dataModel.data.workType }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Spend Type</label>
            <p>{{ dataModel.data.spendType }}</p>
        </div>

        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">Cost Code</label>
            <p>{{ dataModel.data.costcode }}</p>
        </div>
    </div>
    <hr>
    <!--  ROW 8 -->
    <div class="detail-container" *ngIf="dataModel.data.is_admin" fxLayout="row" fxLayout.lt-sm="row wrap"
        fxLayoutGap="32px" fxLayoutAlign="flex-start" >
        <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">BACs Reference</label>
            <p> {{ this.dataModel.data.bacs_ref }} </p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <label for="">SAP Payment Date</label>
            <p>{{ dataModel.data.paymentDate | transformDate }}</p>
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <!-- <label for="">SAP Payment Date</label>
            <p>{{ dataModel.data.paymentDate | transformDate }}</p> -->
        </div>
        <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
            <!-- <label for="">SAP Payment Date</label>
            <p>{{ dataModel.data.paymentDate | transformDate }}</p> -->
        </div>
    </div>
    <hr>
</div>
