import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { RateCardListDataModel, RateCardListConfigModel, RateCardListRow, RateCardListRequestModel } from './rate-card-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MASTER_DATA_DOMAIN } from 'src/app/constants/db.constants';

@Component({
  selector: 'app-rate-card-list',
  templateUrl: './rate-card-list.component.html',
  styleUrls: ['./rate-card-list.component.css']
})
export class RateCardListComponent extends WidgetComponentBase implements OnInit {
  @Input() dataModel: RateCardListDataModel;
  @Input() configModel: RateCardListConfigModel;

  dataSource = new MatTableDataSource<RateCardListRow>();
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  searchInput = new FormControl('');
  pageChangeSubscription: any;
  length: any;
  MAT_HELPER = MatTableHelper;
  DATA_HELPER = DataHelper;
  sortFieldLabel: string = '';

  expandedElement: RateCardListRow;
  highwayAuthorityArr: any[] = [];
  index: number = 0;

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _router: Router,
    public dialog: MatDialog,
    private _notificationService: NotificationService,
    private _validationService: ValidationService,
    private _spinner: NgxSpinnerService,
  ) {
    super(_serverApi, _validationService);
    // this.searchInput.valueChanges.subscribe(this.onSearchValueChange);
  }

  getControlData() {
    //let id = this.dataModel.globalParameters.get('applicationId');
    this._spinner.show()
    return this.wgAPIMethodGet(this.dataModel.widgetApiUrl, this.dataModel.apireqdata);
  }
  convertData(response: any) {
    if (response) {
      this._spinner.hide()
      console.log('api response rate card tab', response);
      let items: RateCardListRow[] = [];
      response.forEach((element) => {
        let item: any = {};
        item.rate_id = element.rate_id;
        item.work_type = element.work_type;
        item.paa_cat_012_nts = element.paa_cat012_nts;
        item.permit_cat_012_nts = element.permit_cat012_nts;
        item.variation_cat_012_nts = element.variation_cat012_nts
        item.paa_cat_34_nts = element.paa_cat34_nts;
        item.permit_cat_34_nts = element.permit_cat34_nts;
        item.variation_cat_34_nts = element.variation_cat34_nts;
        item.paa_cat_012_stts = element.paa_cat012_stts;
        item.permit_cat_012_stts = element.permit_cat012_stts;
        item.variation_cat_012_stts = element.variation_cat012_stts;
        item.paa_cat_34_stts = element.paa_cat34_stts;
        item.permit_cat_34_stts = element.permit_cat34_stts;
        item.variation_cat_34_stts = element.variation_cat34_stts
        item.created_by = element.created_by;
        item.created_date = element.create_date;
        item.updated_by = element.updated_by;
        item.updated_date = element.update_date;
        item.isSliced = true;
        items.push(item);
      });
      this.dataSource = new MatTableDataSource(items);
      this.length = response.totalElements;
    }
  }
  setFieldData() {}

  setMode(responseDataModel: any) {}

  setValue(responseDataModel: any) {}

  applyFilter(filterValue: string) {}

  ngOnInit() {
    // this.dataSource.paginator = this.paginator;
    // this.pageChangeSubscription = this.paginator.page.subscribe(() => {
    //   this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
    //   this.dataModel.apireqdata.top = this.paginator.pageSize;
    //   this.dataModel.apireqdata.skip =
    //     this.dataModel.apireqdata.pageno *
    //     this.dataModel.apireqdata.top;
    //   //  TODO: will update this
    //   this.wgRefreshData();
    // });

    // TODO: attach local handlers
    this.wgOnInit();
    // console.log('onInit', this.dataSource);
  }
  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      if (this.sort.direction) {
        this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();

        switch (this.sort.active) {
          case 'InterfaceName':
            this.sortFieldLabel = 'INTERFACE_NAME';
            break;
          case 'Status':
            this.sortFieldLabel = 'STATUS';
            break;
          // case 'CreatedBy':
          //   this.sortFieldLabel = 'UPDATED_BY';
          //   break;
          case 'Date':
            this.sortFieldLabel = 'CREATE_DATE';
            break;
          default:
            break;
        }

        this.dataModel.apireqdata.sortField = this.sortFieldLabel;

        this.wgRefreshData();
      }
      else {
        try {
          delete this.dataModel.apireqdata.sortOrder;
          delete this.dataModel.apireqdata.sortField;
        }
        catch (err) {
        }
      }
    });
  }
  onViewClick(rowData) {
    rowData.mode = 'View'
    console.log(rowData)
    this.emitEvent('onViewClick', rowData);
  }

  onEditClick(rowData) {
    rowData.mode = 'Edit'
    this.emitEvent('onEditClick', rowData);
  }

  search() {
    let searchString = this.searchInput.value;
    this.dataModel.apireqdata.searchString = searchString;
    this.wgRefreshData()
  }

  ngOnDestroy(): void {
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
  }

  clearSearch() {
    this.searchInput.reset();
    this.dataModel.apireqdata = new RateCardListRequestModel()
    this.wgRefreshData()
  }

  onSearchValueChange(searchVal) {
    let clearSearch = document.getElementsByClassName('clear-search');
    let slide: any;
    for (let i = 0; i < clearSearch.length; i++) {
      slide = clearSearch[i] as HTMLElement;
    }
    if (searchVal) {
      slide.style.visibility = 'visible';
    } else {
      slide.style.visibility = 'hidden';
    }
  }

    // Minimize Functionaltity
    expandRow(rowData, i) {
      if (rowData.isSliced == true) {
        this.dataSource.data[i].isSliced = false;
      } else {
        this.dataSource.data[i].isSliced = true;
      }
    }

    showMore(value, isSliced) {
      if (value) {
        if (value.length > 100 && !!isSliced) {
          return '...more';
        } else if (value.length < 100 && !!isSliced) {
          return;
        } else {
          return '...less';
        }
      }
    }

    getStatusCssClass(element) {
      switch (element) {
        case 'SUCCESS':
          return 'success-status';
        case 'Success with Warnings':
          return 'successwithwarnings-status';
        case 'FAILED':
          return 'failed-status';
        case 'DELETED':
          return 'deleted-status';
        default:
          return '';
      }
    }

    callAPIAgain() {
      this.highwayAuthorityArr = this._appRepoHelperService.getMDataByDomain(MASTER_DATA_DOMAIN.HIGHWAY_AUTORITIES.toString());
      this.index = this.index + 1;
      console.log('index>>>', this.index);
      const highwayAuth = this.highwayAuthorityArr[this.index].authorityId;
      this.dataModel.apireqdata.highwayAuthority = highwayAuth;
      this.wgRefreshData();
    }

    splitString(text) {
      const index = text.indexOf('(');

      if (index !== -1) {
        return `${text.substring(0, index)}<br>${text.substring(index)}`;
      }

      return text;

    }
}
