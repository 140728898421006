<div class="text-center mb-2 headingtitle ">
  Permits Distribution
</div>
<div class="col-md-12  pr-md-0 d-flex justify-content-between align-items-center border-grey">
  <div class="font-weight-bold py-4 ">Charges Processed</div>
  <div class="total-tab">

    <span class="black-tab">{{this.dataModel.total}}</span>
  </div>
</div>
<div class="row align-items-center">
  <div class="col-md-12 mt-5">
    <div id="equalizer" class="equalizer" style="font-size: 12px; font-weight: 500;" fxLayoutGap="10px">

    </div>
  </div>
</div>
