<div class="row">
  <div class="col-md-12 tableColor">
    <div class="permit-list custom-table low-border-padding pb-3 my-3 px-2" style="overflow: auto;">
      <div id="htmlData" #htmlData>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
          <!-- Position Column -->
          <ng-container matColumnDef="tag_matrix">
            <th mat-header-cell *matHeaderCellDef class="iconColWidth120 text-center">To Do</th>
            <td mat-cell class="text-center" *matCellDef="let element">
              <div *ngIf="element.tag_matrix.length">
                <!-- {{element.tag_matrix.length}} -->
                <span *ngFor="let item of element.tag_matrix">
                  <mat-icon [style.color]="item.color_code" [matTooltip]="item.helper_text">{{item.resource_name}}
                  </mat-icon>
                </span>
                <!-- <mat-icon class="icon-color" matTooltip="OPEN_DEFECT" style="color:red">bug_report</mat-icon>
                                          <mat-icon class="icon-color" matTooltip="FPN" style="color:green">file_present</mat-icon>
                                          <mat-icon class="icon-color" matTooltip="OPEN_PMR" style="color:aqua">lock_open</mat-icon>
                                          <mat-icon class="icon-color" matTooltip="REGISTRATION_OVERDUE" style="color:gray">gavel</mat-icon>
                                          <mat-icon class="icon-color" matTooltip="WORKS_START_DUE" style="color: blueviolet;">person</mat-icon>
                                          <mat-icon class="icon-color" matTooltip="REFUSED" style="color:brown">no_accounts</mat-icon>
                                          <mat-icon class="icon-color" matTooltip="WORKS_STOP_DUE" style="color: firebrick;">work_off</mat-icon> -->
                <!-- <mat-icon class="icon-color" matTooltip="UNREAD_COMMENT" style="color:gold">analytics</mat-icon> -->
                <!-- <img src="assets/images/PastedGraphic-4.png" alt="" class="CollaborativeImg" -->
              </div>
            </td>
          </ng-container>
          <!-- Position Column -->

          <!-- Position Column -->
          <!-- <ng-container matColumnDef="CollaborativeWorkingFlag">
                        <th mat-header-cell *matHeaderCellDef>Claim</th>
                        <td mat-cell *matCellDef="let element" class="CollaborativeCell">
                        <td mat-cell *matCellDef="let element">
                            <div>
                                <mat-icon class="icon-color" *ngIf="element.system_claim_status != 'UNCLAIMED'">person_add</mat-icon>
                                <mat-icon class="icon-color" *ngIf="element.system_claim_status == 'UNCLAIMED'">person</mat-icon>
                                <img src="assets/images/PastedGraphic-4.png" alt="" class="CollaborativeImg">
                            </div>
                        </td>
                    </ng-container> -->
          <!-- Position Column -->

          <ng-container matColumnDef="InvoiceRefNumber">
            <th mat-header-cell *matHeaderCellDef class=" word-break">Draft Invoice Ref Number
            </th>
            <!-- <td mat-cell *matCellDef="let element">
                            Z000123 </td> -->
            <td mat-cell *matCellDef="let element">
              {{element.draft_invoice_reference_number}} </td>
          </ng-container>

          <ng-container matColumnDef="PermitRefNumber">
            <th mat-header-cell *matHeaderCellDef class=" word-break"> Final Invoice Ref Number
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.final_invoice_reference_number}} </td>
          </ng-container>

          <ng-container matColumnDef="ChargeNo">
            <th mat-header-cell *matHeaderCellDef class="word-break">Charge Number</th>
            <td mat-cell *matCellDef="let element" class=" word-break">
              {{element.charge_number}} </td>
          </ng-container>
          <!-- Position Column -->

          <ng-container matColumnDef="streetManagerStatus">
            <th mat-header-cell *matHeaderCellDef class="word-break">Street Manager Status</th>
            <td mat-cell *matCellDef="let element" class=" word-break">
              {{element.street_manager_status}} </td>
          </ng-container>

          <ng-container matColumnDef="createddate">
            <th mat-header-cell *matHeaderCellDef class="ColWidth text-center"> Date of Estimated Charge</th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{element.createddate | transformDate}}</td>
          </ng-container>
          <ng-container matColumnDef="inspectionCategory">
            <th mat-header-cell *matHeaderCellDef class="word-break">Inspection Category</th>
            <td mat-cell *matCellDef="let element" class=" word-break">
              {{element.inspection_category}} </td>
          </ng-container>

          <ng-container matColumnDef="inspectionType">
            <th mat-header-cell *matHeaderCellDef class="word-break">Inspection Type</th>
            <td mat-cell *matCellDef="let element" class=" word-break">
              {{element.inspection_type}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="WorksLocationDescription">
            <th mat-header-cell *matHeaderCellDef class="word-break"> Location </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex" class="work-location-description">
              <!-- {{element.WorksLocationDescription}} -->
              {{element.isSliced ? (element.WorksLocationDescription | slice:0:25) : element.WorksLocationDescription }}
              <!-- <span class="more-text" *ngIf="showMore(element.workWorksLocationDescription, element.isSliced)">..more</span> -->
              <span class="more-text" (click)="expandRow(element, i)">{{showMore(element.workWorksLocationDescription,
                element.isSliced)}}</span>
            </td>
          </ng-container>
          <!-- Position Column -->
          <!-- <ng-container matColumnDef="WorkDescription">
                            <th mat-header-cell *matHeaderCellDef class="word-break"> Work Description </th>
                            <td mat-cell *matCellDef="let element"> {{element.WorkDescription}} </td>
                          </ng-container> -->
          <!-- Position Column -->
          <ng-container matColumnDef="WorksType">
            <th mat-header-cell *matHeaderCellDef class="text-center word-break"> Charge Type </th>
            <td mat-cell *matCellDef="let element" class="colalign"> {{element.charge_type}} </td>
          </ng-container>
          <!-- Position Column -->

          <!-- Position Column -->
          <ng-container matColumnDef="HighwayAuthority">
            <th mat-header-cell *matHeaderCellDef class="word-break"> Highway Authority
            </th>
            <td mat-cell *matCellDef="let element"> {{element.highway_authority}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="liabilityStatus">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Liability Status </th>
            <td mat-cell *matCellDef="let element">
              <span class="charge-status"
                [ngClass]="getStatusColor(element.liabilityStatus)">{{element.liabilityStatus}}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="assignedBy">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Assigned By </th>
            <td mat-cell *matCellDef="let element">
              {{element.assignedBy}}
            </td>
          </ng-container>
          <ng-container matColumnDef="liabilityOrganization">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Liability Organization </th>
            <td mat-cell *matCellDef="let element">
              {{element.liabilityOrganization}}
            </td>
          </ng-container>
          <ng-container matColumnDef="liabilityAssignedDate">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Liability Assigned Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.liabilityAssignedDate | transformDate}}
            </td>
          </ng-container>
          <ng-container matColumnDef="liabilityAcceptedDate">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Liability Accepted Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.liabilityAcceptedDate | transformDate}}
            </td>
          </ng-container>
          <ng-container matColumnDef="InvoiceSentDate">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Invoice Sent Date
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{element.invoiceSentDate | transformDateTime}}
            </td>
          </ng-container>
          <ng-container matColumnDef="CreatedDate">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Created Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.createdDate | transformDateTime}}
            </td>
          </ng-container>
          <ng-container matColumnDef="ChargesCount">
            <th mat-header-cell *matHeaderCellDef class="word-break text-center"> Charges Count
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{element.chargesCount}}
            </td>
          </ng-container>
          <ng-container matColumnDef="InvoiceAmountPaidDate">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Invoice Paid Date
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{element.invoiceAmountPaidDate | transformDateTime}}
            </td>
          </ng-container>
          <ng-container matColumnDef="responseDueDate">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Response Due Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.responseDueDate | transformDate}}
            </td>
          </ng-container>
          <ng-container matColumnDef="responseRecievedDate">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Response Recieved Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.responseRecievedDate | transformDate}}
            </td>
          </ng-container>
          <ng-container matColumnDef="TotalAmount">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Total Invoice Amount </th>
            <td mat-cell *matCellDef="let element"> &pound;
              {{element.TotalAmount}}
            </td>
          </ng-container>
          <ng-container matColumnDef="CostRisk">
            <th mat-header-cell *matHeaderCellDef class="word-break" appHelperTextPopover
              [helperTextData]="getHelpTextData('CostRisk')" class="iconColWidth"> Cost Risk </th>
            <td mat-cell *matCellDef="let element">
              <img class="flag-image" [src]="DATA_HELPER.getPermitIconURL(element.CostRisk)">
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="CustomerRisk">
            <th mat-header-cell *matHeaderCellDef class="word-break" appHelperTextPopover
              [helperTextData]="getHelpTextData('CustomerRisk')" class="iconColWidth"> Customer Risk </th>
            <td mat-cell *matCellDef="let element"><img class="flag-image"
                [src]="DATA_HELPER.getPermitIconURL(element.CustomerRisk)"></td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="TrafficManagement">
            <th mat-header-cell *matHeaderCellDef class="word-break" appHelperTextPopover
              [helperTextData]="getHelpTextData('TrafficManagement')" class="iconColWidth"> Traffic Sensitive
            </th>
            <td mat-cell *matCellDef="let element"><img class="flag-image"
                [src]="DATA_HELPER.getPermitIconURL(element.TrafficManagement)"></td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="DeemedDate">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Deemed Date </th>
            <td mat-cell *matCellDef="let element"> {{element.DeemedDate | transformDate}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="StartDate">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Start Date </th>
            <td mat-cell *matCellDef="let element"> {{element.StartDate | transformDate}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="EndDate">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> End Date</th>
            <td mat-cell *matCellDef="let element"> {{element.EndDate | transformDate}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="Claim">
            <th mat-header-cell *matHeaderCellDef>Claim</th>
            <!-- <td mat-cell *matCellDef="let element" class="CollaborativeCell"> -->
            <td mat-cell *matCellDef="let element">
              <div (click)="disableexpand=true;openunclaimeDilog(element)">
                <mat-icon style="cursor: pointer;" [ngClass]="claimIconClass(element)"
                  *ngIf="element.system_claim_status=='Claimed'">person_add</mat-icon>
              </div>

              <div (click)="disableexpand=true;openclaimeDialog(element)">
                <mat-icon style="cursor: pointer;"
                  *ngIf="element.system_claim_status==null || element.system_claim_status=='Unclaimed'">person_add
                </mat-icon>
              </div>
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="EstimateCharges">
            <th mat-header-cell *matHeaderCellDef class="word-break text-center"> Estimated Charges</th>
            <td mat-cell *matCellDef="let element" class="colalign"> &pound; {{element.estimated_charges}} </td>
          </ng-container>
          <!-- Position Column -->

          <!-- Position Column -->
          <ng-container matColumnDef="InvoiceCharges">
            <th mat-header-cell *matHeaderCellDef class="word-break text-center">Draft Invoice Charges
            </th>
            <td mat-cell *matCellDef="let element" class="colalign"> &pound; {{element.draft_invoice_charges}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="Reference">
            <th mat-header-cell *matHeaderCellDef class=" word-break" mat-sort-header>Recharge Invoice
              Reference</th>
            <td mat-cell *matCellDef="let element" class="">
              <!-- <mat-icon class="split-charge"> subdirectory_arrow_right </mat-icon> {{element.Reference}} -->
              {{element.Reference}}
            </td>
          </ng-container>
          <ng-container matColumnDef="InvoivePeriod">
            <th mat-header-cell *matHeaderCellDef class=" word-break" mat-sort-header> Invoice
              Period</th>
            <td mat-cell *matCellDef="let element" class="">
              <!-- <mat-icon class="split-charge"> subdirectory_arrow_right </mat-icon> {{element.Reference}} -->
              {{element.invoicePeriod}}
            </td>
          </ng-container>

          <ng-container matColumnDef="FinalInvoiceReference">
            <th mat-header-cell *matHeaderCellDef class="pRNColWidth120 word-break" mat-sort-header>Final
              Invoice
              Reference</th>
            <td mat-cell *matCellDef="let element" class="pIRNColWidth350">
              {{element.FinalInvoiceReference}}
            </td>
          </ng-container>

          <ng-container matColumnDef="UploadDate">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header>Invoice Date</th>
            <td mat-cell *matCellDef="let element">
              {{element.UploadDate | transformDate}}
            </td>
          </ng-container>

          <ng-container matColumnDef="AuthorityName">
            <th mat-header-cell *matHeaderCellDef class="word-break"> Highway Authority </th>
            <td mat-cell *matCellDef="let element">
              {{element.AuthorityName}}
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="AggreedChargs">
            <th mat-header-cell *matHeaderCellDef class="word-break text-center"> Agreed Charges</th>
            <td mat-cell *matCellDef="let element" class="colalign"> &pound; {{element.agreed_charges}}
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="ChargeType">
            <th mat-header-cell *matHeaderCellDef class="word-break text-center"> Charge Type </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{element.ChargeType}}
            </td>
          </ng-container>
          <ng-container matColumnDef="rechargeAmount">
            <th mat-header-cell *matHeaderCellDef class="word-break text-center"> Recharge Amount</th>
            <td mat-cell *matCellDef="let element" class="text-center">
              &pound; {{element.rechargeAmount}}
            </td>
          </ng-container>
          <ng-container matColumnDef="remainingDays">
            <th mat-header-cell *matHeaderCellDef class="word-break text-center" mat-sort-header> Remaining Days</th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{element.remaining_days }}
            </td>
          </ng-container>
          <ng-container matColumnDef="LiabilityAmount">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Liability Amount</th>
            <td mat-cell *matCellDef="let element">
              &pound; {{element.liabilityAmount}}
            </td>
          </ng-container>
          <ng-container matColumnDef="rechargeDate">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Recharge Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.rechargeDate | transformDate}}
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="matched">
            <th mat-header-cell *matHeaderCellDef class="word-break text-center" mat-sort-header> Charges Validity</th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <!-- <mat-icon class="icon-color">task_alt</mat-icon> -->
              <mat-icon
                [ngClass]="{'icon-color-red': element.chargeValidity == 0, 'icon-color-green': element.chargeValidity ==  1}">flag</mat-icon>
            </td>
          </ng-container>
          <!-- Position Column -->

          <ng-container matColumnDef="PermitStatus">
            <th mat-header-cell *matHeaderCellDef class="pRNColWidth120 word-break text-center" mat-sort-header>Charge
              Status </th>
            <td mat-cell *matCellDef="let element"> <span class="charge-status"
                [ngClass]="getStatusColor(element.PermitStatus)">{{element.PermitStatus}}</span> </td>
          </ng-container>
          <ng-container matColumnDef="EstimatedStatus">
            <th mat-header-cell *matHeaderCellDef class="pRNColWidth120 word-break text-center" mat-sort-header>Charge
              Status
            </th>
            <td mat-cell *matCellDef="let element"> <span class="charge-status inprogress">{{element.cost_type}}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="WorkStatus">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Work Status </th>
            <td mat-cell *matCellDef="let element">
              <p [ngClass]="DATA_HELPER.getWorkStatusClass(element.WorkStatus)">
                {{element.WorkStatusValue}}
              </p>
            </td>
          </ng-container>

          <ng-container matColumnDef="liabilityOrganisation">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Network </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{element.network}}
            </td>
          </ng-container>

          <ng-container matColumnDef="offenseCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Offence Code </th>
            <td mat-cell *matCellDef="let element">
              {{element.offenceCode}}
            </td>
          </ng-container>

          <!-- Minimize Functionaltity -->
          <ng-container matColumnDef="offenseDetails">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Offence Details </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex" style="max-width: 150px;">
              {{element.isSliced ? (element.offenceDetails | slice:0:25) : element.offenceDetails }}
              <!-- <span class="more-text" *ngIf="showMore(element.offenceDetails, element.isSliced)">..more</span> -->
              <span class="more-text" (click)="expandRow(element, i)">{{showMore(element.offenceDetails,
                element.isSliced)}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="issuedDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Issued Date </th>
            <td mat-cell *matCellDef="let element">
              {{element.createddate | transformDate}}
            </td>
          </ng-container>

          <ng-container matColumnDef="overrunWarningReason">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Overrun Warning Reason </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex" class="text-center" style="max-width: 150px;">
              <!-- {{element.overrunWarningReason}} -->
              {{element.isSliced ? (element.overrunWarningReason | slice:0:25) : element.overrunWarningReason }}
              <!-- <span class="more-text" *ngIf="showMore(element.overrunWarningReason, element.isSliced)">..more</span> -->
              <span class="more-text" (click)="expandRow(element, i)">{{showMore(element.overrunWarningReason,
                element.isSliced)}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="failureReason">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Failure Reasons </th>
            <td mat-cell *matCellDef="let element">
              {{element.failureReason}}
            </td>
          </ng-container>

          <ng-container matColumnDef="inspectionLoggedDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Inspection Logged Date </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{element.createddate | transformDate}}
            </td>
          </ng-container>

          <ng-container matColumnDef="Checkbox">
            <th mat-header-cell *matHeaderCellDef style="width: 100px; text-align: center;">
              <span class="d-flex align-items-center justify-content-center">
                <!-- <mat-checkbox [checked]="selectAllCheckbox" (change)="selectAll()"></mat-checkbox> -->
                <p (click)="selectAll()"
                  style="margin-bottom: 0; font-size: 1.2rem; margin-left: 5px; cursor: pointer;text-decoration: underline;"
                  matTooltip="Click to select all charges.">{{checkboxlabel}}</p>
              </span>
            </th>
            <td mat-cell *matCellDef="let element; let index = dataIndex" style="text-align: center;">
              <mat-checkbox [checked]="element.isChecked"
                (change)="onCheckboxClick(index, $event.checked)"></mat-checkbox>
            </td>
          </ng-container>


          <ng-container matColumnDef="AlterationStatus">
            <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Alteration Status
            </th>
            <td mat-cell *matCellDef="let element"> {{element.alteration_status}} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="word-break" style="text-align: center;">Action
            </th>
            <td mat-cell *matCellDef="let row; let i = dataIndex;">
              <div (click)="stopPropagation($event)" fxLayout="row" fxLayoutAlign="center center">
                <button (click)="onViewClick(row,i)" mat-icon-button matTooltip="Click to view" class="iconbutton"
                  color="primary">
                  <mat-icon aria-label="Edit">visibility</mat-icon>
                </button>
                <div *ngIf="checkEditPermitEnable(PERMIT_ACTIONS_CONFIG.EDIT_PERMIT,row)">
                  <button mat-icon-button matTooltip="Click to Edit" class="iconbutton" color="warn"
                    (click)="onEditPermitIconClick($event, row)">
                    <mat-icon aria-label="Delete">edit</mat-icon>
                  </button>
                </div>
                <div *ngIf="checkEditPermitEnable(PERMIT_ACTIONS_CONFIG.DELETE_PERMIT,row)">
                  <button mat-icon-button matTooltip="Click to Delete" class="iconbutton" color="warn"
                    (click)="onDeletePermitIconClick($event, row)">
                    <mat-icon aria-label="Delete">delete</mat-icon>
                  </button>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="navigation">
            <th mat-header-cell *matHeaderCellDef class="word-break" style="text-align: center;">Action
            </th>
            <td mat-cell *matCellDef="let row; let i = dataIndex;">
              <div (click)="stopPropagation($event)" fxLayout="row" fxLayoutAlign="left center">
                <button (click)="onArrowClick(row,i)" mat-icon-button matTooltip="Click to view" class="iconbutton"
                  color="primary" *ngIf="isButtonAvailable(row.actionArr, 'VIEW_INVOICE')">
                  <mat-icon aria-label="Edit">visibility</mat-icon>
                </button>
                <div *ngIf="isButtonAvailable(row.actionArr, 'DOWNLOAD_INVOICE')">
                  <button mat-icon-button matTooltip="Click to Download" class="iconbutton" color="warn"
                    (click)="onDownloadClick(row,i)">
                    <mat-icon aria-label="Delete">download</mat-icon>
                  </button>
                </div>
                <div *ngIf="isButtonAvailable(row.actionArr, 'DELETE_INVOICE')">
                  <button mat-icon-button matTooltip="Click to Delete" class="iconbutton" color="warn"
                    (click)="onDeleteClick(row,i)">
                    <mat-icon aria-label="Delete">delete</mat-icon>
                  </button>
                </div>
                <div *ngIf="isButtonAvailable(row.actionArr, 'SET_INVOICE_PAID')">
                  <button mat-icon-button matTooltip="Click to View Charges" class="iconbutton" color="warn"
                    (click)="onCurrencyClick(row,i)">
                    <mat-icon aria-label="Delete">currency_pound</mat-icon>
                  </button>
                </div>
              </div>

            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td class="w-100" mat-cell *matCellDef="let element" [attr.colspan]="dataModel.columnsToDisplay.length">
              <div class="element-detail w-100" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="element-wrapper">
                  <div class="detail-container" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="info-element">
                      <label for="">Works Reference Number</label>
                      <p>{{element.WorksReferenceNumber}}</p>
                    </div>
                    <div class="info-element">
                      <label for="">Highway Authority</label>
                      <p>{{element.HighwayAuthority}}</p>
                    </div>
                    <div class="info-element">
                      <label for="">Workstream</label>
                      <p>{{element.Workstream}}</p>
                    </div>
                    <div class="info-element">
                      <label for="">Promoter Prefix</label>
                      <p>{{element.PromoterPrefix}}</p>
                    </div>
                    <div class="info-element">
                      <label for="">Promoter Organisation</label>
                      <p>{{element.PromoterOrganization}}</p>
                    </div>
                    <div class="info-element">
                      <label for="">Project Reference Number</label>
                      <p>{{element.ProjectReferenceNumber}}</p>
                    </div>
                    <div class="info-element">
                      <label for="">Contact</label>
                      <p>{{element.Contact}}</p>
                    </div>
                    <div class="info-element">
                      <label for="">Proposed Duration</label>
                      <p>{{element.ProposedDuration}}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="detail-container" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="info-element">
                      <label for="">Road Type</label>
                      <p>{{element.RoadType}}</p>
                    </div>
                    <div class="info-element">
                      <label for="">Traffic Sensitive</label>
                      <p>{{DATA_HELPER.booleanToUserText(element.TrafficSensitive)}}</p>
                    </div>
                    <div class="info-element">
                      <label for="">Footway Closure</label>
                      <p>{{element.FootwayClosureDisplayText}}</p>
                    </div>
                    <div class="info-element">
                      <label for="">Excavation Required</label>
                      <p>{{DATA_HELPER.booleanToUserText(element.ExcavationRequired)}}</p>
                    </div>
                    <div class="info-element">
                      <label for="">Is Lane Rental Applicable?</label>
                      <p>{{DATA_HELPER.booleanToUserText(element.IsLaneRentalApplicable)}}</p>
                    </div>
                    <div class="info-element">
                      <label for="">Traffic Management Required</label>
                      <p>{{element.TrafficManagReqDisplayText}}</p>
                    </div>
                    <div class="info-element">
                      <label for="">Cost</label>
                      <p> &pound; {{element.Cost}}</p>
                    </div>

                    <!-- <div class="info-element">
                                                      <label for="">Deemed Date</label>
                                                      <p>{{element.DeemedDate | transformDate}}</p>
                                                  </div> -->
                    <!-- <ng-container matColumnDef="DeemedDate">
                                  <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Deemed Date </th>
                                  <td mat-cell *matCellDef="let element"> {{element.DeemedDate | transformDate}} </td>
                              </ng-container> -->

                  </div>

                  <div class="detail-container" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="info-element">
                      <label for="">Work Description</label>
                      <p>{{element.WorkDescription}}</p>
                    </div>

                  </div>



                  <!-- <div class="row">
                                                  <div class="col-2">
                                                      <div fxLayout="row wrap" style="flex-flow: wrap !important;   width: 100%;
                                                      max-width: 100%;" fxLayoutGap="30">
                                                          <div>asdfsadfsaf</div>
                                                          <div>asdfsadfsaf</div>
                                                          <div>asdfsadfsaf</div>
                                                          <div>asdfsadfsaf</div>
                                                          <div>asdfsadfsaf</div>
                                                          <div>asdfsadfsaf</div>
                                                          <div>asdfsadfsaf</div>
                                                          <div>asdfsadfsaf</div>
                                                          <div>asdfsadfsaf</div>
                                                          <div>asdfsadfsaf</div>
                                                          <div>asdfsadfsaf</div>
                                                          <div>asdfsadfsaf</div>
                                                          <div>asdfsadfsaf</div>
                                                          <div>asdfsadfsaf</div>
                                                      </div>
                                                  </div>
                                                  <div class="col"></div>
                                                  <div class="col"></div>
                                              </div> -->

                  <hr *ngIf="element.inactive_app_list?.length > 0">
                  <div class="row" *ngIf="element.inactive_app_list?.length > 0">
                    <div class="col-12 mb-2">
                      <div class="info-element">
                        <label for=""> Previous Permit(s)</label>

                      </div>
                    </div>
                    <div class="col-12">
                      <div fxLayout="row wrap" style="flex-flow: wrap !important;   width: 100%;  max-width: 100%;"
                        fxLayoutGap="30px">
                        <div class="statusbox" *ngFor="let item of element.inactive_app_list" fxFlex="12"
                          fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                          <div [hidden]="item.applistStatus==null"
                            [ngClass]="{'cancelstatusborder':item.applistStatus=='Cancelled', 'closedstatusborder':item.applistStatus=='Closed', 'submittedstatusborder':item.applistStatus=='Submitted', 'refusedstatusborder':item.applistStatus=='Refused', 'revokedstatusborder':item.applistStatus=='Revoked',  'grantedstatusborder':item.applistStatus=='Granted' }"
                            (click)="onInactivePermitViewClick(item)"
                            style=" padding:10px; padding-top: 5px; width:100%; margin-top: 15px; height: 40px;">
                            <span class="border-0 info-element permintnumberlink">
                              <!-- <label for="">Permit Reference Number</label> -->
                              {{item.permitRefNo}}
                            </span>

                            <span class="border-0 info-element" style="font-size: 9px;">
                              <!-- <label for="">Permit Status</label> -->
                              {{item.applistStatus}}
                            </span>
                          </div>


                        </div>




                      </div>

                    </div>
                    <!-- <div class="col-6"></div> -->
                  </div>




                </div>



              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="dataModel.columnsToDisplay"></tr>
          <!-- <tr mat-row *matRowDef="let element; columns: dataModel.columnsToDisplay;" class="detail-element-row" [class.example-expanded-row]="expandedElement === element" (click)="onExpandCollapse(element)"> -->
          <!-- <tr mat-row *matRowDef="let element; columns: dataModel.columnsToDisplay; let i = dataIndex"> -->
          <tr mat-row *matRowDef="let element; columns: dataModel.columnsToDisplay; let i = dataIndex"
            class="detail-element-row" [class.example-expanded-row]="expandedElement === element">
          </tr>
          <ng-container *ngIf="!dataModel.removeExpansion">
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
          </ng-container>
        </table>
      </div>


      <mat-paginator [pageSize]="MAT_HELPER.PAGE_SIZE" [pageSizeOptions]="MAT_HELPER.PAGE_SIZE_OPTIONS"
        showFirstLastButtons [length]="length" [pageIndex]="pageIndex"></mat-paginator>

    </div>
  </div>
</div>