import {
  ChargeOverviewConfigModel,
  ChargeOverviewModel,
} from '../charge-overview-tab/charge-overview-tab.model';
import { Component, Input, OnInit } from '@angular/core';
import { ManualChargeOverviewConfigModel, ManualChargeOverviewModel } from './manual-charge-overview.model';

import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { MatDialog } from '@angular/material/dialog';
import { PERMIT_ACTIONS_CONFIG } from 'src/app/constants/db.constants';
import { SaveFileService } from 'src/app/services/save-file.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { TransformDatePipe } from 'src/app/pipe/transform-date/transform-date.pipe';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';

@Component({
  selector: 'app-manual-charge-overview-tab',
  templateUrl: './manual-charge-overview-tab.component.html',
  styleUrls: ['./manual-charge-overview-tab.component.css']
})
export class ManualChargeOverviewTabComponent  extends WidgetComponentBase
implements OnInit
{
@Input() dataModel: ManualChargeOverviewModel;
@Input() configModel: ManualChargeOverviewConfigModel;

DATA_HELPER = DataHelper;
PERMIT_ACTIONS_CONFIG = PERMIT_ACTIONS_CONFIG;

showIcon: boolean = false;
showMore: boolean = true;
showMoreWork: boolean = true;
isDraftPermitView: boolean = false;
reasonNotAccepted: string;
liabiltyOrg_arr: any[] = [];
showMoreChargeDetails: boolean = true;
showMoreOverrunWarningDetails: boolean = true;
showMoreOverrunWarningResons: boolean = true;

constructor(
  private _serverApi: ServerApiInterfaceServiceService,
  private _appRepoHelperService: AppRepoHelperService,
  private _validationService: ValidationService,
  private _saveFileService: SaveFileService,

  public dialog: MatDialog
) {
  super(_serverApi, _validationService);
}

ngOnInit(): void {
  super.wgOnInit();

  console.log('overview dataModel', this.dataModel);
}

setFieldData() {}

setMode(responseDataModel: any) {}
setValue(responseDataModel: any) {}
getControlData() {
  // let id = this.dataModel.globalParameters.get('applicationId');
  // // INFO: ServerAPI
  // let url = `/api/v1/applications/${id}`
  // return this.wgAPIMethodGet(url, null);
  return new Promise((resolve, reject) => {
    let permitform: any = this.dataModel.globalParameters.get(
      GLOBAL_PARAM_KEY.VIEW_PERMIT_FORM
    );
    permitform = permitform ? permitform : new Object();
    if (permitform) {
      resolve(permitform);
    } else {
      reject(permitform);
    }
  });
}

convertData(response: any) {
  if (!response) return;

  console.log('respoine of overview issued', response);
  this.dataModel.data.liabAssignedBy = response.liability_assigned_by;
  this.dataModel.data.bacs_ref = response.clearing_doc_number;
  this.dataModel.data.paymentDate = response.payment_date;
  this.dataModel.data.workstream = response.workstream;

  this.dataModel.data.fpnReferenceNumber = response.fpn_reference_number;
  this.dataModel.data.permitRefNumber = response.permit_reference_number;
  this.dataModel.data.invoiceRefNumber = response.invoice_reference_number;
  this.dataModel.data.draftInvoiceRefNumber = response.draft_invoice_number;
  this.dataModel.data.is_admin = response.is_admin;
  this.dataModel.data.chargeNumber = response.charge_number;
  this.dataModel.data.charge_sub_type = response.charge_sub_type;
  this.dataModel.data.offenceCode = response.fpn_offence_code;
  this.dataModel.data.worksLocationDescription =
    response.works_location_description;
  this.dataModel.data.usrnDetails = response.usrn_details;
  this.dataModel.data.fpnOffenceDetails = response.fpn_offence_details;
  const transformDatePipe = new TransformDatePipe(null);
  this.dataModel.data.date_of_invoice = response.date_of_invoice;
  this.dataModel.data.date_of_invoice.join(', ');
  this.dataModel.data.date_of_recharge_agreed =
    response.date_of_recharge_agreed;
  this.dataModel.data.date_of_recharge_agreed.join(', ');
  this.dataModel.data.doa_approved_to = response.request_approval_assigned_to;
  this.dataModel.data.doa_approved_by = response.request_approval_assigned_by;
  this.dataModel.data.approved_on_behalf = response.approved_on_behalf;
  this.dataModel.data.approved_by = response.approved_by;
  this.dataModel.data.root_cause = response.root_cause;
  this.dataModel.data.rootCauseDetails = response.root_cause_details;

  this.dataModel.data.reason_invalid = response.reason_invalid;
  // this.dataModel.data.liabilityOrganisation = response.liability_organisation;
  this.liabiltyOrg_arr = [];
  response.liability_organisation?.forEach((ele) => {
    this.liabiltyOrg_arr.push(ele.contractor_name);
  });

  this.dataModel.data.liabilityOrganisation = this.liabiltyOrg_arr.join(', ');
  this.dataModel.data.chargeDetails = response.charge_details;
  this.dataModel.data.roadCategoryType1 = response.road_category;
  this.dataModel.data.network = response.network;
  this.dataModel.data.ha_status = response.ha_status;
  this.dataModel.data.promoter_status = response.promoter_status;
  this.dataModel.data.overrun_warning_details = response.overrun_warning_details;
  this.dataModel.data.overrun_warning_reasons = response.overrun_warning_reasons;
  this.dataModel.data.chargeType = response.charge_type;
  switch (this.dataModel.data.chargeType) {
    case 'Permit':
    case 'Permit Charges':
      this.dataModel.filterValue = 'PERMIT_CHARGES';
      break;
    case 'FPN':
      this.dataModel.filterValue = 'FPN';
      break;
    case 'Section 74':
      this.dataModel.filterValue = 'SECTION_74';
      break;
    case 'Miscellaneous':
      this.dataModel.filterValue = 'MISCELLANEOUS';
      break;
    case 'FPN Manual':
      this.dataModel.filterValue = 'MANUAL_FPN';
      break;
    case 'Section 74 Manual':
      this.dataModel.filterValue = 'MANUAL_SECTION_74';
      break;
    default:
      break;
  }
  this.dataModel.data.grantedDate = response.granted_date;
  this.dataModel.data.issuedDate = response.issued_date;
  this.dataModel.data.dateAccepted = response.accepted_date;
  this.dataModel.data.dateFPNpaid = response.date_fpn_paid;
  this.dataModel.data.workType = response.work_type;
  this.dataModel.data.spendType = response.spend_type;
  this.dataModel.data.costcode = response.cost_code;
  this.dataModel.data.assignedTo = response.assigned_to;
  this.dataModel.data.assignedBy = response.assigned_by;
  this.dataModel.data.liabilityAssgned = response.liability_assigned;
  this.dataModel.data.draftInvoiceReceivedDate =
    response.date_draft_invoice_received;
  this.dataModel.data.finalInvoiceReceivedDate =
    response.date_final_invoice_received;
  this.dataModel.data.finalInvoicePaidDate = response.date_final_invoice_paid;

  this.dataModel.data.proposedStartDateTime =
    response.proposed_start_date_time;
  this.dataModel.data.proposedEndDateTime = response.reasonable_end_date_time;
  this.dataModel.data.permitStatus = response.permit_status;
  this.dataModel.data.actualStartDateTime = response.actual_start_date_time;
  this.dataModel.data.actualEndDateTime = response.actual_end_date_time;
  this.dataModel.data.permitType = response.permit_type;
  this.dataModel.data.assessmentStatusComments =
    response.assessment_status_and_comments;

  // For filling the customm data
  this.dataModel.data.inspectionCategory = response.inspection_category;
  this.dataModel.data.inspectionOutcome = response.inspection_outcome;
  this.dataModel.data.inspectionType = response.inspection_type;
  this.dataModel.data.failureReasons = response.failure_reasons;
  this.dataModel.data.worksReferenceNumber = response.works_reference_number;
  this.dataModel.data.highwayAuthority = response.highway_authority;

  this.dataModel.data.projectReferenceNumber =
    response.project_reference_number;
  this.dataModel.data.worksDescription = response.works_description;
  this.dataModel.data.preApprovalAuthoriser =
    response.pre_approval_authoriser;
  // not exist in api response
  // let locationtype = response?.location_types.toString();
  // this.dataModel.data.locationTypes = locationtype?.split(',');

  // this.dataModel.data.postCode = response.postcode;
  // if (response.area_name.length > 0)
  //   this.dataModel.data.usrndetails =
  //     response.usrn +
  //     ' - ' +
  //     response.street_name +
  //     ', ' +
  //     response.area_name +
  //     ', ' +
  //     response.town;
  // if (response.area_name.length == 0)
  //   this.dataModel.data.usrndetails =
  //     response.usrn + ' - ' + response.street_name + ', ' + response.town;
  // if (
  //   response.street_name.length == 0 &&
  //   response.area_name.length == 0 &&
  //   response.town.length == 0
  // )
  // this.dataModel.data.worksDescription = response.work_description;
  // this.dataModel.data.worksCategory =
  //   this._appRepoHelperService.getMDataDisplayTextByValue(
  //     response.work_category
  //   );

  this.dataModel.data.promoterOrganisation = response.promoter_organisation;

  // this.dataModel.data.contact =
  //   response.secondary_contact + ' - ' + response.secondary_contact_number;
  // let workstreams: any[] =
  //   this._appRepoHelperService.getUserAccessibleWorkStream();

  // if (workstreams) {
  //   let workstream: any = workstreams.find(
  //     (c) => c.workstreamId == response.workstream_id
  //   );
  //   this.dataModel.data.workStream = workstream?.description;
  // }

  // let contractors: any[] =
  //   this._appRepoHelperService.getUserAccessibleContractors();
  // if (contractors) {
  //   let contractor: any = contractors.find(
  //     (c) => c.contractor_id == response.contractor_id
  //   );
  // this.dataModel.data.accountability = contractor?.contractor_name;
  // }
  // // not exist in api response
  // // this.dataModel.data.accountability = response.accountability;

  // this.dataModel.data.trafficSensitive = DataHelper.booleanToUserText(
  //   response.traffic_sensitive_flag
  // );
  // this.dataModel.data.footwayClosure =
  //   this._appRepoHelperService.getMDataDisplayTextByValue(
  //     response.close_footway
  //   );

  // this.dataModel.data.excavationRequired = DataHelper.booleanToUserText(
  //   response.excavation_flag
  // );

  // this.dataModel.data.isLaneRentalApplicable = DataHelper.booleanToUserText(
  //   response.lane_rental_flag
  // );

  // this.dataModel.data.trafficManagementRequired =
  //   this._appRepoHelperService.getMDataDisplayTextByValue(
  //     response.traffic_management_type
  //   );

  // this.dataModel.data.trafficManagementPlan = response.geometry;
  // this.dataModel.data.permitStatus = response.status_value;
  // this.dataModel.data.workStatus = response.work_status_value;

  // if (response.status == '') this.isDraftPermitView = true;
  // this.dataModel.data.actualStartDateTime = response.actual_start_date;
  // this.dataModel.data.actualEndDateTime = response.actual_end_date;

  // this.dataModel.data.proposedStartDateTime = response.proposed_start_date;
  // this.dataModel.data.proposedEndDateTime = response.proposed_end_date;

  // this.dataModel.data.proposedDuration = response.working_days;
  // this.dataModel.data.cost = response.total_cost;

  // this.dataModel.data.costRisk = response.cost_risk;
  // this.dataModel.data.customerRisk = response.customer_risk;
  this.dataModel.data.trafficManagementRisk = response.traffic_sensitive;
  // this.dataModel.data.actions = response.actions;
  // this.dataModel.data.changeDecision = response.change_decision;
  // //     this.dataModel.data.changeDecision = [
  // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
  // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
  // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
  // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
  // // ]

  // // console.log("this.dataModel.data.changeDecision",this.dataModel.data.changeDecision)
  // this.dataModel.data.collaborativeWorkingFlag = DataHelper.booleanToUserText(
  //   response.collaborative_working_flag
  // );
  this.dataModel.data.collaborationDetails = response.collaboration_details;

  this.dataModel.data.work_stream = response.workstream;
  this.dataModel.data.coam = response.coam;
  // this.dataModel.data.collaborativeWorks = response.collaborative_works;
  // this.dataModel.data.collaborationType =
  //   this._appRepoHelperService.getMDataDisplayTextByValue(
  //     response.collaboration_type
  //   );
  // this.dataModel.data.ttro_requiredFlag = DataHelper.booleanToUserText(
  //   response.ttro_required_flag
  // );
  // this.dataModel.data.environmental_flag = DataHelper.booleanToUserText(
  //   response.environmental_flag
  // );
  // this.dataModel.data.is_covid19_response = DataHelper.booleanToUserText(
  //   response.is_covid19_response
  // );
  // this.dataModel.data.earlyStartPreApprovalFlag =
  //   DataHelper.booleanToUserText(response.early_start_pre_approval_flag);
  // this.dataModel.data.earlyStartReason = response.early_start_reason;
  // this.dataModel.data.preApprovalAuthoriser =
  //   response.pre_approval_authoriser;
  // this.dataModel.data.activityType =
  //   this._appRepoHelperService.getMDataDisplayTextByValue(
  //     response.activity_type
  //   );
  // this.dataModel.data.preApprovalDetails = response.pre_approval_details;
  // this.dataModel.data.additionalInfo = response.additional_info;
  // this.dataModel.data.additionalcontact = response.additional_contact;
  // this.dataModel.data.emergencyContact = response.additional_contact_number;
  // this.dataModel.data.emergencyNumber = response.additional_contact_email;

  // this.dataModel.data.additionalcontactemail =
  //   response.emergency_contact_name;
  // this.dataModel.data.additionalcontactemail =
  //   response.emergency_contact_number;

  // this.dataModel.data.expectedEndDateTime = response.expected_end_date;
  // this.dataModel.data.is_pmr_responded = response.is_pmr_responded;
  // this.dataModel.data.is_excavation_carried_out =
  //   DataHelper.booleanToUserText(response.excavation_carried_out);
  // if (this.dataModel.data.is_pmr_responded != null) {
  //   this.showIcon = true;
  // }

  // this.dataModel.data.dcStatus = response.dc_details.dc_status;
  // this.dataModel.data.dcReason = response.dc_details.dc_reason;
  // this.dataModel.data.dcResponseDate = response.dc_details.dc_response_date;
  // // switch (response.assessment_decision.status) {
  // //   case ASSESSMENT_STATUS.ASSESSMENT_GRANTED_STATUS:
  // //     this.assessmentDecision.status = response.assessment_decision.display_status;
  // //     break;
  // //   case ASSESSMENT_STATUS.ASSESSMENT_MODIFICATION_STATUS:
  // //     this.assessmentDecision.status = response.assessment_decision.display_status;
  // //     this.assessmentDecision.description = response.assessment_decision.modificationRequestDetails;
  // //     break;
  // //   case ASSESSMENT_STATUS.ASSESSMENT_REFUSED_STATUS:
  // //     this.assessmentDecision.status = response.assessment_decision.display_status;
  // //     this.assessmentDecision.description = response.assessment_decision.refusalDetails;
  // //     this.assessmentDecision.reason = response.assessment_decision.reasonForRefusal;
  // //     this.assessmentDecision.modificationRequestDetails = response.assessment_decision.modificationRequestDetails;
  // //     break;
  // //   default:
  // //     break;
  // // }
}

onCancelPermitBtnClick() {
  let data: any = new Object();
  this.emitEvent('onCancelPermit', data);
  // this.dialog.open(CancelPermitConfirmationComponent);
}

hasPermission(actionName: PERMIT_ACTIONS_CONFIG) {
  // if (this.dataModel.data.actions && this.dataModel.data.actions.length > 0) {
  //   return this.dataModel.data.actions.indexOf(actionName.toString()) >= 0;
  // }
  // return false;
}

onRaiseChangeReqClick() {
  let data: any = new Object();
  this.emitEvent('onRaiseChangeRequest', data);
  // this.emitEvent("onGetAction", data);
}

onProgreesToPAClick() {
  this.emitEvent('onProgressToPA');
}

onShowCostSplitClick() {
  this.emitEvent('onShowCostSplitClick');
}

onNewPermitClick() {
  this.emitEvent('onNewPermitClick');
}

onExtensionRequestBtnClick() {
  this.emitEvent('onWorkExtensionClick');
}

onRegisterClick() {
  this.emitEvent('onRegisterClick');
}

savePdf(type) {
  let appId = this.dataModel.globalParameters.get('applicationId');

  // this.showSpinner();
  this._serverApi
    .download(`/api/v1/pdf/generate-pdf/${type}/${appId}`)
    .subscribe(
      (response) => {
        try {
          // this.hideSpinner();
          // var headers = response.headers;
          // console.log(headers)
          // console.log(headers); //<--- Check log for content disposition
          // var contentDisposition = headers.get('content-disposition');
          // console.log(contentDisposition)

          if (response) {
            console.log('pdf file', response);
            //  console.log(response.headers.get('content-dispositionn'));
            //  console.log(response.headers.get('X-Token'));

            let filename = type + '-list';
            this._saveFileService.saveFile(response, filename);
          }
        } catch (e) {
          // this.hideSpinner();
        }
      },
      (error) => {
        // this.hideSpinner();
        console.log('Permit save as draft error :');
      }
    );
}

getValue() {
  let data: any;

  data = this.dataModel.data;

  return data;
}

showMoreText() {
  this.showMore = !this.showMore;
}

getText() {
  if(this.dataModel.data.fpnOffenceDetails) {
    if (this.dataModel.data.fpnOffenceDetails.length < 35) return;
    if (this.showMore) return '...more';
    else return '..less';
  }
}

showMoreWorkText() {
  this.showMoreWork = !this.showMoreWork;
}

getWorkText() {
  if(this.dataModel.data.worksLocationDescription) {
    if (this.dataModel.data.worksLocationDescription.length < 35) return;
    if (this.showMoreWork) return '...more';
    else return '..less';
  }
}
showMoreChargeText() {
  this.showMoreChargeDetails = !this.showMoreChargeDetails;
}

getChargeText() {
  if(this.dataModel.data.chargeDetails) {
    if (this.dataModel.data.chargeDetails.length < 35) return;
    if (this.showMoreChargeDetails) return '...more';
    else return '..less';
  }
}
showMoreOverrunText() {
  this.showMoreOverrunWarningDetails = !this.showMoreOverrunWarningDetails;
}

getOverrunText() {
  if(this.dataModel.data.overrun_warning_details) {
    if (this.dataModel.data.overrun_warning_details.length < 35) return;
    if (this.showMoreOverrunWarningDetails) return '...more';
    else return '..less';
  }
}
showMoreOverrunWarningText() {
  this.showMoreOverrunWarningResons = !this.showMoreOverrunWarningResons;
}

getOverrunWarningText() {
  if(this.dataModel.data.overrun_warning_reasons) {
    if (this.dataModel.data.overrun_warning_reasons.length < 35) return;
    if (this.showMoreOverrunWarningResons) return '...more';
    else return '..less';
  }
}
}
