<div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Draft Invoice Number</label>
    <p class="font-weight-bold">{{this.dataModel.data.chargeNumber}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Type </label>
    <p>{{this.dataModel.data.chargeType}}</p>
  </div>
  <!-- <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Amount </label>
    <p>&pound;{{this.dataModel.data.chargeAmount}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Permit Type </label>
    <p>{{this.dataModel.data.permitType}}</p>
  </div> -->
</div>
<!-- <hr>
<div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
  <div class="info-element" fxFlex="100" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Description </label>
    <p>{{this.dataModel.data.chargeDesc}}</p>
  </div>
</div> -->
<hr>

<div class="form-group">
  <form form [formGroup]="ApproveChargeForm" id="filter-form" class="d-flex flex-column p-3" appInvalidControlScroll>
    <!-- <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="100">
        <mat-label>Charge Amount</mat-label>
        <input matInput placeholder="Charge Amount" autocomplete="off" formControlName="chargeAmount">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
    </div>
    <div class="fixed-underline" class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="100" hintLabel={{notesMessage}}>
        <mat-label>Notes</mat-label>
        <textarea matInput placeholder="Notes" [maxLength]="500" rows="4" style="resize: none;" autocomplete="off" formControlName="notes" (keyup)="onNotesChange($event.target.value)"></textarea>
        <mat-error>Max character limit 500.</mat-error>
      </mat-form-field>
    </div> -->
    <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="33.33">
        <mat-label>Draft Invoice Amount</mat-label>
        <input matInput placeholder="Charge Amount" autocomplete="off" formControlName="draftInvoiceAmount">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" fxFlex="33.33">
        <mat-label>Final Invoice Amount</mat-label>
        <input matInput placeholder="Charge Amount" autocomplete="off" formControlName="finalInvoiceAmount">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" fxFlex="33.33">
        <mat-label>Invoice Status Validated</mat-label>
        <input matInput placeholder="Charge Amount" autocomplete="off" formControlName="invoiceStatusValidated">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
    </div>
    <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <div fxFlex="50" style="position: relative;">
        <!-- <mat-form-field appearance="fill" fxFlex="100">
          <mat-label>Approval required from </mat-label>
          <input matInput placeholder="Approval required from " autocomplete="off" formControlName="approvalReqFrom">
          <mat-error>Field must be valid</mat-error>
        </mat-form-field> -->
        <mat-icon class="clear-icon" *ngIf="ApproveChargeForm?.get('users')?.value" (click)="ApproveChargeForm?.get('users')?.setValue(null)">clear</mat-icon>
        <mat-form-field appearance="fill">
          <mat-label>Approval By</mat-label>
          <mat-select (selectionChange)="onChangeApprovalRequired($event)" formControlName="approvalReqFrom">
            <input placeholder="Search DOA user" class="search-input" (keyup)="onSearchApprovalBy($event.target.value)">
            <mat-option [value]="user.user_id" *ngFor="let user of arrApprovalReqFrom">
              {{user.doa_user_name}}</mat-option>
          </mat-select>
          <mat-error>Field must be valid</mat-error>

        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field appearance="fill">
          <mat-label>Date of Approval</mat-label>
          <input matInput [max]="maxDate" [matDatepicker]="dateOfOffence" (click)="dateOfOffence.open()" (focus)="dateOfOffence.open()"
            formControlName="approvalDate">
          <mat-datepicker-toggle matSuffix [for]="dateOfOffence"></mat-datepicker-toggle>
          <mat-datepicker #dateOfOffence></mat-datepicker>
          <mat-error>Field must be valid</mat-error>
        </mat-form-field>
      </div>

    </div>
    <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="100">
        <input matInput placeholder="Upload DOA (Optional)" formControlName="fileUp" autocomplete="off"  readonly
          value="Upload DOA (Optional)">
        <mat-icon matSuffix style="cursor: pointer;" (click)="openFileUpload()">attach_file</mat-icon>
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
    </div>
    <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="100" hintLabel={{notesMessage}}>
        <mat-label>Notes</mat-label>
        <textarea matInput placeholder="Notes" [maxLength]="500" rows="4" style="resize: none;" autocomplete="off"
          formControlName="approvalNotes" (keyup)="onNotesChange($event.target.value)"></textarea>
        <mat-error>Max character limit 500</mat-error>
      </mat-form-field>
      <!-- <mat-form-field appearance="fill">
        <mat-label>Basic fill input</mat-label>
       <ngx-mat-file-input formControlName="fill" placeholder="Basic fill placeholder"></ngx-mat-file-input>
        <mat-icon matSuffix>folder</mat-icon>
      </mat-form-field> -->
    </div>
  </form>
</div>
