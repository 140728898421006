         <div class="permit-details">
                <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Promoter Prefix</label>
                        <p>{{ dataModel.data.promoterPrefix }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Permit Ref. Number</label>
                        <p>{{ dataModel.data.permitRefNumber }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Works Reference Number</label>
                        <p>{{ dataModel.data.worksReferenceNumber }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Project Reference Number</label>
                        <p>{{ dataModel.data.projectReferenceNumber }}</p>
                    </div>
                </div>
                <hr />
                <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Works Location Description</label>
                        <p>{{ dataModel.data.worksLocationDescription }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Location details</label>
                        <p>{{ dataModel.data.locationdetails }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Post Code</label>
                        <p>{{ dataModel.data.postCode }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">USRN</label>
                        <p>{{ dataModel.data.usrn }}</p>
                    </div>
                </div>
                <hr />
                <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Works Description</label>
                        <p>{{ dataModel.data.worksDescription }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Works Type</label>
                        <p>{{ dataModel.data.worksCategory }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Highway Authority</label>
                        <p>{{ dataModel.data.highwayAuthority }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Road Type</label>
                        <p>{{ dataModel.data.roadType }}</p>
                    </div>
                </div>
                <hr />
                <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Promoter Organisation</label>
                        <p>{{ dataModel.data.promoterOrganisation }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Contact</label>
                        <p>{{ dataModel.data.contact }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Work Stream</label>
                        <p>{{ dataModel.data.workStream }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Contractor</label>
                        <p>{{ dataModel.data.accountability }}</p>
                    </div>
                </div>
                <hr />
                <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Traffic Sensitive</label>
                        <p>{{ dataModel.data.trafficSensitive }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Footway Closure</label>
                        <p>{{ dataModel.data.footwayClosure }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Excavation Required</label>
                        <p>{{ dataModel.data.excavationRequired }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Is Lane Rental Applicable?</label>
                        <p>{{ dataModel.data.isLaneRentalApplicable }}</p>
                    </div>
                </div>
                <hr />
                <div fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" xLayoutAlign="space-between center">
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Collaborative Working</label>
                        <p>
                            {{ dataModel.data.collaborativeWorkingFlag }}
                        </p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Collaborative Details</label>
                        <p>
                            {{ dataModel.data.collaborationDetails == null ? "-" : dataModel.data.collaborationDetails }}
                        </p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Collaborative Work</label>
                        <p>
                            {{ dataModel.data.collaborativeWorks == null ? "-" : dataModel.data.collaborativeWorks }}
                        </p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Collaborative Type</label>
                        <p>
                            {{ dataModel.data.collaborationType == null ? "-" : dataModel.data.collaborationType }}
                        </p>
                    </div>
                </div>
                <hr />
                <div fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" xLayoutAlign="space-between center">
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Activity Type</label>
                        <p>
                            {{ dataModel.data.activityType == null ? "-" : dataModel.data.activityType }}
                        </p>
                    </div>

                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Traffic Management Required</label>
                        <p>
                            {{ dataModel.data.trafficManagementRequired == null ? "-" : dataModel.data.trafficManagementRequired }}
                        </p>
                    </div>

                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">TTRO required</label>
                        <p>{{ dataModel.data.ttro_requiredFlag }}</p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Environmental Health</label>
                        <p>
                            {{ dataModel.data.environmental_flag }}
                        </p>
                    </div>
                </div>
                <hr />
                <div fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" xLayoutAlign="space-between center">
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Do you have pre-approval for an early start?</label>
                        <p>
                            {{ dataModel.data.earlyStartPreApprovalFlag }}
                        </p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Early Start Reason</label>
                        <p>
                            {{ dataModel.data.earlyStartReason == '' ? "-" : dataModel.data.earlyStartReason }}
                        </p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Pre approved by</label>
                        <p>
                            {{ dataModel.data.preApprovalAuthoriser == '' ? "-" : dataModel.data.preApprovalAuthoriser }}
                        </p>
                    </div>
                    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Is Covid19 Response</label>
                        <p>
                            {{ dataModel.data.is_covid19_response }}
                        </p>
                    </div>
                </div>
                <hr />
                <div fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" xLayoutAlign="space-between center">
                    <div class="info-element" fxFlex="20" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Pre approved Details</label>
                        <p>
                            {{ dataModel.data.preApprovalDetails == '' ? "-" : dataModel.data.preApprovalDetails }}
                        </p>
                    </div>
                </div>
                <hr />
                <div fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" xLayoutAlign="space-between center">
                    <div class="assessment-info-element" fxFlex="100" fxFlex.lt-sm="0 1 calc(50% - 32px)">
                        <label for="">Assessment Decision Status:</label>
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="assessment-status" *ngIf="assessmentDecision.status">
                            <span class="status">{{ assessmentDecision.status }}</span>
                            <span *ngIf="showIcon" class="pmrIcon" fxLayout="column" fxLayoutAlign="center center">
                                <span *ngIf="dataModel.data.is_pmr_responded" class="material-icons pmrTrueIconColor">
                                    check_circle
                                </span>

                            <span *ngIf="!dataModel.data.is_pmr_responded" class="material-icons pmrFalseIconColor">
                                    report_problem
                                </span>

                            </span>
                        </div>
                        <div class="assessment-description">
                            <div *ngIf="assessmentDecision.description">
                                <span class="description-title">{{ assessmentDecision.status }} Details: </span>{{ assessmentDecision.description }}
                            </div>
                            <div *ngIf="assessmentDecision.reason">
                                <span class="description-title">{{ assessmentDecision.status }} Reason: </span>{{ assessmentDecision.reason }}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <!-- amendment ends -->

                <div class="detail-container" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="info-element" fxFlex="100">
                        <!-- <label for="">Traffic Management Plan</label> -->
                        <!-- <div class="map-container" style="background-image: url('/assets/images/map.png');" fxLayout="column"
              fxLayoutAlign="center center"> -->

                        <ng-content select=".elgin-map-view-slot"> </ng-content>
                        <!-- <app-elginmap id="view-permit-elgin-map" [dataModel]="viewElginMapDataModel"
              [configModel]="viewElginMapConfigModel">
            </app-elginmap> -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>

