<div class="row">
    <div class="col-md-12 pt-3 tableColor">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
            <div fxFlex="30">
                <div style="padding:5px 10px;" fxLayout="row" fxLayoutAlign="left" fxLayoutGap="15px">
                    <div>
                        <mat-icon *ngIf="searchInput.value" class="clear-search"
                            (click)="clearSearch()">clear</mat-icon>
                        <input type="text" placeholder="Search" class="search-input" [formControl]="searchInput">
                    </div>
                    <button type="button" fxflex="100" mat-stroked-button class="blue-btn"
                        (click)="search()">GO</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12 tableColor">
        <div class="permit-list custom-table low-border-padding pb-3 my-3 mx-2">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

                <ng-container matColumnDef="ActivityTopic">
                    <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>Activity</th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        {{element.ActivityTopic}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="ActivityType">
                    <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>Type</th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        {{element.ActivityType}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="UpdatedBy">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated By</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.ActivityUpdatedBy}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="UpdatedDate">
                    <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>Date</th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        {{element.ActivityUpdatedDate | transformDateTime}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="dataModel.columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: dataModel.columnsToDisplay;"></tr>
            </table>

            <mat-paginator [pageSize]="MAT_HELPER.PAGE_SIZE" [pageSizeOptions]="MAT_HELPER.PAGE_SIZE_OPTIONS"
                showFirstLastButtons [length]="length"></mat-paginator>

        </div>
    </div>
</div>
