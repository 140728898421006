<div class="permit-list custom-table border-padding">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="TMType">
            <th mat-header-cell *matHeaderCellDef>TM Type</th>
            <td mat-cell *matCellDef="let element">{{element.tmType}}</td>
        </ng-container>
        <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">{{element.description}}</td>
        </ng-container>
        <ng-container matColumnDef="Quantity">
            <th mat-header-cell *matHeaderCellDef>Quantity</th>
            <td mat-cell *matCellDef="let element">{{element.quantity}}</td>
        </ng-container>
        <ng-container matColumnDef="Amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let element"> {{element.amount | transformCurrency}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="detail-element-row"></tr>
    </table>
</div>