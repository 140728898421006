<div class="row">
  <div class="col-md-12 tableColor">
    <div class="permit-list custom-table low-border-padding pb-3 my-3 px-2" style="overflow: auto;">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
        <ng-container matColumnDef="Claim">
          <th mat-header-cell *matHeaderCellDef>Claim</th>
          <!-- <td mat-cell *matCellDef="let element" class="CollaborativeCell"> -->
          <!-- <td mat-cell *matCellDef="let element">
            <div>
              <mat-icon class="icon-color">person_add</mat-icon>
              <img src="assets/images/PastedGraphic-4.png" alt="" class="CollaborativeImg">
            </div>
          </td> -->
          <td mat-cell *matCellDef="let element">
            <div (click)="disableexpand=true;openunclaimeDilog(element)">
              <mat-icon style="cursor: pointer;"  [ngClass]="claimIconClass(element)"
                *ngIf="element.system_claim_status=='Claimed'">person_add</mat-icon>
            </div>

            <div (click)="disableexpand=true;openclaimeDialog(element)">
              <mat-icon style="cursor: pointer;" *ngIf="element.system_claim_status==null || element.system_claim_status=='Unclaimed'">person_add
              </mat-icon>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Reference">
          <th mat-header-cell *matHeaderCellDef class="pRNColWidth120 word-break" mat-sort-header>Invoice
            Reference</th>
          <td mat-cell *matCellDef="let element" class="pIRNColWidth350">
            {{element.Reference}}
          </td>
        </ng-container>

        <ng-container matColumnDef="FinalInvoiceReference">
          <th mat-header-cell *matHeaderCellDef class="pRNColWidth120 word-break" mat-sort-header>Final
            Invoice
            Reference</th>
          <td mat-cell *matCellDef="let element" class="pIRNColWidth350 text-center">
            {{element.FinalInvoiceReference}}
          </td>
        </ng-container>

        <ng-container matColumnDef="UploadDate">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header>Invoice Date</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.UploadDate | transformDate}}
          </td>
        </ng-container>

        <ng-container matColumnDef="AuthorityName">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Highway Authority </th>
          <td mat-cell *matCellDef="let element">
            {{element.AuthorityName}}
          </td>
        </ng-container>
        <ng-container matColumnDef="liabilityStatus">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Liability Status </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.liabilityStatus}}
          </td>
        </ng-container>
        <ng-container matColumnDef="assignedBy">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Assigned By </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.assignedBy}}
          </td>
        </ng-container>
        <ng-container matColumnDef="liabilityOrganization">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Liability Organization </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.liabilityOrganization}}
          </td>
        </ng-container>
        <ng-container matColumnDef="rechargeAmount">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Recharge Amount</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.rechargeAmount}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef class="pRNColWidth120 word-break" mat-sort-header> Invoice
            Status </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <span class="status-pill" [ngClass]="getInvoiceStatusCssClass(element.Status)">{{element.Status}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="StatusMessage">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header>Status Message</th>
          <td mat-cell *matCellDef="let element">
            {{element.StatusMessage}}
          </td>
        </ng-container>

        <ng-container matColumnDef="ChargeType">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Charge Type </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.ChargeType}}
          </td>
        </ng-container>

        <ng-container matColumnDef="ChargesCount">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> # Charges </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.ChargesCount}}
          </td>
        </ng-container>

        <ng-container matColumnDef="InvalidChargesCount">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Invalid Charges </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.InvalidChargesCount}}
          </td>
        </ng-container>

        <ng-container matColumnDef="PeriodFrom">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Invoice Period From Date
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.PeriodFrom | transformDate}}
          </td>
        </ng-container>
        <ng-container matColumnDef="liabilityAssignedDate">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Liability Assigned Date
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.liabilityAssignedDate | transformDate}}
          </td>
        </ng-container>
        <ng-container matColumnDef="responseDueDate">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Response Due Date
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.ResponseDueDate | transformDate}}
          </td>
        </ng-container>
        <ng-container matColumnDef="responseRecievedDate">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Response Recieved Date
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.responseRecievedDate | transformDate}}
          </td>
        </ng-container>
        <ng-container matColumnDef="rechargeDate">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Recharge Date
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.rechargeDate | transformDate}}
          </td>
        </ng-container>

        <ng-container matColumnDef="PeriodTo">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Invoice Period To Date
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.PeriodTo | transformDate}}
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="ResponseDueDate">
                    <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Response Due Date </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.ResponseDueDate | transformDate}}
                    </td>
                </ng-container> -->

        <ng-container matColumnDef="TotalAmount">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Total Invoice Amount </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.TotalAmount | transformCurrency }}
          </td>
        </ng-container>

        <ng-container matColumnDef="HaPaymentDueDate">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> HA Payment Due Date </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.HaPaymentDueDate | transformDate}}
          </td>
        </ng-container>

        <ng-container matColumnDef="PaidDate">
          <th mat-header-cell *matHeaderCellDef class="word-break" mat-sort-header> Invoice Paid Date </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.PaidDate | transformDate}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Checkbox">
          <th mat-header-cell *matHeaderCellDef style="width: 100px;">
            <span class="d-flex align-items-center">
              <!-- <mat-checkbox [checked]="selectAllCheckbox" (change)="selectAll($event.checked)"> </mat-checkbox> -->
              <span class="d-flex align-items-center justify-content-center">
                <!-- <mat-checkbox [checked]="selectAllCheckbox" (change)="selectAll()"></mat-checkbox> -->
                <p (click)="selectAll()" style="margin-bottom: 0; font-size: 1.2rem; margin-left: 5px; cursor: pointer;text-decoration: underline;" matTooltip="Click to select all charges." >{{checkboxlabel}}</p>
              </span>
            </span>
          </th>
          <td mat-cell *matCellDef="let element; let index = dataIndex" style="text-align: center;">
            <span *ngIf="checkChargeType(element)">
              <mat-checkbox [checked]="element.isChecked"
                (change)="onCheckboxClick(index, $event.checked)"></mat-checkbox>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef class="word-break" style="text-align: center;"> Actions </th>
          <td mat-cell *matCellDef="let row">
            <div (click)="stopPropagation($event)" fxLayout="row" fxLayoutAlign="start center">
              <button (click)="onViewClick(row)" mat-icon-button matTooltip="Click to view" class="iconbutton"
                color="primary">
                <mat-icon aria-label="Edit">visibility</mat-icon>
              </button>
              <div *ngIf="checkInvoiceActionAllowed(PERMIT_ACTIONS_CONFIG.DELETE_PERMIT,row)">
                <button mat-icon-button matTooltip="Click to Delete" class="iconbutton" color="warn"
                  (click)="onDeleteInvoiceIconClick($event, row)">
                  <mat-icon aria-label="Delete">delete</mat-icon>
                </button>
              </div>
              <div *ngIf="dataModel.showPaymentRecord">
                <button (click)="onViewRecharge(row)" mat-icon-button matTooltip="Click to view" class="iconbutton"
                  color="primary">
                  <mat-icon aria-label="Edit">currency_pound</mat-icon>
                </button>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="dataModel.columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: dataModel.columnsToDisplay;"></tr>
      </table>

      <mat-paginator [pageSize]="MAT_HELPER.PAGE_SIZE" [pageSizeOptions]="MAT_HELPER.PAGE_SIZE_OPTIONS"
        showFirstLastButtons [length]="length" [pageIndex]="pageIndex"></mat-paginator>

    </div>
  </div>
</div>
