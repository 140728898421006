import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppList, ViewActionHistoryConfigModel, ViewActionHistoryDataModel } from './view-action-history';
import { ChargesRow } from '../cost-penalty-manager/charges-list/charges-list.model';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import { JOURNEY_TYPE, FILTER_TYPE, GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { PERMIT_ACTIONS_CONFIG, MASTER_DATA_DOMAIN } from 'src/app/constants/db.constants';
import { AppFilterModel } from 'src/app/models/common/app-filter';
import { WidgetEvents } from 'src/app/models/common/widget-events';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-view-acion-history',
  templateUrl: './view-acion-history.component.html',
  styleUrls: ['./view-acion-history.component.css']
})
export class ViewAcionHistoryComponent extends WidgetComponentBase implements OnInit {
  @Input() dataModel: ViewActionHistoryDataModel;
  @Input() configModel: ViewActionHistoryConfigModel;
  PERMIT_ACTIONS_CONFIG = PERMIT_ACTIONS_CONFIG;
  dataSource = new MatTableDataSource<ChargesRow>();
  expandedElement: ChargesRow;
  inactiveAppList: AppList[] = [];
  filterValue: any;
  panelOpenState: boolean[] = [];
  paginator: any;

  @ViewChild('htmlData') htmlData!: ElementRef;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.getPagination();
  }
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  pageChangeSubscription: any;
  sortFieldLabel: string = '';
  length: any;
  pageSize = 5;
  MAT_HELPER = MatTableHelper;
  DATA_HELPER = DataHelper;
  arrWorkIdentifier: any = [];
  arrRoadCategori: any = [];
  arrPermitStatus: any[] = [];
  preapplicationid = 0;
  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _router: Router,
    public dialog: MatDialog,
    private _validationService: ValidationService,
    private _appRepoService: AppRepoService
  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    // this.pageChangeSubscription = this.paginator.page.subscribe(() => {
    //   this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
    //   this.dataModel.apireqdata.top = this.paginator.pageSize;
    //   this.pageSize = this.paginator.pageSize
    //   this.dataModel.apireqdata.skip =
    //     this.dataModel.apireqdata.pageno * this.dataModel.apireqdata.top;
    //   // TODO: will update this
    //   this.setValue();
    //   this.wgRefreshData();
    // });

    // TODO: attach local handlers
    this.wgOnInit();

    for (let i = 0; i < this.dataModel.data?.length; i++) {
      // this.panels.push( this.dataModel.data[i] );
      if (i == 0) this.panelOpenState.push(true);
      else {
        this.panelOpenState.push(false);
      }
    }
  }

  getPagination() {
    this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageChangeSubscription = this.paginator.page.subscribe(() => {
        this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
        this.dataModel.apireqdata.top = this.paginator.pageSize;
        this.pageSize = this.paginator.pageSize
        this.dataModel.apireqdata.skip =
          this.dataModel.apireqdata.pageno * this.dataModel.apireqdata.top;
        // TODO: will update this
        this.setValue();
        this.wgRefreshData();
      })
}

  ngAfterViewInit() {
    if(this.dataModel.isSelfDataLoad == false) {
      // this.convertData(this.dataModel.data)
    }
  }

  getControlData() {
    // INFO: ServerAPI
    let url: string = null;
    console.log('url from Action History', this.dataModel.apiUrls);
    console.log('url from Action History', this.dataModel.apireqdata);
    return this.wgAPIMethodGet(this.dataModel.apiUrls, this.dataModel.apireqdata);
  }

  convertData(response: any) {
    console.log('response in Action List', response);
    let items: ChargesRow[] = [];
    response.content.forEach((element) => {
      let item: any = {};

      item.job_owner = element.added_by
      item.note = element.notes_text
      item.date = element.added_date
      item.isSliced = true;


      let temp: AppList[] = [];

      items.push(item);
    });
    this.dataSource = new MatTableDataSource(items);
    this.length = response.totalElements;

    this.dataModel.accordionData[0].count = this.length ? this.length : 0;
  }

  setFieldData() {
    this.arrWorkIdentifier = this._appRepoHelperService.getMDataByDomain(
      MASTER_DATA_DOMAIN.WORK_IDENTIFIER.toString()
    );
    this.arrRoadCategori = this._appRepoHelperService.getMDataByDomain(
      MASTER_DATA_DOMAIN.ROAD_CATEGORY.toString()
    );
    this.arrPermitStatus = this._appRepoHelperService.getMDataByDomain(
      MASTER_DATA_DOMAIN.PERMIT_STATUS.toString()
    );
  }

  setMode(responseDataModel: any) {}

  setValue() {
    // this.isFpnChargview = this.globalParameters.get('isFpnChargview');
    // this.fpnChargeOverviewModel.isFpnChargview = this.isFpnChargview;
    // this.filterValue = this.globalParameters.get('chargesFilterValue');
    // this.dataModel.filterValue = this.filterValue
  }

  // setValue(responseDataModel: any) {}

  applyFilter(filterValue: string) {
    // if (filterValue == "all") {
    //   this.dataSource.filter = "";
    // } else {
    //   filterValue = filterValue.trim();
    //   filterValue = filterValue.toLowerCase();
    //   this.dataSource.filter = filterValue;
    // }
  }

  onExpandCollapse(element) {
    if (element.ApplicationId != this.preapplicationid) {
      this.preapplicationid = element.ApplicationId;
      this.expandedElement = element;
      return this.getApplicationsDetails(element);
    }
  }

  getApplicationsDetails(element) {
    super
      .wgAPIMethodGet(`/api/v1/applications/${element.ApplicationId}`, null)
      .then((response: any) => {
        if (response) {
          element.inactive_app_list = [];
          element.WorksReferenceNumber = response.work_reference_number;
          element.HighwayAuthority = response.highway_authority_name;
          element.Workstream = response.department_name;
          element.PromoterPrefix = response.promoter_prefix;
          element.PromoterOrganization = response.promoter_organisation;
          element.ProjectReferenceNumber = response.project_reference_number;
          element.Contact = response.secondary_contact;
          // not pressent in api response
          element.ProposedDuration = response.working_days;
          element.Cost = response.total_cost;

          //  element.RoadType = this._appRepoHelperService.getMDataDisplayTextByValue(element.road_category.toString());
          element.TrafficSensitive = response.traffic_sensitive_flag;
          element.FootwayClosure = response.close_footway;

          element.ExcavationRequired = response.excavation_flag;
          element.IsLaneRentalApplicable = response.lane_rental_flag;
          element.TrafficManagementRequired = response.traffic_management_type;
          element.WorkDescription = response.work_description;

          // this.arrWorkIdentifier.forEach(element1 => {
          //   if (element1.workIdentifierId == response.work_description) {
          //     element.WorkDescription = element1.description;
          //   }
          // });

          this.arrRoadCategori.forEach((element1) => {
            if (element1.value == response.road_category) {
              element.RoadType = element1.displayText;
            }
          });

          response.inactive_app_list.forEach((element11) => {
            let item1: any = {};
            item1.ApplicationId = element11.application_id;
            item1.permitRefNo = element11.permit_reference_number;
            item1.applistStatus = element11.status;

            element.inactive_app_list.push(item1);
          });

          // this.inactiveAppList =temp;

          // element.inactive_app_list = this.inactiveAppList;

          element.FootwayClosureDisplayText =
            this._appRepoHelperService.getMDataDisplayTextByValue(
              element.FootwayClosure
            );
          element.TrafficManagReqDisplayText =
            this._appRepoHelperService.getMDataDisplayTextByValue(
              element.TrafficManagementRequired
            );
        }
      });
  }
  checkEditPermitEnable(actionName, row) {
    // if (this._appRepoHelperService.hasPermitActionPermission(actionName)) {
    if ('status' in row) {
      if (row.status == 'draft') {
        return true;
      } else {
        return false;
      }
    }
    // }
  }

  onViewClick(row, index) {
    // console.log("row", row);
    console.log('index', index);
    console.log(row);
    row.index = index;
    // let obj = { row: row, index: index}
    console.log(this.emitEvent(WidgetEvents.VIEW_CLICK, row));
    this.emitEvent(WidgetEvents.VIEW_CLICK, row);
  }

  onInactivePermitViewClick(row) {
    this.emitEvent('inActivePermitView', row);
  }

  onEditPermitIconClick($event, rowData) {
    $event.stopPropagation();
    this.emitEvent('editPermit', rowData);
  }

  onDeletePermitIconClick($event, rowData) {
    $event.stopPropagation();
    this.emitEvent('deletePermit', rowData);
  }

  ngOnDestroy(): void {
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
  }

  resetPagination() {
    this.dataModel.apireqdata.pageno = 0;
    this.dataModel.apireqdata.skip = 0;
    this.dataModel.apireqdata.top = this.pageSize;
  }
  stopPropagation(event) {
    event.stopPropagation();
  }

  getHelpTextData(data) {
    // switch (data) {
    //   case 'CostRisk':
    //     return 1001;
    //     case 'CustomerRisk':
    //     return 1002;
    //     case 'TrafficManagement':
    //     return 1003;
    //   default:
    //     return null;
    // }
    return null;
  }
  // downloadPdf() {
  //   console.log('pdf');
  //   let DATA: any = document.getElementById('htmlData');
  //   html2canvas(DATA).then((canvas) => {
  //     let fileWidth = 208;
  //     let fileHeight = (canvas.height * fileWidth) / canvas.width;
  //     const FILEURI = canvas.toDataURL('image/png');
  //     let PDF = new jsPDF('p', 'mm', 'a4');
  //     let position = 0;
  //     PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
  //     PDF.save('Estimated-work-list.pdf');
  //   });
  // }

  resetExpands() {
    this.panelOpenState = [];
    for (let i = 0; i < this.dataModel.data.length; i++) {
      // this.panels.push( this.dataModel.data[i] );
      if (i == 0) this.panelOpenState.push(true);
      else {
        this.panelOpenState.push(false);
      }
    }
  }

  openPanelHandler(index: number) {
    this.panelOpenState = [];
    for (let i = 0; i < this.dataModel.data.length; i++) {
      this.panelOpenState.push(false);
    }
    this.panelOpenState[index] = true;
  }

  expandRow(rowData, i) {
    if(rowData.isSliced == true) {
     this.dataSource.data[i].isSliced = false
    }else {
     this.dataSource.data[i].isSliced = true
    }
   }

   showMore(value, isSliced) {
     if(value) {
       if(value.length > 25 && !!isSliced) {
         return '...more'
       } else if(value.length < 25 && !!isSliced) {
         return
       } else {
        return  '...less'
       }
     }
   }

   getText(rowData) {
     let string = rowData.note.trim()
    if(rowData.isSliced) {
      return string.slice(0, 30)
    } else {
      return string
    }
  }
}
