<div class="d-flex flex-column" fxFlex="100" fxLayout="column" fxLayoutGap="10px">
    <div class="d-flex justify-content-between align-items-center customBackground" *ngFor="let item of dataModel.data; first as isFirst; let i = index">

        <mat-accordion class="w-100">
            <mat-expansion-panel (opened)="openPanelHandler(i)" (closed)="panelOpenState[i] = false" [expanded]="panelOpenState[i]" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title class="d-flex align-items-center">
                        {{item.text}} <span style="font-size:90%; margin-left: .5rem">({{item.count}})</span>
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-icon *ngIf="!panelOpenState[i]">add</mat-icon>
                        <mat-icon *ngIf="panelOpenState[i]">remove</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="my-3" [ngClass]="attachmentList.length > 6 ? 'file-content' : 'file-content-1' ">
                  <div class="d-flex justify-content-between flex-column detail-container" style="border-radius: 5px; padding: 10px; background-color: #f6f8fb; margin: 3px;" *ngFor="let item of attachmentList">
                      <div class="d-flex flex-row">
                          <span>
                            <img [src]="item.imageSrc" alt="" style="height: 25px;">
                          </span>
                          <span class="ml-2">{{item.file_name}}</span>
                      </div>
                      <div class="comment-details d-flex flex-row justify-content-between m-2">
                          <div class="comment-details d-flex flex-column">
                            <span class="list-date">{{item.created_by}}</span>
                            <span class="list-date">{{item.create_date | transformDateTime}}</span>
                          </div>
                          <div class="d-flex align-items-end">
                              <span class="material-icons icon-position" style=" font-size: 20px; color: #3D6EB5; cursor: pointer;" (click)="onDownloadFile(item)">file_download </span>
                              <span class="material-icons icon-position" style=" font-size: 20px; color: #3D6EB5; cursor: pointer;" (click)="onDeleteFile(item)">delete </span>
                          </div>
                      </div>
                  </div>

                </div>


<!-- Old design -->
                <!-- <div class="my-3" [ngClass]="attachmentList.length > 6 ? 'file-content' : 'file-content-1' ">
                    <div class="d-flex justify-content-between flex-column detail-container" style="width: 195px;" *ngFor="let item of attachmentList">
                        <div class="d-flex flex-column">
                            <span class="material-icons-outlined" style=" font-size: 20px; color: black;">
                description </span>
                            <span class="text-wrapp">{{item.file_name}}</span>
                        </div>
                        <div class="comment-details d-flex flex-column">
                            <span class="list-date">{{item.created_by}}</span>
                            <span class="list-date">{{item.create_date | transformDateTime}}</span>
                            <div class="d-flex justify-content-end m-2">
                                <span class="material-icons icon-position" style=" font-size: 20px; color: #3D6EB5; cursor: pointer;" (click)="onDownloadFile(item)">file_download </span>
                                <span class="material-icons icon-position" style=" font-size: 20px; color: #3D6EB5; cursor: pointer;" (click)="onDeleteFile(item)">delete </span>
                            </div>
                        </div>
                    </div>

                </div> -->
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
