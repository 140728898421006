<ng-container>
  <form *ngIf="wgFormGroup" [formGroup]="wgFormGroup">


    <div>
      <div *ngIf="!disabled" class="upload-container" appDragAndDrop (filesChangeEmiter)="onFileChange($event)">
        <div class="text-wrapper">
          <div class="centered">

            <input type="file" name="file" id="file" (change)="onFileChange($event.target.files)" multiple="multiple"
              formControlName="file" accept=".pdf, .jpg, .png, .doc, .docx, .jpeg, .xlsx, .csv, .msg">
            <label class="droplabel" for="file">
              <div>
                <mat-icon class="cloud-icon">cloud_upload</mat-icon>
              </div>
              <div>Drag and drop files here or click to select files</div>
            </label>

          </div>
        </div>
      </div>

      <div class="upload-container-1">
        <div class="file-list">
          <div class="file" *ngFor="let file of files; let ind = index">
            <span class="file-name" title="{{ file.name }}">{{ file.name }}</span>
            <mat-icon class="delete-icon" (click)="cancelFile(ind)">delete</mat-icon>
          </div>
          <p class="no-files-message" *ngIf="files.length === 0">No files selected.</p>
        </div>
      </div>
      <hr>
      <small id="emailHelp" class="form-text text-muted mt-2">Supported file types:.pdf, .jpg, .png, .doc, .docx, .jpeg,
        .xlsx, .csv, .msg, Maximum file size:10MB</small>
    </div>
    <hr>
    <div class="mt-4" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">

      <button mat-raised-button class="primary-button-outline" color="primary"
        (click)="cancelFileUpload()">Cancel</button>
      <button mat-raised-button class="primary-button" [ngClass]="{'disableBtn': disableBtn}" color="primary"
        (click)="openFileUpload()">Upload</button>

    </div>

  </form>

</ng-container>
