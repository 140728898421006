<div class="d-flex flex-column" fxFlex="100" fxLayout="column" fxLayoutGap="10px">
  <div class="d-flex justify-content-between align-items-center customBackground"
    *ngFor="let item of dataModel.accordionData; first as isFirst; let i = index">

    <mat-accordion class="w-100">
      <mat-expansion-panel (opened)="openPanelHandler(i)" (closed)="panelOpenState[i] = false"
        [expanded]="panelOpenState[i]" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title class="d-flex align-items-center">
            {{item.text}} <span style="font-size:90%; margin-left: 1rem">({{item.count}})</span>
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon *ngIf="!panelOpenState[i]">add</mat-icon>
            <mat-icon *ngIf="panelOpenState[i]">remove</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="inspection-list custom-table ">
          <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
              <!-- Position Column -->
              <ng-container matColumnDef="LiabiltyOrg">
                  <th mat-header-cell *matHeaderCellDef>Liability Organisation</th>
                  <td mat-cell *matCellDef="let element">
                      {{element.liability_org}}
                  </td>
              </ng-container>
              <!-- Position Column -->
              <ng-container matColumnDef="Amount">
                  <th mat-header-cell *matHeaderCellDef>Amount</th>
                  <td mat-cell *matCellDef="let element">
                      {{element.amount}}
                  </td>
              </ng-container>
              <!-- Position Column -->
              <ng-container matColumnDef="ResponseDue">
                  <th mat-header-cell *matHeaderCellDef>Response Due</th>
                  <td mat-cell *matCellDef="let element" >
                      {{element.response_due}}
                  </td>
              </ng-container>
              <!-- Position Column -->
              <ng-container matColumnDef="Note">
                  <th mat-header-cell *matHeaderCellDef>Notes</th>
                  <td mat-cell *matCellDef="let element; let i = dataIndex" >
                      <!-- {{element.note}} -->
                      <!-- <pre class="note">{{element.isSliced ? (element.note | slice:0:35) : element.note }} <span class="more-text" (click)="expandRow(element, i)">{{showMore(element.note, element.isSliced)}}</span> </pre> -->
                      <pre class="note">{{getText(element)}}<span class="more-text" (click)="expandRow(element, i)">{{showMore(element.note, element.isSliced)}}</span> </pre>
                  </td>
              </ng-container>
              <!-- Position Column -->
              <ng-container matColumnDef="JobOwner">
                  <th mat-header-cell *matHeaderCellDef>User</th>
                  <td mat-cell *matCellDef="let element" >
                      {{element.job_owner}}
                  </td>
              </ng-container>
              <!-- Position Column -->
              <ng-container matColumnDef="ReasonforDispute">
                  <th mat-header-cell *matHeaderCellDef>Reason for Dispute</th>
                  <td mat-cell *matCellDef="let element" >
                      {{element.reason_dispute}}
                  </td>
              </ng-container>
              <!-- Position Column -->
              <ng-container matColumnDef="Date">
                  <th mat-header-cell *matHeaderCellDef>Date</th>
                  <td mat-cell *matCellDef="let element" >
                      {{element.date | transformDateTime }}
                  </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="dataModel.columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let row; columns: dataModel.columnsToDisplay;"></tr>
          </table>
          <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
          <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="MAT_HELPER.PAGE_SIZE_OPTIONS"
        showFirstLastButtons [length]="length"></mat-paginator>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
