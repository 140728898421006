import { Component, Input, OnInit } from '@angular/core';
import { InvoicesQuickFilterConfigModel, InvoicesQuickFilterDataModel } from './invoices-quick-filter.model';

import { AppFilterModel } from 'src/app/models/common/app-filter';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { DYNAMIC_FILTER_KEY, FILTER_TYPE, QUICK_FILTER_KEY } from 'src/app/app-constants';
import { HELPER_TEXT_DATA_DOMAIN } from 'src/app/constants/db.constants';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';

@Component({
  selector: 'app-invoices-quick-filter',
  templateUrl: './invoices-quick-filter.component.html',
  styleUrls: ['./invoices-quick-filter.component.css']
})
export class InvoicesQuickFilterComponent extends WidgetComponentBase
  implements OnInit {
  @Input() dataModel: InvoicesQuickFilterDataModel;
  @Input() configModel: InvoicesQuickFilterConfigModel;

  quickFilterSelectedValue: string = "";
  quickFilterHelperData: any[] = [];

  constructor(
    private _serverApiBase: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _appRepoService: AppRepoService,
    private _validationService: ValidationService
  ) {
    super(_serverApiBase, _validationService);
  }

  ngOnInit(): void {
    this.wgOnInit();
  }

  ngAfterViewInit() {
    this.wgRefreshData();
  }

  setFieldData() {
    this.quickFilterHelperData = [
      ...this._appRepoService.appRepo.helperTextDataById.values(),
    ].filter((item) => item.domain === HELPER_TEXT_DATA_DOMAIN.QUICK_FILTER);
  }

  getControlData() {
    let url = '/api/v1/cpm/invoices/count';
    return this.wgAPIMethodGet(url);
  }

  convertData(response: any) {
    var i = 0;

    this.dataModel.quickFilters[i++].count = response.total_invoice_count;
    this.dataModel.quickFilters[i++].count = response.draft_invoice_count;
    this.dataModel.quickFilters[i++].count = response.valid_invoice_count;
    this.dataModel.quickFilters[i++].count = response.challenged_invoice_count;
    this.dataModel.quickFilters[i++].count = response.send_to_sap_count;
    this.dataModel.quickFilters[i++].count = response.withdrawn_count;

    return this.dataModel;
  }

  setMode(responseDataModel: any) { }

  setValue(responseDataModel: any) {
    // let filterGlobalParams: AppFilterModel =
    //   this._appRepoService.appRepo.appGlobalPersistantData.get(
    //     this.dataModel.appGlobalParamsKeyForQuickFilter
    //   );

    // if (filterGlobalParams.persistantfilterType == FILTER_TYPE.QUICK_FILTER) {
    //   this.quickFilterSelectedValue =
    //     filterGlobalParams.persistantQuickFilterModel.filterValue;
    //   this.emitEvent('onQuickFilterSetValue');
    // }

    switch (this.dataModel.listName) {
      case DYNAMIC_FILTER_KEY.INVOICE_ALL_WORK_LIST:
        this.quickFilterSelectedValue = this._appRepoHelperService.getGlobalPersistantData(QUICK_FILTER_KEY.INVOICE_ALL_WORK_LIST)
        break;
      case DYNAMIC_FILTER_KEY.INVOICE_MY_WORK_LIST:
        this.quickFilterSelectedValue = this._appRepoHelperService.getGlobalPersistantData(QUICK_FILTER_KEY.INVOICE_MY_WORK_LIST)
        break;
      case DYNAMIC_FILTER_KEY.INVOICE_MY_TEAMS_WORK_LIST:
        this.quickFilterSelectedValue = this._appRepoHelperService.getGlobalPersistantData(QUICK_FILTER_KEY.INVOICE_MY_TEAMS_WORK_LIST)
        break;
      default:
        break;
    }
    if(this.quickFilterSelectedValue == '' || this.quickFilterSelectedValue == undefined) this.quickFilterSelectedValue = ''

    // console.log('quick>>>', this.quickFilterSelectedValue)
  }

  getValue() {
    let data = {
      quickFilter: this.quickFilterSelectedValue,
    };
    return data;
  }

  onQuickFilterSelectionChange(value) {

    let filterGlobalParams =
    this._appRepoService.appRepo.appGlobalPersistantData.get(
      this.dataModel.appGlobalParamsKeyForQuickFilter
    );
  filterGlobalParams.persistantfilterType = FILTER_TYPE.QUICK_FILTER;
  // filterGlobalParams.persistantQuickFilterModel.filterValue =
  //   this.quickFilterSelectedValue;
  // this._appRepoService.appRepo.appGlobalPersistantData.set(
  //   this.dataModel.appGlobalParamsKeyForQuickFilter,
  //   filterGlobalParams
  // );

    this.quickFilterSelectedValue = value.value;
    switch (this.dataModel.listName) {
      case DYNAMIC_FILTER_KEY.INVOICE_ALL_WORK_LIST:
        this._appRepoHelperService.setGlobalPersistantData(QUICK_FILTER_KEY.INVOICE_ALL_WORK_LIST, this.quickFilterSelectedValue)
        break;
      case DYNAMIC_FILTER_KEY.INVOICE_MY_WORK_LIST:
        this._appRepoHelperService.setGlobalPersistantData(QUICK_FILTER_KEY.INVOICE_MY_WORK_LIST, this.quickFilterSelectedValue)
        break;
      case DYNAMIC_FILTER_KEY.INVOICE_MY_TEAMS_WORK_LIST:
        this._appRepoHelperService.setGlobalPersistantData(QUICK_FILTER_KEY.INVOICE_MY_TEAMS_WORK_LIST, this.quickFilterSelectedValue)
        break;
      default:
        break;
    }
    this.emitEvent(InvoicesQuickFilterConfigModel.COMP_TO_CALLER_APPLY_FILTER);
  }

  reset() {
    this.quickFilterSelectedValue = '';
  }

  getHelpTextData() {
    return {
      helpTextId: Math.floor(Math.random() * (100 - 1 + 1) + 1),
    };
  }
}
