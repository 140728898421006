<div class="col-md-12 mt-3">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <div fxFlex="40">
      <div
        style="padding: 5px 10px"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
      >
        <!-- <div>
          <mat-icon *ngIf="searchInput.value" class="clear-search" (click)="clearSearch()">clear</mat-icon>
          <input type="text" placeholder="Search" class="search-input" [formControl]="searchInput">
        </div>
        <div>
          <button type="button" fxflex="100" mat-stroked-button class="blue-btn">GO</button>
        </div> -->
        <div>
          <button
            class="createpermitbtn"
            (click)="onAddComment()"
            mat-stroked-button
            class="plain-btn"
          >
            <mat-icon class="pr-5" matTooltip="Click to add comment."
              >add_circle</mat-icon
            >
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="mx-3 mt-2">
    <p class="note">
      Please note these Comments are internal comments. These will not be sent
      to Street Manager.
    </p>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="comments-list custom-table">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
          <!-- Position Column -->
          <ng-container matColumnDef="Comment">
            <th mat-header-cell *matHeaderCellDef>Comment</th>
            <td
              mat-cell
              *matCellDef="let element"
              style="padding-left: 20px !important"
            >
              {{ element.Comment }}
            </td>
          </ng-container>
          <!-- Position Column -->
          <!-- <ng-container matColumnDef="CommentType">
            <th mat-header-cell *matHeaderCellDef>Comment Type</th>
            <td mat-cell *matCellDef="let element"> {{element.CommentType}} </td>
          </ng-container> -->
          <!-- Position Column -->
          <ng-container matColumnDef="CommentBy">
            <th mat-header-cell *matHeaderCellDef>User</th>
            <td mat-cell *matCellDef="let element">{{ element.CommentBy }}</td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.Date | transformDateTime }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="text-align: center">
              Actions
            </th>
            <td mat-cell *matCellDef="let row">
              <div fxLayout="row" fxLayoutAlign="center center">
                <button
                  (click)="openDialog(row)"
                  mat-icon-button
                  matTooltip="Click to view"
                  class="iconbutton"
                  color="primary"
                >
                  <mat-icon aria-label="Edit">visibility</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
        </table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 20]"
          showFirstLastButtons
          [length]="length"
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>
