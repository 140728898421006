import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { UnclaimedWorkWorkingProcessConfigModel, UnclaimedWorkWorkingProcessDataModel } from './unclaimed-work-working-process.model';

import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { DatePipe } from '@angular/common';
import { EventActionService } from 'src/app/services/event-action.service';
import { MASTER_DATA } from 'src/app/constants/db.constants';
import { MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';

// import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-unclaimed-work-working-process',
  templateUrl: './unclaimed-work-working-process.component.html',
  styleUrls: ['./unclaimed-work-working-process.component.css']
})
export class UnclaimedWorkWorkingProcessComponent extends WidgetComponentBase implements OnInit {
  @ViewChild('menuStartTrigger') trigger: MatMenuTrigger;

  @ViewChild('formComponent') formComponent: NgForm;

  dialogModel: any;
  userInfo: any;
  // startPermitComment:  any= '';
  startPermitFormGroup: FormGroup;
  toggle = true;
  submitted = false;
  arrUsers: any[] = []
  users = new FormControl()
  searchUsers: any;
  arrAllUser : any[] = []

  @Input() dataModel: UnclaimedWorkWorkingProcessDataModel;
  @Input() configModel: UnclaimedWorkWorkingProcessConfigModel;
  claimedby: any;
  claimedbysameuser: boolean = false;
  freshClaim: boolean = false;
  disableUnclaimBtn: boolean = false;

  // @ViewChild('searchUser')
  // searchUser: ElementRef;


  constructor(
    private _serverApiBase: ServerApiInterfaceServiceService,
    private _validationService: ValidationService,
    private _spinner: NgxSpinnerService,
    private _appRepoHelperService: AppRepoHelperService,
    private fbStartPermit: FormBuilder,
    private _eventActionService: EventActionService,
    public datepipe: DatePipe
  ) {
    super(_serverApiBase, _validationService);

  }

  ngOnInit(): void {
    this.createFormGroups();
    this.userInfo = this._appRepoHelperService.getMDataByKey(MASTER_DATA.USER_INFO);
    this.wgOnInit();
    if (this.dataModel.disableDropdown) this.users.disable()
  }

  ngAfterViewInit() {
   if(this.dataModel.isSelfDataLoad) {
    this.wgRefreshData();
   } else {
    this.getUsers()
      if(this.dataModel.isClaimed) {
        this.claimedbysameuser = true;
      } else {
        this.claimedbysameuser = false;
      }
   }
   console.log(this.claimedbysameuser)
  }
  createFormGroups() {
    this.startPermitFormGroup = this.fbStartPermit.group({

      'startPermitComment': ['']
    });
  }

  get f() { return this.startPermitFormGroup.controls; }

  cancelStartPermit() {

    this.startPermitFormGroup.reset();
    this.formComponent.resetForm();

    this.startPermitFormGroup.markAsPristine();

    this.startPermitFormGroup.markAsUntouched();

    this.emitEvent('cancelClaimWork');

  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  // get f() { return this.startPermitFormGroup.controls; }
  unClaimWork() {
    this.startPermitFormGroup.markAllAsTouched();

    if (this.startPermitFormGroup.valid) {

      const user = this.arrUsers.find(user => user.user_id === this.users.value);
      let self_claimed;
      if(user.user_name === this.userInfo.display_name) {
        self_claimed = true;
      } else {
        self_claimed = false;
      }

      let charge_details;
      if(Array.isArray(this.dataModel.apireqdata)) {
        charge_details = this.dataModel.apireqdata
      } else {
        charge_details = [this.dataModel.apireqdata]
      }

      let params = {
        notes_txt: this.startPermitFormGroup.controls.startPermitComment.value,
        // claim_date: new Date().toISOString(),
        claim_charge_details_list: charge_details,
        // is_self_claimed: self_claimed,
        // claimed_by: this.users.value
      }

      this.wgAPIMethodPost(`/api/v1/cpm/unClaimCharges`, params).then(resp => {
        this.cancelStartPermit();
      });



    }

  }

  claimWork() {
    this.startPermitFormGroup.markAllAsTouched();

    if (this.startPermitFormGroup.valid) {

      const user = this.arrUsers.find(user => user.user_id === this.users.value);

      let self_claimed;
      if(user.user_name === this.userInfo.display_name) {
        self_claimed = true;
      } else {
        self_claimed = false;
      }

      let charge_details;
      if(Array.isArray(this.dataModel.apireqdata)) {
        charge_details = this.dataModel.apireqdata
      } else {
        charge_details = [this.dataModel.apireqdata]
      }

      let params = {
        comments: this.startPermitFormGroup.controls.startPermitComment.value,
        claim_date: new Date().toISOString(),
        cpm_claim_charge_details: charge_details,
        is_self_claimed: self_claimed,
        claimed_by: this.users.value
      };

      this.wgAPIMethodPost(`/api/v1/cpm/create-claim-record`, params).then(resp => {
        this.cancelStartPermit();
      });

    }

  }


  setMode(responseDataModel: any) {

  }
  setValue(responseDataModel: any) {

  }
  setFieldData() {
    // throw new Error('Method not implemented.');
  }
  getControlData() {
    let url = this.dataModel.apiDataUrl;
    return this.wgAPIMethodPost(url, this.dataModel.apireqdata);
  }

  // filterOptions() {
  //   this.filteredUsers = this.arrUsers.filter(
  //     (option) =>
  //       option.name
  //         // .toLowerCase()
  //         .indexOf(this.searchUsers.toLowerCase()) !== -1
  //   );
  // }

  // onSelectUser() {
  //   this.searchUsers = '';
  //   this.filteredUsers = this.arrUsers;
  //   this.searchUser.nativeElement.focus();
  // }

  onSearchUser(searchVal){
    let filter = searchVal.toLowerCase();
    if(filter.length > 0) {
      this.arrUsers = this.arrAllUser.filter(option => option.user_name.toLowerCase().includes(filter));
    } else {
      this.arrUsers =  this.arrAllUser;
    }
  }

  convertData(response: any) {
    if(response.claim_history_details.length > 0) {
      if (response.claim_history_details == null) {
        this.freshClaim = true;
      }
      else if (this.userInfo.display_name == response.claim_history_details[0].user_name  ) {
        this.claimedbysameuser = true;
        this.freshClaim = false;
        if(response.claim_history_details[0].cpm_claim_status=='Unclaimed') {
          this.claimedbysameuser = false;
        }
      }
      else {
        this.claimedby = response.claim_history_details[0].user_name;
        this.claimedbysameuser = false;
        this.freshClaim = false;
        if(response.claim_history_details[0].cpm_claim_status=='Unclaimed'){
          this.freshClaim = true;
        }
      }
    } else {
      this.freshClaim = true;
    }

    this.getUsers()
    }

    getUsers() {
      this.wgAPIMethodGet(`/api/v1/cpm/getUsersForClaim`).then((response: any) =>{
        if(response) {
          this.arrUsers = response
          this.arrAllUser = response

          const user = this.arrUsers.find(user => user.user_name === this.userInfo.display_name);
          this.users.setValue(user.user_id)
          // console.log('claimed>>>', response.claim_history_details[0].user_name)
          // const claimed_by = response.claim_history_details[0].user_name
          if(this.dataModel.disableDropdown) {
            if(this.claimedby == undefined) return;
            if(this.claimedby !== this.userInfo.display_name) this.disableUnclaimBtn = true;
          }
          console.log(this.claimedbysameuser)
        }
      });
    }



}
