import { EventEmitter } from 'events';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';

export class SapActivityLogDataModel {
  isGlobalParams: any;
  isSelfDataLoad: any;
  globalParameters: Map<any, any>;
  apireqdata: any = {};
  widgetApiUrl: any;
  columnsToDisplay: any[]
  static getInstance<T>(): SapActivityLogDataModel {
    let model = new SapActivityLogDataModel();
    model.apireqdata = new SapActivityLogRequestModel()
    model.isSelfDataLoad = true;
    model.globalParameters = new Map<any, any>();
    return model;
  }
}

export class SapActivityLogConfigModel {
  static getInstance<T>(): SapActivityLogConfigModel {
    let model = new SapActivityLogConfigModel();
    return model;
  }

  public static readonly CALLER_TO_COMP_REFRESH_DATA = 'REFRESH_DATA';

  CompToCaller = new EventEmitter();
  CallerToComp = new EventEmitter();

  EventAction = new Map();
}

export class SapActivityLogRequestModel {
  skip: number = 0;
  top: number = MatTableHelper.PAGE_SIZE;
  pageno: number = 0;
  sortOrder: string;
  sortField: string;
  searchString: string;
  constructor() {
    this.skip = this.pageno * this.top;
  }
}

export interface SapActivityLogRow {
  interface_name: string;
  response: string;
  request: string;
  status: string;
  created_by: any;
  created_date: any;
  isSliced: boolean
}
