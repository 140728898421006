import {
  AssignLiabilityConfigModel,
  AssignLiabilityModel,
} from './assign-liability.model';
import { CHARGE_FORMS_NAME, GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { MatDialog } from '@angular/material/dialog';
import { PERMIT_ACTIONS_CONFIG } from 'src/app/constants/db.constants';
import { SaveFileService } from 'src/app/services/save-file.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';

@Component({
  selector: 'app-assign-liability-form',
  templateUrl: './assign-liability-form.component.html',
  styleUrls: ['./assign-liability-form.component.css'],
})
export class AssignLiabilityFormComponent
  extends WidgetComponentBase
  implements OnInit
{
  @Input() dataModel: AssignLiabilityModel;
  @Input() configModel: AssignLiabilityConfigModel;

  AssignPotentialForm: FormGroup;

  arrLiabilityOrg: any[] = [
    {
      key: 1,
      displayText: 'A',
    },
    {
      key: 2,
      displayText: 'B',
    },
    {
      key: 3,
      displayText: 'C',
    },
  ];
  allArrUsers: any[] = []
  arrPotentialTeams: any[] = []
  arrPotentialUser: any[] = [];
  allArrPotentialUser: any[] = [];
  notesMessage: string = 'Max 500 characters.';

  arr: FormArray;
  amountValidator: boolean = false;
  totalValidator: boolean = false;
  chargeWarning: boolean = false;

  @Input() min: any;
  mindate = new Date();

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _validationService: ValidationService,
    private _saveFileService: SaveFileService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    super(_serverApi, _validationService);
    this.mindate.setDate(this.mindate.getDate() - 0);
  }

  ngOnInit(): void {
    super.wgOnInit();

    this.createFormGroup();
  }
  OnDestroy() {
    this.AssignPotentialForm.reset();
  }
  createFormGroup(): void {
    this.AssignPotentialForm = this.fb.group({
      team_id: [''],
      user_id: [''],
    });
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.arrPotentialTeams = this.dataModel.arrPotentialTeams
    //   // console.log("arrPotentialTeams",this.arrPotentialTeams)
    //   this.allArrUsers = this.dataModel.arrPotentialTeams
    //   this.arrPotentialUser = []
    //   this.allArrUsers.forEach(item => {
    //     item.lst_cpm_users.forEach(user => {
    //       this.arrPotentialUser.push(user)
    //     })
    //   })
    //   this.allArrPotentialUser = this.arrPotentialUser;

    //   console.log("this.dataModel.data.teamId",this.dataModel.data.teamId)
    //   console.log("this.dataModel.data.userId",this.dataModel.data.userId)

    //   this.AssignPotentialForm.patchValue({
    //     team_id : this.dataModel.data.teamId,
    //     user_id : this.dataModel.data.userId
    //   })

    // }, 1000)

    // teamId: any;
    // userId



  }

  setFieldData() {}
  stopPropagation(event) {
    event.stopPropagation();
  }
  setMode(responseDataModel: any) {}
  setValue(responseDataModel: any) {}
  getControlData() {
    // let id = this.dataModel.globalParameters.get('applicationId');
    // // INFO: ServerAPI
    let url = `/api/v1/cpm/actions/getDetails`;
    return this.wgAPIMethodGet(url, this.dataModel.apireqdata);
    // return new Promise((resolve, reject) => {
    //   let permitform: any = this.dataModel.globalParameters.get(
    //     GLOBAL_PARAM_KEY.VIEW_PERMIT_FORM
    //   );
    //   permitform = permitform ? permitform : new Object();
    //   if (permitform) {
    //     resolve(permitform);
    //   } else {
    //     reject(permitform);
    //   }
    // });
  }
  onSearchTeam(searchVal){
    let filter = searchVal.toLowerCase();
    if(filter.length > 0) {
      this.arrPotentialTeams = this.allArrUsers.filter(option => option.team_name.toLowerCase().includes(filter));
    } else {
      this.arrPotentialTeams =  this.allArrUsers;
    }
  }
  onSearchUser(searchVal){
    let filter = searchVal.toLowerCase();
    if(filter.length > 0) {
      this.arrPotentialUser = this.allArrPotentialUser.filter(option => option.user_name.toLowerCase().includes(filter));
    } else {
      this.arrPotentialUser =  this.allArrPotentialUser;
    }
  }
  convertData(response: any) {
    if (!response) return;

    console.log('respoine of Assign 1', response);

    this.dataModel.data.permitType = response.permit_type;
    this.dataModel.data.chargeDesc = response.charge_description;
    this.dataModel.data.chargeNumber = response.charge_number;

    this.dataModel.data.chargeAmount = response.charge_amount;

    this.dataModel.data.chargeDetails = response.chargeDetails;

    this.dataModel.data.chargeType = response.charge_type;
    // this.arrLiabilityOrg = response.lst_contractor;
    // const itemsArray = this.AssignLiabilityForm.get('arr') as FormArray;
    // const newValues = response.lst_liability_owner_details;
    // for(let i = 0; i < newValues.length - 1; i++) {
    //   this.addItem()
    // }

    // newValues.forEach((item, i) => {
    //   // if (i != 0) {
    //   //   this.addItem();
    //   // }
    //   let itemFormGroup = itemsArray.at(i) as FormGroup;
    //   itemFormGroup.patchValue({
    //     contractor_code: item.contractor_code,
    //     notes_text: item.notes_text,
    //     amount: item.amount,
    //     due_date: item.due_date,
    //   });
    // });

    // newValues.forEach((item) => {
    //   const group = this.fb.group({
    //     contractor_id: [item.contractor_id],
    //     notes_text: [item.notes_text],
    //     amount: [item.amount],
    //     due_date: [item.due_date]
    //   });
    //   itemsArray.push(group);
    // });
    // console.log(itemsArray)

    // For filling the customm data

    // For filling the customm data

    // this.dataModel.data.worksReferenceNumber = response.works_reference_number;
    // this.dataModel.data.highwayAuthority = response.highway_authority_name;

    // this.dataModel.data.projectReferenceNumber =
    //   response.project_reference_number;
    // this.dataModel.data.worksDescription = response.works_description;
    // this.dataModel.data.preApprovalAuthoriser =
    //   response.pre_approval_authoriser;
    // not exist in api response
    // let locationtype = response?.location_types.toString();
    // this.dataModel.data.locationTypes = locationtype?.split(',');

    // this.dataModel.data.postCode = response.postcode;
    // if (response.area_name.length > 0)
    //   this.dataModel.data.usrndetails =
    //     response.usrn +
    //     ' - ' +
    //     response.street_name +
    //     ', ' +
    //     response.area_name +
    //     ', ' +
    //     response.town;
    // if (response.area_name.length == 0)
    //   this.dataModel.data.usrndetails =
    //     response.usrn + ' - ' + response.street_name + ', ' + response.town;
    // if (
    //   response.street_name.length == 0 &&
    //   response.area_name.length == 0 &&
    //   response.town.length == 0
    // )
    // this.dataModel.data.usrndetails = response.usrn;
    // this.dataModel.data.worksDescription = response.work_description;
    // this.dataModel.data.worksCategory =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.work_category
    //   );

    // this.dataModel.data.highwayAuthority = response.highway_authority_name;

    // this.dataModel.data.roadType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.road_category.toString()
    //   );

    // this.dataModel.data.promoterOrganisation = response.promoter_organisation;

    // this.dataModel.data.contact =
    //   response.secondary_contact + ' - ' + response.secondary_contact_number;
    // let workstreams: any[] =
    //   this._appRepoHelperService.getUserAccessibleWorkStream();

    // if (workstreams) {
    //   let workstream: any = workstreams.find(
    //     (c) => c.workstreamId == response.workstream_id
    //   );
    //   this.dataModel.data.workStream = workstream?.description;
    // }

    // let contractors: any[] =
    //   this._appRepoHelperService.getUserAccessibleContractors();
    // if (contractors) {
    //   let contractor: any = contractors.find(
    //     (c) => c.contractor_id == response.contractor_id
    //   );
    // this.dataModel.data.accountability = contractor?.contractor_name;
    // }
    // // not exist in api response
    // // this.dataModel.data.accountability = response.accountability;

    // this.dataModel.data.trafficSensitive = DataHelper.booleanToUserText(
    //   response.traffic_sensitive_flag
    // );
    // this.dataModel.data.footwayClosure =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.close_footway
    //   );

    // this.dataModel.data.excavationRequired = DataHelper.booleanToUserText(
    //   response.excavation_flag
    // );

    // this.dataModel.data.isLaneRentalApplicable = DataHelper.booleanToUserText(
    //   response.lane_rental_flag
    // );

    // this.dataModel.data.trafficManagementRequired =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.traffic_management_type
    //   );

    // this.dataModel.data.trafficManagementPlan = response.geometry;
    // this.dataModel.data.permitStatus = response.status_value;
    // this.dataModel.data.workStatus = response.work_status_value;

    // if (response.status == '') this.isDraftPermitView = true;
    // this.dataModel.data.actualStartDateTime = response.actual_start_date;
    // this.dataModel.data.actualEndDateTime = response.actual_end_date;

    // this.dataModel.data.proposedStartDateTime = response.proposed_start_date;
    // this.dataModel.data.proposedEndDateTime = response.proposed_end_date;

    // this.dataModel.data.proposedDuration = response.working_days;
    // this.dataModel.data.cost = response.total_cost;

    // this.dataModel.data.costRisk = response.cost_risk;
    // this.dataModel.data.customerRisk = response.customer_risk;
    // this.dataModel.data.trafficManagementRisk =
    //   response.traffic_management_risk;
    // this.dataModel.data.actions = response.actions;
    // this.dataModel.data.changeDecision = response.change_decision;
    // //     this.dataModel.data.changeDecision = [
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // //       {alteration_id: 352, alteration_number: 'A300057204754-02-CR-02', alteration_status: 'Refused', assessment_comments: 'Inspector has attended site and confirmed that yoeekend or temporary re instate and re plan works.'},
    // // ]

    // // console.log("this.dataModel.data.changeDecision",this.dataModel.data.changeDecision)
    // this.dataModel.data.collaborativeWorkingFlag = DataHelper.booleanToUserText(
    //   response.collaborative_working_flag
    // );
    // this.dataModel.data.collaborationDetails = response.collaboration_details;
    // this.dataModel.data.collaborativeWorks = response.collaborative_works;
    // this.dataModel.data.collaborationType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.collaboration_type
    //   );
    // this.dataModel.data.ttro_requiredFlag = DataHelper.booleanToUserText(
    //   response.ttro_required_flag
    // );
    // this.dataModel.data.environmental_flag = DataHelper.booleanToUserText(
    //   response.environmental_flag
    // );
    // this.dataModel.data.is_covid19_response = DataHelper.booleanToUserText(
    //   response.is_covid19_response
    // );
    // this.dataModel.data.earlyStartPreApprovalFlag =
    //   DataHelper.booleanToUserText(response.early_start_pre_approval_flag);
    // this.dataModel.data.earlyStartReason = response.early_start_reason;
    // this.dataModel.data.preApprovalAuthoriser =
    //   response.pre_approval_authoriser;
    // this.dataModel.data.activityType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.activity_type
    //   );
    // this.dataModel.data.preApprovalDetails = response.pre_approval_details;
    // this.dataModel.data.additionalInfo = response.additional_info;
    // this.dataModel.data.additionalcontact = response.additional_contact;
    // this.dataModel.data.emergencyContact = response.additional_contact_number;
    // this.dataModel.data.emergencyNumber = response.additional_contact_email;

    // this.dataModel.data.additionalcontactemail =
    //   response.emergency_contact_name;
    // this.dataModel.data.additionalcontactemail =
    //   response.emergency_contact_number;

    // this.dataModel.data.expectedEndDateTime = response.expected_end_date;
    // this.dataModel.data.is_pmr_responded = response.is_pmr_responded;
    // this.dataModel.data.is_excavation_carried_out =
    //   DataHelper.booleanToUserText(response.excavation_carried_out);
    // if (this.dataModel.data.is_pmr_responded != null) {
    //   this.showIcon = true;
    // }

    // this.dataModel.data.dcStatus = response.dc_details.dc_status;
    // this.dataModel.data.dcReason = response.dc_details.dc_reason;
    // this.dataModel.data.dcResponseDate = response.dc_details.dc_response_date;
    // // switch (response.assessment_decision.status) {
    // //   case ASSESSMENT_STATUS.ASSESSMENT_GRANTED_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     break;
    // //   case ASSESSMENT_STATUS.ASSESSMENT_MODIFICATION_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     this.assessmentDecision.description = response.assessment_decision.modificationRequestDetails;
    // //     break;
    // //   case ASSESSMENT_STATUS.ASSESSMENT_REFUSED_STATUS:
    // //     this.assessmentDecision.status = response.assessment_decision.display_status;
    // //     this.assessmentDecision.description = response.assessment_decision.refusalDetails;
    // //     this.assessmentDecision.reason = response.assessment_decision.reasonForRefusal;
    // //     this.assessmentDecision.modificationRequestDetails = response.assessment_decision.modificationRequestDetails;
    // //     break;
    // //   default:
    // //     break;
    // // }
  }
  getValue() {
    if (this.AssignPotentialForm.valid) {
      return this.AssignPotentialForm.value;
    }
  }

  resetForm() {
    this.AssignPotentialForm.reset();
  }

  openFileUpload(contractor_code) {
    this.AssignPotentialForm.markAllAsTouched();
    if (this.AssignPotentialForm.valid) {
      console.log(contractor_code);
      let data: any = {};
      data.reference = this.dataModel.data.chargeNumber;
      data.objectType = CHARGE_FORMS_NAME.ASSIGN_LIABILITY;
      data.owner = contractor_code;
      console.log(data);
      this.emitEvent('openFileUploadAssignLiability', data);
    }
  }
  addNewForm() {
    const add = this.AssignPotentialForm.get('address') as FormArray;
    add.push(
      this.fb.group({
        street: [],
        city: [],
      })
    );
  }
  // createItem() {
  //   return this.fb.group({
  //     contractor_code: ['', [Validators.required]],
  //     notes_text: ['', [Validators.maxLength(500)]],
  //     amount: ['', [Validators.required]],
  //     due_date: ['', [Validators.required]],
  //   });
  // }

  // addItem() {
  //   this.arr = this.AssignLiabilityForm.get('arr') as FormArray;
  //   if (this.arr.length < 5) {
  //     this.arr.push(this.createItem());
  //     this.chargeWarning = false;
  //   } else {
  //     this.chargeWarning = true;
  //   }
  // }

  // onSubmit() {
  //   console.log(this.validateLiability());

  //   this.AssignPotentialForm.markAllAsTouched();
  //   console.log(this.AssignPotentialForm.valid);
  //   if (this.validateLiability() && this.AssignPotentialForm.valid) {
  //     this.AssignPotentialForm.value.arr.forEach((ele) => {
  //       const isoDateString = new Date(ele.due_date).toISOString();
  //       ele.due_date = isoDateString;
  //     });

  //     let data: any = {};
  //     data = {
  //       charge_id: this.dataModel.apireqdata.chargeId,
  //       charge_number: this.dataModel.data.chargeNumber ?? '',
  //       charge_type: this.dataModel.apireqdata.chargeType,
  //       lst_liability_owner_details: this.AssignPotentialForm.value.arr,
  //     };

  //     data.lst_liability_owner_details.forEach((contractor) => {
  //       this.arrLiabilityOrg.forEach((org) => {
  //         if (org.contractor_code == contractor.contractor_code) {
  //           contractor.contractor_id = org.contractor_id;
  //         }
  //       });
  //     });

  //     console.log(this.arrLiabilityOrg);

  //     return data;
  //   }
  // }

  areValuesEqual(array) {
    return array.every((value, index, arr) => value === arr[0]);
  }
  calculateCharge(amount, totalVal) {
    let total = amount.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    if (total == totalVal) {
      return true;
    } else {
      return false;
    }
  }
  showAmountError() {
    this.amountValidator = true;
    this.totalValidator = false;
  }

  totalAmountError() {
    this.totalValidator = true;
    this.amountValidator = false;
  }

  validatePotential() {
    this.AssignPotentialForm.markAllAsTouched()
    return this.AssignPotentialForm.valid
  }

  removeUser(i: any) {
    const remove = this.AssignPotentialForm.get('arr') as FormArray;
    remove.removeAt(i);
    this.chargeWarning = false;
  }

  onNotesChange(value) {
    if (value.length > 0) {
      let charRemaining = 500 - value.length;
      this.notesMessage = `Remaining characters ${charRemaining}.`;
    } else {
      this.notesMessage = 'Max 500 characters.';
    }
  }

  onDateChange(event) {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentSeconds = currentTime.getSeconds();

    this.AssignPotentialForm.value.arr.forEach((ele) => {
      const dateObject = new Date(ele.due_date);
      dateObject.setHours(currentHours);
      dateObject.setMinutes(currentMinutes);
      dateObject.setSeconds(currentSeconds);
      ele.due_date = dateObject.toUTCString();
    });
  }
}
