<div class="row">
  <div class="col-md-12">
    <div class="detail-container mb-4" fxFlex="100" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="20px"
      fxLayoutAlign="space-between center">
      <div class="info-element" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Draft Invoice Reference</label>
        <p class="mt-2 text-break">{{this.dataModel.data.reference}}</p>
      </div>
      <div class="info-element" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Final Invoice Reference</label>
        <p class="mt-2 text-break">{{this.dataModel.data.finalInvoiceReference}}</p>
      </div>
      <div class="info-element" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Charge Type</label>
        <p class="mt-2 text-break">{{this.dataModel.data.chargeType}}</p>
      </div>
      <div class="info-element" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Highway Authority </label>
        <p class="mt-2">{{this.dataModel.data.authorityName}}</p>
      </div>
      <div class="info-element" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Invoice Status </label>
        <p class="mt-2">
          <span class="status-pill"
            [ngClass]="getInvoiceStatusCssClass(this.dataModel.data.status)">{{this.dataModel.data.status}}</span>
        </p>
      </div>
      <div class="info-element" fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="isChargeTypeManual()">
        <label for="">Start Date </label>
        <p class="mt-2">{{this.dataModel.data.periodFrom | transformDate}}</p>
      </div>
      <div class="info-element" fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="isChargeTypeManual()">
        <label for="">End Date </label>
        <p class="mt-2">{{this.dataModel.data.periodTo | transformDate}}</p>
      </div>
      <!-- <div class="info-element" fxFlex="6" fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="isChargeTypeManual()">
        <label for="">Cost Estimated</label>
        <p class="mt-2"><span class="black-tab">&pound;</span></p>
      </div> -->
      <div class="info-element" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Cost Draft Invoice</label>
        <p class="mt-2"><span class="blue-tab">{{ this.dataModel.data.totalAmount | transformCurrency}}</span></p>
      </div>
      <div class="info-element" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Cost Paid</label>
        <p class="mt-2"><span class="green-tab">{{this.dataModel.data.costPaid | transformCurrency}}</span></p>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-8">
    <div class="detail-container mb-4" fxFlex="100" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="10px"
      fxLayoutAlign="flex-start strech">
      <div class="info-element" fxFlex="100" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Last Processed Result</label>
        <p class="mt-2 last-processed-result">{{this.dataModel.data.statusMessage}}</p>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="detail-container mb-4" fxFlex="100" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="20px"
    fxLayoutAlign="space-between center">
    <div class="info-element"  fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="showSAPVariables">
      <label for="">Approved Charges</label>
      <p class="mt-2 text-break">{{this.dataModel.data.approvedCharges}}</p>
    </div>

    <div class="info-element"  fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="showSAPVariables">
      <label for="">Sent To SAP</label>
      <p class="mt-2 text-break">{{this.dataModel.data.sentToSAP}}</p>
    </div>

    <div class="info-element"  fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="showSAPVariables">
      <label for="">Not Sent To SAP</label>
      <p class="mt-2 text-break">{{this.dataModel.data.notSentToSAP}}</p>
    </div>
  </div>
  </div>
  <div class="col-md-1 d-flex justify-content-center align-items-center ">
    <mat-icon class="refresh_icon" (click)="refreshPage()" matTooltip="Refresh">
      refresh
    </mat-icon>
  </div>
</div>
