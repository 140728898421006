<div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Number </label>
    <p class="font-weight-bold">{{this.dataModel.data.chargeNumber}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Type </label>
    <p>{{this.dataModel.data.chargeType}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Amount </label>
    <p>&pound;{{this.dataModel.data.chargeAmount}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Permit Type </label>
    <p>{{this.dataModel.data.permitType}}</p>
  </div>
</div>
<hr>
<div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
  <div class="info-element" fxFlex="100" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Description </label>
    <p>{{this.dataModel.data.chargeDesc}}</p>
  </div>
</div>
<hr>

<div class="form-group">
  <form form [formGroup]="DisputeChargeForm" id="filter-form" class="d-flex flex-column p-3" appInvalidControlScroll>
    <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="100">
        <mat-label>Reason for dispute</mat-label>
        <input matInput placeholder="Reason for dispute" autocomplete="off" formControlName="notes">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
    </div>
    <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="100">
        <!-- <mat-label>Upload Evidence(Optional)</mat-label> -->
        <input matInput placeholder="Evidence for dispute" autocomplete="off" formControlName="fileUp" readonly
          value="Upload Evidence (Optional)">
        <mat-icon class="upload" matSuffix (click)="openFileUpload()">attach_file</mat-icon>
      </mat-form-field>
      <!-- <mat-form-field appearance="fill">
        <mat-label>Basic fill input</mat-label>
       <ngx-mat-file-input formControlName="fill" placeholder="Basic fill placeholder"></ngx-mat-file-input>
        <mat-icon matSuffix>folder</mat-icon>
      </mat-form-field> -->
    </div>
    <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <!-- <button (click)="openFileUpload()" mat-stroked-button color="primary">
        <mat-icon>publish</mat-icon>Upload Images
      </button> -->

    </div>
  </form>
</div>
