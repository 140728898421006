import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(size: number, extension: string = 'KB') {
    return (size / (1024)).toFixed(0) + extension;
  }

  // transform(size: number, extension: string = 'MB') {
  //   return (size / (1024 * 1024)).toFixed(2) + extension;
  // }

}

@NgModule({
  declarations: [FileSizePipe],
  exports: [FileSizePipe]
})
export class FileSizePipeModule { }
