import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  InvoiceChargesListConfigModel,
  InvoiceChargesListDataModel,
  ChargesRow,
  InvoiceChargesListRequestModel,
} from './invoice-charges-list.model';
import { FILTER_TYPE, JOURNEY_TYPE } from 'src/app/app-constants';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { CreatePermitDialogData } from 'src/app/models/dialog/create-permit-dialog-data';

import { DIALOG_MODE_UPDATE } from 'src/app/models/dialog/dialog-mode';
import { DataHelper } from 'src/app/utility/data.helper';
import { MASTER_DATA_DOMAIN } from 'src/app/constants/app-repo.constants';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { MASTER_DATA, PERMIT_ACTIONS_CONFIG } from 'src/app/constants/db.constants';
import { Router } from '@angular/router';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { WidgetEvents } from 'src/app/models/common/widget-events';
import { PrimeNGConfig } from 'primeng/api';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl } from '@angular/forms';
import { LocalStorageService } from 'src/app/services/local-storage.service';
@Component({
  selector: 'app-invoice-charges-list',
  templateUrl: './invoice-charges-list.component.html',
  styleUrls: ['./invoice-charges-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})

export class InvoiceChargesListComponent extends WidgetComponentBase {
  @Input() dataModel: InvoiceChargesListDataModel;
  @Input() configModel: InvoiceChargesListConfigModel;
  PERMIT_ACTIONS_CONFIG = PERMIT_ACTIONS_CONFIG;
  dataSource = new MatTableDataSource<ChargesRow>();
  expandedElement: ChargesRow;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  pageChangeSubscription: any;
  sortFieldLabel: string = '';
  length: any;
  MAT_HELPER = MatTableHelper;
  DATA_HELPER = DataHelper;
  arrWorkIdentifier: any = [];
  arrRoadCategori: any = [];
  arrPermitStatus: any[] = [];
  preapplicationid = 0;
  pageIndex: number = 0;
  searchInput = new FormControl();
  manualPemitArr = ['Section 74 [Manual]', 'FPN [Manual]', 'Permit Charges [Manual]', 'Defect [Manual]']
  manualPemitArrColumns = ['ClaimFunction', 'ObjectReference', 'chargeNumber', 'WorkType', 'ChargeTypeBreakdown',
    'ChargeStatus', 'DoaStatus', 'DraftChargeAmount', 'DraftChargeActualAmount', 'View'];
  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _localStorageService: LocalStorageService,
    private _router: Router,
    public dialog: MatDialog,
    private _validationService: ValidationService,
    private _spinner: NgxSpinnerService,
    private _appRepoService: AppRepoService
  ) {
    super(_serverApi, _validationService);
    this.searchInput.valueChanges.subscribe(this.onSearchValueChange);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.pageChangeSubscription = this.paginator.page.subscribe(() => {
      this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
      this.dataModel.apireqdata.top = this.paginator.pageSize;
      this.dataModel.apireqdata.skip =
        this.dataModel.apireqdata.pageno * this.dataModel.apireqdata.top;
      this.wgRefreshData();
    });

    this.wgOnInit();
    this.wgRefreshData();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      if (this.sort.direction) {
        this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();

        switch (this.sort.active) {
          case 'ObjectReference':
            this.sortFieldLabel = 'REFERENCE';
            break;
          case 'WorkType':
            this.sortFieldLabel = 'WORKS_TYPE';
            break;
          case 'ChargeTypeBreakdown':
            this.sortFieldLabel = 'CHARGE_TYPE_BREAKDOWN';
            break;
          case 'ChargeStatus':
            this.sortFieldLabel = 'CHARGE_STATUS';
            break;
          case 'StatusMessage':
            this.sortFieldLabel = 'STATUS_MESSAGE';
            break;
          case 'DoaStatus':
            this.sortFieldLabel = 'DOA_STATUS';
            break;
          case 'LatestUpdateDate':
            this.sortFieldLabel = 'LATEST_UPDATE_DATE';
            break;
          case 'DraftChargeAmount':
            this.sortFieldLabel = 'DRAFT_CHARGE_AMOUNT';
            break;
          case 'FinalChargeAmount':
            this.sortFieldLabel = 'FINAL_CHARGE_AMOUNT';
            break;
          default:
            break;
        }

        this.dataModel.apireqdata.sortField = this.sortFieldLabel;
      }
      else {
        try {
          delete this.dataModel.apireqdata.sortOrder;
          delete this.dataModel.apireqdata.sortField;
        }
        catch (err) {
        }
      }
      this.wgRefreshData();
    });
  }

  getControlData() {
    let url: string = null;

    // if (this.sort.direction) {
    //   this.dataModel.apireqdata.sortOrder = this.sort.direction.toUpperCase();
    //   this.dataModel.apireqdata.sortField = this.sortFieldLabel;
    // }
    // else {
    //   try {
    //     delete this.dataModel.apireqdata.sortOrder;
    //     delete this.dataModel.apireqdata.sortField;
    //   }
    //   catch (err) { }
    // }

    switch (this.dataModel.filterType) {
      case FILTER_TYPE.ADVANCE_FILTER:
        url = this.dataModel.apiUrls.advanceFilter;
        break;
      case FILTER_TYPE.QUICK_FILTER:
        url = this.dataModel.apiUrls.quickFilter;
        break;
      case FILTER_TYPE.DYNAMIC_FILTER:
        url = this.dataModel.apiUrls.dynamicFilter;
        break;
      default:
        break;
    }

    return this.wgAPIMethodGet(url, this.dataModel.apireqdata);
  }

  convertData(response: any) {
    let items: ChargesRow[] = [];

    response.content.forEach((element) => {
      let item: any = {};

      item.ApplicationId = element.application_id;
      item.ChargeId = element.charge_id;
      item.InvoiceId = element.invoice_id;
      item.WorkId = element.work_id;
      item.ObjectReference = element.object_reference;
      item.ChargeNumber = element.charge_number;
      item.GrantedDate = element.granted_date;
      item.Amount = element.amount;
      item.DiscountAmount = element.discount_amount;
      item.ActualAmount = element.actual_amount;
      item.Status = element.status;
      item.StatusMessage = element.status_message;
      item.LastUpdatedDate = element.last_updated_date;
      item.ChargeTypeBreakdown = element.charge_type_breakdown_enum;
      item.DraftInvoiceNumber = element.draft_invoice_number;
      item.InvoiceType = element.invoice_type;
      item.AuthorityName = element.authority_name;
      item.WorkType = element.work_type;
      item.system_claim_status = element.system_claim_status;

      const objectreference = this._localStorageService.getObject('ObjectReference')
      if(objectreference && objectreference == element.charge_id) {
        item.ishighlight = true;
      } else {
        item.ishighlight = false;
      }


      items.push(item);
    });

    let isManual = items.every((item) => { return this.manualPemitArr.includes(item.ChargeTypeBreakdown); });
    if (isManual) {
      this.dataModel.columnsToDisplay = this.dataModel.columnsToDisplay.filter((item) => {
        return this.manualPemitArrColumns.includes(item);
      });
    }

    this.dataSource = new MatTableDataSource(items);
    this.length = response.totalElements;
    this.pageIndex = this.dataModel.apireqdata.pageno


    this._localStorageService.removeObject('ObjectReference')
  }

  setFieldData() {
    this.arrWorkIdentifier = this._appRepoHelperService.getMDataByDomain(
      MASTER_DATA_DOMAIN.WORK_IDENTIFIER.toString()
    );
    this.arrRoadCategori = this._appRepoHelperService.getMDataByDomain(
      MASTER_DATA_DOMAIN.ROAD_CATEGORY.toString()
    );
    this.arrPermitStatus = this._appRepoHelperService.getMDataByDomain(
      MASTER_DATA_DOMAIN.PERMIT_STATUS.toString()
    );
  }

  setMode(responseDataModel: any) { }

  setValue(responseDataModel: any) { }

  ngOnDestroy(): void {
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
  }

  // setPagination() {
  //   let page = this._appRepoHelperService.getGlobalPersistantData('InvoiceViewPaginationDetails');
  //   if(page) {
  //     this.pageIndex = page.pageno
  //   }
  // }

  resetPagination() {
    this.paginator.pageIndex = 0;
    this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
    this.dataModel.apireqdata.skip = 0;
    this.dataModel.apireqdata.top = this.paginator.pageSize;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  getInvoiceChargeStatusCssClass(element) {
    switch (element) {
      case 'Valid':
      case 'Assigned':
      case 'Awaiting Final Invoice':
      case 'Approved Draft':
      case 'Revised Draft Valid':
      case 'DOA Approved':
      case 'Accepted':
      case 'Approved':
      case 'Validated [User]':
      case 'Awaiting Final Invoice (Valid)':
      case 'Validated [CPM]':
      case 'Validated [User] - Awaiting Approval':
        return 'completed';

      case 'Under Investigation':
      // case 'Not Assigned':
      case 'Assigned For Review':
      case 'Assigned for Review':
      case 'Revised Draft Under Investigation':
      case 'Awaiting DOA Approval':
      case 'Assigned As Liability':
      case 'Awaiting Approval (Valid)':
        case 'Awaiting Revised Draft Invoice':
      case 'Requested':
        return 'in_progress';

      case 'Challenged':
      case 'Disputed':
      case 'Revised Draft Challenged':
      case 'Final Challenged':
      case 'Cancelled':
      case 'Error Sending To SAP':
        return 'cancelled';

      // case 'Open':
      case 'Withdrawn':
      case 'Paid':
      case 'Paid (SAP)':
      case 'Sent To SAP':
      case 'Sent to SAP':
      case 'Ready To Send to SAP':
        // case 'Sent To SAP (Partial)':
      case 'Ready To Send To SAP':
      case 'Written Off':
        case 'Invoice Paid':
          case 'Paid (Not SAP)':
          case 'Paid (IQMS)':
        return 'open';

      case 'Issued':
      // case 'Validated[User]':
      // case 'Validated[CPM]':
      case 'Not Assigned':
      case 'Not Requested':
      case 'Open':
      // case 'Validated [User] - Awaiting Approval':
        return 'grey'

        case 'Sent To SAP (Partial)':
          return 'partial'

      case 'Not open':
        return 'black'

      default:
        return 'grey';
    }
  }

  claimIconClass(element) {
    let userInfo: any = this._appRepoHelperService.getMDataByKey(
      MASTER_DATA.USER_INFO
    );

    if (element.system_claim_status == 'Claimed' ) return 'sameUserIcon';
  }

  onViewClick(row) {
    this.emitEvent('NavigateToCharges', row)
  }

  search() {
    let searchString = this.searchInput.value;
    let params = {
      searchString: searchString
    }
    Object.assign(this.dataModel.apireqdata, params);
    this.wgRefreshData();
  }

  clearSearch() {
    this.searchInput.reset();
    this.dataModel.apireqdata = new InvoiceChargesListRequestModel()
    this.wgRefreshData()
  }

  onSearchValueChange(searchVal) {
    let clearSearch = document.getElementsByClassName('clear-search');
    let slide: any;
    for (let i = 0; i < clearSearch.length; i++) {
      slide = clearSearch[i] as HTMLElement;
    }
    if (searchVal) {
      slide.style.visibility = 'visible';
    } else {
      slide.style.visibility = 'hidden';
    }
  }
}
