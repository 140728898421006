import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { GLOBAL_PARAM_KEY } from '../../../../app-constants';
import {
  ChargeFileAttachmentsConfigModel,
  ChargeFileAttachmentsDataModel,
  ChargeFileAttachmentsRequestModel,
  ChargeFileAttachmentsRow,
} from './charge-file-attachments-tab.model';

@Component({
  selector: 'app-charge-file-attachments-tab',
  templateUrl: './charge-file-attachments-tab.component.html',
  styleUrls: ['./charge-file-attachments-tab.component.css'],
})
export class ChargeFileAttachmentsTabComponent extends WidgetComponentBase {
  @Input() dataModel: ChargeFileAttachmentsDataModel;
  @Input() configModel: ChargeFileAttachmentsConfigModel;

  dataSource = new MatTableDataSource<ChargeFileAttachmentsRow>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  searchInput = new FormControl('');
  pageChangeSubscription: any;
  _ChargeFileAttachmentsRequestModel: ChargeFileAttachmentsRequestModel;
  length: any;
  MAT_HELPER = MatTableHelper;
  DATA_HELPER = DataHelper;

  expandedElement: ChargeFileAttachmentsRow;
  columnsToDisplay = ['FileName', 'CreatedBy', 'Date', 'actions'];

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _router: Router,
    public dialog: MatDialog,
    private _notificationService: NotificationService,
    private _validationService: ValidationService
  ) {
    super(_serverApi, _validationService);
    this._ChargeFileAttachmentsRequestModel =
      new ChargeFileAttachmentsRequestModel();
      this.searchInput.valueChanges.subscribe(this.onSearchValueChange)

  }
  ngAfterViewInit(){
    this.onTabChanged(null);
  }

  getControlData() {
   let id = this.dataModel.globalParameters.get('chargeNumber');
  //  let id = 2408;
    return this.wgAPIMethodGet(
      `/api/v1/cpm/getFilesAndAttachments/${id}`
    );
  }
  convertData(
    response: any //
  ) {
    try {
      if (response) {
        console.log('attachment response', response)
        let items: ChargeFileAttachmentsRow[] = [];
        response[0].lst_cpm_files.forEach((element) => {
          let item: any = {};

          item.file_name = element.file_name;
          item.file_uuid = element.file_uuid;
          item.createdby = element.created_by;
          item.createdate = element.create_date;
          items.push(item);
        });

        this.dataSource = new MatTableDataSource(items);
        this.length = items.length; ///TO Do get lenght from API
      }
    } catch (e) {}
  }
  setFieldData() {}

  setMode(responseDataModel: any) {}

  setValue(responseDataModel: any) {}

  applyFilter(filterValue: string) {}

  ngOnInit() {
    this.searchInput.valueChanges.subscribe(this.onSearchValueChange)

    //
    // this.dataSource.paginator = this.paginator;
    // this.pageChangeSubscription = this.paginator.page.subscribe(() => {
    //   this._ChargeFileAttachmentsRequestModel.pageno = this.paginator.pageIndex;
    //   this._ChargeFileAttachmentsRequestModel.top = this.paginator.pageSize;
    //   this._ChargeFileAttachmentsRequestModel.skip =
    //     this._ChargeFileAttachmentsRequestModel.pageno *
    //     this._ChargeFileAttachmentsRequestModel.top;
    //   // TODO: will update this
    //   this.wgRefreshData();
    // });

    // TODO: attach local handlers
    this.wgOnInit();

  }

  openDialog(rowData) {
    this.emitEvent('onViewChargeFileAttachments', rowData);
  }

  onAddComment() {
    this.emitEvent('onAddComment');
  }

  ngOnDestroy(): void {
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
  }
  onTabChanged(event) {
    let clearSearch = document.getElementsByClassName('clear-search')
    for (let i = 0; i < clearSearch.length; i++) {
      const slide = clearSearch[i] as HTMLElement;
      slide.style.visibility = "hidden";
    }
  }

  clearSearch() {
    this.searchInput.reset()
  }

  onSearchValueChange(searchVal) {
    let clearSearch = document.getElementsByClassName('clear-search')
    let slide:any;
    for (let i = 0; i < clearSearch.length; i++) {
      slide = clearSearch[i] as HTMLElement;
    }
    if(searchVal) {
      slide.style.visibility = "visible";
    }else {
      slide.style.visibility = "hidden"
    }
  }

  openFileUpload() {
    this.emitEvent('openFileUploadDialog')
  }
}

const ELEMENT_DATA1: ChargeFileAttachmentsRow[] = [
  {
    Comment: '',
    CommentType: '',
    CommentBy: '',
    CommentId: '',
    Date: '',
    CommentRead: null,
    CommentReadBy: '',
    CommentReadDate: '',
    CommentReference: '',
    CommentSubmit: null,
    CommentTopic: '',
    UserDisplayName: '',
    CommentOrigin: '',
  },
];
