export class LoginAPIRequest {
    username: string;
    password: string;
    // usertypeid:number;
    // validateusername : boolean;
}

export class AuthLoginAPIRequest {
  is_mfa: boolean;
  temp_token : string;
  token : string;
  username: string;
  // message: string;
  // attemptsLeft: string;
  // authNumber : number;
  // usertypeid:number;
  // validateusername : boolean;
}


