<div>
    <button mat-raised-button color="primary" class="primary-button mb-3 w-100" (click)="onActionBtnClick(RECORD_INVOICE_AMMOUNT)">Record
    Invoice
    Charges</button>
    <button mat-raised-button class="payment-button-1 primary-button mb-3 w-100" color="primary" (click)="onActionBtnClick(RECORD_PAYMENT)">Record
    Payment</button>
    <button mat-raised-button class="request-payment-btn primary-button mb-3 w-100" color="primary" (click)="onActionBtnClick(REQUEST_PAYMENT_APPROVAL)">Request Payment Approval</button>
    <button mat-raised-button class="primary-button mb-3 w-100" color="primary" (click)="onActionBtnClick(ADD_TRAFFIC_MANAGEMENT_COST)">Add
    Traffic
    Management Cost</button>
    <!-- <button mat-raised-button color="primary" class="payment-button-2 primary-button  mb-3 w-100"
    (click)="onActionBtnClick(ACCEPT_CHARGE)">Accept charge</button> -->
    <!-- <button mat-raised-button color="primary" class="primary-button  mb-3 w-100"
    (click)="onActionBtnClick(CHALLENGE_PENALTY)">Challenge
    Penalty/charge</button> -->
    <!-- <button mat-raised-button color="primary" class="primary-button mb-3 w-100"
    (click)="onActionBtnClick(WRITE_OFF)">Write-off</button> -->
    <!-- <button mat-raised-button color="primary" class="payment-button-3 primary-button  mb-3 w-100"
    (click)="onActionBtnClick(RECORD_NEGOTIATED_CHARGE)">Record Negotiated Charge</button> -->
    <!-- <button mat-raised-button color="primary" class="payment-button-3 primary-button  mb-3"
(click)="onActionBtnClick()">Record Payment</button> -->
</div>