import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { ErrorDialogService } from 'src/app/services/error.dialog.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { ValidationService } from 'src/app/services/validation.service';
import { FileUploadItemDataModel } from '../file-upload/file-upload-model';
import {
  DragAndDropUploadDataModel,
  DragAndDropUploadConfigModel,
  DragAndDropUploadItemDataModel,
} from './drag-and-drop-upload';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';

@Component({
  selector: 'app-drag-and-drop-upload',
  templateUrl: './drag-and-drop-upload.component.html',
  styleUrls: ['./drag-and-drop-upload.component.css'],
})
export class DragAndDropUploadComponent
  extends WidgetComponentBase
  implements OnInit
{
  @Input() dataModel: DragAndDropUploadDataModel;
  @Input() configModel: DragAndDropUploadConfigModel;
  file: File | null = null;
  public files: any[] = [];
  wgFormGroup: FormGroup;
  formDataFromParent: FormData;
  uploadedFiles: any[] = [];
  selectedFiles: any[] = [];
  disabled = false;
  progressValue: any = 0;
  sessiontoken = '';
  disableBtn: boolean = true;
  @Input() accept = '.pdf, .jpg, .png, .doc, .docx, .jpeg';
  permitDrawMapDataModel: any;

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private http: HttpClient,
    private fb: FormBuilder,
    private _appRepoHelperService: AppRepoHelperService,
    private _notificationService: NotificationService,
    private _errorDialogService: ErrorDialogService,
    private _sessionStorageService: SessionStorageService,
    private _validationService: ValidationService
  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit(): void {
    // this.wgOnInit();
    this.createFormGroup();

    // this.Disablebutton();
  }

  setFieldData() {}

  getControlData() {
    // return new Promise((resolve, reject) => {
    //   let uplaodform: any = this.dataModel.globalParameters.get(
    //     GLOBAL_PARAM_KEY
    //   );

    //   uplaodform = uplaodform ? uplaodform : new Object();
    //   if (uplaodform) {
    //     resolve(uplaodform);
    //   } else {
    //     reject(uplaodform);
    //   }
    // });
  }

  convertData(response: any) {
    // let attachmentid = this.dataModel.globalParameters.get(
    //   GLOBAL_PARAM_KEY
    // );
    if (this.dataModel.mode == 'edit') {
      this.disabled = true;
      response.forEach((element: DragAndDropUploadItemDataModel) => {
        // if (element.attachment_id === attachmentid) {
        //   this.wgFormGroup.patchValue({
        //     // AttachmentId :response.attachmentId,
        //     filename: element.file_name,
        //     filedescription: element.description,
        //     mapimage: element.is_map_image,
        //     primaryimage: element.is_primary_image,
        //     // file: element.attachment_name,
        //   });
        // }
      });
    } else {
      this.wgFormGroup.patchValue({
        filename: '',
        filedescription: '',
        mapimage: false,
        primaryimage: false,
        // file: '',
      });
      this.wgFormGroup.reset();
      this.disabled = false;
    }
  }

  setMode(responseDataModel: any) {}

  setValue(responseDataModel: any) {}

  createFormGroup() {
    this.wgFormGroup = this.fb.group({
      filename: [''],
      filedescription: [''],
      mapimage: [false],
      primaryimage: [false],
      file: [''],
    });
  }

  openFileUpload() {
    this.wgFormGroup.markAllAsTouched();
    if (this.wgFormGroup.valid) {
      let data: any = {
        filename: this.wgFormGroup.controls.filename.value,
        file: this.files,
        filedescription: this.wgFormGroup.controls.filedescription.value,
        mapimage: this.wgFormGroup.controls.mapimage.value
          ? this.wgFormGroup.controls.mapimage.value
          : false,
        primaryimage: this.wgFormGroup.controls.primaryimage.value
          ? this.wgFormGroup.controls.primaryimage.value
          : false,
      };
      this.emitEvent('uploadclick', data);
      this.files = [];
      this.wgFormGroup.reset();
    }
  }

  cancelFileUpload() {
    let data: any = new Object();
    this.emitEvent('Canceluploadclick', data);
    this.files = [];
    this.wgFormGroup.reset();
    this.enableUploadBtn();
  }

  ConfirmopenFileUpload() {
    this.wgFormGroup.markAllAsTouched();
    if (this.wgFormGroup) {
      let data: any = {
        filename: this.wgFormGroup.controls.filename.value,
        file: this.files,
        filedescription: this.wgFormGroup.controls.filedescription.value,
        mapimage: this.wgFormGroup.controls.mapimage.value
          ? this.wgFormGroup.controls.mapimage.value
          : false,
        primaryimage: this.wgFormGroup.controls.primaryimage.value
          ? this.wgFormGroup.controls.primaryimage.value
          : false,
      };

      this.emitEvent('confirm_newclick', data);
      this.files = [];
      this.wgFormGroup.reset();
    }
  }

  Validatefileupload(event, files, i) {
    let temp = files[i].name.split('.');
    let extension = temp[1];

    if (files[i].size / 1024 / 1024 > 20) {
      this._errorDialogService.openDialog(
        '',
        'The file you are trying to upload is larger than the file size expected by Symology. Please ensure that you file size is less than 20MB'
      );
      this.cancelFile(i);
    } else if (files[i].size / 1024 / 1024 == 0) {
      this._errorDialogService.openDialog(
        '',
        'The file you are trying to upload is of size 0 Bytes expected by Symology.'
      );
      this.cancelFile(i);
    } else if (
      !(
        extension == 'pdf' ||
        extension == 'jpg' ||
        extension == 'png' ||
        extension == 'doc' ||
        extension == 'docx' ||
        extension == 'jpeg' ||
        extension == 'xlsx' ||
        extension == 'csv'
      )
    ) {
      this._errorDialogService.openDialog(
        `Invalid file type, allowed file types: .pdf, .jpg, .png, .doc, .docx, .jpeg., .xlsx, .csv`,
        files[i].name
      );
      this.cancelFile(i);
    } else {
      this._notificationService.success('File Validated');
      return true;
    }
  }

  // onFileChange(pFileList: File[]){
  //   if(this.files.length < 5) {
  //     this.files.push(pFileList[0]);
  //     var temp =  this.files[0].name.split('.')
  //     var extension = temp[1]
  //     let index = this.files.length - 1
  //   this.Validatefileupload(event, this.files, index);
  //   }else {
  //     this._notificationService.error('Maximum 5 files can be uploaded.')
  //   }

  //   this.enableUploadBtn()
  // }

  // onFileChange(pFileList: File[]){

  //   let result = Object.keys(pFileList).length;
  //   if(this.files.length < 5 ) {
  //     let tempObj;
  //     if(this.files.length > 1) {
  //       this.files.forEach(ele => {
  //         if(ele.name == pFileList[0].name) {
  //           this._errorDialogService.openDialog(`File already exists`,pFileList[0].name);
  //           return
  //         }
  //       })
  //     }else if(result != 0) {
  //       this.files.push(pFileList[0]);
  //       tempObj = pFileList[0];
  //       var temp =  this.files[0].name.split('.')
  //     } else {
  //       this.files.push(pFileList);
  //       var temp =  this.files[0].name.split('.')
  //       tempObj = pFileList;
  //     }
  //     let index = this.files.length - 1
  //   this.Validatefileupload(event, this.files, index);
  //   }else {
  //     this._notificationService.error('Maximum 5 files can be uploaded.')
  //   }

  //   this.enableUploadBtn()
  // }

  onFileChange(pFileList: FileList) {
    // this.files = Object.keys(pFileList).map(key => pFileList[key]);
    for (let i = 0; i < pFileList.length; i++) {
      const file = pFileList.item(i);
      const fileNameExists = this.files.some((f) => f.name === file.name);

      if (fileNameExists) {
        console.log('File with the same name already exists:', file.name);
        this._errorDialogService.openDialog(
          'File with the same name already exists',
          file.name
        );
      }

      if (file.size > 10485760) {
        console.log('File size exceeds the limit (10 MB):', file.name);
        this._errorDialogService.openDialog(
          'The file you are trying to upload is larger than the file size expected by Symology. Please ensure that you file size is less than 10MB',
          file.name
        );
        continue;
      }

      const allowedFileTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
        ''
      ];
      if (!allowedFileTypes.includes(file.type)) {
        console.log('Invalid file type:', file.name);
        this._errorDialogService.openDialog(
          `Invalid file type, allowed file types: .pdf, .jpg, .png, .doc, .docx, .jpeg., .xlsx, .csv, .msg`,
          file.name
        );
        continue;
      }

      if (file.size === 0) {
        console.log('Empty file:', file.name);
        this._errorDialogService.openDialog(
          'The file you are trying to upload is of size 0 Bytes expected by Symology.',
          file.name
        );
        continue;
      }

      this.files.push(file);
    }

    // if (this.files.length >= 5) {
    //   this._errorDialogService.openDialog(
    //     'Maximum 5 files can be uploaded.',
    //     ''
    //   );
    //   this.files = [];
    // }

    this.enableUploadBtn();
  }

  enableUploadBtn() {
    if (this.files.length >= 1) {
      this.disableBtn = false;
    } else {
      this.disableBtn = true;
    }
  }

  onClear() {
    this.files = [];
    this.enableUploadBtn();
  }

  cancelFile(index) {
    this.files.splice(index, 1);
    this.enableUploadBtn();
  }

  truncateFileName(fileName: string): string {
    const maxLength = 10; // Adjust the value as needed
    if (fileName.length > maxLength) {
      const truncatedName =
        fileName.substring(0, 6) + '...' + fileName.slice(-6);
      return truncatedName;
    }
    return fileName;
  }
  }
