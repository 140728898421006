import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { ColumnListDataModel, ColumnListConfigModel } from './column-list.model';

@Component({
  selector: 'app-column-list',
  templateUrl: './column-list.component.html',
  styleUrls: ['./column-list.component.css']
})
export class ColumnListComponent extends WidgetComponentBase implements OnInit {
  @Input() dataModel: ColumnListDataModel;
  @Input() configModel: ColumnListConfigModel;
  columnNameFormGroup: FormGroup;
  fields = [];
  constructor(
    private _serverApiBase: ServerApiInterfaceServiceService,
    private _validationService: ValidationService,
    private fb: FormBuilder
  ) {
    super(_serverApiBase, _validationService);
  }

  ngOnInit(): void {
    console.log("Column List ",this.dataModel);
    this.createNewFromGroup();
    this.wgOnInit();
     this.columnNameFormGroup.valueChanges.subscribe((value)=> {
      let group = this.columnNameFormGroup;
      this.dataModel.columnList.map(ele => {
        ele.flag = this.columnNameFormGroup.get(ele.formControlName).value
      })
      this.emitEvent("addColumn",{group: group, columnList:this.dataModel.columnList});
    })
  }
  setFieldData() {
  }

  getControlData() {
  }

  convertData(response: any) {
  }

  setMode(responseDataModel: any) {
  }

//   this.dataModel.columnList.forEach((col) => {
//     if(group.get(col.formControlName).value){
//           this.emitEvent("addColumn",col);
//         // if(!this.chargesListDataModel.columnsToDisplay.includes(col.formControlName)){
//         //   // let index = this.chargesListDataModel.columnsToDisplay.indexOf(col.nextcol);
//         //   // if(index != -1){
//         //     let index = col.position;
//         //     this.chargesListDataModel.columnsToDisplay.splice(index, 0, col.formControlName);
//         // //  }
//         // }
//     }else{
//        this.emitEvent("removeColumn",col);
//       // if(this.chargesListDataModel.columnsToDisplay.includes(col.formControlName)){
//       //   let index = this.chargesListDataModel.columnsToDisplay.indexOf(col.formControlName)
//       //   this.chargesListDataModel.columnsToDisplay.splice(index, 1);
//       // }
//     }
// })

  setValue(responseDataModel: any) {
  }

  createNewFromGroup(){
    const formGroupFields = this.getFormControlsFields();
    this.columnNameFormGroup = new FormGroup(formGroupFields);
    if(this.dataModel.data) {
      for (const [name, value] of Object.entries(this.dataModel.data)) {
        this.columnNameFormGroup.controls[name].setValue(value)
      }
    }
  }
  getFormControlsFields() {
    const formGroupFields = {};
    this.dataModel.columnList.forEach((field)=>{
      formGroupFields[field.formControlName] = new FormControl(true);
      this.fields.push(field);
    })
    // for (const field of Object.keys(this.model)) {
    //   formGroupFields[field] = new FormControl("");
    //   this.fields.push(field);
    // }
    return formGroupFields;
  }


}
