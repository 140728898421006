<div class="stepper-cost-container">
    <div class="row">
        <div class="col-12">
            <ul class="ml-0 pl-0">
                <li *ngFor="let item of dataModel.PCPStepperfilterModel">
                    <div [ngClass]="{'not-allow': item.display == '1' }" [ngStyle]="{'display':item.display=='2'?'none':''}">
                        <div class="a pointer cost-count" [ngClass]="{

            }" [ngStyle]="{ 'pointer-events': item.display == '1' ? 'none' : '', 'backgroundColor': item.display=='1' ? '#b6b7b6':item.color,
            'opacity':item.display=='1'?'1':item.color}" style="cursor: pointer;" [routerLink]="['/admin/pcp-charges-dashboard']">
                            <span class="long-and-truncated" [matTooltip]="item.cost " style="cursor: pointer;">{{ item.count }}</span>
                        </div>

                        <div class="status_tittle" [ngStyle]="{'color':'item.color'}">
                            {{ item.name }}
                            <br />
                            <div class="status_tittle1" *ngIf="item.countvisible == '1'" (click)="onCountClick(item)">
                                <p *ngIf=" item.isValueVisible == 'true'">
                                    {{ item.count }}
                                </p> &nbsp;
                                <p *ngIf=" item.isPercentageVisible == 'true'">
                                    ({{ item.countpercentage | number: "1.0-2" }}%)

                                </p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
