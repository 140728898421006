import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  PERMIT_ACTIONS_CONFIG,
  MASTER_DATA_DOMAIN,
} from 'src/app/constants/db.constants';
import { WidgetEvents } from 'src/app/models/common/widget-events';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { MatTableHelper } from 'src/app/utility/mat-table.helper';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import {
  ViewActionHistoryDataModel,
  ViewActionHistoryConfigModel,
} from '../../view-acion-history/view-action-history';
import { ChargesRow, AppList } from '../charges-list/charges-list.model';
import { AllNotesDataModel, AllNotesConfigModel } from './all-notes';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-all-notes',
  templateUrl: './all-notes.component.html',
  styleUrls: ['./all-notes.component.css'],
})
export class AllNotesComponent extends WidgetComponentBase implements OnInit {
  @Input() dataModel: AllNotesDataModel;
  @Input() configModel: AllNotesConfigModel;
  PERMIT_ACTIONS_CONFIG = PERMIT_ACTIONS_CONFIG;
  dataSource = new MatTableDataSource<ChargesRow>();
  expandedElement: ChargesRow;
  inactiveAppList: AppList[] = [];
  filterValue: any;
  panelOpenState: boolean[] = [];
  paginator: any;
  noteArr: any[] = [];
  searchInput = new FormControl('');
  maxNoteLength = 30;
  @ViewChild('htmlData') htmlData!: ElementRef;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
  //   this.paginator = mp;
  //   this.getPagination();
  // }
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  pageChangeSubscription: any;
  sortFieldLabel: string = '';
  length: any;
  pageSize = 5;
  MAT_HELPER = MatTableHelper;
  DATA_HELPER = DataHelper;
  arrWorkIdentifier: any = [];
  arrRoadCategori: any = [];
  arrPermitStatus: any[] = [];
  preapplicationid = 0;
  showFiles: boolean = false
  showNotes: boolean = false
  showNotesFirst: boolean = false
  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _router: Router,
    public dialog: MatDialog,
    private _validationService: ValidationService,
    private _appRepoService: AppRepoService
  ) {
    super(_serverApi, _validationService);
    this.searchInput.valueChanges.subscribe(this.onSearchValueChange)
  }

  ngOnInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    // this.pageChangeSubscription = this.paginator.page.subscribe(() => {
    //   this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
    //   this.dataModel.apireqdata.top = this.paginator.pageSize;
    //   this.pageSize = this.paginator.pageSize
    //   this.dataModel.apireqdata.skip =
    //     this.dataModel.apireqdata.pageno * this.dataModel.apireqdata.top;
    //   // TODO: will update this
    //   this.setValue();
    //   this.wgRefreshData();
    // });

    // TODO: attach local handlers
    this.wgOnInit();
    console.log('Notes history',this.dataModel)

    for (let i = 0; i < this.dataModel.data?.length; i++) {
      // this.panels.push( this.dataModel.data[i] );
      if (i == 0) this.panelOpenState.push(true);
      else {
        this.panelOpenState.push(false);
      }
    }

    if(this.dataModel.chargeType == 'Section 74') {
      this.showNotesFirst = true
    }
  }

  getPagination() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.pageChangeSubscription = this.paginator.page.subscribe(() => {
      this.dataModel.apireqdata.pageno = this.paginator.pageIndex;
      this.dataModel.apireqdata.top = this.paginator.pageSize;
      this.pageSize = this.paginator.pageSize;
      this.dataModel.apireqdata.skip =
        this.dataModel.apireqdata.pageno * this.dataModel.apireqdata.top;
      // TODO: will update this
      this.setValue();
      this.wgRefreshData();
    });
  }

  ngAfterViewInit() {
    if (this.dataModel.isSelfDataLoad == false) {
      // this.convertData(this.dataModel.data);
    }
  }

  getControlData() {
    // INFO: ServerAPI
    let url: string = null;
    console.log('url from Action History', this.dataModel.apiUrls);
    console.log('url from Action History', this.dataModel.apireqdata);
    return this.wgAPIMethodGet(
      this.dataModel.apiUrls,
      this.dataModel.apireqdata
    );
  }

  convertData(response: any) {
    console.log('response in Action List', response);
    let items: ChargesRow[] = [];
    this.noteArr = [];

    response.forEach((element) => {
      let item: any = {};
      item.lst_cpm_notes = [];
      let tempObj: any = {};
      let lstTempObj: any = {};
      item.lst_cpm_files = [];

      item.action_type = element.action_type ?? element.file_object_type;
      item.count = element.notes_count ?? element.files_count;
      if(element.lst_cpm_notes) {
        element.lst_cpm_notes.forEach((ele) => {
          tempObj.job_owner = ele.added_by;
          tempObj.note = ele.notes_text;
          tempObj.date = ele.added_date;
          tempObj.primary = ele.primary_contractor;
          tempObj.secondary = ele.secondary_contractor;
          tempObj.isSliced = true;
          item.lst_cpm_notes.push(tempObj);
          tempObj = {};
        });
      }
      if(element.lst_cpm_files) {
        element.lst_cpm_files.forEach(ele => {
          lstTempObj.file_name = ele.file_name;
          lstTempObj.imageSrc = this.getExtension(ele.file_extension);
          lstTempObj.file_uuid = ele.file_uuid;
          lstTempObj.create_date = ele.create_date;
          lstTempObj.created_by = ele.created_by;
          item.lst_cpm_files.push(lstTempObj)
          lstTempObj = {}
        })
      }
      this.noteArr.push(item);
    });
    // this.dataSource = new MatTableDataSource(items);
    this.length = response.totalElements;
  }

  setFieldData() {
    this.arrWorkIdentifier = this._appRepoHelperService.getMDataByDomain(
      MASTER_DATA_DOMAIN.WORK_IDENTIFIER.toString()
    );
    this.arrRoadCategori = this._appRepoHelperService.getMDataByDomain(
      MASTER_DATA_DOMAIN.ROAD_CATEGORY.toString()
    );
    this.arrPermitStatus = this._appRepoHelperService.getMDataByDomain(
      MASTER_DATA_DOMAIN.PERMIT_STATUS.toString()
    );
  }

  setMode(responseDataModel: any) {}

  setValue() {
    // this.isFpnChargview = this.globalParameters.get('isFpnChargview');
    // this.fpnChargeOverviewModel.isFpnChargview = this.isFpnChargview;
    // this.filterValue = this.globalParameters.get('chargesFilterValue');
    // this.dataModel.filterValue = this.filterValue
  }

  // setValue(responseDataModel: any) {}

  applyFilter(filterValue: string) {
    // if (filterValue == "all") {
    //   this.dataSource.filter = "";
    // } else {
    //   filterValue = filterValue.trim();
    //   filterValue = filterValue.toLowerCase();
    //   this.dataSource.filter = filterValue;
    // }
  }

  onExpandCollapse(element) {
    if (element.ApplicationId != this.preapplicationid) {
      this.preapplicationid = element.ApplicationId;
      this.expandedElement = element;
      return this.getApplicationsDetails(element);
    }
  }

  getExtension(fileType) {
    switch (fileType) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        return 'assets/images/jpeg-file.png';
      case 'xls':
      case 'xlsx':
        return '/assets/images/xls-file.png';
      case 'pdf':
        return 'assets/images/pdf-file.png';
      case 'docx':
      case 'doc':
        return 'assets/images/doc-file.png';
      case 'csv':
        return 'assets/images/csv-file.png';
      default:
        return;
    }
  }

  getApplicationsDetails(element) {
    super
      .wgAPIMethodGet(`/api/v1/applications/${element.ApplicationId}`, null)
      .then((response: any) => {
        if (response) {
          element.inactive_app_list = [];
          element.WorksReferenceNumber = response.work_reference_number;
          element.HighwayAuthority = response.highway_authority_name;
          element.Workstream = response.department_name;
          element.PromoterPrefix = response.promoter_prefix;
          element.PromoterOrganization = response.promoter_organisation;
          element.ProjectReferenceNumber = response.project_reference_number;
          element.Contact = response.secondary_contact;
          // not pressent in api response
          element.ProposedDuration = response.working_days;
          element.Cost = response.total_cost;

          //  element.RoadType = this._appRepoHelperService.getMDataDisplayTextByValue(element.road_category.toString());
          element.TrafficSensitive = response.traffic_sensitive_flag;
          element.FootwayClosure = response.close_footway;

          element.ExcavationRequired = response.excavation_flag;
          element.IsLaneRentalApplicable = response.lane_rental_flag;
          element.TrafficManagementRequired = response.traffic_management_type;
          element.WorkDescription = response.work_description;

          // this.arrWorkIdentifier.forEach(element1 => {
          //   if (element1.workIdentifierId == response.work_description) {
          //     element.WorkDescription = element1.description;
          //   }
          // });

          this.arrRoadCategori.forEach((element1) => {
            if (element1.value == response.road_category) {
              element.RoadType = element1.displayText;
            }
          });

          response.inactive_app_list.forEach((element11) => {
            let item1: any = {};
            item1.ApplicationId = element11.application_id;
            item1.permitRefNo = element11.permit_reference_number;
            item1.applistStatus = element11.status;

            element.inactive_app_list.push(item1);
          });

          // this.inactiveAppList =temp;

          // element.inactive_app_list = this.inactiveAppList;

          element.FootwayClosureDisplayText =
            this._appRepoHelperService.getMDataDisplayTextByValue(
              element.FootwayClosure
            );
          element.TrafficManagReqDisplayText =
            this._appRepoHelperService.getMDataDisplayTextByValue(
              element.TrafficManagementRequired
            );
        }
      });
  }
  checkEditPermitEnable(actionName, row) {
    // if (this._appRepoHelperService.hasPermitActionPermission(actionName)) {
    if ('status' in row) {
      if (row.status == 'draft') {
        return true;
      } else {
        return false;
      }
    }
    // }
  }

  onViewClick(row, index) {
    // console.log("row", row);
    console.log('index', index);
    console.log(row);
    row.index = index;
    // let obj = { row: row, index: index}
    console.log(this.emitEvent(WidgetEvents.VIEW_CLICK, row));
    this.emitEvent(WidgetEvents.VIEW_CLICK, row);
  }

  onInactivePermitViewClick(row) {
    this.emitEvent('inActivePermitView', row);
  }

  onEditPermitIconClick($event, rowData) {
    $event.stopPropagation();
    this.emitEvent('editPermit', rowData);
  }

  onDeletePermitIconClick($event, rowData) {
    $event.stopPropagation();
    this.emitEvent('deletePermit', rowData);
  }

  openFileUpload() {
    this.emitEvent('openFileUploadDialog');
  }

  onDownloadFile(rowData) {
    this.emitEvent('openFileUponViewChargeFileAttachmentsloadDialog', rowData);
  }

  onDeleteFile(data) {
    this.emitEvent('onClickDeleteFile', data)
  }

  ngOnDestroy(): void {
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
  }

  resetPagination() {
    this.dataModel.apireqdata.pageno = 0;
    this.dataModel.apireqdata.skip = 0;
    this.dataModel.apireqdata.top = this.pageSize;
  }
  stopPropagation(event) {
    event.stopPropagation();
  }

  getHelpTextData(data) {
    // switch (data) {
    //   case 'CostRisk':
    //     return 1001;
    //     case 'CustomerRisk':
    //     return 1002;
    //     case 'TrafficManagement':
    //     return 1003;
    //   default:
    //     return null;
    // }
    return null;
  }
  // downloadPdf() {
  //   console.log('pdf');
  //   let DATA: any = document.getElementById('htmlData');
  //   html2canvas(DATA).then((canvas) => {
  //     let fileWidth = 208;
  //     let fileHeight = (canvas.height * fileWidth) / canvas.width;
  //     const FILEURI = canvas.toDataURL('image/png');
  //     let PDF = new jsPDF('p', 'mm', 'a4');
  //     let position = 0;
  //     PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
  //     PDF.save('Estimated-work-list.pdf');
  //   });
  // }

  resetExpands() {
    this.panelOpenState = [];
    for (let i = 0; i < this.noteArr.length; i++) {
      // this.panels.push( this.dataModel.data[i] );
      if (i == 0) this.panelOpenState.push(true);
      else {
        this.panelOpenState.push(false);
      }
    }
  }

  openPanelHandler(index: number) {
    for (let i = 0; i < this.noteArr.length; i++) {
      if(i == index) {
        this.panelOpenState[i] = !this.panelOpenState[i]
      } else {
        this.panelOpenState[i] = false;
      }
    }
    // this.panelOpenState[index] = true;
  }

  expandRow(rowData, outerindex, innerindex) {
    if (rowData.isSliced == true) {
      this.noteArr[outerindex].lst_cpm_notes[innerindex].isSliced = false;
    } else {
      this.noteArr[outerindex].lst_cpm_notes[innerindex].isSliced = true;
    }
  }

  showMore(value, isSliced) {
    if (value) {
      if (value.length > this.maxNoteLength && !!isSliced) {
        return '...more';
      } else if (value.length < this.maxNoteLength && !!isSliced) {
        return;
      } else {
        return '...less';
      }
    }
  }

  getText(rowData) {
    if (rowData.isSliced) {
      let string = rowData.note.trim();
      return string.slice(0, this.maxNoteLength);
    } else {
      let string = rowData.note.trim();
      return string;
    }
  }

  clearSearch() {
    this.searchInput.reset()
  }

  onSearchValueChange(searchVal) {
    let clearSearch = document.getElementsByClassName('clear-search')
    let slide:any;
    for (let i = 0; i < clearSearch.length; i++) {
      slide = clearSearch[i] as HTMLElement;
    }
    if(searchVal) {
      slide.style.visibility = "visible";
    }else {
      slide.style.visibility = "hidden"
    }
  }
}
