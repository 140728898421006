<div class="row mb-2">
  <div class="col text-center">
    <div class=" headingtitle"> Estimated Risk Exposure ( &#163; )
    </div>
  </div>

  <div style="width: 100%; height:300px">
    <canvas style="width: 100%; height:100%" #barChart></canvas>
  </div>
</div>
