<div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 matDialogTitle>Data Preview</h2>
    <button mat-icon-button class="close-button icon-outside" [mat-dialog-close]="true">
      <mat-icon class="close-icon material-icons color_white">close</mat-icon>
    </button>
  </div>
  <hr >
  <mat-dialog-content class="mat-typography" >
    <div class="inspection-list custom-table ">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
          <!-- Position Column -->
          <ng-container matColumnDef="PermitRefNumber">
            <th mat-header-cell *matHeaderCellDef>Permit Ref Number</th>
            <td mat-cell *matCellDef="let element" style="padding-left: 20px !important;">
              {{element.PermitRefNumber}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="WorksLocation">
            <th mat-header-cell *matHeaderCellDef style="max-width: 150px;">Works Location</th>
            <td mat-cell *matCellDef="let element"> {{element.WorksLocation}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="WorksCategory">
            <th mat-header-cell *matHeaderCellDef>Works Category</th>
            <td mat-cell *matCellDef="let element"> {{element.WorksCategory}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="WorkDescription">
            <th mat-header-cell *matHeaderCellDef>Work Description</th>
            <td mat-cell *matCellDef="let element"> {{element.WorkDescription}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="ProjectRefNumber">
            <th mat-header-cell *matHeaderCellDef>Project Ref Number</th>
            <td mat-cell *matCellDef="let element"> {{element.ProjectRefNumber}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="StartDate">
            <th mat-header-cell *matHeaderCellDef>Start Date</th>
            <td mat-cell *matCellDef="let element"> {{element.StartDate}} </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="EndDate">
            <th mat-header-cell *matHeaderCellDef>End Date</th>
            <td mat-cell *matCellDef="let element"> {{element.EndDate}} </td>
          </ng-container>
           <!-- Position Column -->
           <ng-container matColumnDef="Cost">
            <th mat-header-cell *matHeaderCellDef>Cost</th>
            <td mat-cell *matCellDef="let element"> {{element.Cost}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
  </mat-dialog-content>
  <hr>
  <mat-dialog-actions align="end">
    <button mat-raised-button class="primary-button mb-2" color="primary" [mat-dialog-close]="true" >Upload</button>
  
  </mat-dialog-actions>