<div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Number </label>
    <p class="font-weight-bold">{{this.dataModel.data.chargeNumber}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Amount </label>
    <p>&pound;{{this.dataModel.data.chargeAmount}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Permit Type </label>
    <p>{{this.dataModel.data.permitType}}</p>
  </div>
  <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Type </label>
    <p class="status-pill">{{this.dataModel.data.chargeType}}</p>
  </div>
</div>
<hr>
<div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
  <div class="info-element" fxFlex="100" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">Charge Description </label>
    <p>{{this.dataModel.data.chargeDesc}}</p>
  </div>
</div>
<hr>

<div class="form-group">
  <form form [formGroup]="assignJobOwnerForm" id="filter-form" class="d-flex flex-column p-3" appInvalidControlScroll>
    <div class="fixed-underline" class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <div class="team" fxFlex="50">
        <mat-icon class="clear-icon" *ngIf="assignJobOwnerForm.get('teams').value" (click)="onClearTeam()">clear</mat-icon>
        <!-- <mat-icon class="dropdown-icon" *ngIf="!assignJobOwnerForm.get('teams').value">arrow_drop_down</mat-icon> -->
        <mat-form-field appearance="fill">
          <mat-label>Select Teams</mat-label>
          <mat-select class="select" formControlName="teams" [disableOptionCentering]="true" (selectionChange)="onTeamChange($event.value)" >
            <input placeholder="Search Team" class="search-input" (keyup)="onSearchTeam($event.target.value)">
            <mat-option [value]="permitStatus.team_name" *ngFor="let permitStatus of arrTeams">
              {{permitStatus.team_name}}</mat-option>
          </mat-select>
          <mat-error>Field must be valid</mat-error>
        </mat-form-field>
      </div>
      <div class="user"  fxFlex="50">
        <mat-icon class="clear-icon" *ngIf="assignJobOwnerForm.get('users').value" (click)="onClearUser()">clear</mat-icon>
        <!-- <mat-icon class="dropdown-icon" *ngIf="!assignJobOwnerForm.get('users').value">arrow_drop_down</mat-icon> -->
        <mat-form-field appearance="fill">
          <mat-label>Select User</mat-label>
          <mat-select class="select" formControlName="users" [disableOptionCentering]="true" (selectionChange)="onUserChange($event.value)">
            <input placeholder="Search User" class="search-input" (keyup)="onSearchValueChange($event.target.value)">
            <mat-option [value]="permitStatus.user_id" *ngFor="let permitStatus of arrJobOwner">
              {{permitStatus.user_name}}</mat-option>
          </mat-select>
          <mat-error>Field must be valid</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="fixed-underline" class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="100" hintLabel={{notesMessage}}>
        <mat-label>Notes</mat-label>
        <textarea matInput placeholder="Notes" [maxLength]="500" rows="4" style="resize: none;" autocomplete="off" formControlName="notes" (keyup)="onNotesChange($event.target.value)"></textarea>
        <mat-error>Max character limit 500</mat-error>
      </mat-form-field>
    </div>
    <div class="fixed-underline" fxFlex="100" fxLayoutGap="10px">
      <mat-form-field appearance="fill" fxFlex="100">
        <input matInput placeholder="Upload Document" autocomplete="off" readonly
          value="Upload Evidence (Optional)">
        <mat-icon class="upload" matSuffix (click)="openFileUpload()">attach_file</mat-icon>
      </mat-form-field>
    </div>
  </form>
</div>
