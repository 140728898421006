import { EventEmitter } from 'events';
import { GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { WidgetConstants } from 'src/app/constants/widget-constants';

export class DynamicAdvanceFilterConfigModel {
  static getInstance<T>(): DynamicAdvanceFilterConfigModel {
    let model = new DynamicAdvanceFilterConfigModel();
    return model;
  }

  public static readonly CALLER_TO_COMP_REFRESH_DATA = 'REFRESH_DATA';
  public static readonly COMP_TO_CALLER_APPLY_FILTER =
    WidgetConstants.APPLY_FILTER;
  public static readonly CALLER_TO_COMP_RESET_FILTER =
    WidgetConstants.RESET_TO_DEFAULT;

  CompToCaller = new EventEmitter();
  CallerToComp = new EventEmitter();

  EventAction = new Map();
}

export class DynamicAdvanceFilterDataModel {
  isGlobalParams: any;
  isSelfDataLoad: any;
  columnsToDisplay: any;
  globalParamterKeys: any;
  globalParameters: Map<any, any>;
  showFunnel: boolean = false;
  filterGlobalParams: any;
  listName: any;
  apiUrl: string;
  data: DynamicFilterModel;
  apireqdata: any;
  appGlobalParamsKeyForAdvFilter: string;

  static getInstance<T>(): DynamicAdvanceFilterDataModel {
    let model = new DynamicAdvanceFilterDataModel();
    model.isSelfDataLoad = true;
    model.columnsToDisplay = undefined;
    model.globalParameters = new Map<any, any>();
    model.apireqdata = {};
    model.appGlobalParamsKeyForAdvFilter = GLOBAL_PARAM_KEY.APP_FILTER;
    return model;
  }
}

export class DynamicFilterModel {
  fields: FieldMetaData[];
}

export class FieldMetaData {
  order: number;
  filter_id: number;
  form_control_name_from: string;
  form_control_name_to: string;
  field_type: string;
  label_text: string;
  form_control_name: any[];
  field_data: any[];
  filtered_data: any[];
  show_filter_data: boolean = false;
  default_value: any;
  validations: any;
}
