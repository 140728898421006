import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicAdvanceFilterConfigModel, DynamicAdvanceFilterDataModel, DynamicFilterModel, FieldMetaData } from './dynamic-advance-filter';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';
import { DYNAMIC_FILTER_KEY, FILTER_TYPE, GLOBAL_PARAM_KEY, QUICK_FILTER_KEY } from 'src/app/app-constants';
import { AppFilterModel, PersistantAdvanceFilterModel } from 'src/app/models/common/app-filter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SessionStorageService } from 'src/app/services/session-storage.service';

@Component({
  selector: 'app-dynamic-advance-filter',
  templateUrl: './dynamic-advance-filter.component.html',
  styleUrls: ['./dynamic-advance-filter.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicAdvanceFilterComponent extends WidgetComponentBase implements OnInit {
  @Input() dataModel: DynamicAdvanceFilterDataModel;
  @Input() configModel: DynamicAdvanceFilterConfigModel;

  uiFieldContainerArray: DynamicFilterModel;
  filteredArr: boolean = true;
  expandFilter: boolean = false;
  tooltipVisible: boolean = false;
  selectRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  minDate: any;
  maxDate: any;

  constructor(
    private _serverApiBase: ServerApiInterfaceServiceService,
    private _validationService: ValidationService,
    private fb: FormBuilder,
    private _appRepoService: AppRepoService,
    private _appRepoHelperService: AppRepoHelperService,
    private _notificationService: NotificationService,
    private _sessionStorageService: SessionStorageService
  ) {
    super(_serverApiBase, _validationService);
  }

  ngOnInit() {
    this.createFilterFormGroup();
    this.wgFormGroup.valueChanges.subscribe((formValue) => {
      // this.handleFormChange(formValue);
    });
  }

  createFilterFormGroup() {
    this.wgFormGroup = this.fb.group({})
    this.dataModel.data = {
      "fields": [
        {
          "filter_id": 1,
          "label_text": "Search",
          "field_type": "TEXTBOX",
          "form_control_name": ["searchString"],
          "form_control_name_from": null,
          "form_control_name_to": null,
          "default_value": null,
          "validations": "100",
          "order": 1,
          "field_data": null,
          "show_filter_data": false,
          "filtered_data": []
        },
      ]
    }

    this.dataModel.data.fields.forEach(control => {
      control.form_control_name.forEach(name => {
        this.wgFormGroup.addControl(name, this.fb.control(''))
      })
    });
    this.wgOnInit();
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   if(this.dataModel.filterGlobalParams) {
    //     let elename = document.getElementsByClassName('picker') as HTMLCollection;
    //     let slide;
    //     for (let i = 0; i < elename.length; i++) {
    //       slide = elename[i] as HTMLElement;
    //       slide.style.visibility = "hidden";
    //     }
    //     for (const [name, value] of Object.entries(this.dataModel.filterGlobalParams)) {
    //       if(name.includes('To')) {
    //         continue;
    //       } else {
    //         let hideele = document.getElementById(name)
    //         hideele.style.visibility = 'visible'
    //       }
    //     }
    //   }
    // }, 2000)
    // this.wgRefreshData()
  }

  onPanelOpened() {
    let elename = document.getElementsByClassName('picker') as HTMLCollection;
      let slide;
      for (let i = 0; i < elename.length; i++) {
        slide = elename[i] as HTMLElement;
        slide.style.visibility = "hidden";
      }
      if(this.dataModel.filterGlobalParams) {
        for (const [name, value] of Object.entries(this.dataModel.filterGlobalParams)) {
          if(name.includes('To')) {
            continue;
          } else {
            let hideele = document.getElementById(name)
            hideele.style.visibility = 'visible'
          }
        }
      }
  }

  setFieldData() {
    // this.dataModel.data.fields.forEach(ele => {
    //   this.wgFormGroup.controls[ele.formcontrolname].setValue(ele.default_value)
    // })
  }

  getControlData() {
    return this.wgAPIMethodGet(this.dataModel.apiUrl, this.dataModel.apireqdata);
  }

  convertData(response: any) {
    if(response) {
      // console.log('Dynamic Filter', response)
      this.dataModel.data.fields = []
      this.dataModel.data = response;
    }

    // Add form controls
    // this.dataModel.data.fields.forEach(control => this.wgFormGroup.addControl(control.form_control_name, this.fb.control('')));
    this.dataModel.data.fields.forEach(control => {
      control.form_control_name.forEach(name => {
        this.wgFormGroup.addControl(name, this.fb.control(''))
      })
    });
    // Setting default values from API
    // this.dataModel.data.fields.forEach(ele => {
    //   if(ele.default_value) {
    //     this.wgFormGroup.controls[ele.form_control_name].setValue(ele.default_value)
    //   }
    // })
  }

  setMode(responseDataModel: any) {}

  setValue(responseDataModel: any) {
    switch (this.dataModel.listName) {
      case DYNAMIC_FILTER_KEY.ALL_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.getGlobalPersistantData(DYNAMIC_FILTER_KEY.ALL_WORK_LIST)
        break;
      case DYNAMIC_FILTER_KEY.ESTIMATED_ALL_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.getGlobalPersistantData(DYNAMIC_FILTER_KEY.ESTIMATED_ALL_WORK_LIST)
        break;
      case DYNAMIC_FILTER_KEY.ESTIMATED_MY_TEAMS_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.getGlobalPersistantData(DYNAMIC_FILTER_KEY.ESTIMATED_MY_TEAMS_WORK_LIST)
        break;
      case DYNAMIC_FILTER_KEY.MY_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.getGlobalPersistantData(DYNAMIC_FILTER_KEY.MY_WORK_LIST)
        break;
      case DYNAMIC_FILTER_KEY.MY_TEAMS_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.getGlobalPersistantData(DYNAMIC_FILTER_KEY.MY_TEAMS_WORK_LIST)
        break;
      case DYNAMIC_FILTER_KEY.LIABILITY_ASSIGNMENT:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.getGlobalPersistantData(DYNAMIC_FILTER_KEY.LIABILITY_ASSIGNMENT)
        break;
      case DYNAMIC_FILTER_KEY.INVOICE_ALL_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.getGlobalPersistantData(DYNAMIC_FILTER_KEY.INVOICE_ALL_WORK_LIST)
        break;
      case DYNAMIC_FILTER_KEY.INVOICE_MY_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.getGlobalPersistantData(DYNAMIC_FILTER_KEY.INVOICE_MY_WORK_LIST)
        break;
      case DYNAMIC_FILTER_KEY.INVOICE_MY_TEAMS_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.getGlobalPersistantData(DYNAMIC_FILTER_KEY.INVOICE_MY_TEAMS_WORK_LIST)
        break;
      default:
        break;
    }

    if (this.dataModel.filterGlobalParams) {
      for (let property in this.dataModel.filterGlobalParams) {
        const formControlName = property;
        let searchValue;
        if (this.dataModel.filterGlobalParams[property].length > 1) {
          searchValue = this.dataModel.filterGlobalParams[property];
        } else {
          searchValue = this.dataModel.filterGlobalParams[property][0];
        }

        const field = this.dataModel.data.fields.find(
          (item) => item.form_control_name[0] === formControlName
        );

        let fieldValue;

        if (field && field.field_data && searchValue) {
          // const fieldValue = field.field_data.find(
          //   (item) => item.value === searchValue
          // );
          if(!Array.isArray(searchValue)) {
            let val = searchValue
            searchValue = []
            searchValue.push(val)
          }

          fieldValue = field.field_data.filter((d: any) => {
            // return searchValue.indexOf(d.value) > -1;
            if(d.value == null) {
              return searchValue.indexOf(d.id) > -1;
            } else {
              return searchValue.indexOf(d.value) > -1;
            }
          });

          if (fieldValue) {
            // console.log(
            //   `Found value '${searchValue}' for form control '${formControlName}' in field '${field.label_text}'`
            // );
            this.wgFormGroup.controls[property].setValue(fieldValue)
          } else {
            // console.log(
            //   `Value '${searchValue}' for form control '${formControlName}' not found in field '${field.label_text}'`
            //   );
            }
          } else {
            this.wgFormGroup.controls[property].setValue(this.dataModel.filterGlobalParams[property])
            // console.log(`Value for '${formControlName}' is ${this.dataModel.filterGlobalParams[property]}`);
        }
      }

      setTimeout(() => {
        // console.log(this.dataModel.filterGlobalParams)
        // this.onPanelOpened()
        if(Object.keys(this.dataModel.filterGlobalParams).length > 0) {
          this.expandFilter = true;
          this.dataModel.showFunnel = true;
        } else {
          this.expandFilter = false;
          this.dataModel.showFunnel = false;
        }

        // this.emitEvent(DynamicAdvanceFilterConfigModel.COMP_TO_CALLER_APPLY_FILTER);
      }, 500)
    }
  }

  getValue() {
    const data: any = {};
    for (const key in this.wgFormGroup.value) {
      if (Object.prototype.hasOwnProperty.call(this.wgFormGroup.value, key) && this.wgFormGroup.value[key]) {
        // data[key] = this.wgFormGroup.value[key];
        let value = this.wgFormGroup.value[key];
        if(value instanceof Date) {
          data[key] = this.formatDate(value);
        } else {
            if(typeof value == 'object') {
              data[key] = value.map(val => {
                if(val.id == null) {
                  return val.value;
                } else {
                  return val.id;
                }
              })
            }else {
              data[key] = value
            }
          }
        }
      }
    return data;
  }

  applyFilter() {
    let filterData = this.getValue()
    let filterGlobalParams: AppFilterModel =
    this._appRepoService.appRepo.appGlobalPersistantData.get(
      GLOBAL_PARAM_KEY.APP_FILTER
    );
    filterGlobalParams.persistantfilterType = FILTER_TYPE.ADVANCE_FILTER
    if(Object.keys(filterData).length >= 1) {
      if (this.wgFormGroup.valid) {
        this.setFilterDataToAppGlobalParams();
        this.dataModel.showFunnel = true;
        this.expandFilter = true;
        this.emitEvent(DynamicAdvanceFilterConfigModel.COMP_TO_CALLER_APPLY_FILTER);
      } else {
        try {
          // this._notificationService.error(
          //   this.wgFormGroup.errors.dateCompare.errorMessage
          // );
        } catch (exception) {}
      }

    } else {
      this.onResetFilterClick()
    }
  }

  setFilterDataToAppGlobalParams() {
    let filterData = this.getValue();
    switch (this.dataModel.listName) {
      case DYNAMIC_FILTER_KEY.ALL_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.setGlobalPersistantData(DYNAMIC_FILTER_KEY.ALL_WORK_LIST, filterData)
        break;
      case DYNAMIC_FILTER_KEY.ESTIMATED_ALL_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.setGlobalPersistantData(DYNAMIC_FILTER_KEY.ESTIMATED_ALL_WORK_LIST, filterData)
        break;
      case DYNAMIC_FILTER_KEY.ESTIMATED_MY_TEAMS_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.setGlobalPersistantData(DYNAMIC_FILTER_KEY.ESTIMATED_MY_TEAMS_WORK_LIST, filterData)
        break;
      case DYNAMIC_FILTER_KEY.MY_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.setGlobalPersistantData(DYNAMIC_FILTER_KEY.MY_WORK_LIST, filterData)
        break;
      case DYNAMIC_FILTER_KEY.MY_TEAMS_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.setGlobalPersistantData(DYNAMIC_FILTER_KEY.MY_TEAMS_WORK_LIST, filterData)
        break;
      case DYNAMIC_FILTER_KEY.LIABILITY_ASSIGNMENT:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.setGlobalPersistantData(DYNAMIC_FILTER_KEY.LIABILITY_ASSIGNMENT, filterData)
        break;
      case DYNAMIC_FILTER_KEY.INVOICE_ALL_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.setGlobalPersistantData(DYNAMIC_FILTER_KEY.INVOICE_ALL_WORK_LIST, filterData)
        break;
      case DYNAMIC_FILTER_KEY.INVOICE_MY_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.setGlobalPersistantData(DYNAMIC_FILTER_KEY.INVOICE_MY_WORK_LIST, filterData)
        break;
      case DYNAMIC_FILTER_KEY.INVOICE_MY_TEAMS_WORK_LIST:
        this.dataModel.filterGlobalParams = this._appRepoHelperService.setGlobalPersistantData(DYNAMIC_FILTER_KEY.INVOICE_MY_TEAMS_WORK_LIST, filterData)
        break;
      default:
        break;
    }
    this.handleFormChange(this.dataModel.filterGlobalParams)
  }

  onResetFilterClick() {
    this.reset();
    this.dataModel.showFunnel = false;
    this.expandFilter = false
    this.setFilterDataToAppGlobalParams()
    this.setGlobalPersistantData().then(() => {
      this.emitEvent(DynamicAdvanceFilterConfigModel.COMP_TO_CALLER_APPLY_FILTER);
    });
  }

  setGlobalPersistantData() {
    return new Promise((resolve, reject) => {
      let appFilterModel: AppFilterModel =
        this._appRepoService.appRepo.appGlobalPersistantData.get(
          this.dataModel.appGlobalParamsKeyForAdvFilter
        );
      appFilterModel.persistantfilterType = FILTER_TYPE.ADVANCE_FILTER;
      appFilterModel.persistantAdvanceFilterModel = null;
      resolve(null);
    });
  }

  reset() {
    this.selectRange.reset()
    this.wgReset();
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  onRangeDateChange(event: MatDatepickerInputEvent<Date>) {
    this.minDate = this.selectRange.controls.start.value
    this.maxDate = this.selectRange.controls.end.value
  }

  // onSearch(value, formcontrolname) {
  //   let filter = value.toLowerCase();
  //   if(filter.length > 0) {
  //     this.dataModel.data.fields.forEach((form, index) => {
  //       if(form.form_control_name == formcontrolname) {
  //         this.dataModel.data.fields[index].field_data = form.field_data.filter(value => {
  //           return value.name.toLowerCase().includes(filter)
  //         })
  //       }
  //     })
  //   } else {
  //     this.dataModel.data = this.filterArr
  //   }
  // }

  onSearch(searchValue, fieldData, index) {
    let filterVal = searchValue.toLowerCase()
    if(filterVal.length > 0) {
      this.dataModel.data.fields[index].filtered_data = this.dataModel.data.fields[index].field_data.filter(option => option.name.toLowerCase().includes(filterVal));
      this.dataModel.data.fields[index].show_filter_data = true;
    } else {
      this.dataModel.data.fields[index].filtered_data = this.dataModel.data.fields[index].field_data
      this.dataModel.data.fields[index].show_filter_data = true;
    }
  }

  checkValue(value : any, id){
    if (typeof value == 'object') {
      let ele = document.getElementById(id)
      if(value && ele) {
        ele.style.visibility = 'visible'
      }else {
        ele.style.visibility = 'hidden'
      }
    }
  }

  onClearIndividualFilter(formControlName, formControlName_2?) {
    this.wgFormGroup.controls[formControlName].setValue('')
    if(formControlName_2 != null) {
      this.wgFormGroup.controls[formControlName_2].setValue('')
    }
    this.applyFilter()
  }

  handleFormChange(formValue: any) {
    for (const [name, value] of Object.entries(formValue)) {
      if(name.includes('To')) {
        continue;
      } else {
        let ele = document.getElementById(name)
        if(value && ele) {
          ele.style.visibility = 'visible'
        }

        if(!value && ele) {
          ele.style.visibility = 'hidden'
        }
      }
    }
  }

  showTooltip(event: MouseEvent) {
    this.tooltipVisible = true;
    // Position the tooltip near the cursor
    // console.log(event)
    this.setPosition(event.clientX, event.clientY);
  }

  hideTooltip() {
    this.tooltipVisible = false;
  }

  setPosition(x: number, y: number) {
    // Set the tooltip position based on the cursor coordinates
    // You can use CSS styles or a custom positioning logic here
    const tooltip = document.querySelector('.custom-tooltip');
    y = 10;
    if (tooltip) {
      tooltip.setAttribute('style', `top: ${y}px; left: ${x}px`);
    }
  }

  getObjectLength(obj: object): number {
    if(obj == undefined || obj == null) return 0;
    return Object.keys(this.dataModel.filterGlobalParams).length;
  }

}
