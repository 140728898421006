<!-- <div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 matDialogTitle>Inspection Details</h2>
  <button mat-icon-button class="close-button icon-outside" [mat-dialog-close]="true">
    <mat-icon class="close-icon material-icons color_white">close</mat-icon>
  </button>
</div> -->
<hr>
<!-- <mat-dialog-content class="mat-typography"> -->
<div class="change-request-info mat-typography" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="0">
    <div class="left-side" fxFlex="70">
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Permit reference number</label>
                <p>{{dataModel.data.PermitReferenceNumber}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Section 74 Reference Number</label>
                <p>{{dataModel.data.section74_reference_number}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">HA Status</label>
                <p>{{dataModel.data.ha_status}}</p>
            </div>


        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Promoter Status</label>
                <p>{{dataModel.data.promoter_status}}</p>
            </div>

            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Overrun Warning Reasons</label>
                <p>{{dataModel.data.overrun_warning_reasons_display}}</p>
            </div>

            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Overrun Warning Details</label>
                <p>{{dataModel.data.overrun_warning_details}}</p>
            </div>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">

            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Actual End Date</label>
                <p>{{dataModel.data.actual_end_date | transformDate}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Proposed End Date</label>
                <p>{{dataModel.data.proposed_end_date | transformDateTime}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Inspection Date</label>
                <p>{{dataModel.data.inspection_date | transformDateTime}}</p>
            </div>
        </div>
        <!-- <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="100">
                <label for="">Failure reasons</label>
                <div class="failure-reason-container" fxLayout="column" fxLayoutGap="7px">
                    <div *ngFor="let failurereason of dataModel.data.FailureReasonsArr" fxLayout="row" fxLayoutGap="20px">
                        <p fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start end">{{failurereason.failure_reason}}</p>
                        <mat-icon [inline]="true" class="ic-info" [matTooltip]="failurereason.details">
                            info_outline</mat-icon>
                    </div>
                </div>
            </div>
        </div> -->
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Issuing Authority</label>
                <p>{{dataModel.data.ha_authority}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Authorised Officer Name</label>
                <p>{{dataModel.data.officer_name}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Officer Contact Details</label>
                <p>{{dataModel.data.officer_contact_details}}</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Works Promoter</label>
                <p>{{dataModel.data.Promoter}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Promoter Prefix</label>
                <p>{{dataModel.data.PromoterPrefix}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Road Type</label>
                <p>{{dataModel.data.road_type}}</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Location</label>
                <p>{{dataModel.data.location_description}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Town</label>
                <p>{{dataModel.data.Town}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Traffic Sensitivity</label>
                <p>{{dataModel.data.traffic_sensitivity}}</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">USRN</label>
                <p>{{dataModel.data.usrn}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Location Types</label>
                <p>{{dataModel.data.location_types}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Section74 Evidence</label>
                <p>{{dataModel.data.section74_evidence}}</p>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Latest HA Response Date</label>
                <p>{{dataModel.data.latest_ha_response_date | transformDate}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Latest Promoter Response Date</label>
                <p>{{dataModel.data.latest_promoter_response_date | transformDate}}</p>
            </div>

            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Site Visited Date</label>
                <p>{{dataModel.data.site_visited_date | transformDate}}</p>
            </div>
        </div>



        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">HA Reason For Dispute</label>
                <p>{{dataModel.data.ha_reason_for_dispute}}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Promoter Reason For Dispute</label>
                <p>{{dataModel.data.promoter_reason_for_dispute}}</p>
            </div>



            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">HA Response No. of Days Overrun</label>
                <p>{{dataModel.data.ha_response_number_of_days_overrun}}</p>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Draft Invoice Reference</label>
                <p>{{dataModel.data.draft_invoice_reference }}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Invoice Reference</label>
                <p>{{dataModel.data.invoice_reference }}</p>
            </div>
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Draft Invoice Amount</label>
                <p>{{dataModel.data.draft_invoice_amount}}</p>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="10px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Latest HA Additional Details</label>
                <p>{{dataModel.data.latest_ha_additional_details}}</p>
            </div>

            <div class="info-element left-strip" fxFlex="flex-grow">
                <label for="">Latest Promoter Additional Details</label>
                <p>{{dataModel.data.latest_promoter_additional_details}}</p>
            </div>

        </div>

        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="10px">
            <div class="info-element left-strip" fxFlex="33.3">
                <label for="">Final Agreed Amount</label>
                <p>{{dataModel.data.final_agreed_amount }}</p>
            </div>

            <div class="info-element left-strip" fxFlex="flex-grow">
                <label for="">Site Cleared Date</label>
                <p>{{dataModel.data.site_cleared_date | transformDate}}</p>
            </div>
            <!-- <div class="info-element left-strip" fxFlex="33.3">
        <label for="">Invoice Reference</label>
        <p>{{dataModel.data. }}</p>
    </div>
    <div class="info-element left-strip" fxFlex="33.3">
      <label for="">Draft Invoice Amount</label>
      <p>{{dataModel.data.}}</p>
  </div> -->
        </div>


        <div>
          <form *ngIf="wgFormGroup" [formGroup]="wgFormGroup">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px">
                <mat-form-field appearance="outline" fxFlex="50" *ngIf="dataModel.data.actionList.length > 0">
                    <mat-label>Select Action</mat-label>
                    <mat-select formControlName="status" (selectionChange)="onchangeAction($event)">
                        <mat-option *ngFor="let action of dataModel.data.actionList" [value]="action">{{action.label}}</mat-option>

                    </mat-select>
                    <mat-error>Field must be valid</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" *ngIf="dataModel.siteVisitedDateVisibile">
                    <!-- <mat-form-field appearance="outline" fxFlex="50" *ngIf="this.optionEventActionService.getHideShowData(dataModel.data.actionList,changeActionValue, 'site_visited_date')"> -->
                    <mat-label>Site Visited Date</mat-label>
                    <input matInput autocomplete="off" [ngxMatDatetimePicker]="startDatePicker" placeholder="Choose a date" formControlName="site_visited_date" />
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #startDatePicker [showSeconds]="true" [stepHour]="1" [stepMinute]="1" [stepSecond]="1" [enableMeridian]="false">
                    </ngx-mat-datetime-picker>
                    <mat-error>Field must be valid</mat-error>
                </mat-form-field>


            </div>
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px">

                <mat-form-field appearance="outline" fxFlex="50" *ngIf="dataModel.additionalDetailsVisible">
                    <mat-label>Additional Detials
                    </mat-label>
                    <textarea style="resize: none;" rows="3" formControlName="additional_details" matInput placeholder="Enter your comment"></textarea>
                    <mat-error>Field must be valid</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" *ngIf="dataModel.disputeDetailsVisible">
                    <mat-label>Dispute Reason
                    </mat-label>
                    <textarea style="resize: none;" rows="3" formControlName="reason_for_dispute" matInput placeholder="Enter your Dispute Reason"></textarea>
                    <mat-error>Field must be valid</mat-error>
                </mat-form-field>



            </div>



        </form>
        </div>
    </div>
    <!-- <div class="image-container" fxFlex="30">
        <div fxLayout="row wrap" fxLayoutAlign="space-evenly center" *ngFor="let inspectionFile of dataModel.data.InspectionFiles" fxLayoutGap="50px" (click)="onFileClick(inspectionFile.ImageSrc)">
            <div class="image-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <img class="fileuplod" [src]="inspectionFile.ImageSrc" alt="">
            </div>
        </div>
    </div> -->

    <div class="image-container" fxFlex="30">
        <div fxLayout="row wrap" fxLayoutAlign="space-evenly center" *ngFor="let i of dataModel.data.InspectionFiles" fxLayoutGap="50px" (click)="onFileClick(i.ImageSrc)">

            <div class="image-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <img class="fileuplod" [src]="i.ImageSrc" alt="">
            </div>

        </div>
    </div>


</div>
<!-- </mat-dialog-content> -->
<hr>
<div class="row">
  <div class="col-6">
      <!-- <div class="text-start">
          <mat-icon class="pointer pdfdownload me-4" matTooltip="Download FPN" (click)="savePdf('FPN_DETAILS')">picture_as_pdf</mat-icon>

      </div> -->
  </div>
  <div class="col-6">
      <div class="d-flex justify-content-end">
          <button *ngIf="dataModel.data.actionList.length > 0; else showOkBtn" mat-raised-button class="primary-button mb-2" color="primary" (click)="onUpdateBtnClick()">Update</button>
          <ng-template #showOkBtn>
              <button mat-raised-button class="primary-button mb-2" color="primary" (click)="onOkBtnClick()">Ok</button>
          </ng-template>
      </div>
  </div>


</div>
