<div class="form-group">
  <form form [formGroup]="HAExcelMapForm" id="filter-form" class="d-flex flex-column p-3" appInvalidControlScroll>

    <div class="flex-container">
      <mat-form-field appearance="fill" class="flex-item" *ngIf="displayFields.start_row_index" >
        <mat-label>Start Row Index</mat-label>
        <input matInput placeholder="Start Row Index" autocomplete="off" formControlName="start_row_index">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="flex-item" *ngIf="displayFields.permit_reference">
        <mat-label>Permit Reference</mat-label>
        <input matInput placeholder="Permit Reference" autocomplete="off" formControlName="permit_reference">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="flex-item" *ngIf="displayFields.alteration_reference">
        <mat-label>{{ dataModel.apireqdata.chargeType === 'PERMIT_CHARGES' ? 'Alteration Reference' : 'Inspection Reference'}}</mat-label>
        <input matInput placeholder="{{ dataModel.apireqdata.chargeType === 'PERMIT_CHARGES' ? 'Alteration Reference' : 'Inspection Reference'}}" autocomplete="off" formControlName="alteration_reference">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="flex-item" *ngIf="displayFields.work_prefix">
        <mat-label>Works Prefix</mat-label>
        <input matInput placeholder="Works Prefix" autocomplete="off" formControlName="work_prefix">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
      <div style="position: relative;" class="flex-item" *ngIf="displayFields.prepend_work_prefix">
        <mat-icon class="clear-icon" *ngIf="HAExcelMapForm?.get('prepend_work_prefix')?.value" (click)="HAExcelMapForm?.get('prepend_work_prefix')?.setValue(null)">clear</mat-icon>
        <mat-form-field appearance="fill" fxFlex="100">
          <mat-label>Prepend Works Prefix</mat-label>
          <mat-select [disableOptionCentering]="true" formControlName="prepend_work_prefix">
            <mat-option [value]="user.value" *ngFor="let user of arrIsStandardTemplate">
              {{user.displayText}}</mat-option>
          </mat-select>
          <mat-error>Field must be valid</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="fill" class="flex-item" *ngIf="displayFields.granted_date">
        <mat-label>{{ dataModel.apireqdata.chargeType === 'PERMIT_CHARGES' ? 'Granted Date' : 'Inspection Date'}}</mat-label>
        <input matInput placeholder="{{ dataModel.apireqdata.chargeType === 'PERMIT_CHARGES' ? 'Granted Date' : 'Inspection Date'}}" autocomplete="off" formControlName="granted_date">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="flex-item" *ngIf="displayFields.charge_amount">
        <mat-label>Charge Amount</mat-label>
        <input matInput placeholder="Charge Amount" autocomplete="off" formControlName="charge_amount">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="flex-item" *ngIf="displayFields.discount_amount">
        <mat-label>Discount Amount</mat-label>
        <input matInput placeholder="Charge Amount" autocomplete="off" formControlName="discount_amount">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="flex-item" *ngIf="displayFields.actual_amount">
        <mat-label>Actual Amount</mat-label>
        <input matInput placeholder="Actual Amount" autocomplete="off" formControlName="actual_amount">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>

      <div style="position: relative;" class="flex-item" *ngIf="displayFields.charge_type">
        <mat-icon class="clear-icon" *ngIf="HAExcelMapForm?.get('charge_type')?.value" (click)="HAExcelMapForm?.get('charge_type')?.setValue(null)">clear</mat-icon>
        <mat-form-field appearance="fill" fxFlex="100">
          <mat-label>Charge Type</mat-label>
          <mat-select formControlName="charge_type">
            <mat-option [value]="user.value" *ngFor="let user of arrChargeType">
              {{user.displayText}}</mat-option>
          </mat-select>
          <mat-error>Field must be valid</mat-error>
        </mat-form-field>
      </div>

      <!-- <div style="position: relative;" class="flex-item">
        <mat-icon class="clear-icon" *ngIf="HAExcelMapForm?.get('chargetypeidentifiers')?.value" (click)="HAExcelMapForm?.get('chargetypeidentifiers')?.setValue(null)">clear</mat-icon>
        <mat-form-field appearance="fill" fxFlex="100">
          <mat-label>Charge Type Identifiers</mat-label>
          <mat-select formControlName="chargetypeidentifiers">
            <input placeholder="Search" class="search-input" (keyup)="onSearchApprovalBy($event.target.value)">
            <mat-option [value]="user.user_id" *ngFor="let user of arrChargeType">
              {{user.doa_user_name}}</mat-option>
          </mat-select>
          <mat-error>Field must be valid</mat-error>
        </mat-form-field>
      </div> -->

      <mat-form-field appearance="fill" class="flex-item" *ngIf="displayFields.charge_type_identifiers">
        <mat-label>Charge Type Identifiers</mat-label>
        <input matInput placeholder="Charge Type Identifiers" autocomplete="off" formControlName="charge_type_identifiers">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>

      <!-- <div style="position: relative;" class="flex-item">
        <mat-icon class="clear-icon" *ngIf="HAExcelMapForm?.get('chargetypevariationidentifier')?.value" (click)="HAExcelMapForm?.get('chargetypevariationidentifier')?.setValue(null)">clear</mat-icon>
        <mat-form-field appearance="fill" fxFlex="100">
          <mat-label>Charge Type Variation Identifiers</mat-label>
          <mat-select formControlName="chargetypevariationidentifier">
            <input placeholder="Search" class="search-input" (keyup)="onSearchApprovalBy($event.target.value)">
            <mat-option [value]="user.user_id" *ngFor="let user of arrChargeType">
              {{user.doa_user_name}}</mat-option>
          </mat-select>
          <mat-error>Field must be valid</mat-error>
        </mat-form-field>
      </div> -->

      <mat-form-field appearance="fill" class="flex-item" *ngIf="displayFields.charge_type_variation_identifiers">
        <mat-label>Charge Type Variation Identifiers</mat-label>
        <input matInput placeholder="Charge Type Variation Identifiers" autocomplete="off" formControlName="charge_type_variation_identifiers">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="flex-item" *ngIf="displayFields.sheet_index_to_imports">
        <mat-label>Sheet Indexes To Import</mat-label>
        <input matInput placeholder="Sheet Indexes To Import" autocomplete="off" formControlName="sheet_index_to_imports">
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>

      <div style="position: relative;" class="flex-item" *ngIf="displayFields.is_standardised_template">
        <mat-icon class="clear-icon" *ngIf="HAExcelMapForm?.get('is_standardised_template')?.value" (click)="HAExcelMapForm?.get('is_standardised_template')?.setValue(null)">clear</mat-icon>
        <mat-form-field appearance="fill" fxFlex="100">
          <mat-label>Is Standard Template</mat-label>
          <mat-select [disableOptionCentering]="true" formControlName="is_standardised_template">
            <mat-option [value]="user.value" *ngFor="let user of arrIsStandardTemplate">
              {{user.displayText}}</mat-option>
          </mat-select>
          <mat-error>Field must be valid</mat-error>
        </mat-form-field>
      </div>

    </div>


  </form>
</div>
