import {
  ASSESSMENT_STATUS,
  PRODUCT_SERVICES,
} from 'src/app/constants/app-repo.constants';
import {
  AssessmentDecision,
  FpnChargeKeyDetailsConfigModel,
  FpnChargeKeyDetailsModel,
} from './fpn-charge-key-details.model';
import { Component, Input, OnInit } from '@angular/core';
import {
  StepperCostConfigModel,
  StepperCostDataModel,
} from 'src/app/modules/widget/stepper/stepper-cost/stepper-cost.model';

import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { AppRepoService } from 'src/app/services/app-repo.service';
import { DataHelper } from 'src/app/utility/data.helper';
import { GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { MatDialog } from '@angular/material/dialog';
import { PERMIT_ACTIONS_CONFIG } from 'src/app/constants/db.constants';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';

@Component({
  selector: 'app-fpn-charge-key-details',
  templateUrl: './fpn-charge-key-details.component.html',
  styleUrls: ['./fpn-charge-key-details.component.css'],
})
export class FpnChargeKeyDetailsComponent
  extends WidgetComponentBase
  implements OnInit
{
  @Input() dataModel: FpnChargeKeyDetailsModel;
  @Input() configModel: FpnChargeKeyDetailsConfigModel;

  // will delete later
  MatchStatus: string = 'Not Matched';
  AccountabilityStatus: string = 'Open';
  ReconcillationStatus: string = 'Open';
  PaymentStatus: string = 'Open';
  chargeBtn: boolean = false;
  stepperData = [];
  ChargeValidation: string;
  InvoiceStatus: string;
  InvoiceDOAStatus;
  LiabilityStatus;
  StreetManagerStatus;
  SAPStatus: string;

  DATA_HELPER = DataHelper;
  PERMIT_ACTIONS_CONFIG = PERMIT_ACTIONS_CONFIG;

  stepperCostDataModel: StepperCostDataModel;
  stepperCostConfigModel: StepperCostConfigModel;

  assessmentDecision = new AssessmentDecision();
  showIcon: boolean = false;
  showPermitBtn: boolean = false;

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _validationService: ValidationService,
    private _appRepoService: AppRepoService,
    public dialog: MatDialog
  ) {
    super(_serverApi, _validationService);

    this.stepperCostDataModel = StepperCostDataModel.getInstance();
    this.stepperCostConfigModel = StepperCostConfigModel.getInstance();
  }

  ngOnInit(): void {
    super.wgOnInit();
    this.bindStepperCostDataModel();
    console.log(this.dataModel.showButtons);
  }
  ngAfterViewInit(): void {
    // if (
    //   this._appRepoService.appRepo.appProductCode ==
    //   PRODUCT_SERVICES.COST_AND_PENALTY_MANAGER_PRODUCT_CODE
    // ) {
    // }
  }

  bindStepperCostDataModel() {
    this.stepperCostDataModel.StepperCostfilterModel = [
      {
        code: 'TASK001',
        color: '#3D6EB5',
        count: '110',
        cost: 0,
        countpercentage: '19.5122',
        countvisible: '0',
        display: '0',
        displayheadericon: 'false',
        iconname: 'timer',
        name: 'Estimated Charge',
        producttypename: 'Adhoc Quotation',
        tasktypesequence: 1,
        tasktypeuid: 9050000000,
        isValueVisible: 'true',
        isPercentageVisible: 'false',
      },
      {
        code: 'TASK001',
        color: '#FFC107',
        count: '150',
        cost: 0,
        countpercentage: '19.5122',
        countvisible: '0',
        display: '0',
        displayheadericon: 'false',
        iconname: 'timer',
        name: 'Draft Invoice Charge',
        producttypename: 'Adhoc Quotation',
        tasktypesequence: 2,
        tasktypeuid: 9050000000,
        isValueVisible: 'true',
        isPercentageVisible: 'false',
      },
      {
        code: 'TASK001',
        color: '#DCDCDC',
        count: '80',
        cost: 0,
        countpercentage: '19.5122',
        countvisible: '0',
        display: '0',
        displayheadericon: 'false',
        iconname: 'timer',
        name: 'Agreed Charge',
        producttypename: 'Adhoc Quotation',
        tasktypesequence: 3,
        tasktypeuid: 9050000000,
        isValueVisible: 'true',
        isPercentageVisible: 'false',
      },
      {
        code: 'TASK001',
        color: '#4CAF50',
        count: '50',
        cost: 0,
        countpercentage: '19.5122',
        countvisible: '0',
        display: '0',
        displayheadericon: 'false',
        iconname: 'timer',
        name: 'Paid Charge',
        producttypename: 'Adhoc Quotation',
        tasktypesequence: 3,
        tasktypeuid: 9050000000,
        isValueVisible: 'true',
        isPercentageVisible: 'false',
      },
    ];
  }

  setFieldData() {}

  setMode(responseDataModel: any) {}
  setValue(responseDataModel: any) {}
  getControlData() {
    // let id = this.dataModel.globalParameters.get('applicationId');
    // // INFO: ServerAPI
    // let url = `/api/v1/applications/${id}`
    // return this.wgAPIMethodGet(url, null);
    return new Promise((resolve, reject) => {
      let permitform: any = this.dataModel.globalParameters.get(
        GLOBAL_PARAM_KEY.VIEW_PERMIT_FORM
      );
      permitform = permitform ? permitform : new Object();
      if (permitform) {
        resolve(permitform);
      } else {
        reject(permitform);
      }
    });
  }

  convertData(response: any) {
    if (!response) return;
    console.log('response of key deatils', response);
    this.dataModel.chargesButtonModel = [];
    if (response.left_panel_actions_list ==undefined || response.left_panel_actions_list ==null  || response.right_panel_actions_list.length == 0) {
      this.chargeBtn = false;
    } else {
      this.chargeBtn = true;
      response.right_panel_actions_list.forEach((btn) => {
        if (btn == 'APPROVE_CHARGE') {
          this.dataModel.chargesButtonModel.push({
            event: 'onApproveChargeClick',
            label: 'Approve Invoice',
          });
        }
        if (btn == 'REQUEST_APPROVAL') {
          this.dataModel.chargesButtonModel.push({
            event: 'onRequestApprovalClick',
            label: 'Request Approval',
          });
        }
        if (btn == 'UPDATE_CHARGE_STATUS') {
          this.dataModel.chargesButtonModel.push({
            event: 'onUpdateChargeClick',
            label: 'Update Charge',
          });
        }
        if (btn == 'AUDIT_CHARGE') {
          this.dataModel.chargesButtonModel.push({
            event: 'onAuditChargeClick',
            label: 'Audit Charge',
          });
        }
        if (btn == 'SEND_TO_SAP') {
          this.dataModel.chargesButtonModel.push({
            event: 'onSendToSapClick',
            label: 'Send To SAP',
          });
        }
        if (btn == 'VIEW_PERMIT') {
          this.showPermitBtn = true;
        }
      });
    }

    this.dataModel.data.promoterPrefix = response.promoter_prefix;

    this.dataModel.data.applicationId = response.application_id;
    this.dataModel.data.is_admin = response.is_admin;
    this.dataModel.data.charge_type = response.charge_type;

    this.dataModel.data.permitRefNumber = response.permit_reference_number;
    this.dataModel.data.worksReferenceNumber = response.work_reference_number;
    this.dataModel.data.projectReferenceNumber =
      response.project_reference_number;
    this.dataModel.data.worksLocationDescription =
      response.location_description;
    this.dataModel.data.isDurationChallenged = response.is_duration_challenged;
    this.ChargeValidation = response.charge_validated;
    this.InvoiceStatus = response.invoice_status;
    this.LiabilityStatus = response.liability_status;
    this.PaymentStatus = response.payment_status;
    this.InvoiceDOAStatus = response.invoice_doa_status;
    this.StreetManagerStatus = response.street_manager_status;
    this.SAPStatus = response.sap_status;
    // not exist in api response
    this.dataModel.data.locationdetails = response.location_details;
    this.stepperCostDataModel.StepperCostfilterModel[0].cost =
      response.charges.estimated_charge;
    this.stepperCostDataModel.StepperCostfilterModel[1].cost =
      response.charges.draft_invoice_charge;
    this.stepperCostDataModel.StepperCostfilterModel[2].cost =
      response.charges.agreed_charges;
    this.stepperCostDataModel.StepperCostfilterModel[3].cost =
      response.charges.paid_charge;
    // not exist in api response
    this.dataModel.data.postCode = response.postcode;
    this.dataModel.data.usrn = response.usrn;
    this.dataModel.data.worksDescription = response.work_description;
    if (response.work_category) {
      this.dataModel.data.worksCategory =
        this._appRepoHelperService.getMDataDisplayTextByValue(
          response.work_category
        );
    }

    this.dataModel.data.highwayAuthority = response.highway_authority_name;

    // this.dataModel.data.roadType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.road_category.toString()
    //   );

    this.dataModel.data.promoterOrganisation = response.promoter_organisation;

    this.dataModel.data.contact =
      response.secondary_contact + ' - ' + response.secondary_contact_number;
    let workstreams: any[] =
      this._appRepoHelperService.getUserAccessibleWorkStream();

    if (workstreams) {
      let workstream: any = workstreams.find(
        (c) => c.workstreamId == response.workstream_id
      );
      this.dataModel.data.workStream = workstream?.description;
    }

    let contractors: any[] =
      this._appRepoHelperService.getUserAccessibleContractors();
    if (contractors) {
      let contractor: any = contractors.find(
        (c) => c.contractor_id == response.contractor_id
      );
      this.dataModel.data.accountability = contractor?.contractor_name;
    }
    // not exist in api response
    // this.dataModel.data.accountability = response.accountability;

    // this.dataModel.data.trafficSensitive = DataHelper.booleanToUserText(
    //   response.traffic_sensitive_flag
    // );
    this.dataModel.data.trafficSensitive = response.traffic_sensitive;

    this.dataModel.data.footwayClosure =
      this._appRepoHelperService.getMDataDisplayTextByValue(
        response.close_footway
      );

    this.dataModel.data.excavationRequired = DataHelper.booleanToUserText(
      response.excavation_flag
    );

    this.dataModel.data.isLaneRentalApplicable = DataHelper.booleanToUserText(
      response.lane_rental_flag
    );

    this.dataModel.data.trafficManagementRequired =
      this._appRepoHelperService.getMDataDisplayTextByValue(
        response.traffic_management_type
      );

    this.dataModel.data.trafficManagementPlan = response.geometry;
    this.dataModel.data.permitStatus = response.permit_status;
    this.dataModel.data.permitType = response.permit_type;
    this.dataModel.data.workStatus = response.work_status_value;
    this.dataModel.data.validityEndDate = response.validity_end_date;
    this.dataModel.data.assesmentStatus =
      response.assessment_status_and_comments;
    this.dataModel.data.reasonablePeriodEndDate =
      response.reasonable_end_date_time;

    this.dataModel.data.actualStartDateTime = response.actual_start_date_time;
    this.dataModel.data.actualEndDateTime = response.actual_end_date_time;

    this.dataModel.data.proposedStartDateTime =
      response.proposed_start_date_time;
    this.dataModel.data.proposedEndDateTime = response.reasonable_end_date_time;

    this.dataModel.data.proposedDuration = response.working_days;
    this.dataModel.data.cost = response.total_cost;

    this.dataModel.data.costRisk = response.cost_risk;
    this.dataModel.data.customerRisk = response.customer_risk;
    this.dataModel.data.trafficManagementRisk =
      response.traffic_management_risk;
    this.dataModel.data.actions = response.actions;

    this.dataModel.data.collaborativeWorkingFlag = DataHelper.booleanToUserText(
      response.collaborative_working_flag
    );
    this.dataModel.data.collaborationDetails = response.collaboration_details;
    this.dataModel.data.collaborativeWorks = response.collaborative_works;
    this.dataModel.data.collaborationType =
      this._appRepoHelperService.getMDataDisplayTextByValue(
        response.collaboration_type
      );
    this.dataModel.data.ttro_requiredFlag = DataHelper.booleanToUserText(
      response.ttro_required_flag
    );
    this.dataModel.data.environmental_flag = DataHelper.booleanToUserText(
      response.environmental_flag
    );
    this.dataModel.data.is_covid19_response = DataHelper.booleanToUserText(
      response.is_covid19_response
    );

    this.dataModel.data.earlyStartPreApprovalFlag =
      DataHelper.booleanToUserText(response.early_start_pre_approval_flag);
    this.dataModel.data.earlyStartReason = response.early_start_reason;
    this.dataModel.data.preApprovalAuthoriser =
      response.pre_approval_authoriser;
    this.dataModel.data.activityType = response.activity_type;

    // this.dataModel.data.activityType =
    //   this._appRepoHelperService.getMDataDisplayTextByValue(
    //     response.activity_type
    //   );
    this.dataModel.data.preApprovalDetails = response.pre_approval_details;
    this.dataModel.data.expectedEndDateTime = response.expected_end_date;
    this.dataModel.data.is_pmr_responded = response.is_pmr_responded;
    if (this.dataModel.data.is_pmr_responded != null) {
      this.showIcon = true;
    }
    this.assessmentDecision = new AssessmentDecision();
    switch (response.assessment_decision?.status) {
      case ASSESSMENT_STATUS.ASSESSMENT_GRANTED_STATUS:
        this.assessmentDecision.status =
          response.assessment_decision.display_status;
        break;
      case ASSESSMENT_STATUS.ASSESSMENT_MODIFICATION_STATUS:
        this.assessmentDecision.status =
          response.assessment_decision.display_status;
        this.assessmentDecision.description =
          response.assessment_decision.modificationRequestDetails;
        break;
      case ASSESSMENT_STATUS.ASSESSMENT_REFUSED_STATUS:
        this.assessmentDecision.status =
          response.assessment_decision.display_status;
        this.assessmentDecision.description =
          response.assessment_decision.refusalDetails;
        this.assessmentDecision.reason =
          response.assessment_decision.reasonForRefusal;
        break;
      default:
        break;
    }
  }

  onCancelPermitBtnClick() {
    let data: any = new Object();
    this.emitEvent('onCancelPermit', data);
    // this.dialog.open(CancelPermitConfirmationComponent);
  }

  hasPermission(actionName: PERMIT_ACTIONS_CONFIG) {
    if (this.dataModel.data.actions && this.dataModel.data.actions.length > 0) {
      return this.dataModel.data.actions.indexOf(actionName.toString()) >= 0;
    }
    return false;
  }

  // onApproveChargeClick() {
  //   let data: any = new Object();
  //   this.emitEvent('onRaiseChangeRequest', data);
  //   // this.emitEvent("onGetAction", data);
  // }

  eventEmmiter(event: string) {
    this.emitEvent(event);
    console.log(event);
  }

  onAuditChargeClick() {
    this.emitEvent('onAuditChargeClick');
  }
  onSendToSapClick() {
    this.emitEvent('onSendToSapClick');
  }

  onApproveChargeClick() {
    this.emitEvent('onApproveChargeClick');
  }

  onRequestApprovalClick() {
    this.emitEvent('onRequestApprovalClick');
  }

  onUpdateChargeClick() {
    this.emitEvent('onUpdateChargeClick');
  }

  onViewPermitClick() {
    this.emitEvent('onViewPermitClick');
  }

  getStatusColor(status) {
    switch (status) {
      case 'Valid':
      case 'Assigned':
      case 'Awaiting Final Invoice':
      case 'Approved Draft':
      case 'Revised Draft Valid':
      case 'DOA Approved':
      case 'Accepted':
      case 'Approved':
      case 'Validated [User]':
      case 'Awaiting Final Invoice (Valid)':
      case 'Validated [CPM]':
      case 'Validated [User] - Awaiting Approval':
      case 'Potential Liability Assigned':
        return 'completed';

      case 'Under Investigation':
      // case 'Not Assigned':
      case 'Assigned For Review':
      case 'Assigned for Review':
      case 'Revised Draft Under Investigation':
      case 'Awaiting DOA Approval':
      case 'Assigned As Liability':
      case 'Awaiting Approval (Valid)':
        case 'Awaiting Revised Draft Invoice':
      case 'Requested':
        return 'in_progress';

      case 'Challenged':
      case 'Disputed':
      case 'Revised Draft Challenged':
      case 'Final Challenged':
      case 'Cancelled':
      case 'Error Sending To SAP':
        return 'cancelled';

      // case 'Open':
      case 'Withdrawn':
      case 'Paid':
      case 'Paid (SAP)':
      case 'Sent To SAP':
      case 'Sent to SAP':
      case 'Ready To Send to SAP':
        // case 'Sent To SAP (Partial)':
      case 'Ready To Send To SAP':
      case 'Written Off':
      case 'Invoice Paid':
      case 'Paid (Not SAP)':
      case 'Paid (IQMS)':
        return 'open';

      case 'Issued':
      // case 'Validated[User]':
      // case 'Validated[CPM]':
      case 'Not Assigned':
      case 'Not Requested':
      case 'Open':
      // case 'Validated [User] - Awaiting Approval':
        return 'grey'

        case 'Sent To SAP (Partial)':
          return 'partial'

      case 'Not open':
        return 'black'

      default:
        return 'grey';
    }
  }
}
