<div class="permit-details">
  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
<!--     <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Draft Invoice Reference Number</label>
      <p>{{ dataModel.data.invoiceRefNumber }}</p>
    </div> -->
    <!-- <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Invoice Reference Number</label>
        <p>{{ dataModel.data.invoiceRefNumber }}</p>
      </div> -->
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Invoice Reference Number</label>
      <p>{{ dataModel.data.chargeNumber }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Permit Reference Number</label>
      <p>{{ dataModel.data.permitRefNumber }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <!-- <label for="" *ngIf="dataModel.data.is_admin">Assigned To</label>
      <p *ngIf="dataModel.data.is_admin">{{ dataModel.data.assignedTo }}</p> -->
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <!-- <label for="" *ngIf="dataModel.data.is_admin">Assigned To</label>
      <p *ngIf="dataModel.data.is_admin">{{ dataModel.data.assignedTo }}</p> -->
    </div>
  </div>
  <hr />
  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Works Location Description</label>
      <p>{{ dataModel.data.worksLocationDescription }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Charge type</label>
      <p>{{ dataModel.data.chargeType }}</p>
    </div>
    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Charge Details</label>
      <p>
          {{ showMoreChargeDetails ? (dataModel.data.chargeDetails | slice : 0 : 35) : dataModel.data.chargeDetails }}
          <span class="more-text" (click)="showMoreChargeText()">{{
  getChargeText()
}}</span>
      </p>
  </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <!-- <label for="" *ngIf="dataModel.data.is_admin">Assigned By</label>
      <p *ngIf="dataModel.data.is_admin">{{ dataModel.data.assignedBy }}</p> -->
    </div>

  </div>
  <hr />
  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Highway Authority</label>
      <p>{{ dataModel.data.highwayAuthority }}</p>
    </div>
    <!-- <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Road Category</label>
      <p>{{ dataModel.data.roadType }}</p>
    </div> -->
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Network</label>
      <p>{{ dataModel.data.network }}</p>
    </div>

    <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <!-- <label for="" *ngIf="dataModel.data.is_admin">Reason Invalid</label>
      <p *ngIf="dataModel.data.is_admin">{{ dataModel.data.reason_invalid }}</p> -->
  </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <!-- <label for="">Liability Organisation</label>
        <p>{{ dataModel.data.liabilityOrganisation }}</p> -->
    </div>
  </div>
  <hr />
  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)" *ngIf="dataModel.data.is_admin">
      <label for="">Liability Organisation</label>
      <p>{{ dataModel.data.liabilityOrganisation }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Liability Assigned By</label>
      <p>{{ dataModel.data.liabAssignedBy }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="" *ngIf="dataModel.data.is_admin">Date of Invoice</label>
      <p *ngIf="dataModel.data.is_admin">{{ dataModel.data.date_of_invoice }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="" *ngIf="dataModel.data.is_admin">Date of Recharge Agreed</label>
      <p *ngIf="dataModel.data.is_admin">{{ dataModel.data.date_of_recharge_agreed }}</p>
    </div>

  </div>
  <hr />
  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start"
    *ngIf="dataModel.data.is_admin">
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Approval Requested By</label>
      <p>{{ dataModel.data.doa_approved_by }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">DOA Approver</label>
      <p>{{ dataModel.data.doa_approved_to }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Approved By</label>
      <p>{{ dataModel.data.approved_by }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Approved on behalf</label>
      <p>{{ dataModel.data.approved_on_behalf }}</p>
    </div>

  </div>
  <hr *ngIf="dataModel.data.is_admin" />
  <div class="detail-container" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start"
    *ngIf="dataModel.data.is_admin">
    <!-- <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Granted Date</label>
        <p>{{ dataModel.data.grantedDate | transformDateTime }}</p>
      </div> -->
      <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">COAM</label>
        <p>{{ dataModel.data.coam }}</p>
      </div>
      <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
        <label for="">Workstream</label>
        <p>{{ dataModel.data.workstream }}</p>
      </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Work Type</label>
      <p>{{ dataModel.data.workType }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Spend Type</label>
      <p>{{ dataModel.data.spendType }}</p>
    </div>
    <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">Cost Code</label>
      <p>{{ dataModel.data.costCode }}</p>
    </div>
  </div>
  <hr *ngIf="dataModel.data.is_admin" />

  <div class="detail-container" *ngIf="dataModel.data.is_admin" fxLayout="row" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start"
  >
  <div class="info-element" fxFlex="33.33" fxFlex.lt-sm="0 1 calc(50% - 32px)">
    <label for="">BACs Reference</label>
   <p> {{    this.dataModel.data.bacs_ref }} </p>
  </div>
   <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <label for="">SAP Payment Date</label>
     <p>{{ dataModel.data.paymentDate | transformDate }}</p>
   </div>
   <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <!-- <label for="">SAP Payment Date</label>
     <p>{{ dataModel.data.paymentDate | transformDate }}</p> -->
   </div>
   <div class="info-element" fxFlex="25" fxFlex.lt-sm="0 1 calc(50% - 32px)">
      <!-- <label for="">SAP Payment Date</label>
     <p>{{ dataModel.data.paymentDate | transformDate }}</p> -->
   </div>
  </div>
  <hr  *ngIf="dataModel.data.is_admin"/>
</div>
