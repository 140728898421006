import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  InvoiceDetailsBarConfigModel,
  InvoiceDetailsBarModel,
} from './invoice-details-bar.model';
import { MatDialog } from '@angular/material/dialog';
import { GLOBAL_PARAM_KEY } from 'src/app/app-constants';
import { AppRepoHelperService } from 'src/app/services/app-repo-helper.service';
import { SaveFileService } from 'src/app/services/save-file.service';
import { ServerApiInterfaceServiceService } from 'src/app/services/server-api-interface-service.service';
import { ValidationService } from 'src/app/services/validation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WidgetComponentBase } from 'src/app/utility/widget/widget-utility/widget-component-base';

@Component({
  selector: 'app-invoice-details-bar',
  templateUrl: './invoice-details-bar.component.html',
  styleUrls: ['./invoice-details-bar.component.css'],
})
export class InvoiceDetailsBarComponent
  extends WidgetComponentBase
  implements OnInit {
  @Input() dataModel: InvoiceDetailsBarModel;
  @Input() configModel: InvoiceDetailsBarConfigModel;

  showSAPVariables: boolean = false;

  constructor(
    private _serverApi: ServerApiInterfaceServiceService,
    private _appRepoHelperService: AppRepoHelperService,
    private _validationService: ValidationService,
    private _saveFileService: SaveFileService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    super(_serverApi, _validationService);
  }

  ngOnInit(): void {
    super.wgOnInit();
  }

  setFieldData() { }

  setMode(responseDataModel: any) { }

  setValue(responseDataModel: any) { }

  getControlData() {
    return new Promise((resolve, reject) => {
      let invoiceForm: any = this.dataModel.globalParameters.get(
        GLOBAL_PARAM_KEY.VIEW_INVOICE_FORM
      );
      invoiceForm = invoiceForm ? invoiceForm : new Object();
      if (invoiceForm) {
        resolve(invoiceForm);
      } else {
        reject(invoiceForm);
      }
    });
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file) {
      const formData = new FormData();

      formData.append('thumbnail', file);

      console.log(file);
    }
  }

  convertData(response: any) {
    if (!response) return;

    if(response.charge_type === 'Permit Charges' || response.charge_type === 'Defect') {
      this.showSAPVariables = true;
    }

    this.dataModel.data.invoiceId = response.invoice_id;
    this.dataModel.data.reference = response.reference;
    this.dataModel.data.finalInvoiceReference = response.final_invoice_reference;
    this.dataModel.data.uploadDate = response.upload_date;
    this.dataModel.data.authorityName = response.authority_name;
    this.dataModel.data.status = response.status;
    this.dataModel.data.statusMessage = response.status_message;
    this.dataModel.data.chargeType = response.charge_type;
    this.dataModel.data.chargesCount = response.charges_count;
    this.dataModel.data.validChargesCount = response.valid_charges_count;
    this.dataModel.data.invalidChargesCount = response.invalid_charges_count;
    this.dataModel.data.periodFrom = response.period_from;
    this.dataModel.data.periodTo = response.period_to;
    this.dataModel.data.responseDueDate = response.response_due_date;
    this.dataModel.data.totalAmount = response.total_amount;
    this.dataModel.data.paidDate = response.paid_date;
    this.dataModel.data.costPaid = response.total_amount;
    this.dataModel.data.approvedCharges = response.approved_cnt;
    this.dataModel.data.sentToSAP = response.sent_to_sap_cnt;
    this.dataModel.data.notSentToSAP = response.not_send_to_sap_cnt;
    this.dataModel.data.costEstimated = 0;
  }

  getInvoiceStatusCssClass(element) {
    switch (element) {
      case 'Valid':
      case 'Assigned':
      case 'Awaiting Final Invoice':
      case 'Approved Draft':
      case 'Revised Draft Valid':
      case 'DOA Approved':
      case 'Accepted':
      case 'Approved':
      case 'Validated [User]':
      case 'Awaiting Final Invoice (Valid)':
      case 'Validated [CPM]':
      case 'Validated [User] - Awaiting Approval':
        return 'completed';

      case 'Under Investigation':
      // case 'Not Assigned':
      case 'Assigned For Review':
      case 'Assigned for Review':
      case 'Revised Draft Under Investigation':
      case 'Awaiting DOA Approval':
      case 'Assigned As Liability':
      case 'Awaiting Approval (Valid)':
        case 'Awaiting Revised Draft Invoice':
      case 'Requested':
        return 'in_progress';

      case 'Challenged':
      case 'Disputed':
      case 'Revised Draft Challenged':
      case 'Final Challenged':
      case 'Cancelled':
      case 'Error Sending To SAP':
        return 'cancelled';

      // case 'Open':
      case 'Withdrawn':
      case 'Paid':
      case 'Paid (SAP)':
      case 'Sent To SAP':
      case 'Sent to SAP':
      case 'Ready To Send to SAP':
      case 'Ready To Send To SAP':
      // case 'Sent To SAP (Partial)':
      case 'Written Off':
        case 'Invoice Paid':
          case 'Paid (Not SAP)':
          case 'Paid (IQMS)':
        return 'open';

      case 'Issued':
      // case 'Validated[User]':
      // case 'Validated[CPM]':
      case 'Not Assigned':
      case 'Not Requested':
      case 'Open':
        // case 'Validated [User] - Awaiting Approval':
        return 'grey'

        case 'Sent To SAP (Partial)':
          return 'partial'

      case 'Not open':
        return 'black'

      default:
        return 'grey';
    }
  }

  isChargeTypeManual() {
    return !this.dataModel.data.reference?.endsWith('-M')
  }

  refreshPage() {
    this.emitEvent('refreshPageData')
  }
}
