<div class="cancel-alert-container" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="center center" class="cancel-dialog-row1">
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-icon class="ic-cancel">delete_forever</mat-icon>
      <h3 class="cancel-heading">Do you wish to cancel the permit?</h3>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" class="cancel-dialog-row2">
    <form *ngIf="wgFormGroup" [formGroup]="wgFormGroup" fxLayout="column">
      <p class="cancel-dialog-msg">Are you sure you want to cancel? If you cancel, you wont be able to reverse this
        action!</p>
      <mat-form-field appearance="outline">
        <mat-label>Cancellation Reason</mat-label>
        <mat-select required formControlName="cancellationReason">
          <mat-option *ngFor="let cancellationReason of arrCancellationReason" [value]="cancellationReason.value">
            {{cancellationReason.displayText}}</mat-option>
        </mat-select>
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Comment</mat-label>
        <textarea matInput required formControlName="comment" placeholder="Enter comment"></textarea>
        <mat-error>Field must be valid</mat-error>
      </mat-form-field>
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
        <button class="btn-action" color="primary" mat-stroked-button cdkFocusInitial
          (click)="onActionBtnClick(false)">No</button>
        <button class="primary-button btn-action" color="primary" mat-raised-button
          (click)="onActionBtnClick(true)">Yes,
          Cancel It!</button>
      </div>
    </form>
  </div>
</div>
